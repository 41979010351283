import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { getServiceProviderActivePackage, accessRoleList } from "../api";
import localstorageData from "./localstorageData";
import zustandStore from "./zustandStore";
import { getCreatedBy } from "./commonHelper";

export default function CheckRouteAccess() {
  const user = localstorageData();
  const createdBy = getCreatedBy();
  const { resetFields, setField, menuList } = zustandStore();

  const getServiceProviderActivePackageData = () => {
    axios
      .get(
        `${getServiceProviderActivePackage}?id=${user._id}&userTypeId=${user.userTypeId}&role=${user.role}&child=${user?.createdByUserType}`
      )
      .then(function (res) {
        if (res) {
          const out = [];

          res.data.data.map((item) => {
            if (item?.isSubMenu === false) {
              out.push(item?.mainMenu?.url);
            } else {
              item?.subMenu?.map((sub) => {
                out.push(sub.url);
              });
            }
          });

          setField("menuList", out);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getServiceProviderRouteAccess = () => {
    axios
      .get(
        `${accessRoleList}?roleId=${user.userTypeId}&createdBy=${
          user.createdBy
        }&role=${user.role}&userId=${user._id}&userType=${
          user?.createdByUserType
        }&child=${user?.createdByUserType}&isABNHolderHc=${
          user?.isSoleTrader
        }&isABNHolder=${user?.isSoleTrader}&evlClient=${
          user?.packageMenuId == false && user?.role == "client"
            ? "true"
            : "false"
        }`
      )
      .then(function (res) {
        if (res) {
          setField("menuList", [...menuList, ...res?.data?.data]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (user?.createdByUserType === "superAdmin") {
      if (
        user.role === "serviceProvider" ||
        user.role === "healthCarer" ||
        user.role === "supportCoordinatr" ||
        user.role === "client"
      ) {
        getServiceProviderActivePackageData();
      } else {
        getServiceProviderRouteAccess();
      }
    } else {
      if (user) {
        getServiceProviderRouteAccess();
      }
    }
  }, []);
}
