import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../Web-Header";
import WebFooter from "../Web-footer";
import FeatureTitleBanner from "../../assets/images/feature-1banner.jpg";
import { Link } from "react-router-dom";
import "../../assets/css/dashboard.css";
import "../../assets/css/web-css.css";
import "../HomeUI/home-ui.css";
import healthImage1 from "../../assets/images/healthImage1.png";
import healthImage2 from "../../assets/images/healthImage2.png";
import healthImage3 from "../../assets/images/healthImage3.png";
import healthImage4 from "../../assets/images/healthImage4.png";
import healthImage5 from "../../assets/images/healthImage5.png";
import healthIcon1 from "../../assets/images/healthIcon1.svg";
import healthIcon2 from "../../assets/images/healthIcon2.svg";
import healthIcon3 from "../../assets/images/healthIcon3.svg";
import healthIcon4 from "../../assets/images/healthIcon4.svg";
import healthIcon5 from "../../assets/images/healthIcon5.svg";
import healthIcon6 from "../../assets/images/healthIcon6.svg";
import healthIcon7 from "../../assets/images/healthIcon7.svg";
import healthIcon8 from "../../assets/images/healthIcon8.svg";
import healthIcon9 from "../../assets/images/healthIcon9.svg";
import healthIcon10 from "../../assets/images/healthIcon10.svg";
import healthIcon11 from "../../assets/images/healthIcon11.svg";

import QuestionAccordion from "../HomeUI/Question-Accordian";
import BookDemo from "../HomeUI/BookDemo";

const HealthDashboard = () => {
  return (
    <Box id="features">
      <Box className="web-topBanner-bg  feature-main-banner">
        <WebHeader />

        <Box className="feature-titleBox">
          <Box className="webContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} className="home-content-main ">
                <Box>
                  <h1>
                    Health Dashboard <br />
                    {/* Title */}
                  </h1>
                  <Typography>
                    Revolutionizing health management with a user-friendly
                    interface. Easily store, access vital information, and track
                    health proactively.
                  </Typography>

                  <Box className="d-flex align-items-center">
                    <Link
                      to="/book-demo"
                      className="home-section-button web-primary-btnLarge cta1"
                    >
                      Book A Demo
                    </Link>
                    {/* <Link className="home-section-button cta2">CTA 2</Link> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="manageCarer-cardImg manageCarer-obj">
                  <img src={healthImage1} />
                </Box>

                <Box className="blurBg blurRed"> </Box>
                {/* <Box className="blurBg blurPurple"> </Box> */}
                <Box className="blurBg blurYellow"></Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box className="webContainer">
        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={healthImage2} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
          >
            <Box className="manageCarer-cardContent pLeft-80">
              <h6>Secure Health Information Storage  </h6>
              <h3>Safeguard Your Health Data</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  In today’s digital world, the security of your personal health
                  information is paramount. Hubshift Health Dashboard ensures
                  your data is protected with advanced encryption technologies,
                  giving you peace of mind that your sensitive health records
                  are stored securely and privately.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon1} />
                      </Box>
                      <Box>
                        <Typography>Secure Storage </Typography>
                        <span>
                          Protective strategies to safeguard user data.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon2} />
                      </Box>
                      <Box>
                        <Typography>Peace of Mind Guarantee</Typography>
                        <span>
                          Be assured regarding the security and privacy of
                          stored information.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={purpleIcon} />
                      </Box>
                      <Box>
                        <Typography>Secure Document Storage</Typography>
                        <span>
                          Ensures documents are stored safely and securely.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={blueIcon} />
                      </Box>
                      <Box>
                        <Typography>Simplified Identity Management</Typography>
                        <span>
                          Streamlines the management and organization of
                          identity-related documents.
                        </span>
                      </Box>
                    </Box>
                  </Grid> */}
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>All Your Health Data in One Place</h6>
              <h3>Simplify Your Health Management</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Our personal health dashboard offers a unified platform to
                  store all your health-related information. From medical
                  records and appointment histories to fitness data and wellness
                  goals, everything is organized and easily accessible.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon3} />
                      </Box>
                      <Box>
                        <Typography>Unified Health Data Platform</Typography>
                        <span>
                          Brings together all health-related information in one
                          place for easy access.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon4} />
                      </Box>
                      <Box>
                        <Typography>Personal Health Dashboard</Typography>
                        <span>
                          A dedicated interface for managing and viewing
                          personal health data.
                        </span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon5} />
                      </Box>
                      <Box>
                        <Typography>Informed Health Care Decisions</Typography>
                        <span>
                          Make educated choices regarding health care based on
                          consolidated information.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon6} />
                      </Box>
                      <Box>
                        <Typography>Wellness Goal Tracking</Typography>
                        <span>
                          Monitor progress towards set wellness and health
                          goals.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg">
              <img src={healthImage3} />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={healthImage4} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
          >
            <Box className="manageCarer-cardContent pLeft-80">
              <h6>Proactive Health Tracking</h6>
              <h3>Stay Ahead of Your Health</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Our platform provides the tools you need to stay motivated and
                  engaged with your health, encouraging a proactive rather than
                  reactive approach to wellness.
                </Typography>

                <Grid container sx={{ mt: 2 }} className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon7} />
                      </Box>
                      <Box>
                        <Typography>All-in-One Dashboard</Typography>
                        <span>
                          Consolidates various health tracking features into a
                          single platform
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon8} />
                      </Box>
                      <Box>
                        <Typography>Progress Report Tracking</Typography>
                        <span>
                          Monitors and displays progress over different time
                          frames.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon9} />
                      </Box>
                      <Box>
                        <Typography>
                          Track progress Weekly, Fortnightly, Monthly, Up to 12
                          Months{" "}
                        </Typography>
                        <span>
                          Offers flexible tracking intervals to suit individual
                          preferences and needs.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>Personalized Health Insights</h6>
              <h3>Gain Insights Tailored to You</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Beyond just storing and tracking your health data, Hubshift
                  Health Dashboard analyses your information to provide
                  personalized health insights. These insights can help guide
                  your health decisions, making your journey towards wellness
                  more informed and focused.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon10} />
                      </Box>
                      <Box>
                        <Typography>Tailored Health Analysis</Typography>
                        <span>
                          Get specific and relevant evaluations of personal
                          health information.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={healthIcon11} />
                      </Box>
                      <Box>
                        <Typography>Focused Health Journey</Typography>
                        <span>
                          Direct efforts towards specific health goals and
                          objectives.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={healthImage5} />
            </Box>
          </Grid>
        </Grid>

        {/* <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>Streamlined Communication  </h6>
              <h3>Keep Everyone in the Loop</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  With automatic notifications, your staff will be promptly
                  informed of their schedules, ensuring everyone is on the same
                  page.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={orangeIcon} />
                      </Box>
                      <Box>
                        <Typography>Enhanced Staff Awareness</Typography>
                        <span>
                          Keeps all team members well-informed and aligned.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={yellowIcon} />
                      </Box>
                      <Box>
                        <Typography>Reduced Confusion</Typography>
                        <span>
                          Minimizes misunderstandings and uncertainties.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={purpleIcon} />
                      </Box>
                      <Box>
                        <Typography>Improved Staff Satisfaction</Typography>
                        <span>
                          Boosts overall staff morale and job satisfaction.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={blueIcon} />
                      </Box>
                      <Box>
                        <Typography>Cohesive Team Environment</Typography>
                        <span>
                          Strengthens team cohesion and mutual understanding.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Link to="#" className="home-primary-button">
                Learn More
              </Link> 
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={featureTitle4} />
            </Box>
          </Grid>
        </Grid> */}
      </Box>

      <Box className="question-accordian web-topBanner-bg space-lg">
        <Box className="webContainer">
          <Box className="text-center">
            <h1>Questions? We've got you covered.</h1>
          </Box>
          <Box className="space-pt-60">
            <QuestionAccordion />
          </Box>
        </Box>
      </Box>

      <Box className="space-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
};

export default HealthDashboard;
