import { Route, Routes } from "react-router-dom";
import axios from "../api/axios";
import { useState, useEffect, lazy, Suspense } from "react";
import localstorageData from "../helpers/localstorageData";
//404 page
import Error from "./../components/Error";
import OtherRole from "./OtherRole";
import Loader from "../../../frontend/src/pages/loader/Loader";
import { AllCommanRoutes } from "./AllCommanRoutes";
import { MenuAccessList, listAllMenuMaster, accessRoleList } from "../api";
import { getCreatedBy } from "../helpers/commonHelper";
export default function SuperAdmin() {
  const [routes, setRoutes] = useState([]);
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  // const createdBy = getCreatedBy();
  let userData = JSON.parse(localStorage.getItem("userData"));
  console.log("userData", userData);
  const [menuList, setMenuList] = useState([]);
  const [loading, setLoading] = useState(true); // Loader state
  const user = localstorageData();
  useEffect(() => {
    // fetchRoutes();
    // GetUserRouteAccessList();
    getClientRouteAccess();
  }, []);
  // const fetchRoutes = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${listAllMenuMaster}?role=${user?.role}&createdBy=641062840423e7c1cfec8117&page=1&items_per_page=100`
  //     );
  //     const { data } = response.data;
  //     const filteredRoutes = data.docs.filter(
  //       (item) => item.role === "644015ef07e18998a3421420"
  //     );
  //     const formattedRoutes = filteredRoutes.flatMap((item) => {
  //       if (item.isSubMenu) {
  //         return item.subMenu.map((subItem) => ({
  //           //subMenuUrl: "/accountant/list-leave-management"
  //           // path: subItem.subMenuUrl,
  //           path: subItem.subMenuUrl.substring(
  //             subItem.subMenuUrl.lastIndexOf("/")
  //           ),
  //           //"subMenuPath": "../pages/superAdmin/leaveManagement/list-tab"
  //           componentPath: subItem.subMenuPath,
  //         }));
  //       } else {
  //         return [
  //           {
  //             //mainMenuUrl:"/accountant/list-leave-management"
  //             path: item.mainMenuUrl.substring(
  //               item.mainMenuUrl.lastIndexOf("/")
  //             ),
  //             // path:item.mainMenuUrl,

  //             // "mainMenuPath": "../pages/superAdmin/leaveManagement/list-tab"
  //             componentPath: item.mainMenuPath,
  //           },
  //         ];
  //       }
  //     });

  //     setRoutes(formattedRoutes);
  //     const allowedRoutes = AllCommanRoutes.filter((route) => {
  //       return formattedRoutes.some(
  //         (formattedRoute) => formattedRoute.path === route.path
  //       );
  //     });
  //     setAllowedRoutes(allowedRoutes);
  //     console.log("AllCommanRoutes---------", AllCommanRoutes);
  //     console.log("formattedRoutes---------", formattedRoutes);

  //     console.log("allowedRoutes---------", allowedRoutes);
  //   } catch (error) {
  //     console.error("Error fetching routes:", error);
  //   }
  // };
  // const GetUserRouteAccessList = () => {
  //   axios
  //     .get(
  //       `${MenuAccessList}?roleId=${user.userTypeId}&createdBy=${userData?.createdBy}&role=${user.role}&userId=${user._id}&userType=${user?.createdByUserType}`
  //     )
  //     .then(function (res) {
  //       if (res) {
  //         const formattedRoutes = res.data.data.flatMap((item) => {
  //           if (item.isSubMenu) {
  //             return item.subMenu.map((subItem) => ({
  //               //subMenuUrl: "/accountant/list-leave-management"
  //               // path: subItem.subMenuUrl,
  //               path: subItem.subMenuUrl.substring(
  //                 subItem.subMenuUrl.lastIndexOf("/")
  //               ),
  //               //"subMenuPath": "../pages/superAdmin/leaveManagement/list-tab"
  //               componentPath: subItem.subMenuPath,
  //             }));
  //           } else {
  //             return [
  //               {
  //                 //mainMenuUrl:"/accountant/list-leave-management"
  //                 path: item.mainMenuUrl.substring(
  //                   item.mainMenuUrl.lastIndexOf("/")
  //                 ),
  //                 // path:item.mainMenuUrl,

  //                 // "mainMenuPath": "../pages/superAdmin/leaveManagement/list-tab"
  //                 componentPath: item.mainMenuPath,
  //               },
  //             ];
  //           }
  //         });

  //         setRoutes(formattedRoutes);
  //         const allowedRoutes = AllCommanRoutes.filter((route) => {
  //           return formattedRoutes.some(
  //             (formattedRoute) => formattedRoute.path === route.path
  //           );
  //         });
  //         setAllowedRoutes(allowedRoutes);
  //         console.log("allowedRoutes---------", allowedRoutes);
  //         // setRoutes((prevData) => [...prevData, ...res.data.data]);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const getClientRouteAccess = () => {
    axios
      .get(
        `${accessRoleList}?roleId=${user.userTypeId}&createdBy=${user.createdBy}&role=${user.role}&userId=${user._id}&userType=${user?.createdByUserType}&child=${user?.createdByUserType}&isABNHolderHc=${user?.isSoleTrader}&isABNHolder=${user?.isSoleTrader}&bookAdemo=${user?.isBookDemoUser}&isSc=${user?.isSoleTrader}&evlClient=${(user?.packageMenuId == false && user?.role == "client") ? "true" : "false"}`
      )
      .then(function (res) {
        if (res.data.data.length > 0) {
          const formattedRoutes = res.data.data.flatMap((item) => ({
            path: item?.substring(item.lastIndexOf("/")),
          }));
          setRoutes((prevData) => [...prevData, ...res.data.data]);
          const allowedRoutes = AllCommanRoutes.filter((route) => {
            return formattedRoutes.some(
              (formattedRoute) => formattedRoute.path === route.path
            );
          });

          const allCommomRoutes = ['/settings', '/myaccount']
          const OtherRoutes = AllCommanRoutes.filter((route) => {
            return allCommomRoutes.some(
              (formattedRoute) => formattedRoute === route.path
            );
          });

          setAllowedRoutes([...allowedRoutes, ...OtherRoutes]);
          setLoading(false);
        } else {
          setMenuList((prevData) => [...prevData, ...[]]);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("allowedRoutesallowedRoutes", allowedRoutes)
  if (loading) {
    return <Loader />; // Display loader while loading
  }
  return (
    <Routes>
      {allowedRoutes.map((route, index) => (
        <Route
          key={index}
          path={"/" + user?.role + route.path}
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <route.component />
            </Suspense>
          }
        />
      ))}
      <Route path="*" element={<OtherRole />} />
    </Routes>
  );
}
