import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Vector from "../../assets/images/help-vector.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LinkIcon from "../../assets/images/link-icon.png";
import { getAllHelpManagementPopup } from "../../api";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useState, useEffect } from "react";
import HelpManagementContent from "./Help-Model-Content";
import axios from "axios";

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const [list, setList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState(null);
  const [loading, setLoading] = useState(false);

  const searchItems = (searchValue) => {
    setSearchTerm(searchValue);
  };

  const listExportAll = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${getAllHelpManagementPopup}?searchTerm=${searchTerm}`
      );
      setList(res?.data?.data);
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listExportAll();
  }, [searchTerm]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          width: "443px",
          margin: "32px",
          position: "absolute",
          right: "0",
        },
      }}
      BackdropProps={{
        style: {
          background: "none",
        },
      }}
    >
      <List>
        <div
          style={{
            background: "#15A07D",
            marginTop: "-8px",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <DialogTitle style={{ padding: "7px 21px 0px" }}>
            <div className="d-flex justify-content-between align-items-center">
              <Typography variant="h6" className="Text-white">
                Help
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                className="Text-white"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <ListItem>
            <TextField
              className="helpsearch"
              type="search"
              size="small"
              autoComplete="off"
              placeholder="Search Here"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={searchTerm}
              onChange={(e) => searchItems(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ListItem>
        </div>
        {list.map((item) => (
          <ListItem key={item?._id} className="p-0">
            <Accordion
              expanded={expanded === item?._id}
              onChange={handleChange(item?._id)}
              className="w-100 p-2 help-heading acc-border"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="help-heading"
              >
                <div className="mr-2">
                  <img src={Vector} alt="icon" />
                </div>
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <div>{item?.title}</div>
                  <div className="link-icon-container">
                    <a
                      href={`/help-center-details/?id=${item?._id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={LinkIcon} alt="link" className="link-icon" />
                    </a>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {expanded === item?._id && (
                  <HelpManagementContent
                    props={{ helpModelId: item?._id, title: item?.title }}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <HelpOutlineOutlinedIcon />
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
