import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "./clientprofile.css";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import view from "../../../assets/images/view.svg";
import downloadicon from "../../../assets/images/download-icon.svg";
import { useEffect } from "react";
import axios from "axios";
import { getClientdetails } from "../../../api";
import ClientDetails from "../../components/clientProfile/ClientDetails";
import ClientDetailsCard from "../../../components/client-card/ClientDetails";
import Activepopover from "../../superAdmin/Active-Inactive/Active";
import Inactivepopover from "../../superAdmin/Active-Inactive/Inactive";
import generateUrl from "../../../helpers/urlHelper";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function createData(name, calories, document, status) {
  return { name, calories, document, status };
}

const rows = [
  createData(
    "1",
    "Service Agreement",
    "Client/Document/Service Agreement.pdf",
    "Active"
  ),
  createData(
    "1",
    "NDIA PLAN / RSF",
    "Client/Document/NDIA PLAN / RSF",
    "InActive"
  ),
];
let clientDetailsData = {};
export default function ClientProfile(props) {
  const [client, setClient] = useState({});
  const [clientDetail, setClientDetail] = useState([]);
  const location1 = useLocation();
  const editId = location1.state;
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Client Profile</Typography>
    </Link>,
  ];

  const [value, setValue] = React.useState();

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [showResults, setShowResults] = React.useState(false);
  const [isActive, setIsActive] = useState(false);
  let userData = JSON.parse(localStorage.getItem("userData"));
  const urlLink = generateUrl();

  let sessionId = userData?._id;

  function toogleShow() {
    setShowResults(!showResults);
    setIsActive((current) => !current);
  }
  const clientId = localStorage.getItem("clientId");
  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const user = JSON.parse(userData);

    axios

      .get(`${getClientdetails}/${clientId}`)
      .then((res) => {
        clientDetailsData = res?.data?.data;
        if (clientDetailsData !== undefined) {
          setClientDetail(clientDetailsData);
        }
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
        console.log(error);
      });
  }, []);

  const [isActive2, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive2);
  };

  const [openAttach, setOpenAttach] = React.useState(false);

  const handleClickOpenAttach = () => {
    setOpenAttach(true);
  };

  const handleCloseAttach = () => {
    setOpenAttach(false);
  };

  return (
    <Box className="main">
      <Box
        className="dashboard-form-typo "
        sx={{ borderBottom: "none" }}
      >
        <Grid container className="d-flex header-topsection">
          <Grid item xs={6} sm={6} md={6}>
            <Box id="breadcrumb">
            <Link to={"/" + urlLink + "/home"}>
                {" "}
                <ArrowBackIosIcon />{" "}
              </Link>{" "}
              <Box className="breadcrumb-inr">
                <h4 className="d-flex align-items-center "> Client Profile</h4>
                <Box className="breadcrumbs">
                  <span className="breadcrumbs-link breadcrumbs-link-active">
                    Client Profile
                  </span>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="hide-client-details main-padding" id="profileclient">
        <Grid
          container
          spacing={4}
          className="align-items-start add-appointment-input-box"
        >
          <Grid item xs={12} sm={12} md={12} className="profile-box d-flex" style={{marginTop:"27px"}}>
            Client Details
          </Grid>
        </Grid>
        <ClientDetailsCard userPrimaryId={clientId} />
      </Box>

      {/* <Grid container spacing={4} className="align-items-start table-search">
        <Grid item xs={12} md={12}>
          <div>
            <TextField
              size="small"
              placeholder="Search by Name "
              id="outlined-start-adornment"
              className="search-appointment"
              sx={{ m: 1, width: "90%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {" "}
                    <SearchIcon />{" "}
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid> */}

      {/* <TableContainer
        component={Paper}
        id="document-table"
        className="padding-res-10 client-profile-table"
      >
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead id="client-table-head">
            <TableRow>
              <TableCell className="tableborder  table-head-cell">
                Sr. No.
              </TableCell>
              <TableCell className="tableborder table-head-cell">
                Name
              </TableCell>
              <TableCell className="tableborder table-head-cell " align="left">
                Document
              </TableCell>

              <TableCell
                className="tableborder table-head-cell th-width"
                align="left"
              >
                Status{" "}
              </TableCell>

              <TableCell className="tableborder table-head-cell" align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="client-table-body">
            {rows.map((row, index) => (
              <TableRow key={row.name}>
                <TableCell className="text-start tableborder">
                  <a href="#" className="table-link">
                    {row.name}
                  </a>
                </TableCell>

                <TableCell align="left" className="tableborder">
                  {" "}
                  <a href="#" className="table-link table-category-cell">
                    {row.calories}{" "}
                  </a>
                </TableCell>
                <TableCell align="left" className="tableborder">
                  {" "}
                  {row.document}
                </TableCell>
                <TableCell
                  align="left"
                  className="tableborder status-cell-width"
                >
                  {row.isActive === true ? (
                    <Activepopover />
                  ) : (
                    <Inactivepopover />
                  )}
                </TableCell>

                <TableCell align="left" className="tableborder table-editcell">
                  <Box className="table-edit-del">
                    <Box>
                      <a
                        href="javascript:;"
                        id="edit"
                        label="Domain"
                        className="table-edit  justify-content-center  btn"
                        margin="normal"
                        onClick={handleClickOpenAttach}
                      >
                        <img src={view} /> View
                      </a>
                    </Box>
                  </Box>

                  <Dropdown drop="start" autoClose="outside">
                    <Dropdown.Toggle
                      id="dropdown-autoclose-outside"
                      className="action-toggle-button"
                    >
                      <MoreVertIcon
                        className="dot-icon"
                        onClick={toggleClass}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu client-drop-menu">
                      <Dropdown.Item
                        className="table-edit-del "
                        href="#/action-1"
                      >
                        {" "}
                        <a
                          href="#upload"
                          id="domain1"
                          label="Domain"
                          className="table-edit   justify-content-start client-table-btn btn"
                          margin="normal"
                        >
                          <img src={view} /> View
                        </a>
                      </Dropdown.Item>

                      <Dropdown.Item
                        className="table-edit-del"
                        href="#/action-2"
                      >
                        <a
                          href="#upload"
                          id="domain2"
                          label="Domain"
                          className="table-download justify-content-start client-table-btn  btn"
                          margin="normal"
                        >
                          <img src={downloadicon} /> Download
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <Grid container mt={5}></Grid>
    </Box>
  );
}
