import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { Button, Stack, TableCell, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { FieldsListLoading } from "../pages/components/loading/FieldsListLoading";
import { toast } from "react-toastify";
import "../App.css";
import "../assets/css/dashboard.css";
import googlemaps from "../assets/images/google-maps-svg.png";
import Card from "@mui/material/Card";
import noImage from "../assets/images/noImage.png";
import Autocomplete from "@mui/material/Autocomplete";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { useLocation, useNavigate } from "react-router-dom";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import GradeIcon from "@mui/icons-material/Grade";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Request from "../assets/images/request.png";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import "../assets/css/web-css.css";
import mapLocationIcon from "../assets/images/google-maps-svg.png";
import sleepoverImage from "../assets/images/Date & Time.svg";
import downArrow from "../assets/images/down-arrow.png";
import Filters from "../components/TopFilter/Filters";
import FilterStore from "../components/TopFilter/FilterStore";
import SearchIcon from "@mui/icons-material/Search";
import nodata from "../assets/images/nodata.png";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SILSDA from "../assets/images/SDA.png";
import CommunityAccess from "../assets/images/client-Community Access.png";
import Homecare from "../assets/images/client-Homecare.png";
import STAClient from "../assets/images/client-STA.png";

import {
  getPublicRosterAppointment,
  emailLimit,
  getState,
  setIndvHCinAppt,
  setFavAppointment,
  getHCLikedAppointment,
  getAllFindSupport,
  AustraliaId,
} from "../api";

import { GetCatNameOfCategoryById } from "../helpers/commonHelper";

import RatingClient from "./RatingClient";

export default function WorkerCard(props) {
  const {
    state,
    city,
    maxDistance,
    isCleared,
    searchTerm,
    setField,
    resetFields,
    searchFlag,
    showFavorites,
  } = FilterStore();
  const [statusItem, setStatusItem] = useState(null);

  const pageType = props?.props?.page;

  console.log("pageTypepageTypepageType", pageType);
  //console.log("pageType", pageType);

  // const [chip, setChip] = useState(false);
  const [expanded, setExpanded] = useState(false);
  // const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [count, setCount] = useState("");
  const [pageCount, setpageCount] = useState(0);
  const [currentPageVal, setCurrentPageVal] = useState(1);
  const [stateListData, setStateListData] = useState([]);
  const [favourite, setFavourite] = useState([]);
  const [requestSent, setRequestSent] = useState([]);
  const [sendRequestData, setSendRequestData] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [listClientFindSupport, setListClientFindSupport] = useState([]);
  const [stateId, setStateId] = useState("");
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const handleFavoritesClick = () => {
    setField("showFavorites", !showFavorites);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = userData?._id;
  let isSoleTrader = userData?.isSoleTrader;
  let role = userData?.role;
  const location = useLocation();

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const searchItems = (searchValue) => {
    setField("searchTerm", searchValue);
  };

  useEffect(() => {
    // Update currentLocation to null when isCleared becomes true
    if (isCleared === true) {
      setCurrentLocation(null);
    }
  }, [isCleared]);

  useEffect(() => {
    getAllFindSupportList();
  }, []);

  const getAllFindSupportList = async () => {
    try {
      let latValue = city?.latitude;
      let lngValue = city?.longitude;
      let cityVal = city?.cityFormat;
      if (!city) {
        latValue = currentLocation?.latitude;
        lngValue = currentLocation?.longitude;
        cityVal = "";
      }
      axios
        .get(
          `${getAllFindSupport}?searchTerm=${encodeURIComponent(
            searchTerm
          )}&page=1&items_per_page=${emailLimit}&lat=${latValue}&lng=${lngValue}&maxDistance=${maxDistance}&cityVal=${cityVal}&showFavorites=${showFavorites}&userId=${userId}`
        )
        .then((res) => {
          // console.log("ressss==>>>.", res)
          setListClientFindSupport(res?.data?.data?.docs);
          let total = res?.data?.data?.totalDocs;
          setpageCount(Math.ceil(total / emailLimit));
          setCount(total);
        })
        .catch((error) => {
          console.log("Error:", error);
        });
    } catch (error) {
      console.error("Error getting user location:", error);
    }
  };

  console.log(
    "listClientFindSupportlistClientFindSupport",
    listClientFindSupport
  );
  const InitialStateList = async (apiUrl) => {
    let result = [];
    const response = await axios.get(apiUrl);
    if (response?.status == 200 && response?.statusText == "OK") {
      const res = response?.data;
      if (res?.success) {
        result.push({
          label: `Select`,
          id: "",
        });
        res?.data?.map((d) => {
          result.push({
            label: `${d?.StateName}`,
            id: d?._id,
          });
        });
      }
    }

    setStateListData(result);
  };

  useEffect(() => {
    //  getUserLocation();
  }, []);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  };

  useEffect(() => {
    if (AustraliaId) {
      const apiUrl = `${getState}/${AustraliaId}`;
      InitialStateList(apiUrl);
    }
  }, [AustraliaId]);

  const getAllFilterData = () => {
    if (value == 0) {
      getaList();
    } else {
      getAllFindSupportList();
    }
  };

  const getAllFilterDataClear = () => {
    console.log("check city", city);
    console.log("check maxDistance", maxDistance);
    setLoading(true);
    setField("city", "");
    setField("maxDistance", "");
    setField("placeId", "");
    if (resetFields()) {
      getaList1();
    }
  };

  useEffect(() => {
    if (value == 0) {
      getaList();
    } else {
      getAllFindSupportList();
    }
  }, [
    role,
    emailLimit,
    searchTerm,
    // state,
    city,
    // maxDistance,
    isCleared,
    currentLocation,
    showFavorites,
    value,
  ]);

  const getaList = async () => {
    setLoading(true);
    let latValue = city?.latitude;
    let lngValue = city?.longitude;
    let cityVal = city?.cityFormat;
    if (!city) {
      latValue = currentLocation?.latitude;
      lngValue = currentLocation?.longitude;
      cityVal = "";
    }
    try {
      let apiUrl;
      apiUrl = `${getPublicRosterAppointment}?searchTerm=${encodeURIComponent(
        searchTerm
      )}&page=1&items_per_page=${emailLimit}&lat=${latValue}&lng=${lngValue}&maxDistance=${maxDistance}&cityVal=${cityVal}&showFavorites=${showFavorites}&userId=${userId}`;
      const res = await axios.get(apiUrl);
      setListData(res?.data?.data?.docs);
      let total = res?.data?.data?.totalDocs;
      setpageCount(Math.ceil(total / emailLimit));
      setCount(total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getaList1 = async () => {
    setLoading(true);
    try {
      let apiUrl;
      if (!city) {
        apiUrl = `${getPublicRosterAppointment}?page=1&items_per_page=${emailLimit}`;
      } else {
        apiUrl = `${getPublicRosterAppointment}?page=1&items_per_page=${emailLimit}`;
      }

      const res = await axios.get(apiUrl);
      setListData(res?.data?.data?.docs);
      let dataval = res?.data?.data;
      let total = res?.data?.data?.totalDocs;
      setpageCount(Math.ceil(total / emailLimit));
      setCount(total);
      setLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPaginationList = async (currentPage) => {
    setLoading(true);

    let latValue = city?.latitude;
    let lngValue = city?.longitude;
    let cityVal = city?.cityFormat;
    if (!city) {
      latValue = currentLocation?.latitude;
      lngValue = currentLocation?.longitude;
      cityVal = "";
    }
    try {
      let apiUrl;

      if (value == 0) {
        apiUrl = `${getPublicRosterAppointment}?searchTerm=${encodeURIComponent(
          searchTerm
        )}&page=${currentPage}&items_per_page=${emailLimit}&lat=${latValue}&lng=${lngValue}&maxDistance=${maxDistance}&cityVal=${cityVal}&showFavorites=${showFavorites}&userId=${userId}`;
      } else {
        apiUrl = `${getAllFindSupport}?searchTerm=${encodeURIComponent(
          searchTerm
        )}&page=${currentPage}&items_per_page=${emailLimit}&lat=${latValue}&lng=${lngValue}&maxDistance=${maxDistance}&cityVal=${cityVal}&showFavorites=${showFavorites}&userId=${userId}`;
      }

      const res = await axios.get(apiUrl);
      const op = res?.data?.data?.docs;

      return op;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await fetchPaginationList(currentPage);
    setListData(commentsFormServer);
    setListClientFindSupport(commentsFormServer);
    setCurrentPageVal(currentPage);

    setLoading(false);
  };

  const addLikeApptFunction = async (ApptId, hcId, type, types, clientId) => {
    try {
      if (isSoleTrader === true) {
        axios
          .put(
            `${setFavAppointment}/${ApptId}/${hcId}/${type}?types=${types}&clientId=${clientId}`
          )
          .then((res) => {
            toast.success(res.data.message);
            getaList();
            HCLikeDislike();
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
      } else {
        toast.error("Only ABN Holders Can Shortlist");
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  const addRequestFunction = async (ApptId, hcId, type, types, clientId) => {
    try {
      setDisableButton(true);
      if (isSoleTrader === true) {
        axios
          .put(
            `${setIndvHCinAppt}/${ApptId}/${hcId}/${type}/${types}/${clientId}`
          )
          .then((res) => {
            toast.success(res.data.message);
            setTimeout(() => {
              navigate("/health-carer/appointment-connection-list");
            }, 500);
          })
          .catch((error) => {
            setDisableButton(false);
            toast.error("Something went wrong");
          });
      } else {
        toast.error("Only ABN Holders Can Send Request");
      }
    } catch (error) {
      // setDisableButton(false);
      toast.error("Something went wrong");
    }
  };

  const RedirectDirectFunction = async (appointmentId, type) => {
    try {
      console.log("typeeee", type);
      setTimeout(() => {
        navigate("/health-carer-login", {
          state: { ApptId: appointmentId, type: type },
        });
      }, 500);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (userData?._id !== undefined) {
      HCLikeDislike();
    }
  }, []);

  const HCLikeDislike = async () => {
    try {
      axios
        .get(`${getHCLikedAppointment}/${userData?._id}`)
        .then((res) => {
          let listUserTypeList = res?.data?.data;
          setFavourite(listUserTypeList?.likedAppointment);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  //getClientRating

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  console.log("check value", value);
  return (
    <Box className="w-100 worker-card">
      {pageType === "home" ? (
        <>
          <Box>
            <Grid container className="d-flex header-topsection">
              <Grid item xs={6} sm={6} md={6}>
                <Box id="breadcrumb" className="p-0">
                  <Box className="breadcrumb-inr">
                    <Box className="breadcrumbs">
                      <span className="breadcrumbs-link">Marketplace</span>{" "}
                      <span className="breadcrumbs-icon">{">"}</span>
                      <Link className="breadcrumbs-link breadcrumbs-link-active">
                        Pick Up Shifts
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        ""
      )}

      {searchFlag && (
        <>
          {city !== undefined && city !== null && city !== "" ? (
            <Box style={{ paddingLeft: "2%" }}>
              <Typography className="worker-cards-main-heading">
                {`${count} ${"Records"} found in ${city?.description}`}
              </Typography>
            </Box>
          ) : null}
        </>
      )}
      <Filters
        getAllFilterData={getAllFilterData}
        getAllFilterDataClear={getAllFilterDataClear}
      />
      <Box sx={{ width: "100%" }}>
        <Box className="view-tap-box connection-tab-padding">
          <Tabs
            className="tab-container border-none"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            allowScrollButtonsMobile
          >
            <Tab
              className="view-tab view-tab-request"
              label={
                <>
                  <Typography
                    component="div"
                    style={{ textTransform: "none" }}
                    className="tab-text"
                  >
                    Pickup shifts
                  </Typography>
                </>
              }
              {...a11yProps(0)}
            />
            <Tab
              className="view-tab view-tab-request"
              label={
                <>
                  <Typography
                    component="div"
                    style={{ textTransform: "none" }}
                    className="tab-text"
                  >
                    Pickup clients
                  </Typography>
                </>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        {/* </Box> */}

        <TabPanel value={value} index={0}>
          <Grid container spacing={2} className="d-flex align-items-center">
            <Grid item md={8.5} sm={12} xs={12}>
              <Box
                className="get-support-filter"
                sx={{ mt: 2, ml: 0.5 }}
                style={{ paddingLeft: "1%" }}
              >
                {/* <Filters
                  getAllFilterData={getAllFilterData}
                  getAllFilterDataClear={getAllFilterDataClear}
                /> */}
              </Box>
            </Grid>

            {listData?.length !== 0 ? (
              <Grid item md={2.5} sm={12} xs={12}>
                <Box className="export-market-filters agency-searchbar  searchbox-margin-new ">
                  <TextField
                    className="search-appointment"
                    type="search"
                    size="small"
                    //name="email"
                    fullWidth
                    placeholder="Search Here"
                    value={searchTerm}
                    onChange={(e) => searchItems(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )}

            {listData?.length !== 0 && userData ? (
              <>
                <Grid item md={1} sm={12} xs={12}>
                  <Box className="addTask-btn">
                    <Box className="d-flex align-items-center position-relative justify-content-end">
                      <Box className="hide-content explore-tag">
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ mt: 4 }}
                          color={"#645DD7"}
                          onClick={handleFavoritesClick}
                          style={{
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                            marginRight: "-5px",
                          }}
                        >
                          {showFavorites ? "Show All" : "My Favorites"}
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>

          <Grid container spacing={2} mt={5}>
            <Grid
              container
              spacing={2}
              className="explore-market-cards padding-right-20 padding-left-20 "
            >
              {listData?.length !== 0 ? (
                listData?.map((item, index) => {
                  let pendingCount = 0; // Initialize pending count for each item
                  item?.requestedIndHCBlock?.forEach((appointment) => {
                    if (appointment.hcIsAppointed === "Pending") {
                      pendingCount++;
                    }
                  });

                  return (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={index}
                        className="input pt-0 mt-4"
                      >
                        <Card
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor:
                              item?.status != "Pending"
                                ? "rgb(220 220 220 / 60%)"
                                : "",
                          }}
                          className="worker-card-main"
                        >
                          <CardHeader
                            className="woker-card-header"
                            title={
                              <>
                                <Box className="d-flex align-items-start justify-content-between">
                                  <Stack direction="column">
                                    {item?.CreatedByuserTypeName !==
                                    "serviceProvider" ? (
                                      <>
                                        <Typography
                                          component="div"
                                          className="worker-card-name"
                                          title={
                                            item?.userFullName !== undefined
                                              ? `${
                                                  item?.title
                                                    ? item?.title
                                                    : item?.otherTitle
                                                } ${item?.userFullName}`
                                              : "NA"
                                          }
                                        >
                                          {item?.userFullName !== undefined
                                            ? `${
                                                item?.title
                                                  ? item?.title
                                                  : item?.otherTitle
                                              } ${item?.userFullName}`
                                            : "NA"}
                                        </Typography>
                                        <Tooltip title="Rating">
                                          <Box>
                                            <RatingClient
                                              props={{
                                                clientId: item?.clientId,
                                              }}
                                            />
                                          </Box>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          component="div"
                                          className="worker-card-name"
                                          title={
                                            item?.serviceProviderCompanyname !==
                                            undefined
                                              ? `${item?.serviceProviderCompanyname}`
                                              : "NA"
                                          }
                                        >
                                          {item?.serviceProviderCompanyname !==
                                          undefined
                                            ? `${item?.serviceProviderCompanyname}`
                                            : "NA"}
                                        </Typography>
                                        <Box className="worker-card-rating">
                                          <p>Service Provider</p>
                                        </Box>
                                      </>
                                    )}
                                  </Stack>

                                  <Stack direction="row">
                                    <Tooltip title="Location">
                                      <img
                                        src={mapLocationIcon}
                                        className="worker-map-icon"
                                      />
                                    </Tooltip>

                                    {(() => {
                                      // let AddressVar;
                                      // switch (item?.ValcategoriesName) {
                                      //   case "Homecare":
                                      //     AddressVar = item?.userAddressHome;
                                      //     break;
                                      //   case "Community Access":
                                      //     AddressVar = item?.userAddressComm;
                                      //     break;
                                      //   case "Respite/STA":
                                      //     AddressVar = item?.userAddressResp;
                                      //     break;
                                      //   case "SIL/SDA":
                                      //     AddressVar = item?.userAddressSil;
                                      //     break;
                                      //   default:
                                      //     AddressVar = item?.userAddress;
                                      // }
                                      let AddressVar = item?.userAddress;

                                      return (
                                        AddressVar && (
                                          <Typography
                                            variant="body2"
                                            className="card-location-text"
                                            component="div"
                                            key={AddressVar}
                                            title={
                                              AddressVar !== null
                                                ? AddressVar
                                                : "NA"
                                            }
                                          >
                                            {AddressVar !== null
                                              ? AddressVar
                                              : "NA"}
                                          </Typography>
                                        )
                                      );
                                    })()}
                                  </Stack>
                                </Box>
                              </>
                            }
                            subheader={
                              <>
                                <Box mt={1}>
                                  <Stack
                                    spacing={2}
                                    direction={{
                                      xs: "column",
                                      sm: "column",
                                      lg: "row",
                                    }}
                                  >
                                    <>
                                      <Stack
                                        direction="row"
                                        style={{ whiteSpace: "pre" }}
                                      >
                                        <Tooltip title="Scheduled Date And Time">
                                          <Box>
                                            <img
                                              className="worker-card-explore-icon"
                                              sx={{ marginRight: "-3px" }}
                                              src={sleepoverImage}
                                            />{" "}
                                          </Box>
                                        </Tooltip>
                                        <Typography
                                          variant="body2"
                                          component="div"
                                        >
                                          &nbsp;
                                          {item?.appointmentFullDate !==
                                          undefined
                                            ? `${new Date(
                                                item?.appointmentFullDate
                                              ).toLocaleString("en-US", {
                                                weekday: "short",
                                                month: "short",
                                                day: "numeric",
                                              })}, ${item?.appointmentFullTime}`
                                            : "NA"}
                                          {/* {`${item?.supportPerWeekInHrs !==
                                            undefined
                                            ? item?.supportPerWeekInHrs
                                            : "NA"
                                            }, ${item?.whenSupport}, ${item?.requiredFor
                                            }`} */}
                                        </Typography>
                                      </Stack>
                                    </>
                                  </Stack>
                                </Box>
                              </>
                            }
                          />
                          <CardActions
                            className="justify-content-between d-flex position-relative"
                            sx={{ pb: 2, pl: 2, pr: 2 }}
                          >
                            <>
                              {item?.status == "Pending" ? (
                                <>
                                  {userData?._id !== undefined &&
                                  userData?._id !== null ? (
                                    <>
                                      {item?.requestedIndHCId !== undefined &&
                                      item?.requestedIndHCId !== null ? (
                                        <>
                                          {pendingCount >=
                                          item?.publishLiveRosterLimit ? (
                                            <>
                                              <div>
                                                <Button
                                                  variant="contained"
                                                  className=""
                                                  disabled="true"
                                                >
                                                  Shift Freezed
                                                </Button>
                                                <span
                                                  style={{
                                                    color: "red",
                                                    marginLeft: "5px",
                                                    fontSize: "14px",
                                                  }}
                                                >
                                                  Try after some time
                                                </span>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              {item?.requestedIndHCId?.includes(
                                                userData?._id
                                              ) ? (
                                                <>
                                                  {item?.requestedIndHCBlock?.find(
                                                    (obj) =>
                                                      obj?.hcId ===
                                                        userData?._id &&
                                                      obj?.hcIsAppointed ===
                                                        "Rejected"
                                                  ) ? (
                                                    <Button
                                                      variant="contained"
                                                      className=""
                                                      disabled="true"
                                                    >
                                                      <img
                                                        src={Request}
                                                        className="card-send-icon"
                                                      />
                                                      Request Rejected
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      variant="contained"
                                                      className=""
                                                      disabled="true"
                                                    >
                                                      <img
                                                        src={Request}
                                                        className="card-send-icon"
                                                      />
                                                      Request Sent
                                                    </Button>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {item?.type ==
                                                  "findSupport" ? (
                                                    <Link
                                                      variant="contained"
                                                      className="res-iconbtn"
                                                      state={{
                                                        ApptId: item?._id,
                                                        clientId:
                                                          item?.clientId,
                                                        pathValue: "admin",
                                                        type: item?.type,
                                                        tabValue: 2,
                                                      }}
                                                      to={
                                                        pageType === "home"
                                                          ? item?.CreatedByuserTypeName !==
                                                            "serviceProvider"
                                                            ? "/pickup-shifts/view-details"
                                                            : "/pickup-shifts/view-details-sp"
                                                          : item?.CreatedByuserTypeName !==
                                                            "serviceProvider"
                                                          ? "/health-carer/pickup-shifts/view-details"
                                                          : "/health-carer/pickup-shifts/view-details-sp"
                                                      }
                                                      // disabled={disableButton}
                                                    >
                                                      <Button
                                                        variant="contained"
                                                        className="res-iconbtn"
                                                      >
                                                        <img
                                                          src={Request}
                                                          className="card-send-icon"
                                                        />
                                                        Request
                                                      </Button>
                                                      {/* Request */}
                                                    </Link>
                                                  ) : (
                                                    <Button
                                                      variant="contained"
                                                      className="res-iconbtn"
                                                      onClick={() => {
                                                        addRequestFunction(
                                                          item?._id,
                                                          userId,
                                                          "send"
                                                        );
                                                      }}
                                                      disabled={disableButton}
                                                    >
                                                      <img
                                                        src={Request}
                                                        className="card-send-icon"
                                                      />
                                                      {disableButton === true
                                                        ? "Processing..."
                                                        : "Request"}
                                                      {/* Request */}
                                                    </Button>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item?.type == "findSupport" ? (
                                            <Link
                                              variant="contained"
                                              className="res-iconbtn"
                                              state={{
                                                ApptId: item?._id,
                                                clientId: item?.clientId,
                                                pathValue: "admin",
                                                type: item?.type,
                                                tabValue: 2,
                                              }}
                                              to={
                                                pageType === "home"
                                                  ? item?.CreatedByuserTypeName !==
                                                    "serviceProvider"
                                                    ? "/pickup-shifts/view-details"
                                                    : "/pickup-shifts/view-details-sp"
                                                  : item?.CreatedByuserTypeName !==
                                                    "serviceProvider"
                                                  ? "/health-carer/pickup-shifts/view-details"
                                                  : "/health-carer/pickup-shifts/view-details-sp"
                                              }

                                              // disabled={disableButton}
                                            >
                                              <Button
                                                variant="contained"
                                                className="res-iconbtn"
                                              >
                                                <img
                                                  src={Request}
                                                  className="card-send-icon"
                                                />
                                                Request
                                              </Button>
                                              {/* Request */}
                                            </Link>
                                          ) : (
                                            <Button
                                              variant="contained"
                                              className="res-iconbtn"
                                              onClick={() => {
                                                addRequestFunction(
                                                  item?._id,
                                                  userId,
                                                  "send"
                                                );
                                              }}
                                              disabled={disableButton}
                                            >
                                              <img
                                                src={Request}
                                                className="card-send-icon"
                                              />
                                              {disableButton === true
                                                ? "Processing..."
                                                : "Request"}
                                              {/* Request */}
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {item?.type == "findSupport" ? (
                                        <Link
                                          variant="contained"
                                          className="res-iconbtn"
                                          state={{
                                            ApptId: item?._id,
                                            clientId: item?.clientId,
                                            pathValue: "admin",
                                            type: item?.type,
                                            tabValue: 2,
                                          }}
                                          to={
                                            pageType === "home"
                                              ? item?.CreatedByuserTypeName !==
                                                "serviceProvider"
                                                ? "/pickup-shifts/view-details"
                                                : "/pickup-shifts/view-details-sp"
                                              : item?.CreatedByuserTypeName !==
                                                "serviceProvider"
                                              ? "/health-carer/pickup-shifts/view-details"
                                              : "/health-carer/pickup-shifts/view-details-sp"
                                          }

                                          // disabled={disableButton}
                                        >
                                          <Button
                                            variant="contained"
                                            className="res-iconbtn"
                                          >
                                            <img
                                              src={Request}
                                              className="card-send-icon"
                                            />
                                            Request
                                          </Button>
                                          {/* Request */}
                                        </Link>
                                      ) : (
                                        <Link
                                          to=""
                                          className="explore-view-link"
                                        >
                                          <div
                                            onClick={() =>
                                              RedirectDirectFunction(
                                                item?._id,
                                                item?.type
                                              )
                                            }
                                          >
                                            <Button
                                              variant="contained"
                                              className="res-iconbtn"
                                              disabled={disableButton}
                                            >
                                              <img
                                                src={Request}
                                                className="card-send-icon"
                                              />
                                              Request
                                            </Button>
                                          </div>
                                        </Link>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      position: "absolute",
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      right: "-41px",
                                      bottom: "-19px",
                                      backgroundColor: "white",
                                      color: "#ff000070",
                                      padding: "10px 45px",
                                      transform: "rotate(-45deg)",
                                    }}
                                  >
                                    Booked
                                  </div>
                                </>
                              )}
                            </>

                            {item?.status == "Pending" && (
                              <Box className="d-flex align-items-center">
                                {isSoleTrader === true ? (
                                  <>
                                    {pageType === "home" ? (
                                      <>
                                        <Link
                                          to={
                                            item?.CreatedByuserTypeName !==
                                            "serviceProvider"
                                              ? "/pickup-shifts/view-details"
                                              : "/pickup-shifts/view-details-sp"
                                          }
                                          className="explore-view-link"
                                          state={{
                                            ApptId: item?._id,
                                            clientId: item?.clientId,
                                            pathValue: "home",
                                            type: item?.type,
                                          }}
                                        >
                                          <Tooltip title="View Details">
                                            <RemoveRedEyeIcon className="explore-view-icon" />
                                          </Tooltip>
                                        </Link>
                                      </>
                                    ) : (
                                      <>
                                        <Link
                                          to={
                                            pageType === "home"
                                              ? item?.CreatedByuserTypeName !==
                                                "serviceProvider"
                                                ? "/pickup-shifts/view-details"
                                                : "/pickup-shifts/view-details-sp"
                                              : item?.CreatedByuserTypeName !==
                                                "serviceProvider"
                                              ? "/health-carer/pickup-shifts/view-details"
                                              : "/health-carer/pickup-shifts/view-details-sp"
                                          }
                                          className="explore-view-link"
                                          state={{
                                            ApptId: item?._id,
                                            clientId: item?.clientId,
                                            pathValue: "admin",
                                            type: item?.type,
                                          }}
                                        >
                                          <Tooltip title="View Details">
                                            <RemoveRedEyeIcon className="explore-view-icon" />
                                          </Tooltip>
                                        </Link>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* <Link
                                  onClick={() =>
                                    toast.error("Only ABN Holders Can View")
                                  }
                                  className="explore-view-link"
                                >
                                  <Tooltip title="View Details">
                                    <RemoveRedEyeIcon className="explore-view-icon" />
                                  </Tooltip>
                                </Link> */}

                                    <>
                                      <Link
                                        to={
                                          pageType === "home"
                                            ? item?.CreatedByuserTypeName !==
                                              "serviceProvider"
                                              ? "/pickup-shifts/view-details"
                                              : "/pickup-shifts/view-details-sp"
                                            : item?.CreatedByuserTypeName !==
                                              "serviceProvider"
                                            ? "/health-carer/pickup-shifts/view-details"
                                            : "/health-carer/pickup-shifts/view-details-sp"
                                        }
                                        className="explore-view-link"
                                        state={{
                                          ApptId: item?._id,
                                          clientId: item?.clientId,
                                          pathValue: "admin",
                                          type: item?.type,
                                        }}
                                      >
                                        <Tooltip title="View Details">
                                          <RemoveRedEyeIcon className="explore-view-icon" />
                                        </Tooltip>
                                      </Link>
                                    </>
                                  </>
                                )}
                                <>
                                  {userData?._id !== undefined &&
                                  userData?._id !== null ? (
                                    <Tooltip title="Favorite">
                                      <Button
                                        variant="outlined"
                                        className="fav-iconbtn"
                                        startIcon={
                                          favourite?.includes(item?._id) ? (
                                            <FavoriteIcon
                                              style={{ color: "#15A07D" }}
                                            />
                                          ) : (
                                            <FavoriteBorderOutlinedIcon />
                                          )
                                        }
                                        onClick={() => {
                                          if (favourite?.includes(item?._id)) {
                                            addLikeApptFunction(
                                              item?._id,
                                              userId,
                                              "dislike",
                                              item?.type,
                                              item?.clientId
                                            );
                                          } else {
                                            addLikeApptFunction(
                                              item?._id,
                                              userId,
                                              "like",
                                              item?.type,
                                              item?.clientId
                                            );
                                          }
                                        }}
                                      >
                                        {/* You can include any additional content here */}
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Favorite">
                                      <Link to="" className="explore-view-link">
                                        <div
                                          onClick={() =>
                                            RedirectDirectFunction(
                                              item?._id,
                                              item?.type
                                            )
                                          }
                                        >
                                          <Button
                                            variant="outlined"
                                            className="fav-iconbtn"
                                            startIcon={
                                              <FavoriteBorderOutlinedIcon />
                                            }
                                          />
                                        </div>
                                      </Link>
                                    </Tooltip>
                                  )}
                                </>
                              </Box>
                            )}
                          </CardActions>
                        </Card>
                      </Grid>
                    </>
                  );
                })
              ) : (
                <>
                  <TableCell colSpan={12} sx={{ marginLeft: 55 }}>
                    <Stack
                      direction="column"
                      spacing={2}
                      className="no-data-available"
                    >
                      <img src={nodata} alt="No Data" />
                      <Typography component="div">
                        No Pickup Shift has been added.
                      </Typography>
                    </Stack>
                  </TableCell>
                </>
              )}
            </Grid>

            <Grid
              mt={3}
              item
              xs={12}
              sm={12}
              md={12}
              className="input d-flex align-items-center pt-0"
            >
              {/* <Box className="show-result-box d-flex align-items-center ">
            <span>6</span>
            <img src={downArrow} className="" />
          </Box> */}
              <Grid mt={2} item xs={12} sm={12} md={12} className="input pt-0">
                <div className="record-page">
                  <Typography component="div" className="totalcount">
                    Total Records: {count}
                  </Typography>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-right"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item previous-page-link"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    forcePage={currentPageVal - 1}
                  />
                  {/* {isLoading && <FieldsListLoading />} */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2} className="d-flex align-items-center">
            <Grid item md={8.5} sm={12} xs={12}>
              <Box
                className="get-support-filter"
                sx={{ mt: 2, ml: 0.5 }}
                style={{ paddingLeft: "1%" }}
              >
                {/* <Filters
                  getAllFilterData={getAllFilterData}
                  getAllFilterDataClear={getAllFilterDataClear}
                /> */}
              </Box>
            </Grid>

            {listData?.length !== 0 ? (
              <Grid item md={2.5} sm={12} xs={12}>
                <Box className="export-market-filters agency-searchbar  searchbox-margin-new ">
                  <TextField
                    className="search-appointment"
                    type="search"
                    size="small"
                    //name="email"
                    fullWidth
                    placeholder="Search Here"
                    value={searchTerm}
                    onChange={(e) => searchItems(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )}

            {listData?.length !== 0 && userData ? (
              <>
                <Grid item md={1} sm={12} xs={12}>
                  <Box className="addTask-btn">
                    <Box className="d-flex align-items-center position-relative justify-content-end">
                      <Box className="hide-content explore-tag">
                        <Stack
                          direction="row"
                          spacing={2}
                          sx={{ mt: 4 }}
                          color={"#645DD7"}
                          onClick={handleFavoritesClick}
                          style={{
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                            marginRight: "-5px",
                          }}
                        >
                          {showFavorites ? "Show All" : "My Favorites"}
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
          <Grid container spacing={2} mt={5}>
            <Grid
              container
              spacing={2}
              className="explore-market-cards padding-right-20 padding-left-20 "
            >
              {listClientFindSupport?.length !== 0 ? (
                listClientFindSupport?.map((item, index) => {
                  let pendingCount = 0; // Initialize pending count for each item
                  item?.requestedIndHCBlock?.forEach((appointment) => {
                    if (appointment.hcIsAppointed === "Pending") {
                      pendingCount++;
                    }
                  });

                  return (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={index}
                        className="input pt-0 mt-4"
                      >
                        <Card
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor:
                              item?.status != "Pending"
                                ? "rgb(220 220 220 / 60%)"
                                : "",
                          }}
                          className="worker-card-main"
                        >
                          <CardHeader
                            className="woker-card-header"
                            title={
                              <>
                                <Box className="d-flex align-items-start justify-content-between">
                                  <Stack direction="column">
                                    {item?.CreatedByuserTypeName !==
                                    "serviceProvider" ? (
                                      <>
                                        <Typography
                                          component="div"
                                          className="worker-card-name"
                                          title={
                                            item?.userFullName !== undefined
                                              ? `${
                                                  item?.title
                                                    ? item?.title
                                                    : item?.otherTitle
                                                } ${item?.userFullName}`
                                              : "NA"
                                          }
                                        >
                                          {item?.userFullName !== undefined
                                            ? `${
                                                item?.title
                                                  ? item?.title
                                                  : item?.otherTitle
                                              } ${item?.userFullName}`
                                            : "NA"}
                                        </Typography>
                                        <Tooltip title="Rating">
                                          <Box>
                                            <RatingClient
                                              props={{
                                                clientId: item?.clientId,
                                              }}
                                            />
                                          </Box>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          component="div"
                                          className="worker-card-name"
                                          title={
                                            item?.serviceProviderCompanyname !==
                                            undefined
                                              ? `${item?.serviceProviderCompanyname}`
                                              : "NA"
                                          }
                                        >
                                          {item?.serviceProviderCompanyname !==
                                          undefined
                                            ? `${item?.serviceProviderCompanyname}`
                                            : "NA"}
                                        </Typography>
                                        <Box className="worker-card-rating">
                                          <p>Service Provider</p>
                                        </Box>
                                      </>
                                    )}
                                  </Stack>

                                  <Stack direction="row">
                                    <Tooltip title="Location">
                                      <img
                                        src={mapLocationIcon}
                                        className="worker-map-icon"
                                      />
                                    </Tooltip>

                                    {(() => {
                                      // let AddressVar;
                                      // switch (item?.ValcategoriesName) {
                                      //   case "Homecare":
                                      //     AddressVar = item?.userAddressHome;
                                      //     break;
                                      //   case "Community Access":
                                      //     AddressVar = item?.userAddressComm;
                                      //     break;
                                      //   case "Respite/STA":
                                      //     AddressVar = item?.userAddressResp;
                                      //     break;
                                      //   case "SIL/SDA":
                                      //     AddressVar = item?.userAddressSil;
                                      //     break;
                                      //   default:
                                      //     AddressVar = item?.userAddress;
                                      // }
                                      let AddressVar = item?.userAddress;

                                      return (
                                        AddressVar && (
                                          <Typography
                                            variant="body2"
                                            className="card-location-text"
                                            component="div"
                                            key={AddressVar}
                                            title={
                                              AddressVar !== null
                                                ? AddressVar
                                                : "NA"
                                            }
                                          >
                                            {AddressVar !== null
                                              ? AddressVar
                                              : "NA"}
                                          </Typography>
                                        )
                                      );
                                    })()}
                                  </Stack>
                                </Box>
                              </>
                            }
                            subheader={
                              <>
                                <Box mt={1}>
                                  <Stack
                                    spacing={2}
                                    direction={{
                                      xs: "column",
                                      sm: "column",
                                      lg: "row",
                                    }}
                                  >
                                    <>
                                      <Stack
                                        direction="row"
                                        style={{ whiteSpace: "pre" }}
                                      >
                                        <Tooltip title="Scheduled Date And Time">
                                          <Box>
                                            <img
                                              className="worker-card-explore-icon"
                                              sx={{ marginRight: "-3px" }}
                                              src={sleepoverImage}
                                            />{" "}
                                          </Box>
                                        </Tooltip>
                                        <Typography
                                          variant="body2"
                                          component="div"
                                        >
                                          &nbsp;
                                          {/* {item?.appointmentFullDate !== undefined
                                        ? `${new Date(
                                          item?.appointmentFullDate
                                        ).toLocaleString("en-US", {
                                          weekday: "short",
                                          month: "short",
                                          day: "numeric",
                                        })}, ${item?.appointmentFullTime}`
                                        : "NA"} */}
                                          {`${
                                            item?.supportPerWeekInHrs !==
                                            undefined
                                              ? item?.supportPerWeekInHrs
                                              : "NA"
                                          }, ${item?.whenSupport}, ${
                                            item?.requiredFor
                                          }`}
                                        </Typography>
                                      </Stack>
                                    </>
                                  </Stack>
                                </Box>
                              </>
                            }
                          />
                          <CardActions
                            className="justify-content-between d-flex position-relative"
                            sx={{ pb: 2, pl: 2, pr: 2 }}
                          >
                            <>
                              {item?.requestedIndHCId?.includes(
                                userData?._id
                              ) ? (
                                <>
                                  {item?.requestedIndHCBlock?.find(
                                    (obj) =>
                                      obj?.hcId === userData?._id &&
                                      obj?.hcIsAppointed === "Rejected"
                                  ) ? (
                                    <Button
                                      variant="contained"
                                      className=""
                                      disabled="true"
                                    >
                                      <img
                                        src={Request}
                                        className="card-send-icon"
                                      />
                                      Request Rejected
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      className=""
                                      disabled="true"
                                    >
                                      <img
                                        src={Request}
                                        className="card-send-icon"
                                      />
                                      Request Sent
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  {item?.type == "findSupport" ? (
                                    <Link
                                      variant="contained"
                                      className="res-iconbtn"
                                      state={{
                                        ApptId: item?._id,
                                        clientId: item?.clientId,
                                        pathValue: "admin",
                                        type: item?.type,
                                        tabValue: 2,
                                      }}
                                      to={
                                        pageType === "home"
                                          ? item?.CreatedByuserTypeName !==
                                            "serviceProvider"
                                            ? "/pickup-shifts/view-details"
                                            : "/pickup-shifts/view-details-sp"
                                          : item?.CreatedByuserTypeName !==
                                            "serviceProvider"
                                          ? "/health-carer/pickup-shifts/view-details"
                                          : "/health-carer/pickup-shifts/view-details-sp"
                                      }

                                      // disabled={disableButton}
                                    >
                                      <Button
                                        variant="contained"
                                        className="res-iconbtn"
                                      >
                                        <img
                                          src={Request}
                                          className="card-send-icon"
                                        />
                                        Request
                                      </Button>
                                      {/* Request */}
                                    </Link>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      className="res-iconbtn"
                                      onClick={() => {}}
                                      disabled={disableButton}
                                    >
                                      <img
                                        src={Request}
                                        className="card-send-icon"
                                      />
                                      {disableButton === true
                                        ? "Processing..."
                                        : "Request"}
                                      {/* Request */}
                                    </Button>
                                  )}
                                </>
                              )}
                            </>

                            {item?.status == "Pending" && (
                              <Box className="d-flex align-items-center">
                                {isSoleTrader === true ? (
                                  <>
                                    {pageType === "home" ? (
                                      <>
                                        <Link
                                          to={
                                            item?.CreatedByuserTypeName !==
                                            "serviceProvider"
                                              ? "/pickup-shifts/view-details"
                                              : "/pickup-shifts/view-details-sp"
                                          }
                                          className="explore-view-link"
                                          state={{
                                            ApptId: item?._id,
                                            clientId: item?.clientId,
                                            pathValue: "home",
                                            type: item?.type,
                                          }}
                                        >
                                          <Tooltip title="View Details">
                                            <RemoveRedEyeIcon className="explore-view-icon" />
                                          </Tooltip>
                                        </Link>
                                      </>
                                    ) : (
                                      <>
                                        <Link
                                          to={
                                            pageType === "home"
                                              ? item?.CreatedByuserTypeName !==
                                                "serviceProvider"
                                                ? "/pickup-shifts/view-details"
                                                : "/pickup-shifts/view-details-sp"
                                              : item?.CreatedByuserTypeName !==
                                                "serviceProvider"
                                              ? "/health-carer/pickup-shifts/view-details"
                                              : "/health-carer/pickup-shifts/view-details-sp"
                                          }
                                          className="explore-view-link"
                                          state={{
                                            ApptId: item?._id,
                                            clientId: item?.clientId,
                                            pathValue: "admin",
                                            type: item?.type,
                                          }}
                                        >
                                          <Tooltip title="View Details">
                                            <RemoveRedEyeIcon className="explore-view-icon" />
                                          </Tooltip>
                                        </Link>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* <Link
                                  onClick={() =>
                                    toast.error("Only ABN Holders Can View")
                                  }
                                  className="explore-view-link"
                                >
                                  <Tooltip title="View Details">
                                    <RemoveRedEyeIcon className="explore-view-icon" />
                                  </Tooltip>
                                </Link> */}

                                    <>
                                      <Link
                                        to={
                                          pageType === "home"
                                            ? item?.CreatedByuserTypeName !==
                                              "serviceProvider"
                                              ? "/pickup-shifts/view-details"
                                              : "/pickup-shifts/view-details-sp"
                                            : item?.CreatedByuserTypeName !==
                                              "serviceProvider"
                                            ? "/health-carer/pickup-shifts/view-details"
                                            : "/health-carer/pickup-shifts/view-details-sp"
                                        }
                                        className="explore-view-link"
                                        state={{
                                          ApptId: item?._id,
                                          clientId: item?.clientId,
                                          pathValue: "admin",
                                          type: item?.type,
                                        }}
                                      >
                                        <Tooltip title="View Details">
                                          <RemoveRedEyeIcon className="explore-view-icon" />
                                        </Tooltip>
                                      </Link>
                                    </>
                                  </>
                                )}
                                <>
                                  {userData?._id !== undefined &&
                                  userData?._id !== null ? (
                                    <Tooltip title="Favorite">
                                      <Button
                                        variant="outlined"
                                        className="fav-iconbtn"
                                        startIcon={
                                          favourite?.includes(item?._id) ? (
                                            <FavoriteIcon
                                              style={{ color: "#15A07D" }}
                                            />
                                          ) : (
                                            <FavoriteBorderOutlinedIcon />
                                          )
                                        }
                                        onClick={() => {
                                          if (favourite?.includes(item?._id)) {
                                            addLikeApptFunction(
                                              item?._id,
                                              userId,
                                              "dislike",
                                              item?.type,
                                              item?.clientId
                                            );
                                          } else {
                                            addLikeApptFunction(
                                              item?._id,
                                              userId,
                                              "like",
                                              item?.type,
                                              item?.clientId
                                            );
                                          }
                                        }}
                                      >
                                        {/* You can include any additional content here */}
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Favorite">
                                      <Link to="" className="explore-view-link">
                                        <div
                                          onClick={() =>
                                            RedirectDirectFunction(
                                              item?._id,
                                              item?.type
                                            )
                                          }
                                        >
                                          <Button
                                            variant="outlined"
                                            className="fav-iconbtn"
                                            startIcon={
                                              <FavoriteBorderOutlinedIcon />
                                            }
                                          />
                                        </div>
                                      </Link>
                                    </Tooltip>
                                  )}
                                </>
                              </Box>
                            )}
                          </CardActions>
                        </Card>
                      </Grid>
                    </>
                  );
                })
              ) : (
                <>
                  <TableCell colSpan={12} sx={{ marginLeft: 55 }}>
                    <Stack
                      direction="column"
                      spacing={2}
                      className="no-data-available"
                    >
                      <img src={nodata} alt="No Data" />
                      <Typography component="div">
                        No Pickup Client has been added.
                      </Typography>
                    </Stack>
                  </TableCell>
                </>
              )}
            </Grid>

            <Grid
              mt={3}
              item
              xs={12}
              sm={12}
              md={12}
              className="input d-flex align-items-center pt-0"
            >
              {/* <Box className="show-result-box d-flex align-items-center ">
            <span>6</span>
            <img src={downArrow} className="" />
          </Box> */}
              <Grid mt={2} item xs={12} sm={12} md={12} className="input pt-0">
                <div className="record-page">
                  <Typography component="div" className="totalcount">
                    Total Records: {count}
                  </Typography>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-right"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item previous-page-link"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                    forcePage={currentPageVal - 1}
                  />
                  {/* {isLoading && <FieldsListLoading />} */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Box>
  );
}
