import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import HelpTabIcon from "../../assets/images/help-tab-icon.png";

import bookademo1 from "../../assets/images/book-a-demo-1.png";
import bookademo2 from "../../assets/images/book-a-demo-2.png";
import bookademo3 from "../../assets/images/book-a-demo-3.png";

import PickUpShifts1 from "../../assets/images/PickUpShifts-1.png";
import PickUpShifts2 from "../../assets/images/PickUpShifts-2.png";
import PickUpShifts3 from "../../assets/images/PickUpShifts-3.png";

import GetSupport1 from "../../assets/images/GetSupport-1.png";
import GetSupport2 from "../../assets/images/GetSupport-2.png";
import GetSupport3 from "../../assets/images/GetSupport-3.png";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function HelpTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, display: "flex" }} className="help-tab-responsive">
      <Tabs
        orientation="vertical"
        variant="standard"
        value={value}
        className="helpTabs helpTab-left-panel  "
        onChange={handleChange}
        aria-label="Vertical tabs example"
      >
        <Tab label="Book A Demo" {...a11yProps(0)} />
        <Tab label="Pick Up Shifts" {...a11yProps(1)} />
        <Tab label="Get Support" {...a11yProps(2)} />

      </Tabs>

      <TabPanel value={value} index={0} className="helpTab-panel">
        <Box className="help-tabs-content">
          <h6>
            {" "}

            How to Book a Damo
          </h6>

          <Box className="about-full-divider"></Box>

          <Box>
            <ul>

              <li>
                <Link
                  className="d-flex align-items-center justify-content-between"
                >

                  <span>Step 1: Schedule A Demo </span>
                  {/* <img src={HelpTabIcon} /> */}
                </Link>
              </li>

              <li>

                {/* _____ */}

                <img src={bookademo1} alt="image" />

              </li>

              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  {" "}
                  <span> Step 2: Select Date and Time, and Schedule </span>

                  {/* <img src={HelpTabIcon} /> */}

                </Link>
              </li>

              {/* _____ */}

              <li>

                <img src={bookademo2} alt="image" />

              </li>


              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span> Admin will confirm the Demo meeting.  </span>

                  {/* <img src={HelpTabIcon} /> */}

                </Link>

              </li>

              {/* _____ */}

              <li>

                <img src={bookademo3} alt="image" />

              </li>



            </ul>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1} className="helpTab-panel">
        <Box className="help-tabs-content">
          <h6>
            {" "}

            Pick Up Shifts
          </h6>

          <Box className="about-full-divider"></Box>

          <Box>
            <ul>

              <li>
                <Link
                  className="d-flex align-items-center justify-content-between"
                >

                  <span> Step 1: Search shift by Area </span>
                  {/* <img src={HelpTabIcon} /> */}
                </Link>
              </li>

              <li>

                {/* _____ */}

                <img src={PickUpShifts1} alt="image" />

              </li>

              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  {" "}
                  <span> Step 2: Pick up suitable shift from the list </span>

                  {/* <img src={HelpTabIcon} /> */}

                </Link>
              </li>

              {/* _____ */}

              <li>

                <img src={PickUpShifts2} alt="image" />

              </li>


              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span>Step 3: Connect with your client  </span>

                  {/* <img src={HelpTabIcon} /> */}

                </Link>

              </li>

              {/* _____ */}

              <li>

                <img src={PickUpShifts3} alt="image" />

              </li>



            </ul>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={2} className="helpTab-panel">
        <Box className="help-tabs-content">
          <h6>
            {" "}

            Get Support
          </h6>

          <Box className="about-full-divider"></Box>

          <Box>
            <ul>

              <li>
                <Link
                  className="d-flex align-items-center justify-content-between"
                >

                  <span> Step 1: Search Health Carer by Area </span>
                  {/* <img src={HelpTabIcon} /> */}
                </Link>
              </li>

              <li>

                {/* _____ */}

                <img src={GetSupport1} alt="image" />

              </li>

              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  {" "}
                  <span> Step 2: Request to connect with suitable Health Carer </span>

                  {/* <img src={HelpTabIcon} /> */}

                </Link>
              </li>

              {/* _____ */}

              <li>

                <img src={GetSupport2} alt="image" />

              </li>


              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span> Step 3: Get appointed for the shift  </span>

                  {/* <img src={HelpTabIcon} /> */}

                </Link>

              </li>

              {/* _____ */}

              <li>

                <img src={GetSupport3} alt="image" />

              </li>



            </ul>
          </Box>
        </Box>
      </TabPanel>


      {/* <TabPanel value={value} index={1} className="helpTab-panel">
        <Box className="help-tabs-content">
          <h6>
            {" "}
            Details about creating an account, registration process, and account
            management.
          </h6>

          <Box className="about-full-divider"></Box>

          <Box>
            <ul>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  {" "}
                  <span>Creating a New Account </span> <img src={HelpTabIcon} />{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  {" "}
                  <span> Account Verification </span> <img src={HelpTabIcon} />
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span>Password Reset</span> <img src={HelpTabIcon} />
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span>Profile Management</span> <img src={HelpTabIcon} />
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span>Troubleshooting Registration Issues</span>{" "}
                  <img src={HelpTabIcon} />
                </Link>
              </li>
            </ul>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value={value} index={2} className="helpTab-panel">
        <Box className="help-tabs-content">
          <h6>
            {" "}
            Details about creating an account, registration process, and account
            management.
          </h6>

          <Box className="about-full-divider"></Box>

          <Box>
            <ul>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  {" "}
                  <span>Creating a New Account </span> <img src={HelpTabIcon} />{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  {" "}
                  <span> Account Verification </span> <img src={HelpTabIcon} />
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span>Password Reset</span> <img src={HelpTabIcon} />
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span>Profile Management</span> <img src={HelpTabIcon} />
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="d-flex align-items-center justify-content-between"
                >
                  <span>Troubleshooting Registration Issues</span>{" "}
                  <img src={HelpTabIcon} />
                </Link>
              </li>
            </ul>
          </Box>
        </Box>
      </TabPanel> */}


    </Box>
  );
}
