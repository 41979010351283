import {
  getJobTitleList,
  getJobTypeList,
  getLevelList,
  getState,
  getCountry,
  getCountryDetails,
  getStateDetails,
  getStateList,
  getLevelDetails,
  getJobTypeDetails,
  getJobTitleDetails,
  AustraliaId,
  addGoogleCount,
  updateGoogleCount,
  getIndividualCountDetails,
  SAcreatedBy,
} from "../../../../api";
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MailIcon from "@mui/icons-material/Mail";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import CallIcon from "@mui/icons-material/Call";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import { getAllBeforeOnboarding } from "../../../../helpers/commonHelper";
import HCStore from "../HCStore";
import "../../../../pages/client/form/stakeholder.css";
import RadioGroup from "@mui/material/RadioGroup";
import { Radio } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const PersonalInfo = (props) => {
  const { formik } = props;

  const [dobError, setDobError] = useState("");
  const { resetFields, setField, buttonClickedPinfo } = HCStore();

  let createdBy = getAllBeforeOnboarding();

  if (createdBy === "superAdmin") {
    createdBy = SAcreatedBy;
  }

  const gender = [
    { label: "Male", id: "Male" },
    { label: "Female", id: "Female" },
    { label: "Other", id: "Other" },
  ];

  const relationShipFM = [
    { label: "Son", id: "son" },
    { label: "Father", id: "father" },
    { label: "Brother", id: "brother" },
    { label: "Mother", id: "mother" },
    { label: "Sister", id: "sister" },
    { label: "Daughter", id: "daughter" },
    { label: "Other", id: "Other" },
  ];

  const titles = [
    {
      label: "Ms",
      id: "Ms",
    },
    { label: "Mr", id: "Mr" },
    { label: "Mrs", id: "Mrs" },
  ];

  let dateOfBirth = null;

  if (formik.values.dob !== undefined && formik.values.dob !== null) {
    dateOfBirth = moment(formik.values.dob).format("YYYY-MM-DD");
  }

  const HandleDateEvent = (e, docName) => {
    const name = docName;
    if (new Date(e) == "Invalid Date") {
      setDobError("Please select a valid date.");
    } else {
      formik.setFieldValue(name, new Date(e));
      setDobError("");
    }
  };

  const [open, setOpen] = React.useState(false);

  const userPrimaryKeyIni = formik.values.userPrimaryKeyIni;

  console.log("userPrimaryKeyIni", userPrimaryKeyIni);

  return (
    <Box id="personal-info" className="account-form">
      <Box className="d-flex align-items-center justify-content-between">
        <Typography className="step-count">Step 1/4</Typography>
      </Box>

      <Box className="personal-info-main">
        <Box className="personal-info">
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Box className="personal-info">
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <label>
                      Who wants Support ? <span className="red-color">*</span>
                    </label>

                    {userPrimaryKeyIni !== "" ? (
                      <>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="supportStatus"
                          sx={{ p: 2 }}
                        >
                          <FormControlLabel
                            disabled
                            value="Me"
                            control={<Radio />}
                            label="Me"
                            checked={
                              formik.values.supportStatus === "Me" ? true : ""
                            }
                          />{" "}
                          &nbsp;&nbsp;
                          <FormControlLabel
                            disabled
                            value="Family Member"
                            control={<Radio />}
                            label="Family Member"
                            checked={
                              formik.values.supportStatus === "Family Member"
                                ? true
                                : ""
                            }
                          />{" "}
                          &nbsp;&nbsp;
                          <FormControlLabel
                            disabled
                            value="My Client"
                            control={<Radio />}
                            label="My Client"
                            checked={
                              formik.values.supportStatus === "My Client"
                                ? true
                                : ""
                            }
                          />
                        </RadioGroup>
                      </>
                    ) : (
                      <>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="supportStatus"
                        >
                          <FormControlLabel
                            onClick={() => {
                              formik.setFieldValue("supportStatus", "Me");
                              setField("supportStatus", "Me");
                            }}
                            value="Me"
                            control={<Radio />}
                            label="Me"
                            checked={
                              formik.values.supportStatus === "Me" ? true : ""
                            }
                          />{" "}
                          &nbsp;&nbsp;
                          <FormControlLabel
                            onClick={() => {
                              formik.setFieldValue(
                                "supportStatus",
                                "Family Member"
                              );
                              setField("supportStatus", "Family Member");
                            }}
                            value="Family Member"
                            control={<Radio />}
                            label="Family Member"
                            checked={
                              formik.values.supportStatus === "Family Member"
                                ? true
                                : ""
                            }
                          />{" "}
                          &nbsp;&nbsp;
                          <FormControlLabel
                            onClick={() => {
                              formik.setFieldValue(
                                "supportStatus",
                                "My Client"
                              );
                              setField("supportStatus", "My Client");
                            }}
                            value="My Client"
                            control={<Radio />}
                            label="My Client"
                            checked={
                              formik.values.supportStatus === "My Client"
                                ? true
                                : ""
                            }
                          />
                        </RadioGroup>
                      </>
                    )}
                  </FormControl>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box >
      </Box >

      <Box className="personal-info-main">
        <Box className="form-heading-section">
          <Typography className="form-heading form-heading-align">
            Client Details
          </Typography>
        </Box>

        <Box className="personal-info">
          <Grid container spacing={2}>
            <Grid item xs="2">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <label>
                    Title <span className="red-color">*</span>
                  </label>

                  <Autocomplete
                    name="title"
                    disablePortal
                    key="combo-box-demo-d2"
                    sx={{ mt: 1, mb: 2 }}
                    options={titles}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="title"
                        placeholder="Select"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.title && Boolean(formik.errors.title)
                        }
                        helperText={formik.touched.title && formik.errors.title}
                      />
                    )}
                    size="small"
                    value={
                      titles !== undefined &&
                        titles !== "" &&
                        props?.formik?.values?.title !== undefined &&
                        props?.formik?.values?.title !== ""
                        ? titles.find(
                          (option) =>
                            option.id === props?.formik?.values?.title
                        )
                        : ""
                    }
                    onChange={(event, newInputValue) => {
                      formik.setFieldValue("title", newInputValue?.id);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="10">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={4}>
                  <label>
                    First Name <span className="red-color">*</span>
                  </label>

                  <TextField
                    sx={{ mt: 2, mb: 2 }}
                    className="form-input"
                    type="text"
                    size="small"
                    placeholder="Enter your First Name"
                    onChange={formik.handleChange}
                    name="firstName"
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <label>Middle Name</label>

                  <TextField
                    sx={{ mt: 2, mb: 2 }}
                    className="form-input"
                    type="text"
                    size="small"
                    placeholder="Enter your Middle Name"
                    onChange={formik.handleChange}
                    name="middleName"
                    onBlur={formik.handleBlur}
                    value={formik.values.middleName}
                    error={
                      formik.touched.middleName &&
                      Boolean(formik.errors.middleName)
                    }
                    helperText={
                      formik.touched.middleName && formik.errors.middleName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <label>
                    Last Name <span className="red-color">*</span>
                  </label>

                  <TextField
                    sx={{ mt: 2, mb: 2 }}
                    className="form-input"
                    type="text"
                    size="small"
                    placeholder="Enter your Last Name"
                    onChange={formik.handleChange}
                    name="lastName"
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs="2">

              <Grid container spacing={2}>

                <Grid item xs={12} sm={12} md={12}>
                  <label>
                    Gender <span className="red-color">*</span>
                  </label>
                  <Autocomplete
                    name="gender"
                    disablePortal
                    key="combo-box-demo-d2"
                    sx={{ mt: 1, mb: 1 }}
                    options={gender}
                    value={
                      gender !== undefined &&
                        gender !== "" &&
                        formik?.values?.gender !== undefined &&
                        formik?.values?.gender !== ""
                        ? gender.find(
                          (option) => option.id === formik?.values?.gender
                        )
                        : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="gender"
                        placeholder="Select"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.gender && Boolean(formik.errors.gender)
                        }
                        helperText={
                          formik.touched.gender && formik.errors.gender
                        }
                      />
                    )}
                    size="small"
                    onChange={(event, newInputValue) => {
                      formik.setFieldValue("gender", newInputValue?.id);
                    }}
                  />
                </Grid>

              </Grid>

            </Grid> */}

            {/* <Grid item xs="10"> */}

            <Grid container spacing={2}>

              <Grid style={{ paddingLeft: "31px" }} item xs={12} sm={4} md={2.5}>
                <label>
                  Gender <span className="red-color">*</span>
                </label>
                <Autocomplete
                  name="gender"
                  disablePortal
                  key="combo-box-demo-d2"
                  sx={{ mt: 1, mb: 1, }}
                  options={gender}
                  value={
                    gender !== undefined &&
                      gender !== "" &&
                      formik?.values?.gender !== undefined &&
                      formik?.values?.gender !== ""
                      ? gender.find(
                        (option) => option.id === formik?.values?.gender
                      )
                      : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="gender"
                      placeholder="Select"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.gender && Boolean(formik.errors.gender)
                      }
                      helperText={
                        formik.touched.gender && formik.errors.gender
                      }
                    />
                  )}
                  size="small"
                  onChange={(event, newInputValue) => {
                    formik.setFieldValue("gender", newInputValue?.id);
                  }}
                />
              </Grid>


              <Grid item xs={12} sm={6} md={3.5}>
                <label>
                  Email <span className="red-color">*</span>
                </label>

                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  className="form-input form-input-icon-bg"
                  type="email"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className="input-icon" position="start">
                        <MailIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter your Email"
                  onChange={formik.handleChange}
                  name="email"
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>


              <Grid item xs={12} sm={6} md={3}>
                <label>
                  Mobile No <span className="red-color">*</span>
                </label>

                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  className="form-input form-input-icon-bg"
                  type="text"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment className="input-icon" position="start">
                        <CallIcon />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter your mobile number"
                  onChange={formik.handleChange}
                  name="mobileNumber"
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                  error={
                    formik.touched.mobileNumber &&
                    Boolean(formik.errors.mobileNumber)
                  }
                  helperText={
                    formik.touched.mobileNumber && formik.errors.mobileNumber
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <label>
                  Date of Birth <span className="red-color">*</span>
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3} sx={{ mb: 1 }}>
                    <DesktopDatePicker
                      open={open}
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      className="form-input date-input-filed"
                      inputFormat="DD-MM-YYYY"
                      maxDate={new Date()}
                      value={dateOfBirth}
                      onChange={(e) => HandleDateEvent(e, "dob")}
                      renderInput={(params) => (
                        <TextField
                          onClick={(e) => setOpen(true)}
                          size="small"
                          name="dob"
                          sx={{ mb: 1 }}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                            placeholder: "DD-MM-YYYY",
                          }}
                          error={
                            (formik.touched.dob &&
                              Boolean(formik.errors.dob)) ||
                            Boolean(dobError)
                          }
                          helperText={
                            (formik.touched.dob && formik.errors.dob) ||
                            dobError
                          }
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>

              {/* __________ */}


              <Grid style={{ paddingLeft: "31px" }} item xs={12} sm={4} md={4}>
                <label>NDIS Number</label>

                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  className="form-input"
                  type="text"
                  size="small"
                  placeholder="Enter NDIS Number"
                  onChange={formik.handleChange}
                  name="ndisNumber"
                  onBlur={formik.handleBlur}
                  value={formik.values.ndisNumber}
                  error={
                    formik.touched.ndisNumber &&
                    Boolean(formik.errors.ndisNumber)
                  }
                  helperText={
                    formik.touched.ndisNumber && formik.errors.ndisNumber
                  }
                />
              </Grid>

            </Grid>

            {/* </Grid> */}

          </Grid>
        </Box>
      </Box>

      {
        formik.values.supportStatus === "My Client" ? (
          <>
            <Box className="personal-info-main">
              <Box className="form-heading-section">
                <Typography className="form-heading form-heading-align">
                  Personal Details of the person who is requesting.
                </Typography>
              </Box>

              <Box className="personal-info">
                <Grid container spacing={2}>
                  <Grid item xs="2">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <label>
                          Title <span className="red-color">*</span>
                        </label>

                        <Autocomplete
                          name="titleISC"
                          disablePortal
                          key="combo-box-demo-d2"
                          sx={{ mt: 1, mb: 2 }}
                          options={titles}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="titleISC"
                              placeholder="Select"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.titleISC &&
                                Boolean(formik.errors.titleISC)
                              }
                              helperText={
                                formik.touched.titleISC && formik.errors.titleISC
                              }
                            />
                          )}
                          size="small"
                          value={
                            titles !== undefined &&
                              titles !== "" &&
                              props?.formik?.values?.titleISC !== undefined &&
                              props?.formik?.values?.titleISC !== ""
                              ? titles.find(
                                (option) =>
                                  option.id === props?.formik?.values?.titleISC
                              )
                              : ""
                          }
                          onChange={(event, newInputValue) => {
                            formik.setFieldValue("titleISC", newInputValue?.id);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs="10">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4} md={4}>
                        <label>
                          First Name <span className="red-color">*</span>
                        </label>

                        <TextField
                          sx={{ mt: 2, mb: 2 }}
                          className="form-input"
                          type="text"
                          size="small"
                          placeholder="Enter your First Name"
                          onChange={formik.handleChange}
                          name="firstNameISC"
                          onBlur={formik.handleBlur}
                          value={formik.values.firstNameISC}
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstNameISC)
                          }
                          helperText={
                            formik.touched.firstNameISC &&
                            formik.errors.firstNameISC
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <label>Middle Name</label>

                        <TextField
                          sx={{ mt: 2, mb: 2 }}
                          className="form-input"
                          type="text"
                          size="small"
                          placeholder="Enter your Middle Name"
                          onChange={formik.handleChange}
                          name="middleNameISC"
                          onBlur={formik.handleBlur}
                          value={formik.values.middleNameISC}
                          error={
                            formik.touched.middleNameISC &&
                            Boolean(formik.errors.middleNameISC)
                          }
                          helperText={
                            formik.touched.middleNameISC &&
                            formik.errors.middleNameISC
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <label>
                          Last Name <span className="red-color">*</span>
                        </label>

                        <TextField
                          sx={{ mt: 2, mb: 2 }}
                          className="form-input"
                          type="text"
                          size="small"
                          placeholder="Enter your Last Name"
                          onChange={formik.handleChange}
                          name="lastNameISC"
                          onBlur={formik.handleBlur}
                          value={formik.values.lastNameISC}
                          error={
                            formik.touched.lastNameISC &&
                            Boolean(formik.errors.lastNameISC)
                          }
                          helperText={
                            formik.touched.lastNameISC &&
                            formik.errors.lastNameISC
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs="2">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <label>
                          Gender <span className="red-color">*</span>
                        </label>
                        <Autocomplete
                          name="genderISC"
                          disablePortal
                          key="combo-box-demo-d2"
                          sx={{ mt: 1, mb: 1 }}
                          options={gender}
                          value={
                            gender !== undefined &&
                              gender !== "" &&
                              formik?.values?.genderISC !== undefined &&
                              formik?.values?.genderISC !== ""
                              ? gender.find(
                                (option) =>
                                  option.id === formik?.values?.genderISC
                              )
                              : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="genderISC"
                              placeholder="Select"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.genderISC &&
                                Boolean(formik.errors.genderISC)
                              }
                              helperText={
                                formik.touched.genderISC &&
                                formik.errors.genderISC
                              }
                            />
                          )}
                          size="small"
                          onChange={(event, newInputValue) => {
                            formik.setFieldValue("genderISC", newInputValue?.id);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs="10">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4} md={4}>
                        <label>
                          Email <span className="red-color">*</span>
                        </label>

                        <TextField
                          sx={{ mt: 2, mb: 2 }}
                          className="form-input form-input-icon-bg"
                          type="email"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                className="input-icon"
                                position="start"
                              >
                                <MailIcon />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Enter your Email"
                          onChange={formik.handleChange}
                          name="emailISC"
                          onBlur={formik.handleBlur}
                          value={formik.values.emailISC}
                          error={
                            formik.touched.emailISC &&
                            Boolean(formik.errors.emailISC)
                          }
                          helperText={
                            formik.touched.emailISC && formik.errors.emailISC
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <label>
                          Mobile No <span className="red-color">*</span>
                        </label>

                        <TextField
                          sx={{ mt: 2, mb: 2 }}
                          className="form-input form-input-icon-bg"
                          type="text"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                className="input-icon"
                                position="start"
                              >
                                <CallIcon />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Enter your mobile number"
                          onChange={formik.handleChange}
                          name="mobileNumberISC"
                          onBlur={formik.handleBlur}
                          value={formik.values.mobileNumberISC}
                          error={
                            formik.touched.mobileNumber &&
                            Boolean(formik.errors.mobileNumberISC)
                          }
                          helperText={
                            formik.touched.mobileNumberISC &&
                            formik.errors.mobileNumberISC
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <label>
                          Date of Birth <span className="red-color">*</span>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3} sx={{ mb: 1 }}>
                            <DesktopDatePicker
                              open={open}
                              onOpen={() => setOpen(true)}
                              onClose={() => setOpen(false)}
                              className="form-input date-input-filed"
                              inputFormat="DD-MM-YYYY"
                              maxDate={new Date()}
                              value={dateOfBirth}
                              onChange={(e) => HandleDateEvent(e, "dobISC")}
                              renderInput={(params) => (
                                <TextField
                                  onClick={(e) => setOpen(true)}
                                  size="small"
                                  name="dobISC"
                                  sx={{ mb: 1 }}
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true,
                                    placeholder: "DD-MM-YYYY",
                                  }}
                                  error={
                                    (formik.touched.dobISC &&
                                      Boolean(formik.errors.dobISC)) ||
                                    Boolean(dobError)
                                  }
                                  helperText={
                                    (formik.touched.dobISC &&
                                      formik.errors.dobISC) ||
                                    dobError
                                  }
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        ) : (
          ""
        )
      }
      {
        formik.values.supportStatus === "Family Member" ? (
          <>
            <Box className="personal-info-main">
              <Box className="form-heading-section">
                <Typography className="form-heading form-heading-align">
                  Personal Details of the person who is requesting.
                </Typography>
              </Box>

              <Box className="personal-info">
                <Grid container spacing={2}>
                  <Grid item xs="2">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <label>
                          Title <span className="red-color">*</span>
                        </label>

                        <Autocomplete
                          name="titleFM"
                          disablePortal
                          key="combo-box-demo-d2"
                          sx={{ mt: 1, mb: 2 }}
                          options={titles}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="titleFM"
                              placeholder="Select"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.titleFM &&
                                Boolean(formik.errors.titleFM)
                              }
                              helperText={
                                formik.touched.titleFM && formik.errors.titleFM
                              }
                            />
                          )}
                          size="small"
                          value={
                            titles !== undefined &&
                              titles !== "" &&
                              props?.formik?.values?.titleFM !== undefined &&
                              props?.formik?.values?.titleFM !== ""
                              ? titles.find(
                                (option) =>
                                  option.id === props?.formik?.values?.titleFM
                              )
                              : ""
                          }
                          onChange={(event, newInputValue) => {
                            formik.setFieldValue("titleFM", newInputValue?.id);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs="10">
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4} md={4}>
                        <label>
                          First Name <span className="red-color">*</span>
                        </label>

                        <TextField
                          sx={{ mt: 2, mb: 2 }}
                          className="form-input"
                          type="text"
                          size="small"
                          placeholder="Enter your First Name"
                          onChange={formik.handleChange}
                          name="firstNameFM"
                          onBlur={formik.handleBlur}
                          value={formik.values.firstNameFM}
                          error={
                            formik.touched.firstNameFM &&
                            Boolean(formik.errors.firstNameFM)
                          }
                          helperText={
                            formik.touched.firstNameFM &&
                            formik.errors.firstNameFM
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <label>Middle Name</label>

                        <TextField
                          sx={{ mt: 2, mb: 2 }}
                          className="form-input"
                          type="text"
                          size="small"
                          placeholder="Enter your Middle Name"
                          onChange={formik.handleChange}
                          name="middleNameFM"
                          onBlur={formik.handleBlur}
                          value={formik.values.middleNameFM}
                          error={
                            formik.touched.middleNameFM &&
                            Boolean(formik.errors.middleNameFM)
                          }
                          helperText={
                            formik.touched.middleNameFM &&
                            formik.errors.middleNameFM
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <label>
                          Last Name <span className="red-color">*</span>
                        </label>

                        <TextField
                          sx={{ mt: 2, mb: 2 }}
                          className="form-input"
                          type="text"
                          size="small"
                          placeholder="Enter your Last Name"
                          onChange={formik.handleChange}
                          name="lastNameFM"
                          onBlur={formik.handleBlur}
                          value={formik.values.lastNameFM}
                          error={
                            formik.touched.lastNameFM &&
                            Boolean(formik.errors.lastNameFM)
                          }
                          helperText={
                            formik.touched.lastNameFM && formik.errors.lastNameFM
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4} md={4}>
                    <label>
                      Email <span className="red-color">*</span>
                    </label>

                    <TextField
                      sx={{ mt: 2, mb: 2 }}
                      className="form-input form-input-icon-bg"
                      type="email"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="input-icon" position="start">
                            <MailIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Enter your Email"
                      onChange={formik.handleChange}
                      name="emailFM"
                      onBlur={formik.handleBlur}
                      value={formik.values.emailFM}
                      error={
                        formik.touched.emailFM && Boolean(formik.errors.emailFM)
                      }
                      helperText={formik.touched.emailFM && formik.errors.emailFM}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <label>
                      Mobile No <span className="red-color">*</span>
                    </label>

                    <TextField
                      sx={{ mt: 2, mb: 2 }}
                      className="form-input form-input-icon-bg"
                      type="text"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment className="input-icon" position="start">
                            <CallIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Enter your mobile number"
                      onChange={formik.handleChange}
                      name="mobileNumberFM"
                      onBlur={formik.handleBlur}
                      value={formik.values.mobileNumberFM}
                      error={
                        formik.touched.mobileNumberFM &&
                        Boolean(formik.errors.mobileNumberFM)
                      }
                      helperText={
                        formik.touched.mobileNumberFM &&
                        formik.errors.mobileNumberFM
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <label>
                      Relationship With Client{" "}
                      <span className="red-color">*</span>
                    </label>
                    <Autocomplete
                      name="relationShipFM"
                      disablePortal
                      key="combo-box-demo-d2"
                      sx={{ mt: 1, mb: 1 }}
                      options={relationShipFM}
                      value={
                        relationShipFM !== undefined &&
                          relationShipFM !== "" &&
                          formik?.values?.relationShipFM !== undefined &&
                          formik?.values?.relationShipFM !== ""
                          ? relationShipFM.find(
                            (option) =>
                              option.id === formik?.values?.relationShipFM
                          )
                          : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="relationShipFM"
                          placeholder="Select"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.relationShipFM &&
                            Boolean(formik.errors.relationShipFM)
                          }
                          helperText={
                            formik.touched.relationShipFM &&
                            formik.errors.relationShipFM
                          }
                        />
                      )}
                      size="small"
                      onChange={(event, newInputValue) => {
                        formik.setFieldValue("relationShipFM", newInputValue?.id);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        ) : (
          ""
        )
      }
    </Box >
  );
};

export default PersonalInfo;
