import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import "../../../assets/css/dashboard.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import TimePicker from "rc-time-picker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InputAdornment from "@mui/material/InputAdornment";
import "rc-time-picker/assets/index.css";
import moment from "moment";
function TimeCardTracking(props) {
  const [inputText, setInputText] = useState("");

  const handleEnterPress = (e, type) => {
    if (e.keyCode === 13) {
      props.addNewAvailability(inputText, type);
      setInputText("");
    }
  };

  const initialValues = {
    date: "",
    logInTime: "",
    breakInTime: "",
    breakOutTime: "",
    logOutTime: "",
  };
  function timeToMinutes(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  }
  const validationSchema = Yup.object().shape({
    logInTime: Yup.string()
      .required("Clock In Time is Required")
      .test(
        "is-not-zero",
        "Clock In Time cannot be 00:00",
        (value) => value !== "00:00"
      ),
  });
  // const validationSchema = Yup.object().shape({
  //   logInTime: Yup.string().required("Clock In Time is Required"),
  //   breakInTime: Yup.string()
  //     .required("Break In Time is Required")
  //     .when(
  //       ["logInTime", "breakOutTime"],
  //       (logInTime, breakOutTime, schema) => {
  //         return logInTime && breakOutTime
  //           ? schema.test({
  //               test: function (value) {
  //                 const logInTimeMinutes = timeToMinutes(logInTime);
  //                 const breakOutTimeMinutes = timeToMinutes(breakOutTime);
  //                 const breakInTimeMinutes = timeToMinutes(value);
  //                 return (
  //                   logInTimeMinutes <= breakInTimeMinutes &&
  //                   breakInTimeMinutes <= breakOutTimeMinutes
  //                 );
  //               },
  //               message:
  //                 "Break In Time must be between Clock In Time and Break Out Time",
  //             })
  //           : schema;
  //       }
  //     ),
  //   breakOutTime: Yup.string()
  //     .required("Break Out Time is Required")
  //     .when(
  //       ["logInTime", "breakInTime", "logOutTime"],
  //       (logInTime, breakInTime, logOutTime, schema) => {
  //         return logInTime && breakInTime && logOutTime
  //           ? schema.test({
  //               test: function (value) {
  //                 const logInTimeMinutes = timeToMinutes(logInTime);
  //                 const breakInTimeMinutes = timeToMinutes(breakInTime);
  //                 const logOutTimeMinutes = timeToMinutes(logOutTime);
  //                 const breakOutTimeMinutes = timeToMinutes(value);
  //                 return (
  //                   breakInTimeMinutes <= breakOutTimeMinutes &&
  //                   breakOutTimeMinutes <= logOutTimeMinutes
  //                 );
  //               },
  //               message:
  //                 "Break Out Time must be between Break In Time and Log Out Time",
  //             })
  //           : schema;
  //       }
  //     ),
  //   logOutTime: Yup.string()
  //     .required("Log Out Time is Required")
  //     .when(
  //       ["logInTime", "breakOutTime"],
  //       (logInTime, breakOutTime, schema) => {
  //         return logInTime && breakOutTime
  //           ? schema.test({
  //               test: function (value) {
  //                 const logInTimeMinutes = timeToMinutes(logInTime);
  //                 const breakOutTimeMinutes = timeToMinutes(breakOutTime);
  //                 const logOutTimeMinutes = timeToMinutes(value);
  //                 return breakOutTimeMinutes <= logOutTimeMinutes;
  //               },
  //               message: "Log Out Time must be after Break Out Time",
  //             })
  //           : schema;
  //       }
  //     ),
  // });

  // Helper function to convert time string to minutes

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      props.addTracking(
        inputText,
        values.logInTime,
        values.breakInTime,
        values.breakOutTime,
        values.logOutTime
      );
      props.handleClose();

      setInputText("");
    },
  });

  useEffect(() => {
    if (props?.editlogInTime) {
      formik.setFieldValue("logInTime", props?.editlogInTime);
      formik.setFieldValue("logOutTime", props?.editlogOutTime);
      formik.setFieldValue("breakInTime", props?.editBreakIn);
      formik.setFieldValue("breakOutTime", props?.editBreakOut);
    } else {
      formik.setFieldValue("logInTime", "");
      formik.setFieldValue("logOutTime", "");
      formik.setFieldValue("breakInTime", "");
      formik.setFieldValue("breakOutTime", "");
    }
  }, [
    props?.editlogInTime,
    props?.editlogOutTime,
    props?.editBreakIn,
    props?.editBreakOut,
  ]);
  const format1 = "HH:mm";

  const now = moment();

  const disabledHours = () => {
    const hours = [];
    for (let i = 0; i < now.hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const disabledMinutes = (selectedHour) => {
    const minutes = [];
    if (selectedHour === now.hour()) {
      for (let i = 0; i <= now.minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  return (
    <div className="input-container dashboard-form task-board">
      <Box>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Box className="d-flex flex-column">
            <label>Clock In</label>
            {/* <TimePicker
              //  style={{ width: "150px" }}
              id="logInTime"
              defaultValue="10:10"
              variant="filled"
              type="time"
              // className="form-input"
              className="aad-appt date-picker1"
              onChange={(e) => {
                setInputText(e.target.value);
                formik.setFieldValue("logInTime", e.target.value);
                // formik?.handleChange();
              }}

              format={format1}
              use24Hours
              inputReadOnly
              onBlur={formik?.handleBlur}
              value={formik?.values?.logInTime}
              onKeyDown={(e) => handleEnterPress(e, "logInTime")}
              renderInput={(params) => (
                <TextField
                  size="small"
                  name="appointmentDetails.endTime"
                  // sx={{ mt: 2, mb: 2, width: "100%" }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "00:00",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <AccessTimeIcon /> <AccessTimeIcon />{" "}
                    </InputAdornment>
                  }
                />
              )}
            /> */}
            <TimePicker
              //  style={{ width: "150px" }}

              placeholder={`00:00`}
              showSecond={false}
              name="logInTime"
              // className="form-input"
              className="aad-appt date-picker1"
              onChange={(value) => {
                setInputText(value.format(format1));
                formik.setFieldValue("logInTime", value.format(format1));
              }}
              // onBlur={handleBlur}
              format={format1}
              use24Hours
              inputReadOnly
              //disabledHours={disabledHours}
              //disabledMinutes={disabledMinutes}
              value={
                formik?.values?.logInTime !== null &&
                formik?.values?.logInTime !== undefined &&
                formik?.values?.logInTime !== ""
                  ? moment(formik?.values?.logInTime, format1)
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  size="small"
                  name="logInTime"
                  // sx={{ mt: 2, mb: 2, width: "100%" }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "00:00",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <AccessTimeIcon /> <AccessTimeIcon />{" "}
                    </InputAdornment>
                  }
                  // error={
                  //   touched?.appointmentDetails?.endTime &&
                  //   Boolean(
                  //     errors?.appointmentDetails?.endTime
                  //   )
                  // }
                  // helperText={
                  //   touched?.appointmentDetails?.endTime &&
                  //   errors?.appointmentDetails?.endTime
                  // }
                />
              )}
            />
            {formik.touched.logInTime && formik.errors.logInTime && (
              <span role="alert" style={{ color: "red", paddingBottom: "2%" }}>
                {formik.errors.logInTime}
              </span>
            )}

            <label>Break Start</label>
            <TimePicker
              //  style={{ width: "150px" }}

              placeholder={`00:00`}
              showSecond={false}
              name="breakInTime"
              // className="form-input"
              className="aad-appt date-picker1"
              onChange={(value) => {
                setInputText(value.format(format1));
                formik.setFieldValue("breakInTime", value.format(format1));
              }}
              // onBlur={handleBlur}
              format={format1}
              use24Hours
              inputReadOnly
              value={
                formik?.values?.breakInTime !== null &&
                formik?.values?.breakInTime !== undefined &&
                formik?.values?.breakInTime !== ""
                  ? moment(formik?.values?.breakInTime, format1)
                  : ""
              }
              //disabledHours={disabledHours}
              // disabledMinutes={disabledMinutes}
              renderInput={(params) => (
                <TextField
                  size="small"
                  name="breakInTime"
                  // sx={{ mt: 2, mb: 2, width: "100%" }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "00:00",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <AccessTimeIcon /> <AccessTimeIcon />{" "}
                    </InputAdornment>
                  }
                  // error={
                  //   touched?.appointmentDetails?.endTime &&
                  //   Boolean(
                  //     errors?.appointmentDetails?.endTime
                  //   )
                  // }
                  // helperText={
                  //   touched?.appointmentDetails?.endTime &&
                  //   errors?.appointmentDetails?.endTime
                  // }
                />
              )}
            />
            {/* <TextField
              id="breakInTime"
              defaultValue="10:18"
              variant="filled"
              className=" inputlist-field"
              type="time"
              // value={inputText.id}
              inputProps={{
                step: 300, // set to 5 minutes
                inputMode: "numeric",
                pattern: "[0-9]{2}:[0-9]{2}", // set the format to HH:MM
              }}
              onChange={(e) => {
                setInputText(e.target.value);
                formik.setFieldValue("breakInTime", e.target.value);
                // formik?.handleChange();
              }}
              name="breakInTime"
              onBlur={formik?.handleBlur}
              value={formik?.values?.breakInTime}
              onKeyDown={(e) => handleEnterPress(e, "breakInTime")}
            /> */}

            <label>Break End</label>

            <TimePicker
              //  style={{ width: "150px" }}

              placeholder={`00:00`}
              showSecond={false}
              name="breakOutTime"
              // className="form-input"
              className="aad-appt date-picker1"
              onChange={(value) => {
                setInputText(value.format(format1));
                formik.setFieldValue("breakOutTime", value.format(format1));
              }}
              // onBlur={handleBlur}
              format={format1}
              use24Hours
              inputReadOnly
              value={
                formik?.values?.breakOutTime !== null &&
                formik?.values?.breakOutTime !== undefined &&
                formik?.values?.breakOutTime !== ""
                  ? moment(formik?.values?.breakOutTime, format1)
                  : ""
              }
              //disabledHours={disabledHours}
              //disabledMinutes={disabledMinutes}
              renderInput={(params) => (
                <TextField
                  size="small"
                  name="breakOutTime"
                  // sx={{ mt: 2, mb: 2, width: "100%" }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "00:00",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <AccessTimeIcon /> <AccessTimeIcon />{" "}
                    </InputAdornment>
                  }
                  // error={
                  //   touched?.appointmentDetails?.endTime &&
                  //   Boolean(
                  //     errors?.appointmentDetails?.endTime
                  //   )
                  // }
                  // helperText={
                  //   touched?.appointmentDetails?.endTime &&
                  //   errors?.appointmentDetails?.endTime
                  // }
                />
              )}
            />
            {/* <TextField
              id="breakOutTime"
              defaultValue="10:18"
              variant="filled"
              className=" inputlist-field"
              type="time"
              // value={inputText.id}
              inputProps={{
                step: 300, // set to 5 minutes
                inputMode: "numeric",
                pattern: "[0-9]{2}:[0-9]{2}", // set the format to HH:MM
              }}
              onChange={(e) => {
                setInputText(e.target.value);
                formik.setFieldValue("breakOutTime", e.target.value);
                // formik?.handleChange();
              }}
              name="breakOutTime"
              onBlur={formik?.handleBlur}
              value={formik?.values?.breakOutTime}
              onKeyDown={(e) => handleEnterPress(e, "breakOutTime")}
            /> */}
            <label>Clock Out</label>
            <TimePicker
              //  style={{ width: "150px" }}

              placeholder={`00:00`}
              showSecond={false}
              name="logOutTime"
              // className="form-input"
              className="aad-appt date-picker1"
              style={{ width: "100%" }}
              onChange={(value) => {
                setInputText(value.format(format1));
                formik.setFieldValue("logOutTime", value.format(format1));
              }}
              // onBlur={handleBlur}
              format={format1}
              use24Hours
              inputReadOnly
              value={
                formik?.values?.logOutTime !== null &&
                formik?.values?.logOutTime !== undefined &&
                formik?.values?.logOutTime !== ""
                  ? moment(formik?.values?.logOutTime, format1)
                  : ""
              }
              //disabledHours={disabledHours}
              //disabledMinutes={disabledMinutes}
              renderInput={(params) => (
                <TextField
                  size="small"
                  name="logOutTime"
                  sx={{ mt: 2, mb: 2, width: "100%!important" }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "00:00",
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {" "}
                      <AccessTimeIcon /> <AccessTimeIcon />{" "}
                    </InputAdornment>
                  }
                  // error={
                  //   touched?.appointmentDetails?.endTime &&
                  //   Boolean(
                  //     errors?.appointmentDetails?.endTime
                  //   )
                  // }
                  // helperText={
                  //   touched?.appointmentDetails?.endTime &&
                  //   errors?.appointmentDetails?.endTime
                  // }
                />
              )}
            />
            {/* <TextField
              id="logOutTime"
              defaultValue="10:18"
              variant="filled"
              className=" inputlist-field"
              type="time"
              // value={inputText.id}
              inputProps={{
                step: 300, // set to 5 minutes
                inputMode: "numeric",
                pattern: "[0-9]{2}:[0-9]{2}", // set the format to HH:MM
              }}
              onChange={(e) => {
                setInputText(e.target.value);
                formik.setFieldValue("logOutTime", e.target.value);
                // formik?.handleChange();
              }}
              name="logOutTime"
              onBlur={formik?.handleBlur}
              value={formik?.values?.logOutTime}
              onKeyDown={(e) => handleEnterPress(e, "logOutTime")}
            /> */}
            {formik.touched.logOutTime && formik.errors.logOutTime && (
              <span role="alert" style={{ color: "red", paddingBottom: "2%" }}>
                {formik.errors.logOutTime}
              </span>
            )}

            {formik.touched.numberOf && formik.errors.numberOf && (
              <span role="alert" style={{ color: "red", paddingBottom: "2%" }}>
                {formik.errors.numberOf}
              </span>
            )}
          </Box>
          <Box className="agency-btn-section">
            <a
              href="javascript:;"
              autoFocus
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              Save
            </a>
          </Box>
        </form>
      </Box>
    </div>
  );
}

export default TimeCardTracking;
