import React from 'react';
import moment from 'moment';
import "../../../src/assets/css/custom.css";
import { Stack } from '@mui/material';



const Progress = ({ progressValue, planStartDate, planEndDate }) => {
  const roundedProgressValue = Math.round(progressValue);

  const getProgress = (start, end) => {
    if (start === undefined || end === undefined || start === null || end === null) {
      return { completed: 0, remaining: 100 };
    } else {
      const now = new Date().valueOf();
      start = new Date(start).valueOf();
      end = new Date(end).valueOf();

      let completedProgress = ((now - start) / (end - start)) * 100;
      completedProgress = Math.max(completedProgress, 0);
      completedProgress = Math.min(completedProgress, 100);

      completedProgress = Math.round(completedProgress);

      const remainingProgress = 100 - completedProgress;

      return { completed: completedProgress, remaining: remainingProgress };
    }
  };

  const progress = getProgress(planStartDate, planEndDate);

  return (
    <div className="progress-container">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={100}
        className='w-100 new-progress'
      >
        <div className="completed-value">{Math.ceil(progress.completed)}%</div>
        <div className="remaining-value">{Math.ceil(progress.remaining)}%</div>
      </Stack>

      <div className="progress-bar">
        <div
          className="completed-progress"
          style={{ width: `${progress.completed}%`, backgroundColor: 'red' }}
        />
        <div
          className="remaining-progress"
          style={{ width: `${progress.remaining}%`, backgroundColor: 'green' }}
        />
      </div>
      <div className="date-container">
        <div className="start-date">{moment(planStartDate).format('DD-MM-YYYY')}</div>
        <div className="end-date">{moment(planEndDate).format('DD-MM-YYYY')}</div>
      </div>
    </div>
  );
};

export default Progress;

