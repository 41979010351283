import localstorageData from "./localstorageData";

function generateUrl() {
  const userData = localstorageData();
  const role = userData?.role;

  // let urlLink = "super-admin";
  // if (role !== undefined && role === "serviceProvider") {
  //   urlLink = "service-provider";
  // } else if (role === "generalManager") {
  //   urlLink = "general-manager";
  // } else if (role === "supportCoordinator") {
  //   urlLink = "support-coordinator";
  // }
  //  else if (role === "healthCarer") {
  //   urlLink = "health-carer";
  // }

  let urlLink = "super-admin";
  if (role === "serviceProvider") {
    urlLink = "service-provider";
  } else if (role === "generalManager") {
    urlLink = "general-manager";
  } else if (role === "itManager") {
    urlLink = "it-manager";
  } else if (role === "supportCoordinator") {
    urlLink = "support-coordinator";
  } else if (role === "planManager") {
    urlLink = "plan-manager";
  } else if (role === "Stakeholder") {
    urlLink = "stakeholder";
  } else if (role === "accountant") {
    urlLink = "accountant";
  } else if (role === "hr") {
    urlLink = "hr";
  } else if (role === "healthCarer") {
    urlLink = "health-carer";
  } else if (role === "client") {
    urlLink = "client";
  } else if (role === "superAdmin") {
    urlLink = "super-admin";
  } else if (role === "admin") {
    urlLink = "admin";
  } else if (role === "familyMember") {
    urlLink = "family-member";
  } else {
    urlLink = role;
  }
  // return role;

  return urlLink;
}

export default generateUrl;
