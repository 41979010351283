import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { verifyMailOtp, resendOtp } from "../../api";
import { toast } from "react-toastify";
import BannerSlider from "../../components/ui/BannerSlider";
import Link from "@mui/material/Link";

export default function SignInSide() {
  const [disableButton, setDisableButton] = useState(false);
  const [disableButtonResend, setDisableButtonResend] = useState(false);
  const navigate = useNavigate();

  const fieldLogout = () => {
    localStorage.removeItem("userData");
    navigate("/sign-in");
  };

  const location = useLocation();
  const emailId = location.state;
  const initialValues = {
    emailOTP: "",
  };
  const validationSchema = Yup.object().shape({
    emailOTP: Yup.string().required("OTP is required"),
  });

  const ResendOtpCode = () => {
    const postData = {
      email: emailId,
    };
    setDisableButtonResend(true);
    axios
      .post(resendOtp, postData)
      .then((res) => {
        if (res.data.success === true) {
          toast.success(res.data.message);
          setDisableButtonResend(false);
        } else {
          setDisableButtonResend(false);
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Please try again, Something went wrong!");
      });
  };

  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    ResendOtpCodeTimer();
    if (seconds === 0) {
      setDisableButtonResend(false);
    }
  }, [seconds]);

  // useEffect(() => {
  const ResendOtpCodeTimer = () => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(29);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  };
  //}, [seconds]);
  return (
    <Box id="personal-info" width="100%" height="100%">
      <Grid container>
        <Grid item xs={12} sm={12} md={7}>
          <Box>
            <BannerSlider />
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
            <Box className="form-main-box">
              <Box>
                <h2 mb={3} className="sign-title">
                  Email Verification Success
                </h2>
                <p>Login credentials sent on your registered email.</p>
              </Box>
              <Box>
                <Link href="/sign-in" variant="body2">
                  <Button
                    className="signupbutton"
                    type="submit"
                    fullWidth
                    sx={{
                      mt: 3,
                      mb: 2,
                      width: "350px", // Set the width to 100% to make it expand to the container's width
                    }}
                    disabled={disableButton}
                  >
                    Sign In
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
