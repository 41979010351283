import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Header from "../../HeaderExploreMarket";
import Stepper from "./Stepper";

export default function SetupAccountHealthCarer() {
  return (
    <Box id="account-setup">
      <Header />

      <Box className="container">
        <Box className="account-setup-top-content">
          <h3>Let's Understand Your Requirement</h3>
          <Typography>Fill This Information to Proceed</Typography>
        </Box>
      </Box>
      <Box className="container">
        <Stepper />
      </Box>
    </Box>
  );
}
