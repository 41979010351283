import React, { Component } from 'react';
import './home-ui.css';
import { Grid } from '@mui/material';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

class Counter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      isVisible: false
    };
    this.counterRef = React.createRef();
    this.observer = null;
  }

  componentDidMount() {
    this.observer = new IntersectionObserver(
      this.handleIntersection,
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // Trigger when 50% of the target is visible
      }
    );
    this.observer.observe(this.counterRef.current);
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  handleIntersection = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.setState({ isVisible: true });
        this.startCounters();
        this.observer.unobserve(this.counterRef.current);
      }
    });
  }

  startCounters = () => {
    this.counterInterval = setInterval(() => {
      this.setState(prevState => ({
        count1: prevState.count1 + 1,
        count2: prevState.count2 + 2, // Example: Increase by 2
        count3: prevState.count3 + 1, // Example: Increase by 3
        count4: prevState.count4 + 4  // Example: Increase by 4
      }), () => {
        if (this.state.count1 === 100) {
          clearInterval(this.counterInterval);
        }
      });
    }, 10);
  }

  render() {
    return (
      <div className="counter" ref={this.counterRef}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3}>
            <Box>
              <Box className="different-card-title counter-value">
                <h1>{this.state.count1}K</h1>
                <h3>Lorem Ipsum</h3>
              </Box>
              <Box className="differentCard-text">
                <Typography>
                  Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.
                  Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet.
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            <Box>
              <Box className="different-card-title counter-value">
                <h1>{this.state.count2}+</h1>
                <h3>Lorem Ipsum</h3>
              </Box>
              <Box className="differentCard-text">
                <Typography>
                  Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.
                  Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet.
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            <Box>
              <Box className="different-card-title counter-value">
                <h1>{this.state.count3}%</h1>
                <h3>Lorem Ipsum</h3>
              </Box>
              <Box className="differentCard-text">
                <Typography>
                  Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.
                  Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet.
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            <Box>
              <Box className="different-card-title counter-value">
                <h1>{this.state.count4}+</h1>
                <h3>Lorem Ipsum</h3>
              </Box>
              <Box className="differentCard-text">
                <Typography>
                  Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet.
                  Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Counter;
