// src/components/PrivacyPolicy.js

import React from "react";

import WebHeader from "../../../Website/Web-Header";

import WebFooter from "../../../Website/Web-footer";

import { Box } from "@mui/material";


function PrivacyPolicy() {
  return (

    <Box className="web-topBanner-bg  blog-main-banner">

<WebHeader />

<Box>

<Box className="webContainer">

<div className="">
      <h1 className="mt-3 mb-3 mb-4">Terms and Conditions for Hubshift</h1>
      
      <h4>1. Introduction</h4>
      <p className="mb-3">These terms and conditions ("Terms") constitute the agreement between the user ("Client") and the entity providing Hubshift ("Provider"). By subscribing to and using Hubshift, the Client acknowledges having read, understood, and agreed to these Terms.</p>
      
      <h4>2. Subscription Services</h4>
      <p className="mb-3">Hubshift will be provided to the Client subject to these Terms and any policies and guidelines published by Hubshift from time to time. The service level and features available to the Client depend on the subscription tier selected, which Hubshift may modify according to these Terms. Changes to subscription tiers, including feature adjustments or fee variations, are at Hubshift's discretion, subject to prior notification to the Client.</p>
      
      <h4>3. Fees</h4>
      <p className="mb-3">The Client agrees to pay subscription fees as determined by the selected tier and any fees for additional services. Hubshift may adjust fees to reflect changes in service costs, subject to a maximum increase of 10% and prior written notification to the Client. Failure to make timely payments may result in service suspension or termination at Hubshift's discretion. The Client is responsible for all costs incurred by Hubshift in collecting overdue payments.</p>
      
      <h4>4. The Services & Application</h4>
      <p className="mb-3">Upon agreeing to these Terms and selecting a subscription tier, Hubshift grants the Client a non-exclusive, revocable license to access and use the software in accordance with these Terms. Hubshift retains all rights to the software, data, and content within the application, excluding any client-provided data. The Client is responsible for the accuracy and legality of all data they provide and must ensure the security of their access credentials.</p>
      
      <h4>5. Client Obligations</h4>
      <p className="mb-3">The Client must not use Hubshift to access, store, or distribute prohibited content, including material that is unlawful, defamatory, obscene, or infringes on intellectual property rights. Hubshift reserves the right to disable access in the event of such violations. The Client is also responsible for preventing unauthorized use of the software and complying with all usage instructions provided by Hubshift.</p>
      
      <h4>6. Provider Obligations</h4>
      <p className="mb-3">Hubshift does not guarantee uninterrupted or error-free access to the software and services. Availability may be affected by maintenance, updates, or external factors beyond Hubshift's control. Hubshift will endeavor to maintain service availability and notify the Client of any significant disruptions.</p>
      
      <h4>7. Additional Services</h4>
      <p className="mb-3">Any services beyond the standard subscription offerings will be subject to separate agreement and additional fees. Hubshift may introduce policies related to support and service levels as deemed appropriate.</p>
      
      <h4>8. Third Parties</h4>
      <p className="mb-3">Hubshift may incorporate or offer access to third-party applications, subject to their own terms and conditions. Hubshift is not responsible for the content, operation, or reliability of third-party applications.</p>
      
      <h4>9. Security</h4>
      <p className="mb-3" >Both parties will use best efforts to protect data and confidentiality, with the Client responsible for safeguarding their passwords and files. In the event of unauthorized access, immediate notification and remedial actions are required.</p>
      
      <h4>10. Intellectual Property Rights</h4>
      <p className="mb-3">Hubshift asserts ownership or the right to use all components of the software and services, guaranteeing that its offerings do not infringe on any third-party intellectual property rights.</p>
      
      <h4>11. Warranties</h4>
      <p className="mb-3">The Client warrants their authority to agree to these Terms and ensures that all provided information is accurate. Hubshift disclaims all warranties related to the service outcomes, revenue increases, or accuracy of provided analyses.</p>
      
      <h4>12. Indemnities</h4>
      <p className="mb-3">The Client agrees to indemnify Hubshift against claims arising from their breach of these Terms, legal violations, or misuse of the software.</p>
      
      <h4>13. Liability</h4>
      <p className="mb-3">Hubshift's liability is limited to the greatest extent permitted by law, excluding any liability for the Client's use of the software or for any indirect damages.</p>
      
      <h4>14. GST</h4>
      <p className="mb-3">All fees are exclusive of GST, which will be charged as applicable and in accordance with Australian tax laws.</p>
      
      <h4>15. Suspension</h4>
      <p className="mb-3">Hubshift may suspend services for non-payment, unauthorized access, or at the Client's request, with no liability for resulting claims.</p>
      
      <h4>16. Termination</h4>
      <p className="mb-3">Either party may terminate the agreement for breach by the other, with Hubshift also reserving the right to terminate for convenience. Upon termination, all rights and obligations cease, except those intended to survive termination.</p>
      
      <h4>17. Dispute Resolution</h4>
      <p className="mb-3">Disputes will be addressed through negotiation, followed by mediation if necessary. Urgent relief may be sought through legal proceedings.</p>
      
      <h4>18. Confidentiality</h4>
      <p className="mb-3">Both parties agree to maintain the confidentiality of information shared under these Terms, except as required by law or necessary for the performance of obligations.</p>
      
      <h4>19. Force Majeure</h4>
      <p className="mb-3">Neither party is liable for delays or failures in performance resulting from events beyond their reasonable control.</p>
      
      <h4>20. Variation</h4>
      <p className="mb-3">Hubshift may modify these Terms with appropriate notice to the Client, who accepts such changes by continuing to use the services.</p>
      
      <h4>21. Notices</h4>
      <p className="mb-3">Notices must be in writing and are considered received when delivered according to specified methods.</p>
      
      <h4>22. Interpretation</h4>
      <p className="mb-3">These Terms are governed by the laws of NSW, Australia, with any disputes subject to the jurisdiction of its courts.</p>
      
      <h4>23. General</h4>
      <p className="mb-3">This agreement represents the entire understanding between the Client and Hubshift regarding the use of the software and services.</p>
    </div>

    </Box>





    </Box>

    <WebFooter />

    </Box>
  );
}

export default PrivacyPolicy;
