import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../../../Website/Web-Header";
import WebFooter from "../../../Website/Web-footer";
import BookDemo from "../../../Website/HomeUI/BookDemo";
import phoneImg from "../../../assets/images/guide-phone-img.png";
import TextField from "@mui/material/TextField";
import linkdin from "../../../assets/images/web-linkdin.png";
import Facebook from "../../../assets/images/web-facebook.png";
import Twitter from "../../../assets/images/web-twitter.png";
import "../../../Website/HomeUI/home-ui.css";
import { getGuide, listGuideForDetails, deleteBlog, addGuideDownload } from "../../../api";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import { useFormik } from "formik";
import zustandStore from "../../../helpers/zustandStore";
import localstorageData from "../../../helpers/localstorageData";
import { toast } from "react-toastify";
import * as Yup from "yup";

export default function BlogsDetails() {

  const [data, setData] = useState();
  const [listData, setListData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const { menuList } = zustandStore();
  const [count, setCount] = useState("");
  const [isActive, setActive] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [list, setList] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const navigate = useNavigate();
  const location = useLocation();
  const blogId = location?.state?.blogId;
  const pageType = location?.state?.pageType;



  console.log("location?.state", location?.state)
  let userData = localstorageData();
  let sessionId = userData._id;
  // const editId = "65a8dac742179f25f4197e07"
  const limit = "3"

  useEffect(() => {
    if (blogId !== null) {
      axios
        .get(`${getGuide}/${blogId}`)
        .then((res) => {
          let dataval = res?.data?.data;
          setData(dataval);
        })
        .catch((error) => {
          console.log(error);
        });

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [blogId]);

  useEffect(() => {
    getaList();
  }, [limit, searchTerm, blogId]);

  const getaList = async () => {
    axios
      .get(
        `${listGuideForDetails}?searchTerm=${searchTerm}&page=1&items_per_page=${limit}&currentBlogId=${blogId}`
      )
      .then((res) => {
        // console.log("resdata", res?.data?.data?.docs);
        setListData(res?.data?.data?.docs);
        let total = res.data.data.totalDocs;
        setpageCount(Math.ceil(total / limit));
        setCount(total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const initialValues = {
    email: data !== undefined ? data.email : "",
    guideName: data !== undefined ? data.guideName : "",


  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      console.log("Submitting form with values:", values);
      values.createdBy = sessionId;
      values.updatedBy = sessionId;
      values.guideName = data?.title;

      const guideDownloadUrl = data?.guide?.location;

      // Trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = guideDownloadUrl;
      downloadLink.download = `Guide_${data?.title || "Guide"}.pdf`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

   

      axios
        .post(`${addGuideDownload}?createdBy=${sessionId}`, values)
        .then((res) => {
          if (res.data.success === false) {
            setDisableButton(false);
            toast.error(res.data.message);
          }

          if (res.data.success === true) {
            toast.success(res.data.message);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    },
  });


  return (
    <Box id="guides-details">
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box className="w-100">
          <WebHeader />

          <Box className="webContainer">
            <Box className="W-100 ">
              <Box className="guide-details-Content space-pt-80">
                <Box className="text-center">
                  <h1>
                    {data?.title}
                  </h1>
                  <Typography className="space-32">
                    {data?.description}
                  </Typography>

                  <Typography className="text-sm-bold p-0">
                    {data?.author}
                  </Typography>
                  <span>{new Date(data?.createdAt).toDateString()}</span>
                </Box>
              </Box>
            </Box>

            <Box className="w-100 space-pt-80">
              <Box className="w-100 ">
                <img src={data?.image?.location} className="w-100 radious-20" alt="Guide Image" />
              </Box>

            </Box>

            {/* Guides details text  */}

            <Box>
              <Box className="guides-details-text ">
                <Typography className="space-pt-60">
                  <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                </Typography>


              </Box>
            </Box>


            <Box className="guide-download-section space-80">
              <Grid container className="radious-20 overflow-hidden">
                <Grid item xs={12} sm={4} md={4} className="free-guide-info">
                  <Box className=" text-center">
                    <Typography>Free Guide</Typography>
                    <span>Get it delivered to your inbox</span>

                    <Box className="guide-info-image">
                      <img src={phoneImg} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={8} className="guide-download-login">
                  <Box className="w-100">
                    <h2>{data?.title}</h2>
                    <Typography>
                      Download our guide, packed with actionable strategies,
                      communication techniques, and tools to transform your
                      caregiving and support practices.
                    </Typography>

                    <Box className="space-pt-40 d-flex free-guide-wrapper align-items-center">
                      <TextField
                        required
                        className="guide-email-input"
                        type="email"
                        size="small"
                        name="email"
                        sx={{ mt: 2, mb: 2 }}

                        margin="normal"
                        fullWidth
                        placeholder="Enter Email"
                        onChange={formik?.handleChange}
                        onBlur={formik?.handleBlur}
                        value={formik?.values?.email}

                        error={
                          formik?.touched?.email && Boolean(formik?.errors?.email)
                        }
                        helperText={formik?.touched?.email && formik?.errors?.email}
                      />

                      <Box className="agency-btn-section  agency-btn-section-vk free-guide-button" sx={{ mt: 0 }}>
                        <button type="submit" disabled={disableButton}>
                          {disableButton === true ? "Processing..." : "Download Guide"}
                        </button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box className="guide-sm-divider"></Box>
            <Box className="d-flex space-pt-32 align-items-center justify-content-center">
              <Box className="guide-download-linkIcons">
                <img src={linkdin} />
              </Box>
              <Box className="guide-download-linkIcons">
                <img src={Facebook} />
              </Box>
              <Box className="guide-download-linkIcons">
                <img src={Twitter} />
              </Box>
            </Box>
          </Box>
        </Box>


        <Box className=" space-lg web-topBanner-bg space-mt-lg">
          <Box className="webContainer">
            <h1 className="space-pb-60"> You may also like these Guides</h1>
            <Grid container spacing={2}>
              {listData.map((blogItem) => (
                <Grid item xs={12} sm={6} md={4} key={blogItem?._id}>
                  <Box>
                    <Box className="blog-cardImg">
                      <img src={blogItem?.image?.location} alt={blogItem.title} />
                    </Box>

                    <Box className="blog-card-smTitle">
                      <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
                        <span>{blogItem?.category}</span>
                      </Box>
                    </Box>
                    <Box className="blog-card-title">
                      <Link
                        to={"/guide-details"}
                        state={{ blogId: blogItem?._id, pageType: "blogDetails" }}
                        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                      >
                        <h3>
                          {blogItem?.title}
                        </h3>
                      </Link>
                    </Box>
                    <Box className="blogCard-text">
                      <Typography>{blogItem?.description}</Typography>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

          </Box>
        </Box>

        {/* book demo section  */}

        <Box className="space-m-80">
          <Box className="webContainer">
            <BookDemo />
          </Box>
        </Box>

        <WebFooter />
      </form>
    </Box>
  );
};


