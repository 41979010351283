import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from 'react-router-dom'

import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  CardHeader,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "../../assets/css/thankyou.css"


export default function Thankyou() {
  return (
    <>
      <Box id='thankyou'>
        <Card className="thankyou-card">

          <Box className='thankyou-box'>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                  <CheckCircleIcon className='check-circle-icon'
                  />
                </IconButton>
              }
            />
          </Box>

          <CardContent>
            <Typography variant="h6" color="#248740" textAlign="center">
              Your account setup is completed
            </Typography>

            <Typography
              mt={1}
              fontWeight="bold"
              variant="h6"
              color="#248740"
              textAlign="center"
            >
              Congratulations & thank you for joining Hubshift Family!
            </Typography>
          </CardContent>

          <Box mt={2} mb={2} className='thankyou-box'>
            <CardActions>
              <Button className='buttonsty'
                type="submit"
                variant="contained"
                size="large"
                id="next-btn"
              >
                <Link to="/">Go to Dashboard</Link>

              </Button>
            </CardActions>
          </Box>
        </Card>
      </Box>
    </>
  );
}


