import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import popArrow from "../../../assets/images/popover-arrow.png";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import callIcon from "../../../assets/images/table-call.png";
import locationIcon from "../../../assets/images/location-icon.svg";
import noImage from "../../../assets/images/noImage.png";

const ToggleFunction = (props) => {
  const navigate = useNavigate();
  let userData = JSON.parse(localStorage.getItem("userData"));

  const userTypeName = userData?.userTypeName;

  let userTypeUrl = "super-admin";
  if (userTypeName === "Service Provider") {
    userTypeUrl = "service-provider";
  } else if (userTypeName === "Support Coordinator") {
    userTypeUrl = "support-coordinator";
  } else if (userTypeName === "Admin") {
    userTypeUrl = "admin";
  }

  const [isToggled, setIsToggled] = useState(false);

  // const handleToggle = () => {
  //   setIsToggled((prevState) => !prevState);
  // };
  const handleToggle = () => {
    props.onToggle();
  };

  const addAppointment = (e, healthCarerId) => {
    e.preventDefault();
    const state = {
      healthCarerId: healthCarerId,
      date: null,
      startTime: "",
      endTime: "",
      back: "Add New",
    };
    navigate(`/${userTypeUrl}/add-appointment`, { state });
  };

  return (
    <div>
      <Box className="employee-box cursor-pointer" onClick={handleToggle}>
        <img
          src={
            props?.data?.image !== undefined && props?.data?.image !== ""
              ? props?.data?.image
              : noImage
          }
          className="employee-profile"
        />{" "}
        <span>{props?.data?.fullName.substring(0, 20)}</span>{" "}
        {props?.data?.onCall ? <img src={callIcon} /> : ""}
      </Box>

      {props.isOpen && (
        <div className="calender-popover hc-calender-popover">
          <div className="w-100 calender-main">
            <div className="d-flex align-content-center">
              <img
                className="health-carer-popover-img"
                src={
                  props?.data?.image !== undefined && props?.data?.image !== ""
                    ? props?.data?.image
                    : noImage
                }
              />

              <div className="appoint-popover-profile-text">
                <Typography className="health-carer-popover-client health-carer-popover-block">
                  {props?.data?.fullName}
                </Typography>
                <span>{props?.data?.userType} </span>
              </div>
            </div>

            <div className="popover-content d-flex align-content-center">
              <Typography> Employee ID:</Typography>
              <span>{props?.data?.employeeId}</span>
            </div>
            <div className="popover-content d-flex align-content-center">
              <Typography> Job Title:</Typography>
              <span>{props?.data?.jobtitles}</span>
            </div>

            <div className="popover-content d-flex align-content-center">
              <Typography> Job Type:</Typography>
              <span>{props?.data?.jobtypes}</span>
            </div>

            <div className="popover-content d-flex align-content-center">
              <Typography> Level:</Typography>
              <span>{props?.data?.levels}</span>
            </div>

            <div className="popover-content d-flex align-content-center">
              <Typography> Awards:</Typography>
              <span>-</span>
            </div>

            <div className="popover-location d-flex align-items-center">
              <img src={locationIcon} />
              <Typography>{props?.data?.location}</Typography>
            </div>

            {/* <div className="popover-location d-flex align-items-center">
              <img src={calenderIcon} />
              <Typography>{props?.data?.createdAt}</Typography>
            </div> */}

            <Box
              className="client-btn health-carer-popover-button text-end"
              onClick={(e) => addAppointment(e, props?.data?.healthCarerId)}
            >
              <Link className="add-btn-form">
                <AddIcon />
                Add Appointment
              </Link>
            </Box>

            <img src={popArrow} className="health-carer-popover-arrow" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ToggleFunction;
