import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../../frontend/src/pages/loader/Loader";
import { FieldsListLoading } from "../../../frontend/src/pages/components/loading/FieldsListLoading";

//404 page
import Error from "./../components/Error";

import { SuperAdminRoutes } from "./AllRoutesArray";

export default function SuperAdmin() {
  return (
    <Routes>
      {SuperAdminRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <route.component />
            </Suspense>
          }
        />
      ))}
      <Route path="*" element={<Error />} />
    </Routes>
  );
}
