import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from "react-router-dom";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const rows = [
  createData('Foreign Passport (current)', 'Name, Photo', 70, '',70),
  createData('Australian Passport (current or expired \n within last 2 years but not cancelled)', 'Name, Photo', 70, '', 70),
  createData('Australian Citizenship Certificate', 'Name', 70, '', ''),
  createData('Full Birth certificate (not birth certificate extract)', 'Name', 70, '', ''),
  createData('Certificate of Identity issued by the Australian Government to refugees and non Australian citizens for entry to Australia', 'Name', 70, '', ''),
  createData('Australian Driver Licence/Learner’s Permit)', 'Name, Address, Photo', 40, '', ''),
  createData('Current (Australian) Tertiary Student Identification Card)', 'Name, Photo', 40, '', ''),

];

const rowsSec = [
  createData('Department of Veterans Affairs (DVA) card', 'Name, Address', 40, '', ''),
  createData('Centrelink card (with reference number)', 'Name, Address', 40, '', ''),
]




export default function BasicTable() {

  const [hidden, setHidden] = useState(false);
const [visible, setVisible] = useState(false);

  return (
    <Box>
      <TableContainer className='table-main' component={Paper}>
        <Typography><strong>100 Points Identification</strong> </Typography>
        <Table id='table' aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Document</TableCell>
              <TableCell align="left">Required on Document</TableCell>
              <TableCell align="right">Points <br /> Worth</TableCell>
              <TableCell align="right">Upload</TableCell>
              <TableCell align="right">Points <br /> Gained</TableCell>
            </TableRow>

          </TableHead>

          <TableBody className='table-body'>
            <TableRow><TableCell className='border-bottom-none'> </TableCell><TableCell colSpan={5} className="table-divider border-bottom-none">Primary Documents </TableCell></TableRow>
            {rows.map((row) => (

              <TableRow
                key={row.name}

              >
                <TableCell className='border-bottom-none'>
                  <Checkbox onClick={() => { setVisible(true)}} className='table-check' {...label} />
                </TableCell>
                <TableCell component="th" scope="row" className='table-row-name border-bottom-none'>
                  {row.name}
                </TableCell>
                <TableCell align="left" className='border-bottom-none'>{row.calories}</TableCell>
                <TableCell align="right" className='border-bottom-none'>{row.fat}</TableCell>
                <TableCell align="right" className='table-upload-text border-bottom-none' sx={{ display: visible ? "visible" : "none", }}><span>Browse</span></TableCell>
                <TableCell align="right" className='border-bottom-none'>{row.carbs}</TableCell>
              </TableRow>
            ))}
            <TableRow><TableCell className='border-bottom-none'> </TableCell><TableCell colSpan={5} className="table-divider border-bottom-none">Secondary Documents</TableCell></TableRow>

            {rowsSec.map((rowSec) => (
              <TableRow key={rowSec.name}>
                <TableCell className='border-bottom-none'>
                  <Checkbox onClick={() => { setVisible(true)}} className='table-check' {...label} />
                </TableCell>
                <TableCell component="th" scope="row" className='table-row-name border-bottom-none'>
                  {rowSec.name}
                </TableCell>
                <TableCell align="left" className='border-bottom-none'>{rowSec.calories}</TableCell>
                <TableCell align="right" className='border-bottom-none'>{rowSec.fat}</TableCell>
                <TableCell align="right" className='table-upload-text border-bottom-none' sx={{ display: visible ? "visible" : "none", }}><span>Browse</span></TableCell>
                <TableCell align="right" className='border-bottom-none'>{rowSec.carbs}</TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
        <Box className='table-bottom-content'>
          <Box className='d-flex align-items-center justify-content-between'>
            <Typography>Total Points:</Typography>
            <Typography sx={{ width: '40px', background: "#F5F5F5", padding: '4px 0' }} className='text-center'><strong>140</strong></Typography>


          </Box>
          <Box className='text-center ' sx={{ mt: 5 }}>
            <Link to='/' className='align-items-center' >
              <Button id="next-btn">Submit</Button>
            </Link>

          </Box>
        </Box>
      </TableContainer>



    </Box>
  );
}