import React from "react";
import Box from "@mui/material/Box";
import Appbar from "../../Dashboard/Layout/Appbar";
import Sidebar from "../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import client from "../../assets/images/client.jpg";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "../../assets/css/Appointment.css";
import "../../assets/css/dashboard.css";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import upload from "../../assets/images/upload.svg";
import camera from "../../assets/images/camera.svg";
import Form from "@mui/material/FormGroup";
import { Button } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "Pending",
  "Accepted",
  "Completed",
  "Started",
  "Rejected",
  "Change by Admin",
  "Change by SW",
];

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const category = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];
const times = [
  { label: "1", year: 1994 },
  { label: "2", year: 1972 },
  { label: "3", year: 1974 },
  { label: "4", year: 2008 },
  { label: "5", year: 1957 },
  { label: "6", year: 1993 },
  { label: "7", year: 1994 },
];

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function AddTask() {
  const [value, setValue] = React.useState();

  const [active, setActive] = React.useState(false);

  const [personName, setPersonName] = React.useState([]);

  const [openImg, setOpenImg] = React.useState(false);
  const handleOpenImg = () => {
    setOpenImg(true);
  };

  const handleCloseImg = () => {
    setOpenImg(false);
  };

  const handleChangeCheck = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Appointment List</Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography>Add Routine</Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography>Add Task</Typography>
    </Link>,
  ];

  return (
    <>
      <Box className="dashboard-form" id="AddTask">
        <Box sx={{ width: "100%" }} className="appbar-border">
          <Box className="appbar-main">
            <Appbar />
          </Box>
        </Box>
        <Box className=" d-flex dashboard-main-box">
          <Sidebar />
          <Box className="main">
            <Grid container className="d-flex header-topsection">
              <Grid item xs={12} md={12} sm={12}>
                <Box id="breadcrumb">
                  <ArrowBackIosIcon />
                  <Box className="breadcrumb-inr">
                    <h4 className="d-flex align-items-center ">Add Task</h4>
                    <Box className="breadcrumbs">
                      <Link className="breadcrumbs-link">
                        {" "}
                        Appointment List
                      </Link>
                      <span className="breadcrumbs-icon">{">"}</span>
                      <Link className="breadcrumbs-link">Add Routine</Link>

                      <span className="breadcrumbs-icon">{">"}</span>
                      <Link className="breadcrumbs-link breadcrumbs-link-active">
                        Add Task
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box className="dashboard-form-typo dashboard-form-typo-sec dashboard-form-typo-up">
              <Box className="d-flex align-items-center justify-content-between">
                <h4 className="d-flex align-items-center ">
                  {" "}
                  <ArrowBackIosIcon /> Add Task{" "}
                </h4>
                <Box className="appointment-dashboard-profile d-flex align-items-center ">
                  <img src={client} />{" "}
                  <Box>
                    {" "}
                    <Typography>Jake Knap</Typography> <span>46 yr, Male</span>{" "}
                  </Box>
                </Box>
                <Box className="appointment-dashboard-timebar d-flex align-items-center">
                  <Typography>
                    Routine Name: <span>Exercise</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="dashboard-form-typo dashboard-form-typo-sec dashboard-form-typo-up">
              <Box className="d-flex align-items-center justify-content-between">
                <h4 className="d-flex align-items-center ">
                  {" "}
                  <ArrowBackIosIcon /> Task 3: Squats X10
                </h4>
                <Box className="appointment-dashboard-timebar d-flex align-items-center">
                  <Typography>
                    Status: <span className="select-style select-box-1"></span>
                    Pending
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="Task-list-main">
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6} md={6} className="pt-0">
                  <label>Title</label>
                  <TextField
                    sx={{ mt: 2, mb: 2 }}
                    className="form-input w-100"
                    type="Name"
                    size="small"
                    placeholder="Squats X10"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="pt-0">
                  <label>Category</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    sx={{ mt: 2, mb: 2 }}
                    options={category}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Homecare" />
                    )}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ mt: 2 }}
                  className="pt-0"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} className="input pt-0">
                      <label>Due Date</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            className="form-input date-input-filed"
                            format="DD-MM-YYYY"
                            value={value}
                            onChange={handleChange}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                sx={{
                                  mt: 2,
                                  mb: 2,
                                }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "DD-MM-YY",
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} className="pt-0">
                      <label>Start Time</label>
                      <TextField
                        sx={{ mt: 2, mb: 2 }}
                        className="form-input w-100"
                        type="time"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} className="pt-0">
                      <label>End Time</label>
                      <TextField
                        sx={{ mt: 2, mb: 2 }}
                        className="form-input w-100"
                        type="time"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="pt-0">
                  <Box>
                    <label>Priority</label>
                  </Box>
                  <FormControl sx={{ mt: 1 }} className="w-100">
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      className="d-flex align-items-center flex-row justify-content-between"
                    >
                      <FormControlLabel
                        value="Low"
                        control={<Radio />}
                        label="Low"
                      />
                      <FormControlLabel
                        value="Medium"
                        control={<Radio />}
                        label="Medium"
                      />
                      <FormControlLabel
                        value="High"
                        control={<Radio />}
                        label="High"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="pt-0">
                  <label>Frequency</label>

                  <Box className="d-flex align-items-start justify-content-between ">
                    <FormControl className="Add-Task-frequency">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                      >
                        <Box className="d-flex align-items-center justify-content-between">
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Day"
                          />
                          <Box className="frequency-times d-flex align-items-center">
                            <Autocomplete
                              disablePortal
                              disableClearable
                              freeSolo
                              id="combo-box-demo"
                              options={times}
                              className="times "
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="text-center"
                                />
                              )}
                              size="small"
                            />
                            <label>times</label>
                          </Box>
                        </Box>
                        <Box className="d-flex align-items-center justify-content-between">
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Week"
                          />
                          <Box className="frequency-times d-flex align-items-center">
                            <Autocomplete
                              disablePortal
                              disableClearable
                              freeSolo
                              id="combo-box-demo"
                              options={times}
                              className="times "
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="text-center"
                                />
                              )}
                              size="small"
                            />
                            <label>times</label>
                          </Box>
                        </Box>
                        <Box className="d-flex align-items-center justify-content-between">
                          <FormControlLabel
                            value="other"
                            control={<Radio />}
                            label="Year"
                          />
                          <Box className="frequency-times d-flex align-items-center">
                            <Autocomplete
                              disablePortal
                              disableClearable
                              freeSolo
                              id="combo-box-demo"
                              options={times}
                              className="times "
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="text-center"
                                />
                              )}
                              size="small"
                            />
                            <label>times</label>
                          </Box>
                        </Box>
                      </RadioGroup>
                    </FormControl>

                    <Box className="Add-Task-frequency">
                      <Box
                        className="d-flex justify-content-between align-items-center w-100"
                        sx={{ mt: 2 }}
                      >
                        <Checkbox sx={{ padding: "0", mr: 1 }} {...label} />
                        <span id="general">Repeat for every day </span>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center w-100"
                        sx={{ mt: 2 }}
                      >
                        <Checkbox sx={{ padding: "0", mr: 1 }} {...label} />
                        <span id="general">Repeat for every week </span>
                      </Box>
                      <Box
                        className="d-flex justify-content-between align-items-center w-100"
                        sx={{ mt: 2 }}
                      >
                        <Checkbox sx={{ padding: "0", mr: 1 }} {...label} />
                        <span id="general">Repeat for every year </span>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ mt: 2 }}
                  className="input pt-0"
                >
                  <Box className="attach-document task-attachment">
                    <Box>
                      <h3>Attach Document</h3>
                    </Box>

                    <Box className="task-edit-attach-document">
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="d-flex align-items-center ">
                            <input
                              type="radio"
                              name="noteupload"
                              id="noteupload"
                            ></input>
                            <img src={upload} alt="upload"></img>
                            <label for="noteupload">Upload</label>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="d-flex align-items-center">
                            <input
                              type="radio"
                              name="noteupload"
                              id="notecapture"
                            ></input>
                            <img src={camera}></img>
                            <label for="notecapture">Capture</label>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}></Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ mt: 2 }}
                  className="input pt-0"
                >
                  <Box className="d-flex align-items-center">
                    <Form className=" list-attach-document-browse w-100 ">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        className="attach-file-input"
                        type="file"
                      ></TextField>
                    </Form>
                  </Box>
                  <Button className="upload-btn">Upload</Button>
                </Grid>
              </Grid>
            </Box>

            <Grid id="listNote" container className="list-add-note">
              <Grid item sx={12} sm={12} md={12}>
                <label>Add Notes</label>
                <TextField
                  placeholder="MultiLine with rows: 2 and rowsMax: 4"
                  multiline
                  rows={3}
                  sx={{ width: "100%", mt: 2 }}
                  maxRows={4}
                />
              </Grid>

              <Grid container className="list-status align-items-end">
                <Grid item xs={12} sm={4} md={4}>
                  <label>Status </label>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={personName}
                    className="w-100"
                    size="small"
                    onChange={handleChangeCheck}
                    input={<OutlinedInput label="Tag" />}
                    //renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    sx={{ mt: 2 }}
                  >
                    {names.map((name, index) => (
                      <MenuItem
                        key={name}
                        onClick={() => setActive(index + 1)}
                        className={`select-item-${active === index && "1"}`}
                        value={name}
                      >
                        <span
                          className={`select-style select-box-${index + 1}`}
                        ></span>
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sm={5} md={5}>
                  <Box className="d-flex align-items-center">
                    <Button className="Add-Task-cancel">Cancel</Button>
                    <Button className="list-update-btn">Save</Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}
