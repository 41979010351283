import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import MailIcon from '@mui/icons-material/Mail';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import CallIcon from '@mui/icons-material/Call';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MapLocation from '../../assets/images/map-blue.png'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function BasicTextFields() {
    const top100Films = [
        { label: 'The Shawshank Redemption', year: 1994 },
        { label: 'The Godfather', year: 1972 },
        { label: 'The Godfather: Part II', year: 1974 },
        { label: 'The Dark Knight', year: 2008 },
        { label: '12 Angry Men', year: 1957 },
        { label: "Schindler's List", year: 1993 },
        { label: 'Pulp Fiction', year: 1994 },]

    const title = [
        { label: 'Mr.' },
        { label: 'Mrs.' }
    ]

    const gender = [
        { label: 'Male' },
        { label: 'Female' },
        { label: 'Other' }
    ]


    const [value, setValue] = React.useState();

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <Box id='personal-info' className='account-form'>

            {/* <Box><Typography className='step-count'>Personal Details</Typography></Box> */}
            <Box className='personal-info-main'>

                {/* <Box className='form-heading-section' sx={{backgroundColor:'#E2ECF2',borderRadius:'4px',p:4}}>
                    <Typography className='form-heading'>Personal Details</Typography>
                </Box> */}

                <Box className='personal-info'>

                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={3} md={3} className='pt-0' >
                            <label>Title <span className='danger'>*</span></label>
                            {/* <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            /> */}
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={title}
                                disableClearable
                                sx={{ width: 90 }}
                                size='small'
                                renderInput={(params) => <TextField placeholder='Mr.' sx={{ mt: 1, mb: 1 }} {...params} />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} className='pt-0'>
                            <label>First Name <span className='danger'>*</span></label>
                            <TextField
                                sx={{ mt: 2 }}
                                className='form-input'
                                type="Name"
                                placeholder='Tom'
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} className='pt-0'>
                            <label>Middle Name</label>
                            <TextField
                                sx={{ mt: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} className='pt-0'>
                            <label>Last Name <span className='danger'>*</span></label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                                placeholder='Arren'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='input pt-0'>
                            <label>Email Id <span className='danger'>*</span></label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input form-input-icon-bg'
                                type="Email"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className='input-icon' position="start">
                                            <MailIcon />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='input pt-0'>
                            <label>Contact <span className='danger'>*</span></label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input form-input-icon-bg'
                                size="small"
                                type="Number"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className='input-icon' position="start">
                                            <CallIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='input pt-0'>
                            <label> Date of Birth {" "}<span className='danger'>*</span></label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mt: 2, mb: 2 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className='pt-0' >
                            <label>Gender <span className='danger'>*</span></label>
                            {/* <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            /> */}
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={gender}
                                disableClearable
                                size='small'
                                renderInput={(params) => <TextField placeholder='Gender' sx={{ mt: 1, mb: 1 }} {...params} />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} className='input pt-0'>
                            <label>Residential Address </label>
                            <TextField
                                sx={{ mt: 2, }}
                                className='form-input form-input-icon-bg'
                                type="text"
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment className='input-icon' position="start">
                                            <img className='residential-icon' src={MapLocation} />
                                        </InputAdornment>
                                    ),
                                }}

                            />
                        </Grid>

                        <FormControlLabel sx={{ ml: 1, mb: 1 }} control={<Checkbox />} label="Enter Residential Address manually" />

                        <Grid item xs={12} className='input pt-0'>
                            <label>Address Line 1</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'

                                type="Name"
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} className='input pt-0'>
                            <label>Address Line 2</label>
                            <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'

                                type="Name"
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} className='pt-0' >
                            <label>City/Town <span className='danger'>*</span> </label>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                sx={{ mt: 2, mb: 2 }}
                                options={top100Films}
                                renderInput={(params) => <TextField placeholder='Type or scroll' {...params} />}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} className='pt-0'>
                            <label>Province/State <span className='danger'>*</span> </label>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                sx={{ mt: 2, mb: 2 }}
                                options={top100Films}
                                renderInput={(params) => <TextField placeholder='Type or scroll' {...params} />}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} className='pt-0'>
                            <label>Postal Code <span className='danger'>*</span></label>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                sx={{ mt: 2, mb: 2 }}
                                options={top100Films}
                                renderInput={(params) => <TextField placeholder='Type or scroll' {...params} />}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} className='pt-0'>
                            <label>Country <span className='danger'>*</span></label>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                sx={{ mt: 2, mb: 2 }}
                                options={top100Films}
                                renderInput={(params) => <TextField placeholder='Type or scroll' {...params} />}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} className='pt-0'>
                            <FormControlLabel sx={{ mb: 1 }} control={<Checkbox />} label="Postal address is the same as Residential Address." />
                        </Grid>

                    </Grid>






                </Box>

            </Box>

        </Box>

    );
}