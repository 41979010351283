import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../Web-Header";
import WebFooter from "../Web-footer";
import FeatureTitleBanner from "../../assets/images/feature-1banner.jpg";
import { Link } from "react-router-dom";
import "../../assets/css/dashboard.css";
import "../../assets/css/web-css.css";
import "../HomeUI/home-ui.css";
import staffImage1 from "../../assets/images/staffImage1.png";
import staffImage2 from "../../assets/images/staffImage2.png";
import staffImage3 from "../../assets/images/staffImage3.png";
import staffImage4 from "../../assets/images/staffImage4.png";
import staffImage5 from "../../assets/images/staffImage5.png";
import staffIcon1 from "../../assets/images/staffIcon1.svg";
import staffIcon2 from "../../assets/images/staffIcon2.svg";
import staffIcon3 from "../../assets/images/staffIcon3.svg";
import staffIcon4 from "../../assets/images/staffIcon4.svg";
import staffIcon5 from "../../assets/images/staffIcon5.svg";
import staffIcon6 from "../../assets/images/staffIcon6.svg";
import staffIcon7 from "../../assets/images/staffIcon7.svg";
import staffIcon8 from "../../assets/images/staffIcon8.svg";
import staffIcon9 from "../../assets/images/staffIcon9.svg";
import staffIcon10 from "../../assets/images/staffIcon10.svg";
import staffIcon11 from "../../assets/images/staffIcon11.svg";
import staffIcon12 from "../../assets/images/staffIcon12.svg";
import staffIcon13 from "../../assets/images/staffIcon13.svg";
import staffIcon14 from "../../assets/images/staffIcon14.svg";

import QuestionAccordion from "../HomeUI/Question-Accordian";
import BookDemo from "../HomeUI/BookDemo";

const StaffInduction = () => {
  return (
    <Box id="features">
      <Box className="web-topBanner-bg  feature-main-banner">
        <WebHeader />

        <Box className="feature-titleBox">
          <Box className="webContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} className="home-content-main ">
                <Box>
                  <h1>
                    Staff Induction <br />
                    {/* Title */}
                  </h1>
                  <Typography>
                    Streamline staff induction with our combined onboarding and
                    LMS (Learning Management System) integration
                  </Typography>

                  <Box className="d-flex align-items-center">
                    <Link
                      to="/book-demo"
                      className="home-section-button web-primary-btnLarge cta1"
                    >
                      Book A Demo
                    </Link>
                    {/* <Link className="home-section-button cta2">CTA 2</Link> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="manageCarer-cardImg manageCarer-obj">
                  <img src={staffImage1} />
                </Box>

                <Box className="blurBg blurRed"> </Box>
                {/* <Box className="blurBg blurPurple"> </Box> */}
                <Box className="blurBg blurYellow"></Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box className="webContainer">
        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={staffImage2} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
          >
            <Box className="manageCarer-cardContent pLeft-80">
              <h6>Unparalleled Onboarding Precision </h6>
              <h3>Tailored Learning for Every Role</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Our staff induction and onboarding platform goes beyond
                  generic training; it provides tailored learning paths for each
                  staff member, aligning with specific job roles and
                  responsibilities. This personalized approach ensures that
                  every team member, from care providers to administrative
                  personnel, receives the exact training they need to excel in
                  their positions and uphold NDIS standards. 
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon1} />
                      </Box>
                      <Box>
                        <Typography>Role-Specific Training </Typography>
                        <span>
                          Provide training aligned with specific job roles.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon2} />
                      </Box>
                      <Box>
                        <Typography>Comprehensive Skill Development</Typography>
                        <span>
                          Cover all essential skills required for various
                          positions.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon3} />
                      </Box>
                      <Box>
                        <Typography>Adherence to NDIS Standards</Typography>
                        <span>
                          Guaranteed compliance with National Disability
                          Insurance Scheme regulations.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon4} />
                      </Box>
                      <Box>
                        <Typography>Excellence in Care Provision</Typography>
                        <span>
                          Ensure high-quality care delivery through targeted
                          training.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>Interactive Learning Modules </h6>
              <h3>Enhance Learning</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Engage your staff with interactive content that enhances
                  learning outcomes, including videos, quizzes, and real-life
                  scenarios tailored to the nuances of your service offerings. 
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon5} />
                      </Box>
                      <Box>
                        <Typography>Video Based Learning</Typography>
                        <span>
                          Utilize visual aids for effective knowledge transfer.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon6} />
                      </Box>
                      <Box>
                        <Typography>Interactive Quizzes</Typography>
                        <span>
                          Test understanding and reinforces key concepts
                          interactively.
                        </span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon7} />
                      </Box>
                      <Box>
                        <Typography>Real-Life Scenarios</Typography>
                        <span>
                          Simulate practical situations to enhance application
                          skills.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg">
              <img src={staffImage3} />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={staffImage4} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
          >
            <Box className="manageCarer-cardContent pLeft-80">
              <h6>Real-Time Progress Tracking  </h6>
              <h3>Progress Monitoring made simple</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Monitor the induction progress of each staff member with our
                  real-time tracking features. This allows managers to swiftly
                  identify areas where additional support may be needed,
                  ensuring no one falls behind. 
                </Typography>

                <Grid container sx={{ mt: 2 }} className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon8} />
                      </Box>
                      <Box>
                        <Typography>Individual Staff Tracking</Typography>
                        <span>
                          Monitors the progress of each team member
                          individually.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon9} />
                      </Box>
                      <Box>
                        <Typography>Preventing Lagging Behind</Typography>
                        <span>
                          Ensures timely completion and progress for all staff.
                        </span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon10} />
                      </Box>
                      <Box>
                        <Typography>Ensuring On-Track Progress</Typography>
                        <span>
                          Guarantees that all staff members are progressing as
                          expected.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>Feedback and Continuous Improvement  </h6>
              <h3>Enhancing Performance Through Feedback and Improvement</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Our platform supports a culture of continuous learning and
                  improvement, with feedback mechanisms that encourage staff to
                  share their experiences and suggest enhancements to the
                  training content. 
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon11} />
                      </Box>
                      <Box>
                        <Typography>Feedback Mechanisms</Typography>
                        <span>
                          Implements tools to gather and analyze input
                          effectively.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon12} />
                      </Box>
                      <Box>
                        <Typography>Improvement Opportunities</Typography>
                        <span>
                          Identifies areas for refinement and enhancement.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon13} />
                      </Box>
                      <Box>
                        <Typography>Fostering Growth Mindset</Typography>
                        <span>
                          Cultivates an environment focused on continual
                          improvement and progress.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={staffIcon14} />
                      </Box>
                      <Box>
                        <Typography>Learning from Experiences</Typography>
                        <span>
                          Extracts valuable lessons from staff feedback and
                          experiences.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={staffImage5} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="question-accordian web-topBanner-bg space-lg">
        <Box className="webContainer">
          <Box className="text-center">
            <h1>Questions? We've got you covered.</h1>
          </Box>
          <Box className="space-pt-60">
            <QuestionAccordion />
          </Box>
        </Box>
      </Box>

      <Box className="space-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
};

export default StaffInduction;
