import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../../frontend/src/pages/loader/Loader";
//404 page
import Error from "../components/Error";

export default function GeneralManager() {
  // const Home = lazy(() => import("../pages/GeneralManager/home"));
  const Home = lazy(() =>
    import("../pages/GeneralManager/genralManager-dasjboard.js")
  );
  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );
  const MyAccount = lazy(() =>
    import("../pages/GeneralManager/form/MyAccount")
  );

  const Appointments = lazy(() =>
    import("../pages/GeneralManager/appointment/list")
  );

  const AppointmentView1 = lazy(() =>
    import("../components/calendar-view/Appointment-View")
  );

  const SignedDocument = lazy(() =>
    import("../components/SignedDocument/ListSignedDocument")
  );

  const ViewSignedDocument = lazy(() =>
    import("../components/SignedDocument/ViewSignedDocument")
  );
  const ProgressNoteAdd = lazy(() =>
    import("../pages/superAdmin/progressNote/edit")
  );

  const ListIncident = lazy(() => import("../pages/Supervisor/incident/list"));
  const PdfServiceTaskNotes = lazy(() =>
    import("../pages/superAdmin/serviceTaskNotes/serviceTaskpdf.js")
  );

  const ViewIncident = lazy(() => import("../pages/Supervisor/incident/view"));
  const ClosureIncident = lazy(() =>
    import("../pages/Supervisor/incident/incidentClosure")
  );
  const Riskassesment = lazy(() =>
    import("../pages/Stakeholder/Riskassesment/Riskassesment")
  );
  const Pdfincident = lazy(() => import("../pages/healthCarer/incident/pdf"));

  //leave management new changes

  const ListLeaveCategory = lazy(() =>
    import("../pages/superAdmin/leave-category/list")
  );

  const ListLeaveManagementOld = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list")
  );

  const ListLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list-tab")
  );

  const EditLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/edit")
  );

  const ViewLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/view")
  );

  const Setting = lazy(() =>
    import("../pages/serviceProvider/Settings/setting.js")
  );

  const ListSetLeaves = lazy(() => import("../pages/superAdmin/setLeave/list"));
  const Addbank = lazy(() =>
    import("../pages/GeneralManager/form/Myaccounttab/Addbank")
  );
  const Editbank = lazy(() =>
    import("../pages/GeneralManager/form/Myaccounttab/Editbank")
  );

  const ListFundManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/Fund-Management")
  );

  const ViewFundManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/View-Fund-Management")
  );
  const PrintFundManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/print")
  );
  const Email = lazy(() => import("../components/email/list"));

  const ViewEmail = lazy(() => import("../components/email/view"));

  const EditEmail = lazy(() => import("../components/email/newEmail/edit"));
  const ChatHistory = lazy(() =>
    import("../pages/superAdmin/chathistory/list")
  );

  const Task = lazy(() => import("../pages/superAdmin/service-task/list"));
  const TaskEdit = lazy(() => import("../pages/superAdmin/service-task/edit"));
  const TaskAdd = lazy(() => import("../pages/superAdmin/service-task/edit"));
  const TaskAndNotes = lazy(() =>
    import("../pages/superAdmin/serviceTaskNotes/list")
  );
  const ShiftNoteList = lazy(() =>
    import("../pages/superAdmin/shift-notes/shiftNoteclientlist.js")
  );
  const ViewShiftNote = lazy(() =>
    import("../pages/superAdmin/shiftNotes/view")
  );
  const Attendance = lazy(() =>
    import("../pages/CommanComponant/Attendance/Attendance")
  );

  const ListCommonCalander = lazy(() =>
    import("../pages/superAdmin/schedule-common-meeting/client/list-tab")
  );
  const AddServiceTask = lazy(() =>
    import("../pages/superAdmin/service-task/edit")
  )

  const ViewMeetingDetails = lazy(() =>
    import("../components/meeting-view/details")
  );

  const ViewInterviewDetails = lazy(() =>
    import("../components/interview-view/details")
  );

  const ViewOtherMeetingDetails = lazy(() =>
    import("../components/meeting-view-other/details")
  );

  const ScheduleOtherMeeting = lazy(() =>
    import("../pages/superAdmin/schedule-other-meeting/meeting")
  );
  return (
    <Routes>
      <Route>
        <Route
          path="/general-manager/home"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/pending-approval"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <PendingApproval />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/add-service-task"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <AddServiceTask />
            </Suspense>
          }
        />

        <Route
          path="/general-manager/pdf-service-task-notes"
          element={
            <Suspense>
              <PdfServiceTaskNotes />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-service-task"
          element={
            <Suspense>
              <Task />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/add-service-item"
          element={
            <Suspense>
              <TaskAdd />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/edit-service-item"
          element={
            <Suspense>
              <TaskEdit />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/shift-notes-list"
          element={
            <Suspense>
              <ShiftNoteList />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-service-task-notes"
          element={
            <Suspense>
              <TaskAndNotes />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/appointments-view"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <AppointmentView1 />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/add-progressnote"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ProgressNoteAdd />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-appointments"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Appointments />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/myaccount"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MyAccount />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-signed-document"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <SignedDocument />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/view-signed-document"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewSignedDocument />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListIncident />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/closure-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClosureIncident />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/email"
          element={
            <Suspense>
              <Email />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/view-email"
          element={
            <Suspense>
              <ViewEmail />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/edit-email"
          element={
            <Suspense>
              <EditEmail />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/chat-history"
          element={
            <Suspense>
              <ChatHistory />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/view-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewIncident />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/risk-assesment"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Riskassesment />
            </Suspense>
          }
        />
        {/* leave management new changes */}
        <Route
          path="/general-manager/list-leave-categories"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListLeaveCategory />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-leave-management-old"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListLeaveManagementOld />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-leave-management"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListLeaveManagement />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/add-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditLeaveManagement />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/edit-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <EditLeaveManagement />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/view-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewLeaveManagement />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-set-leave"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListSetLeaves />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/pdf-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Pdfincident />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/add-bank-details"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Addbank />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/edit-bank-details"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Editbank />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/settings"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Setting />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-funds-management"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListFundManagement />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/view-funds-management"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewFundManagement />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/print-funds-management"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <PrintFundManagement />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/attendance"
          element={
            <Suspense>
              <Attendance />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/view-shift-notes"
          element={
            <Suspense>
              <ViewShiftNote />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-calendar"
          element={
            <Suspense>
              <ListCommonCalander />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-view-schedule-meeting"
          element={
            <Suspense>
              <ViewMeetingDetails />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-view-schedule-interview"
          element={
            <Suspense>
              <ViewInterviewDetails />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/list-view-other-schedule-meeting"
          element={
            <Suspense>
              <ViewOtherMeetingDetails />
            </Suspense>
          }
        />

        <Route
          path="/general-manager/schedule-other-meeting"
          element={
            <Suspense>
              <ScheduleOtherMeeting />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/edit-other-meeting"
          element={
            <Suspense>
              <ScheduleOtherMeeting />
            </Suspense>
          }
        />

        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
