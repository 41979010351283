import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "../../assets/css/dashboard.css";
import { Typography, Grid, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import CallIcon from "@mui/icons-material/Call";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { useNavigate } from "react-router-dom";
import AllRoutes from "./sidebar/AllRoutes";
import AdminUserRoutes from "./sidebar/AdminUserRoutes";
import moment from "moment";
import localstorageData from "../../helpers/localstorageData";
import {
  getuserDetail,
  getServiceProviderDetailsByUserId,
  getHealthCarerDetails,
  getHealthCarerPackageDetailsByUserId,
} from "../../../src/api";
import noImage from "../../assets/images/noImage.png";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function Sidebar() {
  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 150) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const fieldLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("clientId");
    localStorage.removeItem("exploreMarket");
    navigate("/sign-in");
    window.location.reload();
    toast.success("You have successfully logged out!");
  };
  const [sPDataPlan, setSPDataPlan] = useState("");
  const [sPExpiryDate, setSPExpiryDate] = useState("");
  const [userImage, setUserImage] = useState([]);
  const user = localstorageData();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hideSideBar, setHideSideBar] = useState(false);

  const toggleDrawer = () => {
    // console.log("Drawer toggled");
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia('(max-width: 761px)').matches) {
        setIsDrawerOpen(true);
      } else {
        setIsDrawerOpen(false);
      }
    };

    handleResize(); // Check on initial render
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  let role = user?.userTypeName;
  useEffect(() => {
    axios
      .get(`${getuserDetail}/${user._id}`)
      .then(function (res) {
        if (res) {
          setUserImage(res?.data?.data?.image?.location);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const suscriptionHistory = () => {
    if (user.role === "healthCarer") {
      navigate("/health-carer/subscription-plans-list");
    } else if (user.role === "supportCoordinator") {
      navigate("/support-coordinator/subscription-plans-list");
    } else if (user.role === "client") {
      navigate("/client/subscription-plans-list");
    } else if (user.role === "serviceProvider") {
      navigate("/service-provider/subscription-plans-list");
    } else if (user.role === "superAdmin") {
      navigate("/super-admin/subscription-plans-list");
    }
  };
  const fieldMyAccount = () => {
    if (user.role === "healthCarer") {
      navigate("/health-carer/myaccount");
    } else if (user.role === "supportCoordinator") {
      navigate("/support-coordinator/myaccount");
    } else if (user.role === "client") {
      navigate("/client/myaccount");
    } else if (user.role === "accountant") {
      navigate("/accountant/myaccount");
    } else if (user.role === "itManager") {
      navigate("/it-manager/myaccount");
    } else if (user.role === "supervisor") {
      navigate("/supervisor/myaccount");
    } else if (user.role === "superAdmin") {
      navigate("/super-admin/myaccount");
    } else if (user.role === "familyMember") {
      navigate("/family-member/myaccount");
    } else if (user.role === "Stakeholder") {
      navigate("/stakeholder/myaccount");
    } else if (user.role === "generalManager") {
      navigate("/general-manager/myaccount");
    } else if (user.role === "serviceProvider") {
      navigate("/service-provider/myaccount");
    } else if (user.role === "planManager") {
      navigate("/plan-manager/myaccount");
    } else if (user.role === "hr") {
      navigate("/hr/myaccount");
    }
  };

  useEffect(() => {
    if (user._id !== null && user.role === "serviceProvider") {
      axios
        .get(`${getServiceProviderDetailsByUserId}/${user._id}`)
        .then((res) => {
          let dataval = res?.data?.data[0];
          console.log("datavaldatavaldatavaldataval", dataval)
          setSPExpiryDate(dataval?.packageExpiryDate);
          let data = JSON.parse(
            localStorage.getItem("userData")
          );
          data["userStatus"] = dataval.userDataV[0].userStatus
          localStorage.setItem("userData", JSON.stringify(data));
          setSPDataPlan(dataval?.packagesDetails?.name);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (user.role === "healthCarer") {
      axios
        .get(`${getHealthCarerDetails}/${user._id}`)
        .then((res) => {
          let dataval = res?.data?.data;
          setSPExpiryDate(dataval?.packageExpiryDate);
          setSPDataPlan(dataval?.packagesDetails?.name);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (user.role === "client") {
      getUserData();
    } else if (user.role === "supportCoordinator") {
      getUserData();
    } else {
      getUserData();
    }
  }, []);

  const getUserData = () => {
    axios
      .get(
        `${getHealthCarerPackageDetailsByUserId}/${user._id}?role=${user.role}`
      )
      .then((res) => {
        let dataval = res?.data?.data[0];
        console.log("datavaldatavaldatavaldataval", res)
        setSPExpiryDate(dataval?.packageExpiryDate);
        setSPDataPlan(dataval?.packagesDetails?.name);
        let data = JSON.parse(
          localStorage.getItem("userData")
        );
        data["userDataV.userStatus"] = dataval.userStatus
        localStorage.setItem("userData", JSON.stringify(data));
      })
      .catch((error) => {
        console.log("scscdscdsc", error);
      });
  };

  let dateOfPlanExpiry, remainDays;
  if (sPExpiryDate !== "") {
    dateOfPlanExpiry = moment(sPExpiryDate).format("DD-MM-YYYY");
    remainDays = moment(sPExpiryDate).diff(moment(new Date()), "days");
  }
  useEffect(() => {
    if (user.userTypeName != "Super Admin") {
      setHideSideBar(true)
    }
  }, [])
  return (
    <div className={`app ${isDrawerOpen ? "drawer-closed" : ""}`}>
      <Box
        id="sidebar"
        className={`fixed-element sidebar ${isFixed ? "fixed" : ""}`}
        style={{ width: isDrawerOpen ? "45px" : "240px" }}
      >
        {/* Add IconButton to toggle drawer */}


        <div
          className={`sidebar-openclose ${isDrawerOpen ? "sidebar-openclose1" : ""
            }`}
        >
          <IconButton onClick={toggleDrawer} className="hover-sidebar-btn hide-sidebar-icons">
            {isDrawerOpen ? (
              <ArrowForwardIosIcon fontSize="small" />
            ) : (
              <ArrowBackIosIcon fontSize="small" />
            )}
          </IconButton>
        </div>


        <Drawer
          variant="permanent"
          anchor="left"
          className={`MuiDrawer-border sidebar  ${isDrawerOpen ? "open" : ""}`}
        >
          {user?.createdByUserType === "superAdmin" ||
            user?.createdByUserType === "admin" ? (
            <AllRoutes remainDays={remainDays} />
          ) : (
            <AdminUserRoutes />
          )}

          {/* sidebar second section  */}
          <Box className="sidebar-second-section">
            <Box className="sidebar-porfile">
              <img src={userImage ? userImage : noImage} alt="profile" />
              <Box className="sidebar-porfile-content menu-link-hide">
                <Typography> {user ? user?.firstName : ""}</Typography>
                <span> {user ? role : ""} </span>
                <br />

                {user?.role === "supportCoordinator" ||
                  user?.role === "serviceProvider" ||
                  user?.role === "Stakeholder" ||
                  user?.role === "familyMember" ||
                  user?.role === "itManager" ||
                  user?.role === "planManager" ||
                  user?.role === "supervisor" ||
                  user?.role === "accountant" ||
                  user?.role === "generalManager" ? (
                  <>
                    <span>
                      <b>{user ? user?.employeeId : ""}</b>
                    </span>
                  </>
                ) : (
                  ""
                )}
                {user?.role === "client" ? (
                  <>
                    <span> Client ID: {user ? user?.employeeId : ""}</span>
                  </>
                ) : (
                  ""
                )}

                <br />

                {user?.role === "serviceProvider" && sPDataPlan !== "" &&
                  sPDataPlan !== undefined && (user?.createdByUserType == "superAdmin" || user?.isSoleTrader === true) ||
                  user?.role === "client" && sPDataPlan !== "" &&
                  sPDataPlan !== undefined && user?.createdByUserType == "superAdmin" ||
                  (user?.role === "supportCoordinator" &&
                    sPDataPlan !== "" &&
                    sPDataPlan !== undefined &&
                    (user?.createdByUserType == "superAdmin" || user?.isSoleTrader === true)) ||
                  (user?.role === "healthCarer" &&
                    sPDataPlan !== "" &&
                    sPDataPlan !== undefined && (user?.createdByUserType == "superAdmin" || user?.isSoleTrader === true)) ? (
                  <>
                    {remainDays >= 0 ? (
                      <>
                        <span>
                          Current Plan: <b>{sPDataPlan}</b>
                        </span>
                        <span>
                          Valid Till: <b>{dateOfPlanExpiry}</b>
                        </span>
                        <span>
                          Validity: <b>{remainDays} Days Left</b>
                        </span>
                      </>
                    ) : (
                      <span>
                        {" "}
                        Current Plan: <b>No Active Plan</b>
                      </span>
                    )}
                  </>
                ) : (
                  ""
                )}
              </Box>
            </Box>

            <List
              className="sidebar-item"
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton
                component="a"
                href="/privacy-policy"
                target="_blank"
                className="sidebar-item-link"
              >
                <ListItemIcon>
                  <AddModeratorIcon />
                </ListItemIcon>
                <ListItemText
                  className="menu-link-hide"
                  primary={
                    <>
                      <Typography component="div" className="sidebar-icon-text">
                        Privacy Policy
                      </Typography>
                    </>
                  }
                />
              </ListItemButton>
            </List>

            <List
              className="sidebar-item"
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <ListItemButton className="sidebar-item-link">
                <ListItemIcon>
                  <CallIcon />
                </ListItemIcon>
                <ListItemText
                  className="menu-link-hide"
                  primary={
                    <>
                      <Typography component="div" className="sidebar-icon-text">
                        01184-21422-254
                      </Typography>
                    </>
                  }
                />
              </ListItemButton>
            </List>

            {/* {user?.createdByUserType === "superAdmin" ? (
            <List
              className="sidebar-item"
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {user?.role === "healthCarer" ||
              user?.role === "supportCoordinator" ||
              user?.role === "client" ||
              user?.role === "serviceProvider" ? (
                <ListItemButton
                  onClick={suscriptionHistory}
                  className="sidebar-item-link"
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-link-hide"
                    primary={
                      <>
                        <Typography
                          component="div"
                          className="sidebar-icon-text"
                        >
                          Suscription History
                        </Typography>
                      </>
                    }
                  />
                </ListItemButton>
              ) : (
                ""
              )}
            </List>
          ) : (
            ""
          )} */}

            {/* <List
            className="sidebar-item"
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {user?.role === "superAdmin" ? (
              <ListItemButton
                onClick={suscriptionHistory}
                className="sidebar-item-link"
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  className="menu-link-hide"
                  primary={
                    <>
                      <Typography component="div" className="sidebar-icon-text">
                        {" "}
                        Suscription History{" "}
                      </Typography>
                    </>
                  }
                />
              </ListItemButton>
            ) : (
              ""
            )}
          </List> */}

            {/* <List
            className="sidebar-item"
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {user?.role === "healthCarer" ||
            user?.role === "supportCoordinator" ||
            user?.role === "client" ||
            user?.role === "itManager" ||
            user?.role === "serviceProvider" ||
            user?.role === "accountant" ||
            user?.role === "familyMember" ||
            user?.role === "supervisor" ||
            user?.role === "superAdmin" ||
            user?.role === "Stakeholder" ||
            user?.role === "generalManager" ||
            user?.role === "hr" ||
            user?.role === "planManager" ? (
              <>
                <ListItemButton
                  onClick={fieldMyAccount}
                  className="sidebar-item-link"
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="menu-link-hide"
                    primary={
                      <>
                        <Typography
                          component="div"
                          className="sidebar-icon-text"
                        >
                          {" "}
                          My Account{" "}
                        </Typography>
                      </>
                    }
                  />
                </ListItemButton>
              </>
            ) : (
              ""
            )}

            <ListItemButton onClick={fieldLogout} className="sidebar-item-link">
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                className="menu-link-hide"
                primary={
                  <>
                    <Typography component="div" className="sidebar-icon-text">
                      Log Out
                    </Typography>
                  </>
                }
              />
            </ListItemButton>
          </List> */}
          </Box>
        </Drawer>
      </Box>
    </div >
  );
}
