import React, { useState } from "react";
import Box from "@mui/material/Box";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Avatar from "@mui/material/Avatar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import notesImg from "../../../assets/images/clinical_notes.svg";
import popupimage from "../../../assets/images/table-popup-profile.jpg";
import video from "../../../assets/images/video.svg";
import Dropdown from "react-bootstrap/Dropdown";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import View from "../../../assets/images/view.svg";
import chaticon from "../../../assets/images/chat.svg";
import taskIcon from "../../../assets/images/task-icon.svg";
import voice from "../../../assets/images/voice.svg";
import users from "../../../assets/images/users.svg";

import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';



function createData(
  name,
  calories,
  fat,
  carbs,
  category,
  onboarding,
  risk,
  schedule,
  meeting,
  status,
  protein
) {
  return {
    name,
    calories,
    fat,
    carbs,
    category,
    onboarding,
    risk,
    schedule,
    meeting,
    status,
    protein,
  };
}

const meetingCell = (
  <Box className="table-meeting-cell">
    <Typography>
      Thu 23-03-23, <br /> 12:00 to 12:12{" "}
    </Typography>
    <Box className="d-flex align-items-center justify-content-between">
      <img src={notesImg}></img> <span>Tasks & Notes</span>
    </Box>
  </Box>
);

const scheduleIcon = (
  <Box className="d-flex align-items-center justify-content-between">
    <Box className="schedule-icon">
      {" "}
      <img src={video}></img>{" "}
    </Box>
    <Box className="schedule-icon">
      {" "}
      <img src={voice}></img>{" "}
    </Box>
    <Box className="schedule-icon">
      {" "}
      <img src={chaticon}></img>{" "}
    </Box>
    <Box className="schedule-icon">
      <img src={users}></img>
    </Box>
  </Box>
);

const rows = [
  createData(
    1,
    "Andy Lawrence",
    "andyl@gmail.com",
    "01234567890",
    "Homecare",
    "25%",
    "Pending",
    scheduleIcon,
    meetingCell,
    "Pending"
  ),
  createData(
    2,
    "Sara Gomez",
    "sarag@gmail.com",
    "1234567890",
    "Community Access",
    "35%",
    "Approved",
    scheduleIcon,
    meetingCell,
    "Approved"
  ),
  createData(
    3,
    "Harry Nathan",
    "harryn@gmail.com",
    "01234567890",
    "SIL / SDA",
    "26%",
    "Pending",
    scheduleIcon,
    meetingCell,
    "Pending"
  ),
  createData(
    4,
    "Larry Daniel",
    "larryd@gmail.com",
    "1234567890",
    "Homecare",
    "20%",
    "Approved",
    scheduleIcon,
    meetingCell,
    "Approved"
  ),
];

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// client management pop-up tab

function ClientTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ClientTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a12yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ClientManagement() {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [show, setShow] = React.useState(true);
  const [value, setValue] = React.useState(0);

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // client pop-up tab

  const [clientValue, setClientValue] = React.useState(0);

  const handleChangeClient = (event, newValue) => {
    setClientValue(newValue);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Client Management</Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography>Evaluation</Typography>
    </Link>,
  ];




  return (
    <Box className="dashboard-form" id="client-management">
     
      <Box className=" d-flex dashboard-main-box">
     
        <Box className="w-100">
          <Box className="dashboard-form-typo padding-left-20 padding-right-20 dashboard-form-typo-up">
            <h3>Client Management</h3>
            <Box>
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Stack>
            </Box>
          </Box>
          <Box className="dashboard-form-typo padding-left-20 padding-right-20 dashboard-form-typo-sec dashboard-form-typo-up">
            <h4 className="d-flex align-items-center ">
              {" "}
              <ArrowBackIosIcon /> Client Management
            </h4>
          </Box>

          {/* Tab Start here  */}

          <Box className="tab-main">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="tabs-item-field"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Dashboard"
                    className="tab-label"
                    {...a11yProps(0)}
                  />
                  <Tab
                    label="Evaluation"
                    className="tab-label"
                    {...a11yProps(1)}
                  />
                  <Tab
                    label="Clients"
                    className="tab-label"
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                Item One
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box className="padding-left-20 padding-right-20">

                  <Box className="agency-searchbar me-32">
                    <Grid className="align-items-center justify-content-between">
                      <Grid xs={12} md={12}>
                        <Autocomplete
                          id="free-solo-demo"
                          placeholder="Search Agency"
                          options={top100Films.map((option) => option.title)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                          className="doc-search"
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <TableContainer
                    component={Paper}
                    id="document-table"
                    className="agency-table client-management-table"
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead id="client-table-head">
                        <TableRow>
                          <TableCell className="tableborder table-head-cell">
                            S.R.
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Agency Name{" "}
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Email
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Phone
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell "
                            align="left"
                          >
                            Category
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Onboarding <br /> Completed
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Risk <br /> Assessment
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell th-width"
                            align="left"
                          >
                            Schedule <br /> Meeting
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell th-width"
                            align="left"
                          >
                            Meeting Details
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Status
                          </TableCell>
                          <TableCell
                            className="tableborder table-head-cell"
                            align="left"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody id="client-table-body">
                        {rows.map((row, index) => (
                          <TableRow key={row.name}>
                            <TableCell
                              component="th"
                              scope="row"
                              className="text-start tableborder"
                            >
                              <a href="#" className="table-link">
                                {row.name}
                              </a>
                            </TableCell>
                            <TableCell align="left" className="tableborder ">
                              {" "}
                              <a
                                href="#"
                                className="table-link main-dark-color"
                              >
                                {row.calories}
                              </a>
                            </TableCell>
                            <TableCell align="left" className="tableborder">
                              {" "}
                              <a href="#" className="table-link">
                                {row.fat}
                              </a>
                            </TableCell>
                            <TableCell align="left" className="tableborder">
                              {" "}
                              <a href="#" className="table-link">
                                {row.carbs}
                              </a>
                            </TableCell>
                            <TableCell align="left" className="tableborder">
                              {" "}
                              <a
                                href="#"
                                className="table-link table-category-cell"
                              >
                                {row.category}
                              </a>
                            </TableCell>
                            <TableCell align="left" className="tableborder">
                              {" "}
                              <a href="#" className="table-link">
                                {row.onboarding}
                              </a>
                            </TableCell>
                            <TableCell
                              align="left"
                              id="client-color-td"
                              className={`status-cell-${index + 1}`}
                            >
                              {" "}
                              <a href="#" className="table-link">
                                {row.risk}
                              </a>
                            </TableCell>
                            <TableCell
                              align="left"
                              className="tableborder schedule-cell sechedule-td"
                            >
                              {" "}
                              {row.schedule}
                            </TableCell>
                            <TableCell
                              align="left"
                              id="meetingCell"
                              className={`status-cell-${index + 2}`}
                            >
                              {/* Dropdown start here  */}


                              <div className="meeting-cell">


                                <a
                                  aria-describedby={id}
                                  variant="contained"
                                  onClick={handleClick}
                                  href="javascript:;"
                                  className="table-link"
                                >
                                  {row.meeting}
                                </a>


                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  className="client-manage-popup-shadow"
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                >
                                  <Box >


                                    <Box sx={{ width: "100%" }} className="client-popover-head">
                                      <Box id="alert-dialog-description">
                                        <Box className="d-flex align-items-center ">
                                          <Box className="popup-img">
                                            <Avatar
                                              src={popupimage}
                                              variant="rounded"
                                            ></Avatar>
                                          </Box>
                                          <Box className="client-detail-modal">
                                            <Box className="d-flex align-items-center">
                                              <Typography>Name:</Typography>{" "}
                                              <span> Harry Nathan</span>
                                            </Box>
                                            <Box className="d-flex align-items-center">
                                              <Typography>
                                                Client ID:
                                              </Typography>{" "}
                                              <span> A12345</span>
                                            </Box>
                                            <Box className="d-flex align-items-center">
                                              <img src={video}></img>{" "}
                                              <span>
                                                {" "}
                                                Wed 22-03-23, 10:00 to 11:00
                                              </span>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>


                                    <Box
                                      className="client-tab-box"
                                      id="client-manage-popup-main"
                                      sx={{ width: "100%" }}
                                    >
                                      <Box className="padding-left-20">
                                        <Tabs
                                          value={clientValue}
                                          onChange={handleChangeClient}
                                          className="clientmanage-popup-tab"
                                          aria-label="basic tabs example"
                                        >
                                          <Tab
                                            label="Tasks"
                                            className="client-tab client-tab-item"
                                            icon={
                                              <img
                                                src={taskIcon}
                                                className="tab-icon"
                                              ></img>
                                            }
                                            {...a12yProps(0)}
                                          />
                                          <Tab
                                            label="Notes"
                                            className="client-tab2 client-tab-item"
                                            icon={
                                              <img
                                                src={taskIcon}
                                                className="tab-icon"
                                              ></img>
                                            }
                                            {...a12yProps(1)}
                                          />
                                        </Tabs>
                                      </Box>
                                      <TabPanel className="client-manage-tabpanel" value={clientValue} index={0}>
                                        <Box className="task-tab-main">
                                          <Box className="d-flex task-tab-row-main task-tab-row align-items-center justify-content-between">
                                            <Link
                                              to="/addtask"
                                              className="d-flex align-items-center task-tab-row-typo"
                                            >
                                              GP <AddCircleIcon />
                                            </Link>{" "}
                                            <sapn className="task-tab-row-span">
                                              No task added
                                            </sapn>
                                          </Box>

                                          {/* accordian start  */}

                                          <Accordion className="accordian">
                                            <AccordionSummary
                                              expandIcon={<PlayArrowIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                              className="d-flex task-tab-row accordianfirst-row align-items-center justify-content-between"
                                            >
                                              <Link
                                                to="/addtask"
                                                className="d-flex align-items-center task-tab-row-typo"
                                              >
                                                Physiotherapist{" "}
                                                <AddCircleIcon />
                                              </Link>{" "}
                                              <sapn className="task-tab-row-span">
                                                ETA: 25-03-23
                                              </sapn>
                                            </AccordionSummary>
                                            <AccordionDetails className="accordian-details">
                                              <Box className="d-flex task-tab-row align-items-center p-0 justify-content-between">
                                                <Typography className="task-tab-row-typo">
                                                  Do tests & share Reports{" "}
                                                </Typography>{" "}
                                                <sapn className="task-tab-row-span">
                                                  {" "}
                                                  <span className="status-box accordianfirst-row"></span>{" "}
                                                  Pending
                                                </sapn>
                                              </Box>
                                              <Box className="accordian-details-text">
                                                <sapn className="task-tab-row-span sapn1">
                                                  {" "}
                                                  As discussed in meeting,
                                                  please do all the required
                                                  tests & share reports, we’ll
                                                  review them & get back.
                                                </sapn>
                                                <span className="task-add-img task-tab-row-span">
                                                  image.jpg
                                                </span>
                                                <sapn className="task-tab-row-span span-italic">
                                                  {" "}
                                                  Task Added On: 22-03-23, 11:45
                                                  AM{" "}
                                                </sapn>
                                              </Box>
                                            </AccordionDetails>
                                          </Accordion>
                                          <Accordion className="accordian">
                                            <AccordionSummary
                                              expandIcon={<PlayArrowIcon />}
                                              aria-controls="panel2a-content"
                                              id="panel2a-header"
                                              className="d-flex task-tab-row accordiansecond-row align-items-center justify-content-between"
                                            >
                                              <Link
                                                to="/addtask"
                                                className="d-flex align-items-center task-tab-row-typo"
                                              >
                                                Psychologist <AddCircleIcon />
                                              </Link>{" "}
                                              <sapn className="task-tab-row-span">
                                                ETA: 25-03-23
                                              </sapn>
                                            </AccordionSummary>
                                            <AccordionDetails className="accordian-details">
                                              <Box className="d-flex task-tab-row align-items-center p-0 justify-content-between">
                                                <Typography className="task-tab-row-typo">
                                                  Do tests & share Reports{" "}
                                                </Typography>{" "}
                                                <sapn className="task-tab-row-span">
                                                  {" "}
                                                  <span className="status-box accordiansecond-row "></span>{" "}
                                                  Rejected
                                                </sapn>
                                              </Box>
                                              <Box className="accordian-details-text">
                                                <sapn className="task-tab-row-span sapn1">
                                                  {" "}
                                                  As discussed in meeting,
                                                  please do all the required
                                                  tests & share reports, we’ll
                                                  review them & get back.
                                                </sapn>
                                                <span className="task-add-img task-tab-row-span">
                                                  image.jpg
                                                </span>
                                                <sapn className="task-tab-row-span span-italic">
                                                  {" "}
                                                  Task Added On: 22-03-23, 11:45
                                                  AM{" "}
                                                </sapn>
                                              </Box>
                                            </AccordionDetails>
                                          </Accordion>
                                          <Accordion className="accordian">
                                            <AccordionSummary
                                              expandIcon={<PlayArrowIcon />}
                                              aria-controls="panel3a-content"
                                              id="panel3a-header"
                                              className="d-flex task-tab-row accordianthird-row align-items-center justify-content-between"
                                            >
                                              <Link
                                                to="/addtask"
                                                className="d-flex align-items-center task-tab-row-typo"
                                              >
                                                Hospital <AddCircleIcon />
                                              </Link>{" "}
                                              <sapn className="task-tab-row-span">
                                                ETA: 25-03-23
                                              </sapn>
                                            </AccordionSummary>
                                            <AccordionDetails className="accordian-details">
                                              <Box className="d-flex task-tab-row align-items-center p-0 justify-content-between">
                                                <Typography className="task-tab-row-typo">
                                                  Do tests & share Reports{" "}
                                                </Typography>{" "}
                                                <sapn className="task-tab-row-span">
                                                  {" "}
                                                  <span className="status-box accordianthird-row"></span>{" "}
                                                  Completed
                                                </sapn>
                                              </Box>
                                              <Box className="accordian-details-text">
                                                <sapn className="task-tab-row-span sapn1">
                                                  {" "}
                                                  As discussed in meeting,
                                                  please do all the required
                                                  tests & share reports, we’ll
                                                  review them & get back.
                                                </sapn>
                                                <span className="task-add-img task-tab-row-span">
                                                  image.jpg
                                                </span>

                                                <sapn className="task-tab-row-span span-italic">
                                                  {" "}
                                                  Task Added On: 22-03-23, 11:45
                                                  AM{" "}
                                                </sapn>
                                              </Box>
                                            </AccordionDetails>
                                          </Accordion>
                                        </Box>
                                      </TabPanel>
                                      <TabPanel className="client-manage-tabpanel" value={clientValue} index={1}>
                                        <Box className="task-tab-main">
                                          <Box className="d-flex task-tab-row-main align-items-center justify-content-between">
                                            <Link
                                              to="/add-note"
                                              className="d-flex align-items-center task-tab-row-typo"
                                            >
                                              GP <AddCircleIcon />
                                            </Link>{" "}
                                            <sapn className="task-tab-row-span">
                                              No notes added
                                            </sapn>
                                          </Box>

                                          {/* accordian start  */}

                                          <Accordion className="accordian">
                                            <AccordionSummary
                                              expandIcon={<PlayArrowIcon />}
                                              aria-controls="panel1a-content"
                                              id="panel1a-header"
                                              className="d-flex task-tab-row align-items-center justify-content-between"
                                            >
                                              <Link
                                                to="/add-note"
                                                className="d-flex align-items-center task-tab-row-typo"
                                              >
                                                Physiotherapist{" "}
                                                <AddCircleIcon />
                                              </Link>{" "}
                                              <sapn className="task-tab-row-span">
                                                ETA: 25-03-23
                                              </sapn>
                                            </AccordionSummary>
                                            <AccordionDetails className="accordian-details">
                                              <Box className="d-flex task-tab-row align-items-center p-0 justify-content-between">
                                                <Typography className="task-tab-row-typo">
                                                  Do tests & share Reports{" "}
                                                </Typography>{" "}
                                                <sapn className="task-tab-row-span">
                                                  {" "}
                                                  <span className="status-box accordianfirst-row"></span>{" "}
                                                  Pending
                                                </sapn>
                                              </Box>
                                              <Box className="accordian-details-text">
                                                <sapn className="task-tab-row-span sapn1">
                                                  {" "}
                                                  As discussed in meeting,
                                                  please do all the required
                                                  tests & share reports, we’ll
                                                  review them & get back.
                                                </sapn>
                                                <span className="task-add-img task-tab-row-span">
                                                  image.jpg
                                                </span>

                                                <sapn className="task-tab-row-span span-italic">
                                                  {" "}
                                                  Task Added On: 22-03-23, 11:45
                                                  AM{" "}
                                                </sapn>
                                              </Box>
                                            </AccordionDetails>
                                          </Accordion>
                                          <Accordion className="accordian">
                                            <AccordionSummary
                                              expandIcon={<PlayArrowIcon />}
                                              aria-controls="panel2a-content"
                                              id="panel2a-header"
                                              className="d-flex task-tab-row  align-items-center justify-content-between"
                                            >
                                              <Link
                                                to="/add-note"
                                                className="d-flex align-items-center task-tab-row-typo"
                                              >
                                                Psychologist <AddCircleIcon />
                                              </Link>{" "}
                                              <sapn className="task-tab-row-span">
                                                ETA: 25-03-23
                                              </sapn>
                                            </AccordionSummary>
                                            <AccordionDetails className="accordian-details">
                                              <Box className="d-flex task-tab-row align-items-center p-0 justify-content-between">
                                                <Typography className="task-tab-row-typo">
                                                  Do tests & share Reports{" "}
                                                </Typography>{" "}
                                                <sapn className="task-tab-row-span">
                                                  {" "}
                                                  <span className="status-box accordiansecond-row "></span>{" "}
                                                  Rejected
                                                </sapn>
                                              </Box>
                                              <Box className="accordian-details-text">
                                                <sapn className="task-tab-row-span sapn1">
                                                  {" "}
                                                  As discussed in meeting,
                                                  please do all the required
                                                  tests & share reports, we’ll
                                                  review them & get back.
                                                </sapn>
                                                <span className="task-add-img task-tab-row-span">
                                                  image.jpg
                                                </span>

                                                <sapn className="task-tab-row-span span-italic">
                                                  {" "}
                                                  Task Added On: 22-03-23, 11:45
                                                  AM{" "}
                                                </sapn>
                                              </Box>
                                            </AccordionDetails>
                                          </Accordion>
                                          <Accordion className="accordian">
                                            <AccordionSummary
                                              expandIcon={<PlayArrowIcon />}
                                              aria-controls="panel3a-content"
                                              id="panel3a-header"
                                              className="d-flex task-tab-row align-items-center justify-content-between"
                                            >
                                              <Link
                                                to="/add-note"
                                                className="d-flex align-items-center task-tab-row-typo"
                                              >
                                                Hospital <AddCircleIcon />
                                              </Link>{" "}
                                              <sapn className="task-tab-row-span">
                                                ETA: 25-03-23
                                              </sapn>
                                            </AccordionSummary>
                                            <AccordionDetails className="accordian-details">
                                              <Box className=" task-tab-row  p-0 ">
                                                <Typography className="task-tab-row-typo">
                                                  Regarding Reports{" "}
                                                </Typography>
                                              </Box>
                                              <Box className="accordian-details-text">
                                                <sapn className="task-tab-row-span sapn1">
                                                  {" "}
                                                  We have checked your reports,
                                                  they look fine.
                                                </sapn>
                                                <span className="task-add-img task-tab-row-span">
                                                  image.jpg
                                                </span>

                                                <sapn className="task-tab-row-span span-italic">
                                                  {" "}
                                                  Note Added On: 22-03-23, 11:45
                                                  AM{" "}
                                                </sapn>
                                              </Box>
                                            </AccordionDetails>
                                          </Accordion>
                                        </Box>
                                      </TabPanel>
                                    </Box>

                                  </Box>
                                </Popover>
                              </div>


                            </TableCell>
                            <TableCell
                              align="left"
                              className={`status-cell-${index + 1}`}
                            >
                              {" "}
                              <a href="#" className="table-link">
                                {row.status}
                              </a>
                            </TableCell>

                            <TableCell
                              align="left"
                              className="tableborder table-editcell"
                            >
                              <Box className="table-edit-del">
                                <Box>
                                  <a
                                    href="#upload"
                                    id="edit"
                                    label="Domain"
                                    className="table-edit success-btn justify-content-center  btn"
                                    margin="normal"
                                  >
                                    <DoneIcon /> Approve
                                  </a>
                                </Box>
                              </Box>

                              <Dropdown drop="start" autoClose="outside">
                                <Dropdown.Toggle id="dropdown-autoclose-outside" className="action-toggle-button">
                                  <MoreVertIcon
                                    className="dot-icon"
                                    onClick={toggleClass}
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu client-drop-menu">
                                  <Dropdown.Item
                                    className="table-edit-del"
                                    href="#/action-1"
                                  >
                                    {" "}
                                    <a
                                      href="#upload"
                                      id="domain1"
                                      label="Domain"
                                      className="table-edit  justify-content-start client-table-btn btn"
                                      margin="normal"
                                    >
                                      <Avatar
                                        src={View}
                                        className="view-icon"
                                      ></Avatar>{" "}
                                      View
                                    </a>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="table-edit-del "
                                    href="#/action-1"
                                  >
                                    {" "}
                                    <a
                                      href="#upload"
                                      id="domain1"
                                      label="Domain"
                                      className="table-edit approve-btn  justify-content-start client-table-btn btn"
                                      margin="normal"
                                    >
                                      <DoneIcon /> Approve
                                    </a>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="table-edit-del"
                                    href="#/action-2"
                                  >
                                    <a
                                      href="#upload"
                                      id="domain2"
                                      label="Domain"
                                      className="table-del justify-content-start client-table-btn  btn"
                                      margin="normal"
                                    >
                                      <CloseIcon /> Reject
                                    </a>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={2}>
                Item Three
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
