import { lazy } from "react";

const SuperAdminRoutes = [
  {
    path: "/super-admin/list-main-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/mainTypeSymptoms/list")),
  },

  {
    path: "/super-admin/pay-run",
    // component: lazy(() => import("../pages/superAdmin/PayRun/Pay-Run")),
    component: lazy(() => import("../components/pay-run/PayRun")),
  },
  {
    path: "/super-admin/list-payrun",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/payRun/payRun")
    ),
  },

  {
    path: "/super-admin/funds-management",
    component: lazy(() =>
      import("../Dashboard/FundManagement/Fund-Management")
    ),
  },

  {
    path: "/super-admin/add-main-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/mainTypeSymptoms/edit")),
  },
  {
    path: "/super-admin/edit-main-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/mainTypeSymptoms/edit")),
  },
  {
    path: "/super-admin/list-sub-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/subTypeSymptoms/list")),
  },
  {
    path: "/super-admin/add-sub-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/subTypeSymptoms/edit")),
  },
  {
    path: "/super-admin/edit-sub-type-symptoms",
    component: lazy(() => import("../pages/superAdmin/subTypeSymptoms/edit")),
  },

  {
    path: "/super-admin/list-medical-history",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },

  {
    path: "/super-admin/medical-history-pdf",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/medicalHistoryPdf")
    ),
  },
  {
    path: "/super-admin/medical-history-print",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/print")
    ),
  },

  {
    path: "/super-admin/list-medication-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },

  {
    path: "/super-admin/list-observation",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },

  {
    path: "/super-admin/list-prn-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },

  {
    path: "/super-admin/medical-history-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medical-History")
    ),
  },
  {
    path: "/super-admin/add-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Add-Medical-History"
      )
    ),
  },

  {
    path: "/super-admin/edit-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Add-Medical-History"
      )
    ),
  },

  {
    path: "/super-admin/view-medical-history-dev",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/View-Medical-History"
      )
    ),
  },

  {
    path: "/super-admin/add-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Add-Medication")
    ),
  },
  {
    path: "/super-admin/edit-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Add-Medication")
    ),
  },
  {
    path: "/super-admin/view-medication-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/View-Medication")
    ),
  },
  {
    path: "/super-admin/medication-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medication-chart")
    ),
  },

  {
    path: "/super-admin/observation-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Observation-chart")
    ),
  },
  {
    path: "/super-admin/observation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/Viewtab/Observation/observation")
    ),
  },
  {
    path: "/super-admin/prn-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medication-chart")
    ),
  },

  {
    path: "/super-admin/medical-history",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/Medical-History")
    ),
  },
  {
    path: "/super-admin/add-medical-history",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/Add-Medical-History")
    ),
  },
  {
    path: "/super-admin/add-medication",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/Add-Medication")
    ),
  },
  {
    path: "/super-admin/edit-medication",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/Edit-Medication")
    ),
  },
  {
    path: "/super-admin/medication-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/Medication-chart")
    ),
  },
  {
    path: "/super-admin/prn-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management/PrnChart")
    ),
  },
  {
    path: "/timesheetadmin",
    component: lazy(() => import("../Dashboard/Timesheet/TimesheetAdmin")),
  },
  {
    path: "/timesheetview",
    component: lazy(() => import("../Dashboard/Timesheet/TimesheetView")),
  },
  {
    path: "/super-admin/list-hundred-points",
    component: lazy(() => import("../pages/superAdmin/hundred-points/list")),
  },
  {
    path: "/super-admin/list-tooltips",
    component: lazy(() => import("../pages/superAdmin/tooltip/list")),
  },
  {
    path: "/super-admin/add-tooltips",
    component: lazy(() => import("../pages/superAdmin/tooltip/edit")),
  },
  {
    path: "/super-admin/edit-tooltips",
    component: lazy(() => import("../pages/superAdmin/tooltip/edit")),
  },
  {
    path: "/super-admin/edit-hundred-points",
    component: lazy(() => import("../pages/superAdmin/hundred-points/edit")),
  },
  {
    path: "/super-admin/add-hundred-points",
    component: lazy(() => import("../pages/superAdmin/hundred-points/edit")),
  },
  {
    path: "/super-admin/list-support-group",
    component: lazy(() => import("../pages/superAdmin/support-group/list")),
  },
  {
    path: "/super-admin/edit-support-group",
    component: lazy(() => import("../pages/superAdmin/support-group/edit")),
  },
  {
    path: "/super-admin/add-support-group",
    component: lazy(() => import("../pages/superAdmin/support-group/edit")),
  },

  {
    path: "/super-admin/list-packages",
    component: lazy(() => import("../pages/superAdmin/packages/list")),
  },
  {
    path: "/super-admin/edit-packages",
    component: lazy(() => import("../pages/superAdmin/packages/edit")),
  },
  {
    path: "/super-admin/add-packages",
    component: lazy(() => import("../pages/superAdmin/packages/edit")),
  },

  {
    path: "/super-admin/list-induction-category",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/list")),
  },
  {
    path: "/super-admin/edit-induction-category",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/edit")),
  },
  {
    path: "/super-admin/add-induction-category",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/edit")),
  },

  {
    path: "/super-admin/list-induction-heading-title",
    component: lazy(() => import("../pages/superAdmin/inductionHeading/list")),
  },
  {
    path: "/super-admin/add-induction-heading",
    component: lazy(() => import("../pages/superAdmin/inductionHeading/edit")),
  },
  {
    path: "/super-admin/edit-induction-heading-title",
    component: lazy(() => import("../pages/superAdmin/inductionHeading/edit")),
  },
  {
    path: "/super-admin/add-induction-heading-title",
    component: lazy(() => import("../pages/superAdmin/inductionCategory/edit")),
  },

  {
    path: "/super-admin/list-menu-masters",
    component: lazy(() => import("../pages/superAdmin/menuMaster/list")),
  },
  {
    path: "/super-admin/smtp-setup",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/smtpSetup.js")
    ),
  },
  {
    path: "/super-admin/edit-menu-masters",
    component: lazy(() => import("../pages/superAdmin/menuMaster/edit")),
  },
  {
    path: "/super-admin/add-menu-masters",
    component: lazy(() => import("../pages/superAdmin/menuMaster/edit")),
  },

  {
    path: "/super-admin/list-agencies",
    component: lazy(() => import("../pages/superAdmin/agencies/list")),
  },
  {
    path: "/super-admin/edit-agencies",
    component: lazy(() => import("../pages/superAdmin/agencies/edit")),
  },
  {
    path: "/super-admin/add-agencies",
    component: lazy(() => import("../pages/superAdmin/agencies/edit")),
  },

  {
    path: "/super-admin/list-shifttype",
    component: lazy(() => import("../pages/superAdmin/shift-type/list")),
  },
  {
    path: "/super-admin/edit-shifttype",
    component: lazy(() => import("../pages/superAdmin/shift-type/edit")),
  },
  {
    path: "/super-admin/add-shifttype",
    component: lazy(() => import("../pages/superAdmin/shift-type/edit")),
  },

  {
    path: "/super-admin/list-zone",
    component: lazy(() => import("../pages/superAdmin/zone/list")),
  },
  {
    path: "/super-admin/edit-zone",
    component: lazy(() => import("../pages/superAdmin/zone/edit")),
  },
  {
    path: "/super-admin/add-zone",
    component: lazy(() => import("../pages/superAdmin/zone/edit")),
  },

  {
    path: "/super-admin/list-skills",
    component: lazy(() => import("../pages/superAdmin/skill/list")),
  },
  {
    path: "/super-admin/edit-skills",
    component: lazy(() => import("../pages/superAdmin/skill/edit")),
  },
  {
    path: "/super-admin/add-skills",
    component: lazy(() => import("../pages/superAdmin/skill/edit")),
  },
  {
    path: "/super-admin/edit-meals",
    component: lazy(() => import("../pages/superAdmin/meals/edit")),
  },
  {
    path: "/super-admin/add-meals",
    component: lazy(() => import("../pages/superAdmin/meals/edit")),
  },

  {
    path: "/super-admin/list-cancellation-reason",
    component: lazy(() =>
      import("../pages/superAdmin/cancellationReason/list")
    ),
  },
  {
    path: "/super-admin/edit-cancellation-reason",
    component: lazy(() =>
      import("../pages/superAdmin/cancellationReason/edit")
    ),
  },
  {
    path: "/super-admin/add-cancellation-reason",
    component: lazy(() =>
      import("../pages/superAdmin/cancellationReason/edit")
    ),
  },

  {
    path: "/super-admin/list-levels",
    component: lazy(() => import("../pages/superAdmin/level/list")),
  },
  {
    path: "/super-admin/edit-levels",
    component: lazy(() => import("../pages/superAdmin/level/edit")),
  },
  {
    path: "/super-admin/add-levels",
    component: lazy(() => import("../pages/superAdmin/level/edit")),
  },

  {
    path: "/super-admin/list-job-titles",
    component: lazy(() => import("../pages/superAdmin/job-title/list")),
  },
  {
    path: "/super-admin/edit-job-titles",
    component: lazy(() => import("../pages/superAdmin/job-title/edit")),
  },
  {
    path: "/super-admin/add-job-titles",
    component: lazy(() => import("../pages/superAdmin/job-title/edit")),
  },

  {
    path: "/super-admin/list-job-types",
    component: lazy(() => import("../pages/superAdmin/job-type/list")),
  },
  {
    path: "/super-admin/edit-job-types",
    component: lazy(() => import("../pages/superAdmin/job-type/edit")),
  },
  {
    path: "/super-admin/add-job-types",
    component: lazy(() => import("../pages/superAdmin/job-type/edit")),
  },

  {
    path: "/super-admin/list-quarters",
    component: lazy(() => import("../pages/superAdmin/quarter/list")),
  },
  {
    path: "/super-admin/edit-quarters",
    component: lazy(() => import("../pages/superAdmin/quarter/edit")),
  },
  {
    path: "/super-admin/add-quarters",
    component: lazy(() => import("../pages/superAdmin/quarter/edit")),
  },

  {
    path: "/super-admin/client-list",
    component: lazy(() => import("../pages/superAdmin/client-list/list")),
  },
  {
    path: "/super-admin/edit-client",
    component: lazy(() => import("../pages/superAdmin/client-list/edit")),
  },
  {
    path: "/super-admin/add-client",
    component: lazy(() => import("../pages/superAdmin/client-list/edit")),
  },
  {
    path: "/super-admin/view-client",
    component: lazy(() => import("../pages/superAdmin/client-list/view")),
  },
  {
    path: "/super-admin/view-client-details-old",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/view-details")
    ),
  },

  {
    path: "/super-admin/list-health-carer",
    component: lazy(() => import("../pages/superAdmin/health-carer/list")),
  },
  {
    path: "/super-admin/add-health-carer",
    component: lazy(() => import("../pages/superAdmin/health-carer/edit")),
  },
  {
    path: "/super-admin/edit-health-carer",
    component: lazy(() => import("../pages/superAdmin/health-carer/edit")),
  },
  {
    path: "/super-admin/health-carer-details",
    component: lazy(() => import("../pages/superAdmin/health-carer/details")),
  },

  {
    path: "/super-admin/view-health-carer-details",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/view-details-hc")
    ),
  },
  {
    path: "/super-admin/view-health-carer-details-SN",
    component: lazy(() =>
      import("../components/emavailability/ViewDetailHC.js")
    ),
  },
  {
    path: "/super-admin/health-carer-view",
    component: lazy(() => import("../pages/superAdmin/health-carer/view")),
  },
  {
    path: "/super-admin/list-health-carer/interview-history",
    component: lazy(() =>
      import(
        "../pages/superAdmin/health-carer/scheduleInterviewHistory/History"
      )
    ),
  },
  {
    path: "/super-admin/swap-appointments",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/swap-appointment")
    ),
  },

  {
    path: "/super-admin/list-health-carer-offboarding",
    component: lazy(() =>
      import("../pages/superAdmin/off-boarding/health-carer/list")
    ),
  },
  {
    path: "/super-admin/list-support-coordinator-offboarding",
    component: lazy(() =>
      import("../pages/superAdmin/off-boarding/support-coordinator/list")
    ),
  },

  {
    path: "/super-admin/add-expenses",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/add-expense")
    ),
  },
  {
    path: "/super-admin/onboard",
    component: lazy(() => import("../pages/superAdmin/Account-Setup")),
  },

  {
    path: "/super-admin/off-boarding",
    component: lazy(() => import("../pages/superAdmin/off-boarding/MyAccount")),
  },

  {
    path: "/super-admin/on-board",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/on-boarding")
    ),
  },

  //onBoardingStepper

  {
    path: "/super-admin/health-carer/onboard",
    component: lazy(() => import("../pages/healthCarer/Account-Setup")),
  },

  {
    path: "/super-admin/support-coordinator/onboard",
    component: lazy(() => import("../pages/supportCoordinator/Account-Setup")),
  },

  {
    path: "/super-admin/service-provider/onboard",
    component: lazy(() => import("../pages/serviceProvider/Account-Setup")),
  },

  {
    path: "/super-admin/client/onboard",
    component: lazy(() => import("../pages/client/Account-Setup")),
  },

  {
    path: "/super-admin/add-state",
    component: lazy(() => import("../pages/superAdmin/state/edit")),
  },
  {
    path: "/super-admin/edit-state",
    component: lazy(() => import("../pages/superAdmin/state/edit")),
  },
  {
    path: "/super-admin/list-state",
    component: lazy(() => import("../pages/superAdmin/state/list")),
  },

  {
    path: "/super-admin/edit-meeting-type",
    component: lazy(() => import("../pages/superAdmin/meeting-type/edit")),
  },
  {
    path: "/super-admin/add-meeting-type",
    component: lazy(() => import("../pages/superAdmin/meeting-type/edit")),
  },
  {
    path: "/super-admin/list-meeting-type",
    component: lazy(() => import("../pages/superAdmin/meeting-type/list")),
  },

  {
    path: "/super-admin/edit-task-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-task/edit")
    ),
  },
  {
    path: "/super-admin/add-task-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-task/edit")
    ),
  },
  {
    path: "/super-admin/list-task",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-task/list")
    ),
  },

  {
    path: "/super-admin/view-tasks",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-task/view")
    ),
  },
  {
    path: "/super-admin/add-note-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-note/edit")
    ),
  },
  {
    path: "/super-admin/edit-note-evaluation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-note/edit")
    ),
  },

  {
    path: "/super-admin/list-note",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-note/list")
    ),
  },
  {
    path: "/super-admin/view-note",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/add-note/view")
    ),
  },
  {
    path: "/super-admin/add-conflict",
    component: lazy(() => import("../pages/superAdmin/conflict/edit")),
  },
  {
    path: "/super-admin/edit-conflict",
    component: lazy(() => import("../pages/superAdmin/conflict/edit")),
  },
  {
    path: "/super-admin/list-conflict",
    component: lazy(() => import("../pages/superAdmin/conflict/list")),
  },
  {
    path: "/super-admin/edit-category",
    component: lazy(() => import("../pages/superAdmin/client-category/edit")),
  },
  // commenting it because it is not needed
  // {
  //   path: "/super-admin/add-category",
  //   component: lazy(() => import("../pages/superAdmin/client-category/edit")),
  // },

  {
    path: "/super-admin/list-category",
    component: lazy(() => import("../pages/superAdmin/client-category/list")),
  },
  // {
  //   path: "/add-category",
  //   component: lazy(() => import("../pages/superAdmin/client-category/edit")),
  // },
  // {
  //   path: "/super-admin/home",
  //   component: lazy(() => import("../pages/superAdmin/home")),
  // },

  // SA New Dashboard
  {
    path: "/super-admin/home",
    component: lazy(() => import("../pages/superAdmin/Dashboard/SA-Dashboard")),
  },

  {
    path: "/super-admin/pending-approval",
    component: lazy(() => import("../pages/superAdmin/pending-approval")),
  },
  {
    path: "/super-admin/availibility",
    component: lazy(() =>
      import("../pages/superAdmin/availability/Availibility")
    ),
  },
  {
    path: "/super-admin/list-support-coordinator",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/list")
    ),
  },

  {
    path: "/super-admin/edit-support-coordinator",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/edit")
    ),
  },
  {
    path: "/super-admin/add-support-coordinator",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/edit")
    ),
  },

  {
    path: "/super-admin/support-coordinator-details",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/details")
    ),
  },
  {
    path: "/super-admin/view-support-coordinator-details",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/view-details-sc")
    ),
  },
  {
    path: "/super-admin/list-service-provider",
    component: lazy(() => import("../pages/superAdmin/service-provider/list")),
  },

  {
    path: "/super-admin/edit-service-provider",
    component: lazy(() => import("../pages/superAdmin/service-provider/edit")),
  },
  {
    path: "/super-admin/add-service-provider",
    component: lazy(() => import("../pages/superAdmin/service-provider/edit")),
  },
  {
    path: "/super-admin/service-provider-details",
    component: lazy(() =>
      import("../pages/superAdmin/service-provider/details")
    ),
  },
  {
    path: "/super-admin/schedule-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-meeting/meeting")
    ),
  },
  {
    path: "/super-admin/schedule-interview",
    component: lazy(() =>
      import(
        "../pages/superAdmin/schedule-interview/health-carer/schedule-interview"
      )
    ),
  },
  {
    path: "/super-admin/health-carer-on-call",
    component: lazy(() =>
      import("../pages/superAdmin/health-carer/on-call/list")
    ),
  },

  {
    path: "/super-admin/add-risk-assessment",
    component: lazy(() => import("../pages/superAdmin/risk-assessment/edit")),
  },
  {
    path: "/super-admin/edit-risk-assessment",
    component: lazy(() => import("../pages/superAdmin/risk-assessment/edit")),
  },

  {
    path: "/super-admin/list-risk-assessment",
    component: lazy(() => import("../pages/superAdmin/risk-assessment/list")),
  },
  {
    path: "/super-admin/list-user-type",
    component: lazy(() => import("../pages/superAdmin/user-type/list")),
  },
  {
    path: "/super-admin/edit-user-type",
    component: lazy(() => import("../pages/superAdmin/user-type/edit")),
  },
  {
    path: "/super-admin/add-user-type",
    component: lazy(() => import("../pages/superAdmin/user-type/edit")),
  },
  {
    path: "/super-admin/list-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/list")),
  },

  {
    path: "/super-admin/edit-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/edit")),
  },
  {
    path: "/super-admin/add-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/edit")),
  },

  {
    path: "/super-admin/list-severity",
    component: lazy(() => import("../pages/superAdmin/severity/list")),
  },
  {
    path: "/super-admin/add-severity",
    component: lazy(() => import("../pages/superAdmin/severity/edit")),
  },
  {
    path: "/super-admin/edit-severity",
    component: lazy(() => import("../pages/superAdmin/severity/edit")),
  },

  {
    path: "/super-admin/list-appointment-type",
    component: lazy(() => import("../pages/superAdmin/Appointment-type/list")),
  },
  {
    path: "/super-admin/edit-appointment-type",
    component: lazy(() => import("../pages/superAdmin/Appointment-type/edit")),
  },
  {
    path: "/super-admin/add-appointment-type",
    component: lazy(() => import("../pages/superAdmin/Appointment-type/edit")),
  },

  {
    path: "/super-admin/list-help-management",
    component: lazy(() => import("../pages/superAdmin/Help-management/list")),
  },
  {
    path: "/super-admin/edit-help-management",
    component: lazy(() => import("../pages/superAdmin/Help-management/edit")),
  },
  {
    path: "/super-admin/add-help-management",
    component: lazy(() => import("../pages/superAdmin/Help-management/edit")),
  },

  {
    path: "/super-admin/list-routines",
    component: lazy(() => import("../pages/superAdmin/routine/list")),
  },
  {
    path: "/super-admin/add-routines",
    component: lazy(() => import("../pages/superAdmin/routine/edit")),
  },
  {
    path: "/super-admin/edit-routines",
    component: lazy(() => import("../pages/superAdmin/routine/edit")),
  },

  {
    path: "/super-admin/list-textspeechkit",
    component: lazy(() => import("../pages/superAdmin/textspeechkit/list")),
  },

  {
    path: "/super-admin/add-textspeechkit",
    component: lazy(() => import("../pages/superAdmin/textspeechkit/edit")),
  },

  {
    path: "/super-admin/add-speech-to-text",
    component: lazy(() =>
      import("../pages/superAdmin/textspeechkit/addSpeechToText")
    ),
  },
  {
    path: "/super-admin/view-textspeechkit",
    component: lazy(() => import("../pages/superAdmin/textspeechkit/details")),
  },
  {
    path: "/super-admin/add-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/add-appointment")
    ),
  },

  {
    path: "/super-admin/edit-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/add-appointment")
    ),
  },

  {
    path: "/super-admin/myaccount",
    component: lazy(() => import("../pages/superAdmin/form/MyAccount")),
  },

  {
    path: "/super-admin/list-package-menu",
    component: lazy(() => import("../pages/superAdmin/packageMenu/list")),
  },

  {
    path: "/super-admin/add-package-menu",
    component: lazy(() => import("../pages/superAdmin/packageMenu/edit")),
  },
  {
    path: "/super-admin/edit-package-menu",
    component: lazy(() => import("../pages/superAdmin/packageMenu/edit")),
  },

  {
    path: "/super-admin/import-ndis",
    component: lazy(() => import("../pages/superAdmin/ndis/NdisFileImport")),
  },

  {
    path: "/super-admin/pay-rates",
    component: lazy(() => import("../pages/superAdmin/payRates/PayRates")),
  },

  {
    path: "/super-admin/add-routine-icons",
    component: lazy(() => import("../pages/superAdmin/routine-icon")),
  },

  {
    path: "/super-admin/edit-sidebar-menu",
    component: lazy(() => import("../pages/superAdmin/sidebar-menu/edit")),
  },
  {
    path: "/super-admin/add-sidebar-menu",
    component: lazy(() => import("../pages/superAdmin/sidebar-menu/edit")),
  },
  {
    path: "/super-admin/list-sidebar-menu",
    component: lazy(() => import("../pages/superAdmin/sidebar-menu/list")),
  },
  {
    path: "/super-admin/list-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/list/AppointmentList")
    ),
  },
  {
    path: "/super-admin/list-delayed-appointment",
    component: lazy(() =>
      import("../components/calendar-view/Table/DelayedAppointment")
    ),
  },

  {
    path: "/super-admin/view-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/list/Appointment-view")
    ),
  },

  {
    path: "/super-admin/swap-appointment",
    component: lazy(() =>
      import("../components/calendar-view/swap-appointment/swapappointment")
    ),
  },
  {
    path: "/super-admin/add-routine",
    component: lazy(() =>
      import("../components/calendar-view/routine/Add-Routine")
    ),
  },

  {
    path: "/super-admin/edit-task",
    component: lazy(() =>
      import("../components/calendar-view/task/AddRoutineTask")
    ),
  },
  {
    path: "/super-admin/task-add",
    component: lazy(() =>
      import("../components/calendar-view/task/AddRoutineTask")
    ),
  },

  {
    path: "/super-admin/timesheet-pdf",
    component: lazy(() => import("../components/pay-run/PayRunPdf")),
  },

  {
    path: "/super-admin/appointmentView",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/list/Appointment-view")
    ),
  },

  {
    path: "/super-admin/list-groups",
    component: lazy(() => import("../pages/superAdmin/groups/list")),
  },

  {
    path: "/super-admin/edit-groups",
    component: lazy(() => import("../pages/superAdmin/groups/edit")),
  },
  {
    path: "/super-admin/add-groups",
    component: lazy(() => import("../pages/superAdmin/groups/edit")),
  },

  {
    path: "/super-admin/list-incident",
    component: lazy(() => import("../pages/superAdmin/incident/list")),
  },
  {
    path: "/super-admin/edit-incident",
    component: lazy(() => import("../pages/superAdmin/incident/edit")),
  },
  {
    path: "/super-admin/add-incident",
    component: lazy(() => import("../pages/superAdmin/incident/edit")),
  },
  {
    path: "/super-admin/view-incident",
    component: lazy(() => import("../pages/superAdmin/incident/view")),
  },
  {
    path: "/super-admin/pdf-incident",
    component: lazy(() => import("../pages/healthCarer/incident/pdf")),
  },

  {
    path: "/super-admin/add-agreement",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/appointment-agreement/edit")
    ),
  },
  {
    path: "/super-admin/edit-agreement",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/appointment-agreement/edit")
    ),
  },
  {
    path: "/super-admin/list-agreement",
    component: lazy(() =>
      import("../pages/superAdmin/appointment/appointment-agreement/list")
    ),
  },

  {
    path: "/super-admin/appointments-view",
    component: lazy(() =>
      import("../components/calendar-view/Appointment-View")
    ),
  },
  {
    path: "/super-admin/availability-calendar-view",
    component: lazy(() => import("../components/availability/Calendar")),
  },
  {
    path: "/super-admin/weekly-availability",
    component: lazy(() =>
      import("../components/weekly-availability/weekly-availability")
    ),
  },

  {
    path: "/super-admin/add-user-role",
    component: lazy(() => import("../pages/superAdmin/user-role/edit")),
  },
  {
    path: "/super-admin/edit-user-role",
    component: lazy(() => import("../pages/superAdmin/user-role/edit")),
  },
  {
    path: "/super-admin/list-user-role",
    component: lazy(() => import("../pages/superAdmin/user-role/list")),
  },

  {
    path: "/super-admin/offer-letter",
    component: lazy(() =>
      import("../pages/superAdmin/contentManagement/offer-letter/edit")
    ),
  },

  {
    path: "/super-admin/list-template-master",
    component: lazy(() => import("../pages/superAdmin/template-master/list")),
  },

  {
    path: "/super-admin/edit-template-master",
    component: lazy(() => import("../pages/superAdmin/template-master/edit")),
  },
  {
    path: "/super-admin/add-template-master",
    component: lazy(() => import("../pages/superAdmin/template-master/edit")),
  },

  {
    path: "/super-admin/list-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/list")),
  },
  {
    path: "/super-admin/list-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/list")),
  },

  {
    path: "/super-admin/edit-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/edit")),
  },

  {
    path: "/super-admin/add-emergency-contact",
    component: lazy(() => import("../pages/superAdmin/emergency-contact/edit")),
  },

  {
    path: "/super-admin/add-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/edit")),
  },

  {
    path: "/super-admin/edit-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/edit")),
  },

  {
    path: "/super-admin/list-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/list")),
  },

  {
    path: "/super-admin/list-template-variable-master",
    component: lazy(() =>
      import("../pages/superAdmin/template-master/variable/list")
    ),
  },

  {
    path: "/super-admin/edit-template-variable-master",
    component: lazy(() =>
      import("../pages/superAdmin/template-master/variable/edit")
    ),
  },
  {
    path: "/super-admin/add-template-variable-master",
    component: lazy(() =>
      import("../pages/superAdmin/template-master/variable/edit")
    ),
  },

  {
    path: "/super-admin/add-file",
    component: lazy(() => import("../pages/superAdmin/files/edit")),
  },
  {
    path: "/super-admin/edit-file",
    component: lazy(() => import("../pages/superAdmin/files/edit")),
  },
  {
    path: "/super-admin/list-file",
    component: lazy(() => import("../pages/superAdmin/files/list")),
  },

  {
    path: "/super-admin/edit-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/edit")),
  },
  {
    path: "/super-admin/add-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/edit")),
  },

  {
    path: "/super-admin/list-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/list")),
  },

  {
    path: "/super-admin/view-folder",
    component: lazy(() => import("../pages/superAdmin/Folder/view")),
  },

  {
    path: "/super-admin/list-funding-source",
    component: lazy(() => import("../pages/superAdmin/Funding-Source/list")),
  },

  {
    path: "/super-admin/edit-funding-source",
    component: lazy(() => import("../pages/superAdmin/Funding-Source/edit")),
  },
  {
    path: "/super-admin/add-funding-source",
    component: lazy(() => import("../pages/superAdmin/Funding-Source/edit")),
  },

  {
    path: "/super-admin/list-meeting-client-old",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-meeting/client/list")
    ),
  },

  {
    path: "/super-admin/list-view-schedule-meeting-old",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-meeting/client/view")
    ),
  },
  {
    path: "/super-admin/list-meeting-client",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-meeting/client/list-tab")
    ),
  },

  {
    path: "/super-admin/list-view-schedule-meeting",
    component: lazy(() => import("../components/meeting-view/details")),
  },
  {
    path: "/super-admin/list-view-schedule-meeting-download",
    component: lazy(() => import("../components/meeting-view/mettingpdf.js")),
  },
  {
    path: "/super-admin/list-interview-health-carer-old",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-interview/health-carer/list")
    ),
  },
  {
    path: "/super-admin/list-interview-health-carer",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-interview/health-carer/list-tab")
    ),
  },

  {
    path: "/super-admin/list-view-schedule-interview",
    component: lazy(() => import("../components/interview-view/details")),
  },

  {
    path: "/super-admin/time-sheet",
    // component: lazy(() => import("../components/timesheet/Timesheet")),
    component: lazy(() => import("../components/timesheet/CommonTimeSheet")),
  },
  {
    path: "/super-admin/invoice",
    //component: lazy(() => import("../components/invoice/Invoice")),
    component: lazy(() => import("../components/invoice/CommonInvoice")),
  },
  {
    path: "/super-admin/invoice-pdf",
    component: lazy(() => import("../components/invoice/InvoicePdf")),
  },

  {
    path: "/super-admin/timesheet-view",
    component: lazy(() => import("../components/timesheet/TimesheetView")),
  },
  {
    path: "/super-admin/time-sheet-payslip",
    component: lazy(() => import("../components/timesheet/PayslipPdf")),
  },
  {
    path: "/super-admin/time-sheet-payslip-individual",
    component: lazy(() =>
      import("../components/timesheet/PayslipPdfForIndividual")
    ),
  },
  {
    path: "/super-admin/list-report",
    component: lazy(() => import("../pages/superAdmin/Reports/list")),
  },

  {
    path: "/super-admin/add-report",
    component: lazy(() => import("../pages/superAdmin/Reports/edit")),
  },
  {
    path: "/super-admin/edit-report",
    component: lazy(() => import("../pages/superAdmin/Reports/edit")),
  },
  {
    path: "/super-admin/reports",
    component: lazy(() => import("../pages/superAdmin/Reports/report")),
  },
  {
    path: "/super-admin/list-employee-birthdays",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeBirthday")
    ),
  },
  {
    path: "/super-admin/report-list/shift-swapping",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/timeAndAttendance/shiftswapping")
    ),
  },

  {
    path: "/super-admin/list-employee-details",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeDetails")
    ),
  },
  {
    path: "/super-admin/list-employee-emergency-contacts",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/emergencyContact")
    ),
  },

  {
    path: "/super-admin/list-unpaid-employee",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/unpaidEmployee")
    ),
  },
  {
    path: "/super-admin/list-jobmaker-employee-notice",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/atoReporting/jobmakerEmployeeNotice")
    ),
  },

  {
    path: "/super-admin/list-jobkeeper-employee-nominations",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Reports/atoReporting/jobkeeperEmployeeNomination"
      )
    ),
  },
  {
    path: "/super-admin/list-tax-file-declaration",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/atoReporting/taxFileDeclaration")
    ),
  },
  {
    path: "/super-admin/list-attendance",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/timeAndAttendance/attendanceReport")
    ),
  },
  {
    path: "/super-admin/list-employee-satisfaction",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeSatisfaction")
    ),
  },
  {
    path: "/super-admin/list-single-touch-payroll",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/atoReporting/singleTouchPayroll")
    ),
  },
  {
    path: "/super-admin/list-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/list")),
  },

  {
    path: "/super-admin/add-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/edit")),
  },
  {
    path: "/super-admin/edit-holiday",
    component: lazy(() => import("../pages/superAdmin/public-holiday/edit")),
  },

  {
    path: "/super-admin/checksendmailpdf",
    component: lazy(() =>
      import(
        "../pages/superAdmin/contentManagement/offer-letter/sendAttachEmail"
      )
    ),
  },
  {
    path: "/super-admin/list-progress-objective-masters",
    component: lazy(() => import("../pages/superAdmin/progressObjective/list")),
  },
  {
    path: "/super-admin/add-progress-objective-masters",
    component: lazy(() => import("../pages/superAdmin/progressObjective/edit")),
  },
  {
    path: "/super-admin/edit-progress-objective-masters",
    component: lazy(() => import("../pages/superAdmin/progressObjective/edit")),
  },

  {
    path: "/super-admin/employee-leave-history",
    component: lazy(() => import("../pages/superAdmin/Reports/leave-history")),
  },
  {
    path: "/super-admin/bulk-upload",
    component: lazy(() => import("../pages/superAdmin/ndis/BulkUpload")),
  },
  {
    path: "/super-admin/xero-export",
    component: lazy(() => import("../components/xero-export/XeroExport")),
  },
  {
    path: "/super-admin/myob-export",
    component: lazy(() => import("../components/myob-export/MyobExport")),
  },
  {
    path: "/super-admin/list-employee-details-audit",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/employeeDetailsAudit")
    ),
  },

  {
    path: "/super-admin/bulk-upload-client-onboard",
    component: lazy(() => import("../components/Bulk-Upload/Client/Import")),
  },

  {
    path: "/super-admin/bulk-upload-serviceprovider-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/ServiceProvider/Import")
    ),
  },

  {
    path: "/super-admin/bulk-upload-supportcoordinator-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/SupportCoordinator/Import")
    ),
  },

  {
    path: "/super-admin/react-sample-template",
    component: lazy(() =>
      import("../pages/superAdmin/contentManagement/offer-letter/reactEditor")
    ),
  },
  {
    path: "/super-admin/react-sample-template-value",
    component: lazy(() =>
      import(
        "../pages/superAdmin/contentManagement/offer-letter/reactEditorValue"
      )
    ),
  },
  {
    path: "/super-admin/view-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/view")),
  },
  {
    path: "/super-admin/list-employee-jokeeper-eligibility",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/employee/jobKeeperEligibility")
    ),
  },

  {
    path: "/super-admin/list-medicine-frequency",
    component: lazy(() => import("../pages/superAdmin/medicineFrequency/list")),
  },
  {
    path: "/super-admin/edit-medicine-frequency",
    component: lazy(() => import("../pages/superAdmin/medicineFrequency/edit")),
  },
  {
    path: "/super-admin/add-medicine-frequency",
    component: lazy(() => import("../pages/superAdmin/medicineFrequency/edit")),
  },

  {
    path: "/super-admin/select-client",
    component: lazy(() => import("../components/goals/ClientSelect")),
  },

  {
    path: "/super-admin/add-goal",
    component: lazy(() => import("../components/goals/edit")),
  },

  {
    path: "/super-admin/edit-goal",
    component: lazy(() => import("../components/goals/edit")),
  },

  {
    path: "/super-admin/list-goal-as-per-client",
    component: lazy(() => import("../components/goals/list")),
  },

  {
    path: "/super-admin/add-goal-routine",
    component: lazy(() => import("../components/goals/addRoutineTask/edit")),
  },

  {
    path: "/super-admin/view-task",
    component: lazy(() => import("../components/goals/view")),
  },
  {
    path: "/super-admin/export-pdf",
    component: lazy(() => import("../components/goals/pdf")),
  },
  {
    path: "/super-admin/export-pdf-with-image",
    component: lazy(() => import("../components/goals/pdfWithImage")),
  },

  {
    path: "/super-admin/edit-goal-routine",
    component: lazy(() => import("../components/goals/addRoutineTask/edit")),
  },

  {
    path: "/super-admin/access-role-permission",
    component: lazy(() =>
      import("../pages/superAdmin/access-role-permission/list")
    ),
  },

  {
    path: "/super-admin/bulk-upload-staff",
    component: lazy(() => import("../components/Bulk-Upload/Staff/Import")),
  },

  {
    path: "/super-admin/bulk-upload-service-provider-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/ServiceProvider/Import")
    ),
  },
  {
    path: "/super-admin/set-access-role",
    component: lazy(() => import("../pages/superAdmin/access-role/list")),
  },

  {
    path: "/super-admin/add-access-role",
    component: lazy(() => import("../pages/superAdmin/access-role/add.js")),
  },

  {
    path: "/super-admin/client-view",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/client-view")
    ),
  },

  {
    path: "/super-admin/view-client-details",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/view-details-client")
    ),
  },

  {
    path: "/super-admin/list-health-issue",
    component: lazy(() => import("../pages/superAdmin/healthIssue/list")),
  },
  {
    path: "/super-admin/edit-health-issue",
    component: lazy(() => import("../pages/superAdmin/healthIssue/edit")),
  },
  {
    path: "/super-admin/add-health-issue",
    component: lazy(() => import("../pages/superAdmin/healthIssue/edit")),
  },
  {
    path: "/super-admin/list-information",
    component: lazy(() => import("../pages/superAdmin/information/list")),
  },
  {
    path: "/super-admin/edit-information",
    component: lazy(() => import("../pages/superAdmin/information/edit")),
  },
  {
    path: "/super-admin/add-information",
    component: lazy(() => import("../pages/superAdmin/information/edit")),
  },

  {
    path: "/super-admin/bulk-upload-health-carer-onboard",
    component: lazy(() =>
      import("../components/Bulk-Upload/HealthCarer/Import")
    ),
  },

  {
    path: "/super-admin/chart-goal",
    component: lazy(() => import("../components/goals/Chart")),
  },
  {
    path: "/super-admin/bar-chart-goal",
    component: lazy(() => import("../components/goals/barChart")),
  },

  {
    path: "/super-admin/list-dose-form",
    component: lazy(() => import("../pages/superAdmin/doseForm/list")),
  },
  {
    path: "/super-admin/edit-dose-form",
    component: lazy(() => import("../pages/superAdmin/doseForm/edit")),
  },
  {
    path: "/super-admin/add-dose-form",
    component: lazy(() => import("../pages/superAdmin/doseForm/edit")),
  },

  {
    path: "/super-admin/list-observationName",
    component: lazy(() => import("../pages/superAdmin/observationName/list")),
  },
  {
    path: "/super-admin/edit-observationName",
    component: lazy(() => import("../pages/superAdmin/observationName/edit")),
  },
  {
    path: "/super-admin/add-observationName",
    component: lazy(() => import("../pages/superAdmin/observationName/edit")),
  },

  {
    path: "/super-admin/list-taxations",
    component: lazy(() => import("../pages/superAdmin/taxation/list")),
  },
  {
    path: "/super-admin/edit-taxations",
    component: lazy(() => import("../pages/superAdmin/taxation/edit")),
  },
  {
    path: "/super-admin/add-taxations",
    component: lazy(() => import("../pages/superAdmin/taxation/edit")),
  },

  {
    path: "/super-admin/support-coordinator-view",
    component: lazy(() =>
      import("../pages/superAdmin/support-coordinator/view")
    ),
  },

  {
    path: "/super-admin/view-blood-observation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/Viewtab/Observation/Blood/view")
    ),
  },

  {
    path: "/super-admin/view-bowel-observation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/Viewtab/Observation/Bowel/view")
    ),
  },

  {
    path: "/super-admin/view-food-observation",
    component: lazy(() =>
      import("../pages/superAdmin/client-list/Viewtab/Observation/Food/view")
    ),
  },

  {
    path: "/super-admin/service-provider-view",
    component: lazy(() => import("../pages/superAdmin/service-provider/view")),
  },

  {
    path: "/super-admin/view-service-provider-details",
    component: lazy(() =>
      import("../pages/superAdmin/service-provider/view-details-sp")
    ),
  },

  {
    path: "/super-admin/view-mestruation-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/client-list/Viewtab/Observation/Mestruation/view"
      )
    ),
  },
  {
    path: "/super-admin/view-personalcare-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/client-list/Viewtab/Observation/PersonalCare/view"
      )
    ),
  },
  {
    path: "/super-admin/view-seizure-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Observation/Seizure/view"
      )
    ),
  },
  {
    path: "/super-admin/view-weight-observation",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Medication-management-dev/Observation/weight/view"
      )
    ),
  },

  {
    path: "/super-admin/list-funds-management",
    component: lazy(() =>
      import("../pages/superAdmin/FundManagement/Fund-Management")
    ),
  },
  {
    path: "/super-admin/view-funds-management",
    component: lazy(() =>
      import("../pages/superAdmin/FundManagement/View-Fund-Management")
    ),
  },
  {
    path: "/super-admin/print-funds-management",
    component: lazy(() => import("../pages/superAdmin/FundManagement/print")),
  },

  {
    path: "/super-admin/list-payschedule",
    component: lazy(() => import("../pages/superAdmin/paySchedule/list")),
  },

  {
    path: "/super-admin/add-payschedule",
    component: lazy(() => import("../pages/superAdmin/paySchedule/edit")),
  },

  {
    path: "/super-admin/edit-payschedule",
    component: lazy(() => import("../pages/superAdmin/paySchedule/edit")),
  },
  {
    path: "/super-admin/edit-payrunstatus",
    component: lazy(() => import("../pages/superAdmin/payrunStatus/edit")),
  },
  {
    path: "/super-admin/add-payrunstatus",
    component: lazy(() => import("../pages/superAdmin/payrunStatus/edit")),
  },
  {
    path: "/super-admin/list-payrunstatus",
    component: lazy(() => import("../pages/superAdmin/payrunStatus/list")),
  },
  {
    path: "/super-admin/subscription-plans-list",
    component: lazy(() => import("../pages/superAdmin/subscription-plan/list")),
  },

  {
    path: "/super-admin/email",
    component: lazy(() => import("../components/email/list")),
  },

  {
    path: "/super-admin/email/chatopenai",
    component: lazy(() => import("../components/email/chatAi")),
  },

  {
    path: "/super-admin/view-email",
    component: lazy(() => import("../components/email/view")),
  },
  {
    path: "/super-admin/edit-email",
    component: lazy(() => import("../components/email/newEmail/edit")),
  },
  {
    path: "/super-admin/list-signed-document",
    component: lazy(() => import("../pages/superAdmin/signedDocuments/list")),
  },
  {
    path: "/super-admin/view-signed-documents",
    component: lazy(() => import("../pages/superAdmin/signedDocuments/view")),
  },

  {
    path: "/super-admin/list-super-annuation",
    component: lazy(() => import("../pages/superAdmin/superFunds/list")),
  },

  {
    path: "/super-admin/add-super-annuation",
    component: lazy(() => import("../pages/superAdmin/superFunds/edit")),
  },

  {
    path: "/super-admin/edit-super-annuation",
    component: lazy(() => import("../pages/superAdmin/superFunds/edit")),
  },

  {
    path: "/super-admin/list-region",
    component: lazy(() => import("../pages/superAdmin/Region/list")),
  },

  {
    path: "/super-admin/list-document",
    component: lazy(() =>
      import("../pages/superAdmin/Request-Document/Documentview")
    ),
  },

  {
    path: "/super-admin/list-policy",
    component: lazy(() => import("../pages/superAdmin/policy/list")),
  },
  {
    path: "/super-admin/edit-policy",
    component: lazy(() => import("../pages/superAdmin/policy/edit")),
  },
  {
    path: "/super-admin/add-policy",
    component: lazy(() => import("../pages/superAdmin/policy/edit")),
  },
  {
    path: "/super-admin/request-connection-list",
    component: lazy(() =>
      import("../pages/superAdmin/Request-Document/Connection-request")
    ),
  },
  {
    path: "/super-admin/view-request",
    component: lazy(() =>
      import("../pages/superAdmin/Request-Document/View-request")
    ),
  },

  {
    path: "/super-admin/list-availability-status",
    component: lazy(() => import("../pages/superAdmin/availabilitySatus/list")),
  },
  {
    path: "/super-admin/edit-availability-status",
    component: lazy(() => import("../pages/superAdmin/availabilitySatus/edit")),
  },
  {
    path: "/super-admin/add-availability-status",
    component: lazy(() => import("../pages/superAdmin/availabilitySatus/edit")),
  },

  {
    path: "/super-admin/support-duration-add",
    component: lazy(() => import("../pages/superAdmin/supportDuration/edit")),
  },
  {
    path: "/super-admin/support-duration-edit",
    component: lazy(() => import("../pages/superAdmin/supportDuration/edit")),
  },
  {
    path: "/super-admin/support-duration-list",
    component: lazy(() => import("../pages/superAdmin/supportDuration/list")),
  },

  {
    path: "/super-admin/support-hours-add",
    component: lazy(() => import("../pages/superAdmin/support-Hours/edit")),
  },
  {
    path: "/super-admin/support-hours-edit",
    component: lazy(() => import("../pages/superAdmin/support-Hours/edit")),
  },
  {
    path: "/super-admin/support-hours-list",
    component: lazy(() => import("../pages/superAdmin/support-Hours/list")),
  },

  {
    path: "/super-admin/list-service-item",
    component: lazy(() => import("../pages/superAdmin/service-item/list")),
  },
  {
    path: "/super-admin/edit-service-item",
    component: lazy(() => import("../pages/superAdmin/service-item/edit")),
  },
  {
    path: "/super-admin/add-service-item",
    component: lazy(() => import("../pages/superAdmin/service-item/edit")),
  },

  {
    path: "/super-admin/list-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/list")),
  },
  {
    path: "/super-admin/edit-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/edit")),
  },
  {
    path: "/super-admin/add-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/edit")),
  },
  {
    path: "/super-admin/bsp",
    component: lazy(() => import("../pages/superAdmin/Bsp/Bsp")),
  },

  {
    path: "/super-admin/add-complaint",
    component: lazy(() => import("../pages/superAdmin/complaint/edit")),
  },
  {
    path: "/super-admin/edit-complaint",
    component: lazy(() => import("../pages/superAdmin/complaint/edit")),
  },
  {
    path: "/super-admin/list-complaint",
    component: lazy(() => import("../pages/superAdmin/complaint/list")),
  },
  {
    path: "/super-admin/list-year-to-date",
    component: lazy(() => import("../pages/superAdmin/YeatTODate/list")),
  },
  {
    path: "/super-admin/list-bsp-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
    ),
  },
  {
    path: "/super-admin/add-bsp-chart",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/addBsp")
    ),
  },
  {
    path: "/super-admin/list-bsp-question",
    component: lazy(() => import("../pages/superAdmin/Bsp/list")),
  },
  {
    path: "/super-admin/add-bsp-question",
    component: lazy(() => import("../pages/superAdmin/Bsp/edit")),
  },
  {
    path: "/super-admin/edit-bsp-question",
    component: lazy(() => import("../pages/superAdmin/Bsp/edit")),
  },

  {
    path: "/super-admin/list-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/list")),
  },

  {
    path: "/super-admin/view-progressnote",
    component: lazy(() => import("../pages/superAdmin/progressNote/view")),
  },

  // leave management new changes

  {
    path: "/super-admin/list-leave-categories",
    component: lazy(() => import("../pages/superAdmin/leave-category/list")),
  },

  {
    path: "/super-admin/add-leave-categories",
    component: lazy(() => import("../pages/superAdmin/leave-category/edit")),
  },

  {
    path: "/super-admin/edit-leave-categories",
    component: lazy(() => import("../pages/superAdmin/leave-category/edit")),
  },

  {
    path: "/super-admin/list-leave-management-old",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/list")),
  },

  {
    path: "/super-admin/list-leave-management",
    component: lazy(() =>
      import("../pages/superAdmin/leaveManagement/list-tab")
    ),
  },

  {
    path: "/super-admin/add-leave",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/edit")),
  },

  {
    path: "/super-admin/edit-leave",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/edit")),
  },

  {
    path: "/super-admin/view-leave",
    component: lazy(() => import("../pages/superAdmin/leaveManagement/view")),
  },

  {
    path: "/super-admin/list-set-leave",
    component: lazy(() => import("../pages/superAdmin/setLeave/list")),
  },

  {
    path: "/super-admin/add-set-leave",
    component: lazy(() => import("../pages/superAdmin/setLeave/edit")),
  },

  {
    path: "/super-admin/edit-set-leave",
    component: lazy(() => import("../pages/superAdmin/setLeave/edit")),
  },
  {
    path: "/super-admin/chat-history",
    component: lazy(() => import("../pages/superAdmin/chathistory/list")),
  },

  {
    path: "/super-admin/add-homefeature",
    component: lazy(() => import("../pages/superAdmin/HomeFeature/edit")),
  },
  {
    path: "/super-admin/list-homefeature",
    component: lazy(() => import("../pages/superAdmin/HomeFeature/list")),
  },
  {
    path: "/super-admin/edit-homefeature",
    component: lazy(() => import("../pages/superAdmin/HomeFeature/edit")),
  },

  {
    path: "/super-admin/sda-location",
    component: lazy(() => import("../pages/superAdmin/SDALocation/list")),
  },
  {
    path: "/super-admin/add-loction",
    component: lazy(() => import("../pages/superAdmin/SDALocation/edit")),
  },
  {
    path: "/super-admin/edit-loction",
    component: lazy(() => import("../pages/superAdmin/SDALocation/edit")),
  },
  {
    path: "/super-admin/sil-sda-location-add",
    component: lazy(() => import("../pages/superAdmin/sil-sda-location/edit")),
  },
  {
    path: "/super-admin/sil-sda-location-edit",
    component: lazy(() => import("../pages/superAdmin/sil-sda-location/edit")),
  },
  {
    path: "/super-admin/sil-sda-location-list",
    component: lazy(() => import("../pages/superAdmin/sil-sda-location/list")),
  },
  {
    path: "/super-admin/list-asset",
    component: lazy(() => import("../pages/superAdmin/assetManagement/list")),
  },
  {
    path: "/super-admin/add-asset",
    component: lazy(() => import("../pages/superAdmin/assetManagement/edit")),
  },
  {
    path: "/super-admin/edit-asset",
    component: lazy(() => import("../pages/superAdmin/assetManagement/edit")),
  },
  {
    path: "/super-admin/edit-compliance",
    component: lazy(() => import("../pages/superAdmin/compliance/edit")),
  },
  {
    path: "/super-admin/add-compliance",
    component: lazy(() => import("../pages/superAdmin/compliance/edit")),
  },
  {
    path: "/super-admin/list-compliance",
    component: lazy(() => import("../pages/superAdmin/compliance/list")),
  },

  {
    path: "/super-admin/add-lms-student",
    component: lazy(() => import("../pages/superAdmin/lms-student/edit")),
  },
  {
    path: "/super-admin/edit-lms-student",
    component: lazy(() => import("../pages/superAdmin/lms-student/edit")),
  },
  {
    path: "/super-admin/manage-lms-student",
    component: lazy(() =>
      import("../pages/superAdmin/lms-student/openMoodle.js")
    ),
  },
  {
    path: "/super-admin/list-client-rating",
    component: lazy(() => import("../pages/superAdmin/Ratings/client")),
  },
  {
    path: "/super-admin/list-health-carer-rating",
    component: lazy(() => import("../pages/superAdmin/Ratings/healthCarer")),
  },

  {
    path: "/super-admin/manage-training",
    component: lazy(() => import("../pages/superAdmin/LMS/training/list")),
  },

  {
    path: "/super-admin/list-template",
    component: lazy(() =>
      import("../components/email/templateMaster/list-cards")
    ),
  },
  {
    path: "/super-admin/edit-template",
    component: lazy(() => import("../components/email/templateMaster/edit")),
  },
  {
    path: "/super-admin/add-template",
    component: lazy(() => import("../components/email/templateMaster/edit")),
  },
  {
    path: "/super-admin/list-leads",
    component: lazy(() => import("../pages/superAdmin/demoRequest/list")),
  },
  {
    path: "/super-admin/demo-meeting",
    component: lazy(() => import("../pages/superAdmin/demoRequest/meeting")),
  },
  {
    path: "/super-admin/view-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/demoRequest/viewMeeting")
    ),
  },
  {
    path: "/super-admin/edit-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/demoRequest/editMeeting.js")
    ),
  },
  {
    path: "/super-admin/list-blogs",
    component: lazy(() => import("../pages/superAdmin/blog/list")),
  },
  {
    path: "/super-admin/list-user-pay-run",
    component: lazy(() =>
      import("../pages/superAdmin/PayRun/list-user-pay-run")
    ),
  },
  {
    path: "/super-admin/settings",
    component: lazy(() => import("../pages/serviceProvider/Settings/setting")),
  },
  {
    path: "/super-admin/financial-setting-list",
    component: lazy(() =>
      import(
        "../pages/serviceProvider/Settings/financialSettings/financialSettings"
      )
    ),
  },
  {
    path: "/super-admin/list-superannuation-setting",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/superAnnuation/list")
    ),
  },
  {
    path: "/super-admin/ato-settings",
    component: lazy(() =>
      import("../pages/serviceProvider/Settings/atoSetting")
    ),
  },
  {
    path: "/super-admin/payments",
    component: lazy(() => import("../pages/serviceProvider/Settings/payment")),
  },
  {
    path: "/super-admin/settings-xero-myob-list",
    component: lazy(() => import("../pages/superAdmin/xero-myob/list")),
  },
  {
    path: "/super-admin/add-blogs",
    component: lazy(() => import("../pages/superAdmin/blog/edit")),
  },
  {
    path: "/super-admin/edit-blogs",
    component: lazy(() => import("../pages/superAdmin/blog/edit")),
  },
  {
    path: "/super-admin/list-guides",
    component: lazy(() => import("../pages/superAdmin/guide/list")),
  },
  {
    path: "/super-admin/add-guides",
    component: lazy(() => import("../pages/superAdmin/guide/edit")),
  },
  {
    path: "/super-admin/edit-guides",
    component: lazy(() => import("../pages/superAdmin/guide/edit")),
  },
  {
    path: "/super-admin/add-blog-and-guide-category",
    component: lazy(() =>
      import("../pages/superAdmin/blogAndGuideCategory/edit")
    ),
  },
  {
    path: "/super-admin/edit-blog-and-guide-category",
    component: lazy(() =>
      import("../pages/superAdmin/blogAndGuideCategory/edit")
    ),
  },
  {
    path: "/super-admin/list-blog-and-guide-category",
    component: lazy(() =>
      import("../pages/superAdmin/blogAndGuideCategory/list")
    ),
  },
  {
    path: "/super-admin/add-template-type",
    component: lazy(() => import("../pages/superAdmin/templateType/edit")),
  },
  {
    path: "/super-admin/edit-template-type",
    component: lazy(() => import("../pages/superAdmin/templateType/edit")),
  },
  {
    path: "/super-admin/list-template-type",
    component: lazy(() => import("../pages/superAdmin/templateType/list")),
  },
  {
    path: "/super-admin/list-demo-time",
    component: lazy(() => import("../pages/superAdmin/demoTime/list")),
  },
  {
    path: "/super-admin/edit-demo-time",
    component: lazy(() => import("../pages/superAdmin/demoTime/edit")),
  },
  {
    path: "/super-admin/add-demo-time",
    component: lazy(() => import("../pages/superAdmin/demoTime/edit")),
  },

  {
    path: "/super-admin/list-live-roaster-appointment-request",
    component: lazy(() =>
      import("../pages/superAdmin/requestLiveRoasterAppointment/list.js")
    ),
  },

  {
    path: "/super-admin/appointment-connection-view",
    component: lazy(() =>
      import("../components/Explore-Market/Appointment-request-view")
    ),
  },
  {
    path: "/super-admin/user-email/edit-email",
    component: lazy(() =>
      import("../components/secondaryEmail/newEmaill/edit")
    ),
  },
  {
    path: "/super-admin/list-shift-notes",
    component: lazy(() => import("../pages/superAdmin/shiftNotes/list")),
  },
  {
    path: "/super-admin/list-service-task-notes",
    component: lazy(() => import("../pages/superAdmin/serviceTaskNotes/list")),
  },
  {
    path: "/super-admin/add-left-menu",
    component: lazy(() => import("../pages/superAdmin/leftMenu/edit.js")),
  },
  {
    path: "/super-admin/edit-left-menu",
    component: lazy(() => import("../pages/superAdmin/leftMenu/edit.js")),
  },
  {
    path: "/super-admin/list-left-menu",
    component: lazy(() => import("../pages/superAdmin/leftMenu/list.js")),
  },
  {
    path: "/super-admin/list-website-contact",
    component: lazy(() =>
      import("../pages/superAdmin/website-management/list")
    ),
  },
  {
    path: "/super-admin/add-website-contact",
    component: lazy(() =>
      import("../pages/superAdmin/website-management/edit")
    ),
  },
  {
    path: "/super-admin/edit-website-contact",
    component: lazy(() =>
      import("../pages/superAdmin/website-management/edit")
    ),
  },
  {
    path: "/super-admin/add-sub-left-menu",
    component: lazy(() => import("../pages/superAdmin/sub-Left-Menu/edit.js")),
  },
  {
    path: "/super-admin/edit-sub-left-menu",
    component: lazy(() => import("../pages/superAdmin/sub-Left-Menu/edit.js")),
  },
  {
    path: "/super-admin/list-sub-left-menu",
    component: lazy(() => import("../pages/superAdmin/sub-Left-Menu/list.js")),
  },
  {
    path: "/super-admin/add-experiences",
    component: lazy(() => import("../pages/superAdmin/Experiences/edit.js")),
  },
  {
    path: "/super-admin/edit-experiences",
    component: lazy(() => import("../pages/superAdmin/Experiences/edit.js")),
  },
  {
    path: "/super-admin/list-experiences",
    component: lazy(() => import("../pages/superAdmin/Experiences/list.js")),
  },
  {
    path: "/super-admin/all-timesheet-pdf",
    component: lazy(() => import("../components/timesheet/timesheetPdf.js")),
  },
  {
    path: "/super-admin/shift-notes-list",
    component: lazy(() =>
      import("../pages/superAdmin/shift-notes/shiftNoteclientlist.js")
    ),
  },
  {
    path: "/super-admin/view-shift-notes",
    component: lazy(() => import("../pages/superAdmin/shiftNotes/view.js")),
  },
  {
    path: "/super-admin/pdf-shift-task-notes",
    component: lazy(() => import("../pages/superAdmin/shiftNotes/pdf")),
  },
  {
    path: "/super-admin/add-avtar-icons",
    component: lazy(() => import("../pages/superAdmin/avtaricon.js")),
  },

  {
    path: "/super-admin/profit",
    component: lazy(() => import("../pages/superAdmin/Profit/Profit.js")),
  },

  {
    path: "/super-admin/edit-module",
    component: lazy(() =>
      import("../pages/superAdmin/Library/modules/edit.js")
    ),
  },

  {
    path: "/super-admin/add-module",
    component: lazy(() =>
      import("../pages/superAdmin/Library/modules/edit.js")
    ),
  },

  {
    path: "/super-admin/list-module",
    component: lazy(() =>
      import("../pages/superAdmin/Library/modules/list.js")
    ),
  },

  {
    path: "/super-admin/edit-link",
    component: lazy(() => import("../pages/superAdmin/Library/link/edit.js")),
  },

  {
    path: "/super-admin/add-link",
    component: lazy(() => import("../pages/superAdmin/Library/link/edit.js")),
  },

  {
    path: "/super-admin/list-link",
    component: lazy(() => import("../pages/superAdmin/Library/link/list.js")),
  },
  {
    path: "/super-admin/add-expence-type",
    component: lazy(() => import("../pages/superAdmin/Expence-Type/edit.js")),
  },
  {
    path: "/super-admin/edit-expence-type",
    component: lazy(() => import("../pages/superAdmin/Expence-Type/edit.js")),
  },
  {
    path: "/super-admin/list-expence-type",
    component: lazy(() => import("../pages/superAdmin/Expence-Type/list.js")),
  },

  // {
  //   path: "/super-admin/list-calendar",
  //   component: lazy(() => import("../pages/superAdmin/Dashboard/SA-Dashboard")),
  // },

  {
    path: "/super-admin/list-calendar",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-common-meeting/client/list-tab")
    ),
  },

  {
    path: "/super-admin/list-calendar-static",
    component: lazy(() =>
      import(
        "../pages/superAdmin/schedule-common-meeting/client/list-tab-static"
      )
    ),
  },

  {
    path: "/super-admin/list-view-calendar",
    component: lazy(() => import("../components/common-meeting-view/details")),
  },
  {
    path: "/super-admin/list-view-calendar-download",
    component: lazy(() =>
      import("../components/common-meeting-view/mettingpdf.js")
    ),
  },
  {
    path: "/super-admin/view-service-task",
    component: lazy(() => import("../pages/superAdmin/service-task/view.js")),
  },

  {
    path: "/super-admin/add-sil-sda-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/sil-sda/silsda-add-appointment.js")
    ),
  },

  {
    path: "/super-admin/new-sil-sda-appointment",
    component: lazy(() =>
      import("../pages/superAdmin/sil-sda/new-sil-sda-appointment")
    ),
  },

  {
    path: "/super-admin/list-meeting-other",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-other-meeting/client/list-tab")
    ),
  },
  {
    path: "/super-admin/list-view-other-schedule-meeting",
    component: lazy(() => import("../components/meeting-view-other/details")),
  },
  {
    path: "/super-admin/view-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/view.js")),
  },
  {
    path: "/super-admin/schedule-other-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-other-meeting/meeting")
    ),
  },
  {
    path: "/super-admin/edit-other-meeting",
    component: lazy(() =>
      import("../pages/superAdmin/schedule-other-meeting/meeting")
    ),
  },
  {
    path: "/super-admin/list-help-center-app",
    component: lazy(() => import("../pages/superAdmin/Help-center-app/list")),
  },
  {
    path: "/super-admin/edit-help-center-app",
    component: lazy(() => import("../pages/superAdmin/Help-center-app/edit")),
  },
  {
    path: "/super-admin/add-help-center-app",
    component: lazy(() => import("../pages/superAdmin/Help-center-app/edit")),
  },

  {
    path: "/admin/health-carer/onboard",
    component: lazy(() => import("../pages/healthCarer/Account-Setup")),
  },
  {
    path: "/admin/pdf-service-task-notes",
    component: lazy(() =>
      import("../pages/superAdmin/serviceTaskNotes/serviceTaskpdf.js")
    ),
  },
  {
    path: "/admin/medication-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medication-chart")
    ),
  },
  {
    path: "/admin/observation-chart-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Observation-chart")
    ),
  },
  {
    path: "/admin/medical-history-dev",
    component: lazy(() =>
      import("../pages/superAdmin/Medication-management-dev/Medical-History")
    ),
  },

  {
    path: "/admin/list-user-pay-run",
    component: lazy(() =>
      import("../pages/superAdmin/PayRun/list-user-pay-run")
    ),
  },
  {
    path: "/admin/list-view-other-schedule-meeting",
    component: lazy(() => import("../components/meeting-view-other/details")),
  },
  {
    path: "/admin/report-list/shift-swapping",
    component: lazy(() =>
      import("../pages/superAdmin/Reports/timeAndAttendance/shiftswapping")
    ),
  },
  {
    path: "/admin/client/onboard",
    component: lazy(() => import("../pages/client/Account-Setup")),
  },
  {
    path: "/admin/support-coordinator/onboard",
    component: lazy(() => import("../pages/supportCoordinator/Account-Setup")),
  },

  {
    path: "/admin/service-provider/onboard",
    component: lazy(() => import("../pages/serviceProvider/Account-Setup")),
  },

  {
    path: "/super-admin/edit-attendance",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Reports/timeAndAttendance/TimeCardTracking.js"
      )
    ),
  },
  {
    path: "/admin/set-access-role",
    component: lazy(() => import("../pages/superAdmin/access-role/list")),
  },
  {
    path: "/super-admin/pay-run-attendance",
    component: lazy(() =>
      import(
        "../pages/superAdmin/Reports/timeAndAttendance/payRunAttandance.js"
      )
    ),
  },

  {
    path: "/super-admin/add-family-member",
    component: lazy(() =>
      import("../pages/superAdmin/family-member-client/edit")
    ),
  },

  {
    path: "/super-admin/edit-family-member",
    component: lazy(() =>
      import("../pages/superAdmin/family-member-client/edit")
    ),
  },

  {
    path: "/super-admin/add-emergency-contact",
    component: lazy(() =>
      import("../pages/healthCarer/Emergency-Contact/edit")
    ),
  },
  {
    path: "/super-admin/edit-emergency-contact",
    component: lazy(() =>
      import("../pages/healthCarer/Emergency-Contact/edit")
    ),
  },

  {
    path: "/super-admin/profit-and-loss",
    component: lazy(() => import("../pages/superAdmin/Profit/Profit.js")),
  },

  {
    path: "/super-admin/view-secondary-users",
    component: lazy(() => import("../pages/superAdmin/users/view.js")),
  },
];

export { SuperAdminRoutes };
