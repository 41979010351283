import React, { useState } from "react";
import Box from "@mui/material/Box";
import "./timesheet.css";
import Appbar from "../../../Dashboard/Layout/Appbar";
import Sidebar from "../../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import upload from "../../../assets/images/upload.svg";
import camera from "../../../assets/images/camera.svg";
import Form from "@mui/material/FormGroup";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function AddExpenses() {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Timesheets</Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography>Add Expenses</Typography>
    </Link>,
  ];

  return (
    <Box className="main">
      <Box
        className="dashboard-form-typo dashboard-form-typo-up"
        sx={{ borderBottom: "none" }}
      >
        {/* Title/ Breadcrumb & Button section */}

        <Grid container className="d-flex header-topsection">
          <Grid item xs={6} sm={6} md={6}>
            <Box id="breadcrumb">
              <Link to="/timesheet">
                <ArrowBackIosIcon />
              </Link>

              <Box className="breadcrumb-inr">
                <h4 className="d-flex align-items-center ">Timesheets</h4>

                <Box className="breadcrumbs">
                  <Link className="breadcrumbs-link"> Timesheets </Link>{" "}
                  <span className="breadcrumbs-icon">{">"}</span>
                  <Link className="breadcrumbs-link breadcrumbs-link-active">
                    Add Timesheets
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Tab Start here  */}

      <Box className="d-flex justify-content-center add-expenses-p-10">
        <Box className="add-expenses">
          <Box className="add-expenses-head d-flex align-items-center justify-content-between">
            <Typography> Add Expenses</Typography>
            <Typography>
              Client: <span>Jake Knap</span>
            </Typography>
          </Box>
          <Box className="add-expenses-body">
            <Grid className="align-items-center w-100 add-expenses-input-box justify-content-between">
              <Grid container spacing={4} className="align-items-end">
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className="input flex-column d-flex pt-0"
                >
                  <label>Expense Title</label>

                  <TextField
                    id="outlined-basic"
                    className="w-100"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className="input flex-column d-flex pt-0"
                >
                  <label>Amount (in $)</label>

                  <TextField
                    id="outlined-basic"
                    className="w-100"
                    placeholder="$"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Box className="attach-document">
              <h3>Attach Document</h3>
              <Box className="attach-document-body d-flex align-items-center">
                <Box className="d-flex align-items-center ">
                  <input
                    type="radio"
                    name="attach-document"
                    id="upload"
                  ></input>
                  <img src={upload}></img>
                  <label for="upload">Upload</label>
                </Box>
                <Box className="d-flex align-items-center" sx={{ pl: 2 }}>
                  <input
                    type="radio"
                    name="attach-document"
                    id="capture"
                  ></input>
                  <img src={camera}></img>
                  <label for="capture">Capture</label>
                </Box>
              </Box>
              <Box>
                <Form className="d-flex align-items-center flex-column-res  attach-document-browse w-100 justify-content-between">
                  <TextField
                    id="outlined-basic"
                    size="small"
                    className="attach-file-input"
                    type="file"
                  ></TextField>
                  <Button>Upload</Button>
                </Form>
              </Box>
            </Box>

            <Box className="agency-btn-section add-expenses-btn">
              <Link href="#cancel">Cancel</Link>
              <Link href="#save" to="/timesheet">
                Save
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
