import React, { useState } from "react";
import Box from "@mui/material/Box";

// import css start
import "../../pages/superAdmin/Timesheet/timesheet.css";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";
import "./appointment.css";
// css end

import Appbar from "../../Dashboard/Layout/Appbar";
import Sidebar from "../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextareaAutosize } from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function AddExpenses() {
  const [isShown, setIsShown] = useState(false);
  const [isShown2, setIsShown2] = useState(false);
  const [isShown3, setIsShown3] = useState(false);
  const [isShown4, setIsShown4] = useState(false);

  const showClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };
  const showClick2 = (event) => {
    setIsShown2((current) => !current);
  };
  const showClick3 = (event) => {
    setIsShown3((current) => !current);
  };
  const showClick4 = (event) => {
    setIsShown4((current) => !current);
  };

  // show hide checkbox fuctions

  const [check, setIsCheck] = useState(false);
  const [check2, setIsCheck2] = useState(false);
  const [check3, setIsCheck3] = useState(false);
  const [check4, setIsCheck4] = useState(false);
  const [check5, setIsCheck5] = useState(false);

  const showCheck = (event) => {
    // 👇️ toggle shown state
    setIsCheck((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };
  const showCheck2 = (event) => {
    setIsCheck2((current) => !current);
  };
  const showCheck3 = (event) => {
    setIsCheck3((current) => !current);
  };
  const showCheck4 = (event) => {
    setIsCheck4((current) => !current);
  };

  const showCheck5 = (event) => {
    setIsCheck4((current) => !current);
  };

  return (
    <Box className="dashboard-form client-task" id="appointment-details">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box className=" d-flex dashboard-main-box">
        <Sidebar />
        <Box className="main">
          <Box id="breadcrumb">
            <ArrowBackIosIcon />
            <Box className="breadcrumb-inr">
              <h4 className="d-flex align-items-center "> After Appointment</h4>
              <Box className="breadcrumbs">
                <Link className="breadcrumbs-link">Appointments</Link>
                <span className="breadcrumbs-icon">{">"}</span>
                <Link className="breadcrumbs-link">Appointment Details</Link>
                <span className="breadcrumbs-icon">{">"}</span>
                <Link className="breadcrumbs-link breadcrumbs-link-active">
                  After Appointment
                </Link>
              </Box>
            </Box>
          </Box>

          {/* <Box className='dashboard-form-typo dashboard-form-typo-sec d-flex align-items-center justify-content-between dashboard-form-typo-up'>
                        <h4 className='d-flex align-items-center '> <ArrowBackIosIcon /> After Appointment Details</h4>
                        <Typography><span>Appointment Date</span>: 12-02-2023,  11:00 - 12:00</Typography>

                    </Box> */}

          {/* Tab Start here  */}

          <Box className="d-flex justify-content-center add-expenses-p-10">
            <Box className="add-expenses">
              <Box className="add-expenses-head d-flex align-items-center justify-content-between">
                <Typography> End Appointment</Typography>
                <Typography>
                  Client: <span>Jake Knap</span>
                </Typography>
              </Box>
              <Box className="add-expenses-body">
                <Grid className="align-items-center w-100 add-expenses-input-box justify-content-between">
                  <Grid container spacing={4} className="align-items-start">
                    <Grid item xs={12} sm={6} md={6} className=" pt-0">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input flex-column d-flex pt-0"
                      >
                        <FormControlLabel
                          onClick={showCheck}
                          control={<Checkbox />}
                          label="Key Handover"
                        />
                        {check && (
                          <Box
                            onClick={showClick}
                            className="d-flex add-description align-items-center"
                          >
                            <AddCircleIcon />
                            <Typography>Add Description</Typography>
                          </Box>
                        )}
                        {isShown && (
                          <Grid
                            item
                            xs={12}
                            sm={10}
                            md={10}
                            className="input check-text-area-box text-area-box d-flex flex-column pt-0"
                          >
                            <TextareaAutosize
                              className="form-input check-text-area text-area"
                              placeholder="type here"
                            />
                            <span className="danger validation-text">
                              Description is required
                            </span>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input flex-column d-flex pt-0"
                      >
                        <FormControlLabel
                          onClick={showCheck2}
                          control={<Checkbox />}
                          label="Comms"
                        />
                        {check2 && (
                          <Box
                            onClick={showClick2}
                            className="d-flex add-description align-items-center"
                          >
                            <AddCircleIcon />
                            <Typography>Add Description</Typography>
                          </Box>
                        )}
                        {isShown2 && (
                          <Grid
                            item
                            xs={12}
                            sm={10}
                            md={10}
                            className="input check-text-area-box text-area-box d-flex flex-column pt-0"
                          >
                            <TextareaAutosize
                              className="form-input check-text-area text-area"
                              placeholder="type here"
                            />
                            <span className="danger validation-text">
                              Description is required
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} className=" pt-0">
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input flex-column d-flex pt-0"
                      >
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Medical Appointment"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="input flex-column d-flex pt-0"
                      >
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Any Visitor"
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="input flex-column d-flex pt-0"
                    >
                      <FormControlLabel
                        onClick={showCheck3}
                        control={<Checkbox />}
                        label="Medication Chart"
                      />
                      {check3 && (
                        <Box
                          onClick={showClick3}
                          className="d-flex add-description align-items-center"
                        >
                          <AddCircleIcon />
                          <Typography>Add Description</Typography>
                        </Box>
                      )}
                      {isShown3 && (
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          md={5}
                          className="input check-text-area-box text-area-box d-flex flex-column pt-0"
                        >
                          <TextareaAutosize
                            className="form-input check-text-area text-area"
                            placeholder="type here"
                          />
                          <span className="danger validation-text">
                            Description is required
                          </span>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} className="d-flex pt-0">
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        className="appoint-check pt-0"
                      >
                        <FormControlLabel
                          onClick={showCheck4}
                          control={<Checkbox />}
                          label="Require Grocery Order"
                        />
                        {check4 && (
                          <Box className="d-flex add-description align-items-center">
                            <Link
                              to="/appoint-expenses"
                              className="d-flex align-items-center"
                            >
                              <AddCircleIcon />
                              <Typography>Add Expenses</Typography>
                            </Link>
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        className="appoint-check pt-0"
                        sx={{ pl: 2 }}
                      >
                        <FormControlLabel
                          onClick={showCheck5}
                          control={<Checkbox />}
                          label="Any Incident"
                        />
                        {check5 && (
                          <Box
                            onClick={showClick4}
                            className="d-flex add-description align-items-center"
                          >
                            <AddCircleIcon />
                            <Typography>Add Incident</Typography>
                          </Box>
                        )}
                        {isShown4 && (
                          <Grid
                            item
                            xs={12}
                            sm={10}
                            md={10}
                            className="input check-text-area-box text-area-box d-flex flex-column pt-0"
                          >
                            <TextareaAutosize
                              className="form-input check-text-area text-area"
                              placeholder="type here"
                            />
                            <span className="danger validation-text">
                              Description is required
                            </span>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="input text-area-box d-flex flex-column pt-0"
                    >
                      <label>Additional Note</label>
                      <TextareaAutosize
                        sx={{ mb: 2 }}
                        className="form-input text-area"
                        placeholder="type here"
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ mt: 1 }}
                      className="input flex-column d-flex pt-0"
                    >
                      <FormControlLabel
                        control={<Checkbox />}
                        label="I agree that I have completed all the particulars"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Box className="agency-btn-section add-expenses-btn">
                  <Link href="javascript:;">Back</Link>
                  <Link href="javascript:;">Submit</Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
