import { lazy, Suspense, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import Box from "@mui/material/Box";

import Appbar from "./../Dashboard/Layout/Appbar";
import Sidebar from "./../Dashboard/Layout/Sidebar";
import localstorageData from "../helpers/localstorageData";
import { DelayedError } from "./DelayedError";
import { useState, useEffect } from "react";
import { accessRoleList, listSidebarMenuByRoleID } from "../api";
import { getCreatedBy } from "../helpers/commonHelper";
import Loader from "../../../frontend/src/pages/loader/Loader";

import axios from "axios";

//404 page
import Error from "../components/Error";

export default function HealthCarer() {
  const user = localstorageData();

  const MyAccount = lazy(() => import("../pages/healthCarer/form/MyAccount"));

  // const SetupAccountHealthCarer = lazy(() =>
  //   import("../pages/healthCarer/Account-Setup")
  // );

  let MemoizedAppbar, MemoizedSidebar;

  MemoizedAppbar = useMemo(() => <Appbar />, []);
  MemoizedSidebar = useMemo(() => <Sidebar />, []);

  const Availibility = lazy(() =>
    import("../pages/healthCarer/availability/Availibility")
  );
  const Attendance = lazy(() =>
    import("../pages/CommanComponant/Attendance/Attendance")
  );

  const Editincident = lazy(() => import("../pages/healthCarer/incident/edit"));
  const Listincident = lazy(() => import("../pages/healthCarer/incident/list"));
  const Viewincident = lazy(() => import("../pages/healthCarer/incident/view"));
  const Pdfincident = lazy(() => import("../pages/healthCarer/incident/pdf"));

  const Editpolicy = lazy(() => import("../pages/healthCarer/Lms/policy"));

  const SubscriptionPlan = lazy(() =>
    import("../pages/serviceProvider/subscription-plan/list")
  );
  const PaymentGateway = lazy(() =>
    import("../pages/serviceProvider/subscription-plan/paymentGateway")
  );

  const AppointmentList = lazy(() =>
    import("../pages/healthCarer/appointment/AppointmentList")
  );

  const AppointmentDashboard = lazy(() =>
    import("../pages/healthCarer/appointment/dashboard/AppointmentDashboard")
  );
  const QuickTask = lazy(() =>
    import("../pages/healthCarer/appointment/dashboard/QuickTask")
  );
  // const Home = lazy(() => import("../pages/healthCarer/form/home")); // older dashboard
  const Home = lazy(() => import("../pages/healthCarer/HealthCarer-Dashboard"));

  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );

  const ServiceProviderList = lazy(() =>
    import("../pages/superAdmin/service-provider/list")
  );

  // const ListIncident = lazy(() => import("../pages/superAdmin/incident/list"));
  // const EditIncident = lazy(() => import("../pages/superAdmin/incident/edit"));

  /**induction routing */
  // import Induction from "./pages/healthCarer/Induction/Induction";
  // const Induction = lazy(() => import("../pages/healthCarer/form/home"));
  // const Confirmation = lazy(() => import("../pages/healthCarer/Lms/policy.js"));
  const Confirmation = lazy(() =>
    import("../pages/healthCarer/Induction/Confirmation")
  );
  const Editemergencycontact = lazy(() =>
    import("../pages/healthCarer/Emergency-Contact/edit")
  );
  const Listemergencycontact = lazy(() =>
    import("../pages/healthCarer/Emergency-Contact/list")
  );

  const Listriskassesment = lazy(() =>
    import("../pages/healthCarer/Clients/Riskassessment")
  );

  const ViewAppointment = lazy(() =>
    import("../pages/healthCarer/appointment/Appointment-view")
  );

  const ViewAggrement = lazy(() =>
    import("../pages/healthCarer/appointment/Agreement")
  );
  // const Swapappointment = lazy(() =>
  //   import("../pages/healthCarer/swap-appointment/swapappointment")
  // );

  const TaskList = lazy(() => import("../pages/healthCarer/task/task"));

  // const AddRoutine = lazy(() =>
  //   import("../pages/healthCarer/appointment/Add-Routine")
  // );
  const StartAppointment = lazy(() =>
    import("../pages/healthCarer/appointment/Start-Appointment")
  );

  // const AddRoutineTask = lazy(() =>
  //   import("../pages/healthCarer/appointment/Add-RoutineTask")
  // );

  const AddRoutine = lazy(() =>
    import("../components/calendar-view/routine/Add-Routine")
  );

  const AddRoutineTask = lazy(() =>
    import("../components/calendar-view/task/AddRoutineTask")
  );

  const Swapappointment = lazy(() =>
    import("../components/calendar-view/swap-appointment/swapappointment")
  );

  const Viewtask = lazy(() => import("../pages/healthCarer/task/view"));

  const AppointmentView = lazy(() =>
    import("../pages/healthCarer/appointment/Appointment-view")
  );

  const AfterStartAppointment = lazy(() =>
    import("../pages/healthCarer/appointment/After-Start-Appointment")
  );

  const EditTask = lazy(() =>
    import("../pages/healthCarer/appointment/Edit-RoutineTask")
  );

  const FinishAp = lazy(() =>
    import("../pages/healthCarer/appointment/finish/Finish-Appointment")
  );

  const TimeSheet = lazy(() =>
    import("../pages/healthCarer/appointment/Timesheet/Timesheet")
  );

  const AddExpense = lazy(() =>
    import("../pages/healthCarer/appointment/Timesheet/AddExpenses")
  );

  const AddAppointmentExpense = lazy(() =>
    import("../pages/healthCarer/appointment/expenses/expenses")
  );

  const EditTimesheet = lazy(() =>
    import("../pages/healthCarer/appointment/Timesheet/EditTimesheet")
  );

  const ViewTimesheet = lazy(() =>
    import("../pages/healthCarer/appointment/Timesheet/ViewTimeSheet")
  );

  const AppointmentView1 = lazy(() =>
    import("../components/calendar-view/Appointment-View")
  );

  const Healthcarerweek = lazy(() =>
    import("../Dashboard/HealthCarer/Health-Carer-Week")
  );

  // const HcDashboard = lazy(() =>
  //   import("../pages/healthCarer/HealthCarer-Dashboard")
  // );

  const HealthCarerAvailability = lazy(() =>
    import("../Dashboard/HealthCarer/Health-Carer-Availability")
  );

  const AvailabilityCalendar = lazy(() =>
    import("../components/availability/Calendar")
  );

  const ProgressNote = lazy(() =>
    import("../pages/superAdmin/progressNote/edit")
  );

  const ProgressNotelist = lazy(() =>
    import("../pages/superAdmin/progressNote/list")
  );

  /*interview routes*/
  const InterviewList = lazy(() =>
    import("../pages/healthCarer/interview/list")
  );
  const ListHealthCarerScheduleInterviewNew = lazy(() =>
    import("../pages/superAdmin/schedule-interview/health-carer/list-tab")
  );

  const ListCommonCalander = lazy(() =>
    import("../pages/superAdmin/schedule-common-meeting/client/list-tab")
  );
  //test

  const HealthCarerScheduleInterviewNew = lazy(() =>
    import("../components/interview-view/details")
  );

  const ApppoitmentFeedBack = lazy(() =>
    import("../pages/healthCarer/appointment/Appointment-FeedBack")
  );

  const ProgressNoteView = lazy(() =>
    import("../pages/superAdmin/progressNote/view")
  );

  const Observation = lazy(() =>
    import("../pages/healthCarer/Observation/Observation")
  );
  const Editobservation = lazy(() =>
    import("../pages/healthCarer/Observation/Blood/edit")
  );
  const Editbowel = lazy(() =>
    import("../pages/healthCarer/Observation/Bowel/edit")
  );

  const ListBPObservation = lazy(() =>
    import("../pages/healthCarer/Observation/Blood/Bloodlist")
  );

  const Editweight = lazy(() =>
    import("../pages/healthCarer/Observation/weight/edit")
  );
  const Editseizure = lazy(() =>
    import("../pages/healthCarer/Observation/Seizure/edit")
  );
  const EditPersonalcare = lazy(() =>
    import("../pages/healthCarer/Observation/Personal-Care/edit")
  );

  const ListPersonalcare = lazy(() =>
    import("../pages/healthCarer/Observation/Personal-Care/list")
  );

  const EditMenstruation = lazy(() =>
    import("../pages/healthCarer/Observation/Menstruation/edit")
  );
  const ListPackageMenu = lazy(() => import("../components/packageMenu/list"));

  const EditFoodAndFluid = lazy(() =>
    import("../pages/healthCarer/Observation/Food/edit")
  );

  const Exploremarket = lazy(() =>
    import("../pages/client/Exploremarket/Exploremarket")
  );
  const Viewexploremarket = lazy(() =>
    import("../pages/client/Exploremarket/Client-view")
  );

  const SendRequestViewByHC = lazy(() =>
    import("../pages/client/Exploremarket/SendRequestViewByHC")
  );

  const EditContinueshift = lazy(() =>
    import("../pages/healthCarer/Continue-Shift/edit")
  );
  const ListContinueshift = lazy(() =>
    import("../pages/healthCarer/Continue-Shift/list")
  );
  const ViewContinueshift = lazy(() =>
    import("../pages/healthCarer/Continue-Shift/view")
  );
  const RequestConnections = lazy(() =>
    import("../components/Explore-Market/Connection-request")
  );

  const AppointmentConnections = lazy(() =>
    import("../components/Explore-Market/Appointment-request")
  );

  const AppointmentConnectionsView = lazy(() =>
    import("../components/Explore-Market/Appointment-request-view")
  );

  const ConnectionAvailability = lazy(() =>
    import("../components/Explore-Market/Availability-connection-request")
  );

  const DocumentRequest = lazy(() =>
    import("../components/Explore-Market/Connect-request-tab/Document_request")
  );

  const ViewDocumentRequest = lazy(() =>
    import("../pages/client/Exploremarket/Document-View-Request")
  );

  // const AddAppointment = lazy(() =>
  //   import("../pages/healthCarer/appointment/Create-Appointment")
  // );

  const AddAppointment = lazy(() =>
    import("../pages/superAdmin/appointment/add-appointment")
  );

  // import("../pages/superAdmin/appointment/add-appointment")

  const Viewriskassesment = lazy(() =>
    import("../pages/client/Exploremarket/riskassesment")
  );

  const ProgressNotesList = lazy(() =>
    import("../pages/superAdmin/progressNote/list")
  );

  const AddProgressNotes = lazy(() =>
    import("../pages/superAdmin/progressNote/edit")
  );

  const EditProgressNotes = lazy(() =>
    import("../pages/superAdmin/progressNote/edit")
  );

  const ViewProgressNotes = lazy(() =>
    import("../pages/superAdmin/progressNote/view")
  );

  const RecievedRequestDetails = lazy(() =>
    import("../pages/client/Exploremarket/View-Client-RecievedRequest")
  );

  const RecievedRequestViewDetailsAl = lazy(() =>
    import("../components/Explore-Market/availability/ClientForm")
  );

  const ViewDataBackup = lazy(() => import("../components/dataBackup/list"));
  const Favclient = lazy(() =>
    import("../pages/client/Exploremarket/Fav-client")
  );

  const SelectClient = lazy(() => import("../components/goals/ClientSelect"));

  const ViewSelectClientDetails = lazy(() =>
    import("../pages/superAdmin/client-list/view-details-client")
  );

  const ViewGoalChart = lazy(() => import("../components/goals/barChart"));

  const EditGoal = lazy(() => import("../components/goals/edit"));

  const ViewGoalRoutine = lazy(() => import("../components/goals/view"));

  const EditGoalRoutine = lazy(() =>
    import("../components/goals/addRoutineTask/edit")
  );
  const PdfGoal = lazy(() => import("../components/goals/pdf"));
  const PdfGoalImage = lazy(() => import("../components/goals/pdfWithImage"));
  const StartAppointmentNew = lazy(() =>
    import("../components/calendar-view/StartAppointmentNew")
  );
  const Report = lazy(() => import("../pages/superAdmin/Reports/report"));
  const EmployeeBirthday = lazy(() =>
    import("../pages/superAdmin/Reports/employee/employeeBirthday")
  );
  const EmployeeDetails = lazy(() =>
    import("../pages/superAdmin/Reports/employee/employeeDetails")
  );
  const EmergencyContact = lazy(() =>
    import("../pages/superAdmin/Reports/employee/emergencyContact")
  );
  const UnpaidEmployee = lazy(() =>
    import("../pages/superAdmin/Reports/employee/unpaidEmployee")
  );
  const JobmakerEmployeeNotice = lazy(() =>
    import("../pages/superAdmin/Reports/atoReporting/jobmakerEmployeeNotice")
  );
  const JobkeeperEmployeeNomination = lazy(() =>
    import(
      "../pages/superAdmin/Reports/atoReporting/jobkeeperEmployeeNomination"
    )
  );
  const JobkeeperEligibilityReport = lazy(() =>
    import("../pages/superAdmin/Reports/employee/jobKeeperEligibility")
  );
  const TaxFileDeclaration = lazy(() =>
    import("../pages/superAdmin/Reports/atoReporting/taxFileDeclaration")
  );
  const AttendanceReport = lazy(() =>
    import("../pages/superAdmin/Reports/timeAndAttendance/attendanceReport")
  );
  const EmployeeSatisfaction = lazy(() =>
    import("../pages/superAdmin/Reports/employee/employeeSatisfaction")
  );
  const SingleTouchPayroll = lazy(() =>
    import("../pages/superAdmin/Reports/atoReporting/singleTouchPayroll")
  );

  const LeaveHistory = lazy(() =>
    import("../pages/superAdmin/Reports/leave-history")
  );

  const EmployeeDetailsAudit = lazy(() =>
    import("../pages/superAdmin/Reports/employee/employeeDetailsAudit")
  );

  const JobKeeperEligibility = lazy(() =>
    import("../pages/superAdmin/Reports/employee/jobKeeperEligibility")
  );

  const ListMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
  );

  const MedicalHistoryPdf = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/medicalHistoryPdf")
  );

  const MedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Medical-History")
  );

  const EditMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medical-History")
  );

  const InProgressPage = lazy(() => import("../pages/client/in-progress"));
  //ViewMedicalHistory

  const ViewMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/View-Medical-History")
  );

  const MedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Medication-chart")
  );

  const EditMedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medication")
  );

  const ViewMedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/View-Medication")
  );

  const ObservationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Observation-chart")
  );
  //

  const ViewBloodObservation = lazy(() =>
    import("../pages/superAdmin/client-list/Viewtab/Observation/Blood/view")
  );

  const ViewBowelObservation = lazy(() =>
    import("../pages/superAdmin/client-list/Viewtab/Observation/Bowel/view")
  );

  const ViewFoodObservation = lazy(() =>
    import("../pages/superAdmin/client-list/Viewtab/Observation/Food/view")
  );

  const ViewMestruationObservation = lazy(() =>
    import(
      "../pages/superAdmin/client-list/Viewtab/Observation/Mestruation/view"
    )
  );

  const ViewPersonalCareObservation = lazy(() =>
    import(
      "../pages/superAdmin/client-list/Viewtab/Observation/PersonalCare/view"
    )
  );

  const ViewSeizureObservation = lazy(() =>
    import(
      "../pages/superAdmin/Medication-management-dev/Observation/Seizure/view"
    )
  );

  const ViewWeightObservation = lazy(() =>
    import(
      "../pages/superAdmin/Medication-management-dev/Observation/weight/view"
    )
  );
  const Induction = lazy(() =>
    import("../pages/healthCarer/Induction/Induction")
  );

  //leave management new changes

  const ListLeaveCategory = lazy(() =>
    import("../pages/superAdmin/leave-category/list")
  );

  const ListLeaveManagementOld = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list")
  );

  const ListLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list-tab")
  );

  const Closerincident = lazy(() =>
    import("../pages/Supervisor/incident/incidentClosure")
  );

  const EditLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/edit")
  );

  const SupportCoordinatorList = lazy(() =>
    import("../pages/superAdmin/support-coordinator/list")
  );
  //

  const EditLeaveManagementOld = lazy(() =>
    import("../pages/healthCarer/leaveManagement/edit")
  );

  const ViewLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/view")
  );
  const ChatHistory = lazy(() =>
    import("../pages/superAdmin/chathistory/list")
  );
  const ListSetLeaves = lazy(() => import("../pages/superAdmin/setLeave/list"));

  const ListCpmpliance = lazy(() =>
    import("../pages/superAdmin/compliance/list")
  );

  //

  const ClientOnboard = lazy(() => import("../pages/client/Account-Setup"));

  const AddCpmpliance = lazy(() =>
    import("../pages/superAdmin/compliance/edit")
  );

  const SendCustomEmail = lazy(() => import("../components/email/list"));
  const ViewLmsCourse = lazy(() =>
    import("../pages/superAdmin/LMS/health-carer/view")
  );
  const OpenMoodle = lazy(() =>
    import("../pages/superAdmin/lms-student/openMoodle.js")
  );
  const ListLmsCourse = lazy(() =>
    import("../pages/superAdmin/LMS/health-carer/list")
  );
  const Addbankdetails = lazy(() =>
    import("../pages/healthCarer/form/Myaccounttab/Addbank")
  );
  const Editbank = lazy(() =>
    import("../pages/healthCarer/form/Myaccounttab/Editbank")
  );

  const SignedDocument = lazy(() =>
    import("../components/ReceiverDocument/ListSignedDocument")
  );

  const ViewSignedDocument = lazy(() =>
    import("../components/ReceiverDocument/ViewSignedDocument")
  );

  const ListShiftNotes = lazy(() =>
    import("../pages/superAdmin/shiftNotes/list")
  );
  const EditShiftNotes = lazy(() =>
    import("../pages/superAdmin/shiftNotes/edit")
  );
  const AddShiftNotes = lazy(() =>
    import("../pages/superAdmin/shiftNotes/edit")
  );
  const ViewShiftNotes = lazy(() =>
    import("../pages/superAdmin/shiftNotes/view")
  );

  const InvoicesServiceTask = lazy(() =>
    import("../components/invoice-service-task/Invoice")
  );
  const Invoice = lazy(() => import("../components/invoice/Invoice"));

  const InvoicePdf = lazy(() => import("../components/invoice/InvoicePdf"));

  const ClientListTaskNote = lazy(() =>
    import("../pages/healthCarer/TaskNotes/clientListTaskNote")
  );
  const ListTasknote = lazy(() =>
    import("../pages/healthCarer/TaskNotes/listTasknote")
  );
  const ViewTasknote = lazy(() =>
    import("../pages/healthCarer/TaskNotes/viewTasknotes")
  );

  const HCPickupShifts = lazy(() =>
    import("../pages/healthCarer/pickupShift/list")
  );
  const AccessRoleListPermission = lazy(() =>
    import("../pages/superAdmin/access-role-permission/list")
  );

  const ListSeverity = lazy(() => import("../pages/superAdmin/severity/list"));
  const AddSeverity = lazy(() => import("../pages/superAdmin/severity/edit"));
  const EditSeverity = lazy(() => import("../pages/superAdmin/severity/edit"));

  //   import PickupShiftsViewDetail from "./components/Explore-Market/View-Details/PickupShifts-View-Details";
  // import PickupShiftsViewDetailSp from "./components/Explore-Market/View-Details/PickupShifts-View-Details-SP";

  const HCPickupShiftsView = lazy(() =>
    import("../pages/healthCarer/pickupShift/details")
  );
  const HCPickupShiftsViewSp = lazy(() =>
    import("../pages/healthCarer/pickupShift/details")
  );

  const LibraryLink = lazy(() =>
    import("../pages/superAdmin/Library/link/employeeList")
  );
  const GoalsasperClient = lazy(() => import("../components/goals/list"));
  const SasperClientgoals = lazy(() =>
    import("../pages/healthCarer/Clients/selectClient")
  );
  const ListComplaint = lazy(() =>
    import("../pages/superAdmin/complaint/list")
  );

  const ShiftNotesTab = lazy(() =>
    import("../components/ContinueShiftNotes/Connection-request")
  );

  const ViewClientDetails = lazy(() =>
    import("../pages/superAdmin/client-list/view-details-client")
  );
  const EditClient = lazy(() => import("../pages/superAdmin/client-list/edit"));
  const ClientList = lazy(() => import("../pages/superAdmin/client-list/list"));

  const Settings = lazy(() =>
    import("../pages/serviceProvider/Settings/setting")
  );
  const ViewShiftNotesList = lazy(() =>
    import("../pages/superAdmin/shift-notes/shiftNoteclientlist.js")
  );

  const ViewEmail = lazy(() => import("../components/email/view"));
  const EditEmail = lazy(() =>
    import("../components/secondaryEmail/newEmaill/edit")
  );

  const MasterDataSetting = lazy(() =>
    import("../pages/serviceProvider/Settings/manageMasterData")
  );

  const SettingPayRun = lazy(() =>
    import("../pages/serviceProvider/Settings/payRun/payRun")
  );
  const SettingATO = lazy(() =>
    import("../pages/serviceProvider/Settings/atoSetting")
  );
  const SettingPayment = lazy(() =>
    import("../pages/serviceProvider/Settings/payment")
  );
  const SettingXero = lazy(() => import("../pages/superAdmin/xero-myob/list"));
  const SettingSuperannuationList = lazy(() =>
    import("../pages/serviceProvider/Settings/superAnnuation/list")
  );
  const SettingSuperannuationAdd = lazy(() =>
    import("../pages/serviceProvider/Settings/superAnnuation/edit.js")
  );
  const SettingSuperannuationEdit = lazy(() =>
    import("../pages/serviceProvider/Settings/superAnnuation/edit.js")
  );

  const HealthCarerOnCall = lazy(() =>
    import("../pages/superAdmin/health-carer/on-call/list")
  );
  const SettingViewFinancial = lazy(() =>
    import(
      "../pages/serviceProvider/Settings/financialSettings/financialSettings"
    )
  );
  const EditComplaint = lazy(() =>
    import("../pages/superAdmin/complaint/edit")
  );

  const ListClientRating = lazy(() =>
    import("../pages/superAdmin/Ratings/client")
  );

  const ProfitOrLoss = lazy(() =>
    import("../pages/superAdmin/Profit/Profit.js")
  );

  const BSPList = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
  );

  const BSPAdd = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/addBsp")
  );

  const MedicalHistoryPrint = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/print")
  );

  const ListRiskAssessment = lazy(() =>
    import("../pages/superAdmin/risk-assessment/list")
  );

  const AddRiskAssessment = lazy(() =>
    import("../pages/superAdmin/risk-assessment/edit.js")
  );

  const EditRiskAssessment = lazy(() =>
    import("../pages/superAdmin/risk-assessment/edit.js")
  );

  const ListConflict = lazy(() =>
    import("../pages/superAdmin/conflictClient/list")
  );
  const EditConflict = lazy(() =>
    import("../pages/superAdmin/conflictClient/edit")
  );

  const ListBSP = lazy(() =>
    import("../pages/superAdmin/Bsp/list")
  );

  const AddBSP = lazy(() =>
    import("../pages/superAdmin/Bsp/edit")
  );

  const EditBSP = lazy(() =>
    import("../pages/superAdmin/Bsp/edit")
  );

  const ListPublicHoliday = lazy(() =>
    import("../pages/superAdmin/public-holiday/list.js")
  );
  const AddPublicHoliday = lazy(() =>
    import("../pages/superAdmin/public-holiday/edit")
  );
  const EditPublicHoliday = lazy(() =>
    import("../pages/superAdmin/public-holiday/edit")
  );

  const ListInductionCategory = lazy(() =>
    import("../pages/superAdmin/inductionCategory/list")
  );

  const EditInductionCategory =  lazy(() =>
    import("../pages/superAdmin/inductionCategory/edit")
  );

  const AddInductionCategory =  lazy(() =>
    import("../pages/superAdmin/inductionCategory/edit")
  );
  const AddInductionHeading =  lazy(() =>
    import("../pages/superAdmin/inductionHeading/edit")
  );

  const ListInductionHeadingTitle = lazy(() =>
    import("../pages/superAdmin/inductionHeading/list")
  );

  const ListTemplateType = lazy(() =>
    import("../pages/superAdmin/templateType/list")
  );

  const AddTemplateType = lazy(() =>
    import("../pages/superAdmin/templateType/edit")
  );
  const EditTemplateType = lazy(() =>
    import("../pages/superAdmin/templateType/edit")
  );

  const ListAgreement = lazy(() =>
    import("../pages/superAdmin/appointment/appointment-agreement/list")
  );

  const AddAgreement = lazy(() =>
    import("../pages/superAdmin/appointment/appointment-agreement/edit")
  ); 

  const EditAgreement = lazy(() =>
    import("../pages/superAdmin/appointment/appointment-agreement/edit")
  ); 

  const SmtpSetup = lazy(() =>
    import("../pages/serviceProvider/Settings/smtpSetup.js")
  );

  const HcRoutes = [
    {
      path: "/health-week",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Healthcarerweek />
        </Suspense>
      ),
    },
    // {
    //   path: "/health-carer/dashboard",
    //   element: (
    //     <Suspense fallback={<div><Loader /></div>}>
    //       <HcDashboard />
    //     </Suspense>
    //   ),
    // },
    {
      path: "/health-availability",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HealthCarerAvailability />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/availability-calendar-view",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AvailabilityCalendar />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/home",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Home />
        </Suspense>
      ),
    },
    // {
    //   path: "/health-carer/add-incident",
    //   element: (
    //     <Suspense fallback={<div><Loader /></div>}>
    //       <EditIncident />
    //     </Suspense>
    //   ),
    // },
    {
      path: "/health-carer/list-support-coordinator",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SupportCoordinatorList />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-service-provider",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderList />
        </Suspense>
      ),
    },
    // {
    //   path: "/health-carer/add-incident",
    //   element: (
    //     <Suspense fallback={<div><Loader /></div>}>
    //       <EditIncident />
    //     </Suspense>
    //   ),
    // },
    {
      path: "/health-carer/list-support-coordinator",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SupportCoordinatorList />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-service-provider",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderList />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/pending-approval",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PendingApproval />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-package-menu",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListPackageMenu />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/appointments-view",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentView1 />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/induction",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Induction />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-complaint",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditComplaint />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-client-rating",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListClientRating />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/shift-notes-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewShiftNotesList />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/myaccount",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MyAccount />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/appointment-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentList />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-appointments",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentList />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/appointment-details/dashboard",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentDashboard />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/quick-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <QuickTask />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/availability",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Availibility />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/attendance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Attendance />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editincident />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listincident />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editincident />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/closure-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Closerincident />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Viewincident />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/pdf-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Pdfincident />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-severity",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListSeverity />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-severity",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddSeverity />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-severity",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditSeverity />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/policy",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editpolicy />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/subscription-plans-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SubscriptionPlan />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/payment-gateway",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PaymentGateway />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/confirmation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Confirmation />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editemergencycontact />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editemergencycontact />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listemergencycontact />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-risk-assesment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listriskassesment />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/task-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TaskList />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/swap-appointments",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Swapappointment />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/swap-appointment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Swapappointment />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-appointment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewAppointment />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-agreement",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewAggrement />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-routine",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddRoutine />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/start-appointment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <StartAppointment />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/task-add",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddRoutineTask />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/task-view",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Viewtask />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/appointment-start",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AfterStartAppointment />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/appointmentView",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentView />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/finish-appointment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <FinishAp />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditTask />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/interview-list-old",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InterviewList />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/interview-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListHealthCarerScheduleInterviewNew />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-calendar",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListCommonCalander />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-view-schedule-interview",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HealthCarerScheduleInterviewNew />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/time-sheet",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TimeSheet />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edittime-sheet",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditTimesheet />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-time-sheet",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewTimesheet />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-expense",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddExpense />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/appointment/add-expense",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddAppointmentExpense />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNote />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNotelist />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNote />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/appointment-feedback",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ApppoitmentFeedBack />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNoteView />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Observation />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/editobservation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editobservation />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-bowel",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editbowel />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-bowel",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editbowel />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-blood-pressure",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListBPObservation />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-personal-care",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListPersonalcare />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-personal-care",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditPersonalcare />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-weight",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editweight />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-weight",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editweight />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-personal-care",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditPersonalcare />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-bloodpressure",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editobservation />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-menstruation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMenstruation />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-menstruation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMenstruation />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-food-fluid",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditFoodAndFluid />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-food-fluid",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditFoodAndFluid />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/health-carer-on-call",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HealthCarerOnCall />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-seizure",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editseizure />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-seizure",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editseizure />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-continue-shift",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          {/* <ListContinueshift /> */}
          <ShiftNotesTab />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-continue-shift",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditContinueshift />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-continue-shift",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditContinueshift />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-continue-shift",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewContinueshift />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/exploremarket",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Exploremarket />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/viewexploremarket",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Viewexploremarket />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/availability-connection-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ConnectionAvailability />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/request-connection-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <RequestConnections />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/appointment-connection-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentConnections />
        </Suspense>
      ),
    },
    //

    {
      path: "/health-carer/appointment-connection-view",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentConnectionsView />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-appointment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddAppointment />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/document-connection-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <DocumentRequest />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-document-request",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewDocumentRequest />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-risk-assesment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Viewriskassesment />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-send-request",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SendRequestViewByHC />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNotesList />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddProgressNotes />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditProgressNotes />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewProgressNotes />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/recieved-request-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <RecievedRequestDetails />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-al-recieved-request-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <RecievedRequestViewDetailsAl />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-backup-options",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewDataBackup />
        </Suspense>
      ),
    },

    {
      path: "/healthcarer/explore-market-favorites",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Favclient />
        </Suspense>
      ),
    },

    //

    {
      path: "/health-carer/appointment-started",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <StartAppointmentNew />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/select-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          {/* <SelectClient /> */}
          <SasperClientgoals />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-complaint",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListComplaint />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-client-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewSelectClientDetails />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/bar-chart-goal",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewGoalChart />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-goal",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditGoal />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-goals",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SelectClient />
          {/* <SasperClientgoals /> */}
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewGoalRoutine />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/export-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PdfGoal />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/export-pdf-with-image",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PdfGoalImage />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-goal",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditGoal />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-goal-routine",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditGoalRoutine />
        </Suspense>
      ),
    },

    // /health-carer/add-goal-routine

    {
      path: "/health-carer/add-goal-routine",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditGoalRoutine />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/reports",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Report />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-employee-birthdays",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmployeeBirthday />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-employee-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmployeeDetails />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-employee-emergency-contacts",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmergencyContact />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-unpaid-employee",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <UnpaidEmployee />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-jobmaker-employee-notice",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <JobmakerEmployeeNotice />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-jobkeeper-employee-nominations",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <JobkeeperEmployeeNomination />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-employee-jokeeper-eligibility",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <JobkeeperEligibilityReport />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-tax-file-declaration",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TaxFileDeclaration />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-attendance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AttendanceReport />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-employee-satisfaction",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmployeeSatisfaction />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-single-touch-payroll",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SingleTouchPayroll />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/employee-leave-history",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <LeaveHistory />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-employee-details-audit",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmployeeDetailsAudit />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-employee-jokeeper-eligibility",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <JobKeeperEligibility />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/medical-history-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicalHistoryPdf />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-medical-history",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-medication-chart",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-prn-chart",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/medical-history-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicalHistory />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-medical-history-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMedicalHistory />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-medical-history-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMedicalHistory />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-medical-history-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewMedicalHistory />
        </Suspense>
      ),
    },
    //
    {
      path: "/health-carer/medication-chart-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicationChart />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-medication-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMedicationChart />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-medication-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMedicationChart />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-medication-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewMedicationChart />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/observation-chart-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ObservationChart />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-blood-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewBloodObservation />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-bowel-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewBowelObservation />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-food-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewFoodObservation />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-mestruation-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewMestruationObservation />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-personalcare-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewPersonalCareObservation />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-seizure-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewSeizureObservation />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-weight-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewWeightObservation />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/prn-chart-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicationChart />
        </Suspense>
      ),
    },

    // leave management new changes
    {
      path: "/health-carer/list-leave-categories",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListLeaveCategory />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-leave-management-old",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListLeaveManagementOld />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-leave-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListLeaveManagement />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-leave",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditLeaveManagement />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-leave",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditLeaveManagement />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-leave-old",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditLeaveManagementOld />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-leave-old",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditLeaveManagementOld />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/view-leave",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewLeaveManagement />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-set-leave",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListSetLeaves />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/chat-history",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ChatHistory />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-compliance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddCpmpliance />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-compliance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddCpmpliance />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-compliance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListCpmpliance />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-course",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListLmsCourse />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/manage-lms-student",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <OpenMoodle />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-course",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewLmsCourse />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-compliance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListCpmpliance />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-compliance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddCpmpliance />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-compliance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddCpmpliance />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-bank-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Addbankdetails />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-bank-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editbank />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-signed-documents",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SignedDocument />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-signed-documents",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewSignedDocument />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-shift-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListShiftNotes />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-shift-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditShiftNotes />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-shift-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddShiftNotes />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-shift-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewShiftNotes />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/invoice",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Invoice />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/invoice-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InvoicePdf />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-invoices",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InProgressPage />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SendCustomEmail />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/task-notes-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ClientListTaskNote />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-task-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListTasknote />
          {/* <HCPickupShifts /> */}
        </Suspense>
      ),
    },
    {
      path: "/health-carer/task-notes-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListTasknote />
          {/* <HCPickupShifts /> */}
        </Suspense>
      ),
    },
    {
      path: "/health-carer/pickup-shifts",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HCPickupShifts />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/pickup-shifts/view-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HCPickupShiftsView />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/pickup-shifts/view-details-sp",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HCPickupShiftsViewSp />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-task-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewTasknote />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-library",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <LibraryLink />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-goal-as-per-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <GoalsasperClient />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/client-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ClientList />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/client/onboard",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ClientOnboard />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditClient />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditClient />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-client-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewClientDetails />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/settings",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Settings />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/user-email/edit-email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditEmail />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/manage-master-data",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MasterDataSetting />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/view-email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewEmail />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-payrun",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingPayRun />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/ato-settings",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingATO />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/payments",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingPayment />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-superannuation-setting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingSuperannuationList />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-superannuation-setting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingSuperannuationAdd />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-superannuation-setting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingSuperannuationEdit />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/settings-xero-myob-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingXero />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/financial-setting-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingViewFinancial />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/access-role-permission",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AccessRoleListPermission />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/profit-and-loss",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProfitOrLoss />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-bsp-chart",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <BSPList />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-bsp-chart",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <BSPAdd />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/medical-history-print",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MedicalHistoryPrint />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-risk-assessment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListRiskAssessment />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-risk-assessment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddRiskAssessment />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-risk-assessment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditRiskAssessment />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-conflict",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListConflict />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-conflict",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditConflict />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-conflict",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditConflict />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-bsp-question",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListBSP />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-bsp-question",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddBSP />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-bsp-question",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditBSP />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/list-holiday",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListPublicHoliday />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/add-holiday",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddPublicHoliday />
        </Suspense>
      ),
    },

    {
      path: "/health-carer/edit-holiday",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditPublicHoliday />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-induction-category",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListInductionCategory />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-induction-heading-title",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListInductionHeadingTitle />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-induction-category",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditInductionCategory />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-induction-category",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddInductionCategory />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-induction-heading",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddInductionHeading />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-template-type",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListTemplateType />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-template-type",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddTemplateType />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-template-type",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditTemplateType />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/list-agreement",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListAgreement />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/add-agreement",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddAgreement />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/edit-agreement",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditAgreement />
        </Suspense>
      ),
    },
    {
      path: "/health-carer/smtp-setup",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SmtpSetup />
        </Suspense>
      ),
    },
  ];

  const [menuList, setMenuList] = useState([]);

  const getServiceProviderRouteAccess = () => {
    const createdBy = getCreatedBy();
    axios
      .get(
        `${accessRoleList}?roleId=${
          user.userTypeId
        }&createdBy=${createdBy}&role=${user.role}&userId=${
          user._id
        }&userType=${user?.createdByUserType}&child=${
          user?.createdByUserType
        }&isABNHolderHc=${user?.isSoleTrader}&bookAdemo=${
          user?.isBookDemoUser
        }&isSc=${user?.isSoleTrader}&isABNHolder=${
          user?.isSoleTrader
        }&evlClient=${
          user?.packageMenuId == false && user?.role == "client"
            ? "true"
            : "false"
        }`
      )
      .then(function (res) {
        if (res.data.data.length > 0) {
          setMenuList((prevData) => [
            ...prevData,
            ...res.data.data,
            ...[
              "/health-carer/interview-list",
              "/health-carer/list-package-menu",
              "/health-carer/myaccount",
              "/health-carer/list-view-schedule-interview",
              "/health-carer/settings",
              "/health-carer/add-bank-details",
              "/health-carer/edit-bank-details",
              "/health-carer/subscription-plans-list",
            ],
          ]);
        } else {
          setMenuList((prevData) => [
            ...prevData,
            ...[
              "/health-carer/interview-list",
              "/health-carer/list-package-menu",
              "/health-carer/myaccount",
              "/health-carer/list-view-schedule-interview",
              "/health-carer/settings",
              "/health-carer/add-bank-details",
              "/health-carer/edit-bank-details",
              "/health-carer/subscription-plans-list",
            ],
          ]);
        }
      })
      .catch(function (error) {
        setMenuList((prevData) => [
          "/health-carer/interview-list",
          "/health-carer/list-package-menu",
          "/health-carer/myaccount",
          "/health-carer/list-view-schedule-interview",
          "/health-carer/settings",
          "/health-carer/add-bank-details",
          "/health-carer/edit-bank-details",
          "/health-carer/subscription-plans-list",
        ]);
      });
  };

  useEffect(() => {
    getServiceProviderRouteAccess();
  }, []);

  function isPathInRoutes(path) {
    if (path === "/health-carer/myaccount") {
      return true;
    } else {
      return menuList.includes(path);
    }
  }

  return (
    <Box className="dashboard-form" id="agency">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">{MemoizedAppbar}</Box>
      </Box>
      <Box className="d-flex dashboard-main-box">
        {MemoizedSidebar}
        <Routes>
          {HcRoutes?.map(
            (route, index) =>
              isPathInRoutes(route.path) ? (
                <Route key={index} path={route.path} element={route.element} />
              ) : null // You can also use an empty fragment <> </> instead of null
          )}
          {menuList.length > 0 && <Route path="*" element={<DelayedError />} />}
        </Routes>
      </Box>
    </Box>
  );
}
