import React, { useState } from "react";
import Box from '@mui/material/Box';
import Appbar from "../Layout/Appbar";
import Sidebar from "../Layout/Sidebar";
import { Link } from 'react-router-dom';
import { Button, Typography } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TextField from '@mui/material/TextField';
import '../../assets/css/dashboard.css';
import '../../assets/css/Appointment.css';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from "@mui/material/Grid";
import AttachmentIcon from '@mui/icons-material/Attachment';
import Table from '@mui/material/Table';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dropdown from 'react-bootstrap/Dropdown';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MapIcon from '@mui/icons-material/Map';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from "@mui/icons-material/Add";

function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const closeIcon = (
    <CloseIcon className="danger" />
);

const doneIcon = (
    <DoneIcon className="done-icon" />
)

function createData(name, calories, fat, carbs,) {
    return { name, calories, fat, carbs, };
}

const rows = [
    createData("1", "Dashboard", closeIcon, 'No', ''),
    createData("2", "Leave Management", doneIcon, 'Leaves List-Accountant/Leave Management/Leave List', ''),


];





const ExpensesAdd = (

    <Link className="expenses-add" to='/add-expenses'> <AddCircleIcon />  Add Expenses</Link>

)

const workType = (
    <Box className="workType-text">
        <Typography>Homecare</Typography>
        <span> Assistance in Supported <br /> Independent Living</span>
    </Box>
)

const appointTime = (
    <Box className="table-time">
        <span>Mon 04-02-2023 <br /> 09:00 - 17:00</span>
    </Box>
)

export default function SidebarMenu() {

    const [showhide, setShowhide] = React.useState('');

    const handleshowhide = (event) => {
        const getuser = event.target.value;
        setShowhide(getuser);

    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const [SelectCategory, setSelectCategory] = React.useState('');

    const handleCategory = (event) => {
        setSelectCategory(event.target.value);
    };

    const [toggleAppoint, setToggleAppoint] = useState(false);
    const [toggleAppoint2, setToggleAppoint2] = useState(false);
    const [toggleAppoint3, setToggleAppoint3] = useState(false);





    const onDragEnd = (result) => {
        const { destination, source } = result;
    }

    const handleClose = () => {
        setOpen(false);
    };

    // attach file modal 

    const [openAttach, setOpenAttach] = React.useState(false);



    const handleCloseAttach = () => {
        setOpenAttach(false);
    };

    const [searchTermPending, setSearchTermPending] = useState("");
    const searchItemsPending = (searchValue) => {
        setSearchTermPending(searchValue);
    };

    // client pop-up tab 


    const [isActive, setActive] = useState(false);

    const [toogle, setToogle] = useState(false);
    const [showResults, setShowResults] = React.useState(false);

    function toogleShow() {
        setShowResults(!showResults);
        setToogle((current) => !current);
    }
    const [clientDetail, setClientDetail] = useState([]);


    const toggleClass = () => {
        setActive(!isActive);
    };

    const [view, setView] = useState(false);

    const toggleView = () => {
        setView(!view);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className="dashboard-form">
            <Box sx={{ width: "100%" }} className="appbar- 
       border">
                <Box className="appbar-main">
                    <Appbar />
                </Box>
            </Box>
            <Box id="sidebar-menu" className=' d-flex dashboard-main-box'>
                <Sidebar />
                <Box className="main section-content">
                    <Box className='dashboard-form-typo dashboard-form-typo-up'>
                        <Grid container className="d-flex header-topsection">
                            <Grid item xs={6} sm={6} md={6}>
                                <Box id="breadcrumb">
                                    <ArrowBackIosIcon />
                                    <Box className="breadcrumb-inr">
                                        <h4 className="d-flex align-items-center ">
                                            {" "}
                                            Sidebar Menu List
                                        </h4>
                                        <Box className="breadcrumbs">
                                            {/* <Link className="breadcrumbs-link"></Link>{" "}
                      <span className="breadcrumbs-icon">{">"}</span> */}
                                            <Link className="breadcrumbs-link">Masters</Link>  <span
                                                className="breadcrumbs-icon">{">"}</span>
                                            <Link className="breadcrumbs-link breadcrumbs-link-active">
                                                Sidebar Menu List
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={6} sm={6} md={6}>
                                <Box className="addTask-btn">
                                    <Box className="d-flex align-items-center justify-content-end">
                                        <Link
                                            to="/add-hundred-points"
                                            className="agency-add btn next-btn"
                                        >
                                            <AddIcon /> Add
                                        </Link>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ mt: 0 }} className="agency-searchbar group-list-search-main searchbox-margin ">
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Accountant" {...a11yProps(0)} />
                                    <Tab label="Client" {...a11yProps(1)} />
                                    <Tab label="Family Member" {...a11yProps(2)} />
                                    <Tab label="Generel Manager" {...a11yProps(3)} />
                                    <Tab label="Health Carer" {...a11yProps(4)} />
                                    <Tab label="IT Manager" {...a11yProps(5)} />
                                    <Tab label="Plan Manager" {...a11yProps(6)} />
                                    
                                </Tabs> */}

                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    className="sidebar-menu-tab"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                >
                                    <Tab label="Accountant" {...a11yProps(0)} />
                                    <Tab label="Client" {...a11yProps(1)} />
                                    <Tab label="Family Member" {...a11yProps(2)} />
                                    <Tab label="Generel Manager" {...a11yProps(3)} />
                                    <Tab label="Health Carer" {...a11yProps(4)} />
                                    <Tab label="IT Manager" {...a11yProps(5)} />
                                    <Tab label="Plan Manager" {...a11yProps(6)} />
                                    <Tab label="Accountant" {...a11yProps(7)} />
                                    <Tab label="Client" {...a11yProps(8)} />
                                    <Tab label="Family Member" {...a11yProps(9)} />
                                    <Tab label="Generel Manager" {...a11yProps(10)} />
                                    <Tab label="Health Carer" {...a11yProps(11)} />
                                    <Tab label="IT Manager" {...a11yProps(12)} />
                                    <Tab label="Plan Manager" {...a11yProps(13)} />
                                </Tabs>

                            </Box>
                            <CustomTabPanel value={value} className="tab-pannel-submenu" index={0}>
                                <TableContainer component={Paper} id='document-table' className="p-0 padding-res-10">
                                    <Table sx={{ width: "100%" }} className="p-0" aria-label="caption table">

                                        <TableHead id="client-table-head">
                                            <TableRow>
                                                <TableCell className='tableborder tableHeader table-head-cell'>Sr. No.</TableCell>
                                                <TableCell className='tableborder tableHeader table-head-cell' align="left">Menu</TableCell>
                                                <TableCell className='tableborder submenu-contains-cell tableHeader table-head-cell' align="left">Contains <br /> Submenu</TableCell>
                                                <TableCell className='tableborder tableHeader table-head-cell' align="left">Submenu</TableCell>
                                                <TableCell className='tableborder tableHeader table-head-cell ' align="left">Action</TableCell>

                                            </TableRow>

                                        </TableHead>
                                        <TableBody id="client-table-body">
                                            {rows.map((row, index) => (
                                                <TableRow key={row.name} >
                                                    <TableCell component="th" scope="row" className='text-start tableborder'>
                                                        <a href='#' className='table-link'>{row.name}</a>
                                                    </TableCell>
                                                    <TableCell align="left" className='tableborder work-type-td'> <a href='#' className='table-link main-dark-color'>{row.calories}</a></TableCell>
                                                    <TableCell align="center" className='tableborder'> <a href='#' className='table-link'>{row.fat}</a></TableCell>
                                                    <TableCell align="left" className='tableborder'> <a href='#' className='table-link'>{row.carbs}</a></TableCell>
                                                    <TableCell align="left" className='tableborder table-editcell'>

                                                        <Box className='table-edit-del'>
                                                            <Box >
                                                                <a href='javascript:;'
                                                                    id="edit"
                                                                    label="Domain"
                                                                    className="table-edit  justify-content-center  btn"
                                                                    margin="normal"
                                                                // onClick={handleClickOpenAttach}
                                                                >
                                                                    <BorderColorIcon />   Edit
                                                                </a>
                                                            </Box>
                                                        </Box>

                                                        <Dropdown drop="start" autoClose="outside">
                                                            <Dropdown.Toggle id="dropdown-autoclose-outside" className="action-toggle-button">
                                                                <MoreVertIcon className='dot-icon' onClick={toggleClass} />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className="dropdown-menu client-drop-menu">
                                                                <Dropdown.Item className='table-edit-del ' href="#/action-1">  <a href='#upload'
                                                                    id="domain1"
                                                                    label="Domain"
                                                                    className="table-edit   justify-content-start client-table-btn btn"
                                                                    margin="normal"
                                                                >
                                                                    <BorderColorIcon />   Edit
                                                                </a></Dropdown.Item>

                                                                <Dropdown.Item className='table-edit-del' href="#/action-2">
                                                                    <a href='#upload'
                                                                        id="domain2"
                                                                        label="Domain"
                                                                        className="table-del justify-content-start client-table-btn  btn"
                                                                        margin="normal"
                                                                    >
                                                                        <DeleteOutlineIcon />   Delete

                                                                    </a>

                                                                </Dropdown.Item>


                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </TableCell>



                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                Item Two
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                                Item Three
                            </CustomTabPanel>
                        </Box>



                    </Box>




                </Box>


            </Box>






        </Box>



    )

}