import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Banner from "../../assets/images/Banner.jpg";
import Banner2 from "../../assets/images/Banner2.jpg";
import Banner3 from "../../assets/images/Banner3.jpg";
import logo from "../../assets/images/hubshift-logo.png";

// import './Signup.css';

import { Box } from "@mui/material";

const options = {
  items: 1,
  loop: true,
  margin: 10,
  autoplay: true,
  nav: true,
  dots: true,
  responsive: {
    0: {
      items: 1,
      nav: false,
    },
    600: {
      items: 1,
      nav: true,
    },
    1000: {
      items: 1,
      nav: true,
    },
  },
};

const OwlCarouselDemo = () => {
  return (
    <>
      <div className="carousel-container">
        <OwlCarousel className="owl-theme" {...options}>
          <div className="item">
            <div className="carousel-image-container">
              <img src={Banner} alt="Banner" className="banner-img" />
              <div className="carousel-caption">
                {/* <Box className="login_logo">
                  <img src={logo} alt="logo"></img>
                </Box> */}
                <h2 className="carousel-text">
                  {" "}
                  Connecting Care for the Community
                </h2>
              </div>
            </div>
          </div>

          <div className="item">
            <div className="carousel-image-container">
              <img src={Banner2} alt="Banner" className="banner-img" />
              <div className="carousel-caption">
                {/* <Box className="login_logo">
                  <img src={logo} alt="logo"></img>
                </Box> */}
                <h2 className="carousel-text">
                  Connecting Care for the Community
                </h2>
              </div>
            </div>
          </div>

          <div className="item">
            <div className="carousel-image-container">
              <img src={Banner3} alt="Banner" className="banner-img" />
              <div className="carousel-caption">
                {/* <Box className="login_logo">
                  <img src={logo} alt="logo"></img>
                </Box> */}
                <h2 className="carousel-text">
                  Connecting Care for the Community
                </h2>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </>
  );
};

export default OwlCarouselDemo;
