import * as React from "react";
import "../assets/css/header.css";
import "../assets/css/pagination.css";
import "../App.css";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Logout from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";
import logo from "../assets/images/hubshift-logo1.png";
import { Container } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Stack } from "@mui/material";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fieldLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("clientId");
    navigate("/");
    window.location.reload();
    toast.success("You have successfully logged out!");
  };

  const userData = localStorage.getItem("userData");
  const userObject =
    userData !== undefined && userData !== null ? JSON.parse(userData) : "";
  const [user, setUser] = useState(userObject);
  return (
    <React.Fragment>
      <Box className="header">
        <Container className="container">
          <Box className="navbar">
            <Box className="logo ">
              <Link to="/">
                <img src={logo} />
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
}
