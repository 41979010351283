import React, { useState } from "react";
import Box from "@mui/material/Box";

import Appbar from "../../Dashboard/Layout/Appbar";
import Sidebar from "../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";
import upload from "../../assets/images/upload.svg";
import camera from "../../assets/images/camera.svg";
import Form from "@mui/material/FormGroup";
import profileImg from "../../assets/images/client.jpg";
import calenderIcon from "../../assets/images/calenderIcon.svg";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import exercise from "../../assets/images/exercise.svg";
import AddIcon from "@mui/icons-material/Add";
import AppointmentDrag from "../Appointmentschedule/Appointment-Drag";
import meal from "../../assets/images/meal.svg";

// modal import
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function AppointmentDashboard() {
  const [toggleAppoint, setToggleAppoint] = useState(false);
  const [toggleAppoint2, setToggleAppoint2] = useState(false);
  const [toggleAppoint3, setToggleAppoint3] = useState(false);

  const [open, setOpen] = React.useState(false);

  const onDragEnd = (result) => {
    const { destination, source } = result;
  };

  const handleClose = () => {
    setOpen(false);
  };

  // attach file modal

  const [openAttach, setOpenAttach] = React.useState(false);

  const handleCloseAttach = () => {
    setOpenAttach(false);
  };

  // client pop-up tab

  const [isActive2, setIsActive] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);

  const handleClick2 = (event) => {
    setIsActive((current) => !current);
  };
  const handleClick3 = (event) => {
    setIsActive3((current) => !current);
  };
  const handleClick4 = (event) => {
    setIsActive4((current) => !current);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Appointments </Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
    >
      <Typography>Appointment Details</Typography>
    </Link>,
    <Link
      underline="hover"
      key="3"
      color="inherit"
      href="/material-ui/getting-started/installation/"
    >
      <Typography>Appointment Dashboard</Typography>
    </Link>,
  ];

  return (
    <Box className="dashboard-form" id="Appointment-Dashboard">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box className=" d-flex dashboard-main-box">
        <Sidebar />
        <Box className="main">
          <Box className="dashboard-form-typo dashboard-form-typo-up">
            <h3>Appointments </h3>
            <Box>
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Stack>
            </Box>
          </Box>
          <Box className="dashboard-form-typo dashboard-form-typo-sec dashboard-form-typo-up">
            <Box className="d-flex align-items-center justify-content-between">
              <h4 className="d-flex align-items-center ">
                {" "}
                <ArrowBackIosIcon /> Appointment Dashboard
              </h4>
              <Box className="appointment-dashboard-profile d-flex align-items-center ">
                <img src={profileImg} />{" "}
                <Box>
                  {" "}
                  <Typography>Jake Knap</Typography> <span>46 yr, Male</span>{" "}
                </Box>
              </Box>

              <Box className="appointment-dashboard-timebar d-flex align-items-center">
                <img src={calenderIcon} />
                <Typography>12-02-2023, 11:00 - 01:00</Typography>
              </Box>
            </Box>
          </Box>
          <Box className="timesheet-box ">
            {/* approved leave request section  */}
            <Box className="approved-leave-section padding-res-10">
              <Box className="d-flex align-items-center approved-leave-section-res  justify-content-between">
                <Box className="approved-head">
                  <Box onClick={handleClick2}>
                    <h3
                      className="approved-heading d-flex align-items-center"
                      onClick={() => setToggleAppoint(!toggleAppoint)}
                    >
                      {" "}
                      <img src={exercise} /> Exercise
                      <PlayArrowIcon
                        id="play-icon"
                        className={isActive2 ? "rotate" : ""}
                      />
                    </h3>
                  </Box>
                </Box>
                <Box className="tasksAssigned">
                  <Typography>
                    Assigned Tasks: <span>5</span>
                  </Typography>
                </Box>
                <Box className="addTask-btn">
                  <Link>
                    {" "}
                    <AddIcon /> Add Task
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* approved leave detail section  */}
          {toggleAppoint && (
            <Box className="appointment-detail padding-res-10">
              <AppointmentDrag />
            </Box>
          )}

          <Box className="timesheet-box " sx={{ mt: 5 }}>
            {/* approved leave request section  */}
            <Box className="approved-leave-section padding-res-10">
              <Box className="d-flex align-items-center approved-leave-section-res  justify-content-between">
                <Box className="approved-head">
                  <Box onClick={handleClick3}>
                    <h3
                      className="approved-heading d-flex align-items-center"
                      onClick={() => setToggleAppoint2(!toggleAppoint2)}
                    >
                      {" "}
                      <img src={meal} /> Meal
                      <PlayArrowIcon
                        id="play-icon"
                        className={isActive3 ? "rotate" : ""}
                      />
                    </h3>
                  </Box>
                </Box>
                <Box className="tasksAssigned">
                  <Typography>
                    Assigned Tasks: <span>5</span>
                  </Typography>
                </Box>
                <Box className="addTask-btn">
                  <Link>
                    {" "}
                    <AddIcon /> Add Task
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>

          {toggleAppoint2 && (
            <Box className="approved-detail padding-res-10">
              <AppointmentDrag />
            </Box>
          )}

          <Box className="timesheet-box " sx={{ mt: 5 }}>
            {/* approved leave request section  */}
            <Box className="approved-leave-section padding-res-10">
              <Box className="d-flex align-items-center approved-leave-section-res  justify-content-between">
                <Box className="approved-head">
                  <Box onClick={handleClick4}>
                    <h3
                      className="approved-heading d-flex align-items-center"
                      onClick={() => setToggleAppoint3(!toggleAppoint3)}
                    >
                      {" "}
                      <img src={meal} /> Medicines
                      <PlayArrowIcon
                        id="play-icon"
                        className={isActive4 ? "rotate" : ""}
                      />
                    </h3>
                  </Box>
                </Box>
                <Box className="tasksAssigned">
                  <Typography>
                    Assigned Tasks: <span>5</span>
                  </Typography>
                </Box>
                <Box className="addTask-btn">
                  <Link>
                    {" "}
                    <AddIcon /> Add Task
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>

          {toggleAppoint3 && (
            <Box className="approved-detail padding-res-10">
              <AppointmentDrag />
            </Box>
          )}
        </Box>
      </Box>

      {/* attach file modal   */}

      <Dialog
        open={openAttach}
        onClose={handleCloseAttach}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="attach-file-modal"
      >
        <DialogContent className="modal-text">
          <DialogContentText id="alert-dialog-description">
            <Box className="d-flex justify-content-center">
              <Box className="add-expenses">
                <Box className="add-expenses-head d-flex align-items-center justify-content-between">
                  <Typography> Add Expenses</Typography>
                  <Typography>
                    Client: <span>Jake Knap</span>
                  </Typography>
                </Box>
                <Box className="add-expenses-body">
                  <Box className="attach-document">
                    <h3>Attach Document</h3>
                    <Box className="attach-document-body d-flex align-items-center">
                      <Box className="d-flex align-items-center ">
                        <input
                          type="radio"
                          name="uploadfile"
                          id="attachupload"
                        ></input>
                        <img src={upload}></img>
                        <label for="attachupload">Upload</label>
                      </Box>
                      <Box className="d-flex align-items-center" sx={{ pl: 2 }}>
                        <input
                          type="radio"
                          name="uploadfile"
                          id="attachcapture"
                        ></input>
                        <img src={camera}></img>
                        <label for="attachcapture">Capture</label>
                      </Box>
                    </Box>
                    <Box>
                      <Form className="d-flex align-items-center attach-document-browse w-100 justify-content-between">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          className="attach-file-input"
                          type="file"
                        ></TextField>
                        <Button>Upload</Button>
                      </Form>
                    </Box>
                  </Box>

                  <Box className="agency-btn-section add-expenses-btn">
                    <Link href="javascript:;" onClick={handleCloseAttach}>
                      Cancel
                    </Link>
                    <Link
                      href="javascript:;"
                      to="/timesheet"
                      onClick={handleCloseAttach}
                    >
                      Save
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="time-sheet-modal"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="modal-content"
          >
            <Box className="map-modal bg-light">
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    width="100%"
                    height="323px"
                    className="map-iframe"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Sydney&t=&z=10&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                  <Box className="d-flex align-items-center justify-content-between map-modal-footbar">
                    <Typography className="distance-txt" sx={{ pb: 0 }}>
                      Distance: 45 KM
                    </Typography>
                    <Button onClick={handleClose}>Go Back</Button>
                  </Box>
                </div>
              </div>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
