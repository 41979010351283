import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../../assets/css/Appointment.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import MonthField from "../../calendar-view/CalenderFilter/MonthField";
import DatePickerComponent from "../../calendar-view/CalenderFilter/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import FilterStore from "../../availability/store/FilterStore";
import { apDropdwonList } from "../../../api";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import moment from "moment";

export default function TopFilter({ type, setFields }) {
  const {
    resetFields,
    tabIndex,
    setField,
    searchTerm,
    showhide,
    slotType,
    selectedCity,
    cityList,
    isShowAllHc,
  } = FilterStore();

  const [city, setcity] = useState(cityList);


  useEffect(() => {
    if (type == "findSupport") {
      setField("slotType", "")
    }
  }, [])
  const handleCategory = (event) => {
    setField("selectedCity", event.target.value);
  };

  const handleshowhide = (event) => {
    const getuser = event.target.value;
    if (getuser === "Custom") {
      setField("startDate1", null);
      setField("endDate1", null);
    }
    setField("showhide", getuser);
    setField("slotType", getuser);
  };

  const showAllHc = (e) => {
    setField("isShowAllHc", e.target.checked);
  };
  const currentYear = moment().format("YYYY");


  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={12}
          className="appointment-calender-filed-box justify-content-end align-items-center"
        >
          <Box className="appointment-calender-filed-select">
            <FormControl fullWidth className="select-category-main">
              {/* <InputLabel id="demo-simple-select-label">Select Category</InputLabel> */}
              {type != "findSupport" &&
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className="calender-category"
                  value={selectedCity}
                  onChange={handleCategory}
                  size="small"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    Select Area
                  </MenuItem>
                  {cityList !== undefined &&
                    cityList.length > 0 &&
                    cityList.map((val) => {
                      return (
                        <MenuItem
                          value={val.id}
                          className="select-category-menuItem"
                        >
                          {val?.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              }
            </FormControl>

            <FormControl fullWidth className="slot-calender">
              <Select
                labelId="demo-simple-select-label"
                id="date-day-slot"
                value={slotType}
                size="small"
                onChange={(e) => handleshowhide(e)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled>
                  Select Slot
                </MenuItem>
                <MenuItem value="Today">Today</MenuItem>
                {/* <MenuItem value="Week">Week</MenuItem>
                <MenuItem value="Fortnightly">Fortnightly</MenuItem> */}
                <MenuItem value="Month">Month({currentYear})</MenuItem>
                <MenuItem value="Custom">Custom</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* </Box> */}

          {showhide === "Week" || showhide === "Fortnightly" ? (
            <FormControlLabel
              className="checkbox-label"
              control={
                <Checkbox
                  defaultChecked
                  className="checkbox-color"
                  onChange={showAllHc}
                  checked={isShowAllHc}
                />
              }
              label="Show All HC"
            />
          ) : (
            ""
          )}

          {showhide === "Month" && (
            <Box id="Day-slide" className="slide-filed">
              <MonthField />
            </Box>
          )}
          {showhide === "Custom" && (
            <Box id="custom-slide" className="appointment-calender-date-filed">
              <DatePickerComponent setFields={setFields} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
