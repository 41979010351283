import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../../frontend/src/pages/loader/Loader";
//404 page
import Error from "../components/Error";

export default function PlanManager() {
  const Home = lazy(() => import("../pages/PlanManager/home"));
  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );

  const MyAccounts = lazy(() => import("../pages/PlanManager/form/MyAccount"));

  //leave management new changes

  const ListLeaveCategory = lazy(() =>
    import("../pages/superAdmin/leave-category/list")
  );

  const ListLeaveManagementOld = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list")
  );

  const ListLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list-tab")
  );

  const EditLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/edit")
  );

  const ViewLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/view")
  );

  const ListSetLeaves = lazy(() => import("../pages/superAdmin/setLeave/list"));

  return (
    <Routes>
      <Route>
        <Route
          path="/plan-manager/home"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/plan-manager/pending-approval"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <PendingApproval />
            </Suspense>
          }
        />

        <Route
          path="/plan-manager/myaccount"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <MyAccounts />
            </Suspense>
          }
        />

        {/* leave management new changes */}
        <Route
          path="/plan-manager/list-leave-categories"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <ListLeaveCategory />
            </Suspense>
          }
        />

        <Route
          path="/plan-manager/list-leave-management-old"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <ListLeaveManagementOld />
            </Suspense>
          }
        />

        <Route
          path="/plan-manager/list-leave-management"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <ListLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/plan-manager/add-leave"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <EditLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/plan-manager/edit-leave"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <EditLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/plan-manager/view-leave"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <ViewLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/plan-manager/list-set-leave"
          element={
           <Suspense fallback={<div><Loader /></div>}>
              <ListSetLeaves />
            </Suspense>
          }
        />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
