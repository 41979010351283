import React, { useState } from "react";
import Box from "@mui/material/Box";
// import 'superAdmin../Timesheet/timesheet.css'

import Appbar from "../../../Dashboard/Layout/Appbar";
import Sidebar from "../../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Button, List, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import upload from "../../../assets/images/upload.svg";
import camera from "../../../assets/images/camera.svg";
import Form from "@mui/material/FormGroup";

import Autocomplete from "@mui/material/Autocomplete";

import Team_a from "../../../assets/images/team-a.png";

import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function AddGoals() {
  const top100Films = [
    { label: "1 Month", year: 1994 },
    { label: "2 Month", year: 1972 },
    { label: "3 Month", year: 1974 },
    { label: "4 Month", year: 2008 },
    { label: "5 Month", year: 1957 },
    { label: "6 Month", year: 1993 },
    { label: "7 Month", year: 1993 },
    { label: "8 Month", year: 1993 },
    { label: "9 Month", year: 1993 },
    { label: "10 Month", year: 1993 },
    { label: "11 Month", year: 1993 },
    { label: "12 Month", year: 1993 },
  ];

  return (
    <Box className="dashboard-form" id="add-expenses">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box className=" d-flex dashboard-main-box">
        <Sidebar />
        <Box className="main">
          <Grid container className="d-flex align-items-center header-topsection">
            <Grid item xs={6} md={6} sm={6}>
              <Box id="breadcrumb">
                <ArrowBackIosIcon />
                <Box className="breadcrumb-inr">
                  <h4 className="d-flex align-items-center ">Add Goals</h4>
                  <Box className="breadcrumbs">
                    <Link className="breadcrumbs-link">client Details</Link>
                    <span className="breadcrumbs-icon">{">"}</span>
                    <Link className="breadcrumbs-link">Goals</Link>
                    <span className="breadcrumbs-icon">{">"}</span>
                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                      Add Goals
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={6} sm={6}>
              <List sx={{p:0}}>
                <ListItem sx={{p:0}}>
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={Team_a} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="Jake Knap"
                    secondary={
                      <>
                        <Typography
                          sx={{
                            fontSize: "13px !important",
                            color: "#929495 !important",
                          }}
                          component="span"
                          variant="body2"
                        >
                          46 yr, Male
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>

          <Box className="divider"></Box>

          {/* Tab Start here  */}

          <Box className="d-flex justify-content-center add-expenses-p-10">
            <Box className="add-expenses">
              <Box className="add-expenses-head">
                <Typography> Add Goals</Typography>
              </Box>
              <Box className="add-expenses-body">
                <Grid className="align-items-center w-100 add-expenses-input-box justify-content-between">
                  <Grid container spacing={4} className="align-items-end">
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="input flex-column d-flex pt-0"
                    >
                      <label>Name</label>

                      <TextField
                        id="outlined-basic"
                        className="w-100"
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <label> Duration </label>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        size="small"
                        renderInput={(params) => (
                          <TextField {...params} sx={{ mt: 2 }} />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Box className="agency-btn-section add-expenses-btn">
                  <Link href="#cancel">Cancel</Link>
                  <Link href="#save" to="/timesheet">
                    Save
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
