import * as React from "react";
import Box from "@mui/material/Box";
import Appbar from "../../../Dashboard/Layout/Appbar";
import { Typography } from "@mui/material";
import Sidebar from "../../../Dashboard/Layout/Sidebar";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../App.css";
import Shift from "../../../components/form/Shifttype/Shift";
import NextWeek from "../../../components/form/Shifttype/NextWeek";
// modal tools
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormGroup from "@mui/material/FormGroup";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

// One time slot every 30 minutes.
const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) =>
    `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${
      index % 2 === 0 ? "00" : "30"
    }`
);

export default function Availability() {
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [show, setShow] = React.useState(true);
  const [show2, setShow2] = React.useState(true);
  const [show3, setShow3] = React.useState(true);
  const [check, setCheck] = React.useState(true);
  const [check2, setCheck2] = React.useState(true);
  const [check3, setCheck3] = React.useState(true);

  return (
    <Box className="dashboard-form">
      <Box className="main">
        <Box>
          <Box id="breadcrumb">
            <ArrowBackIosIcon />
            <Box className="breadcrumb-inr">
              <h4 className="d-flex align-items-center ">Availability</h4>
              <Box className="breadcrumbs">
                <Link className="breadcrumbs-link breadcrumbs-link-active">
                  Availability
                </Link>
              </Box>
            </Box>
          </Box>
          <Box className="dashboard-form-section">
            <Box className="shift-type-section d-flex align-items-center ">
              <Box className="shift-type-section-box">
                <Typography className="form-text">Shift Types</Typography>
              </Box>
              <Box className="d-flex align-items-center shift-type-checkbox justify-content-between ">
                <Box className="shift-type-check ms-0 d-flex align-items-center justify-content-between">
                  <Checkbox sx={{ padding: "0" }} {...label} defaultChecked />
                  <span id="general">General </span>
                </Box>
                <Box className="shift-type-check d-flex align-items-center justify-content-between">
                  <Checkbox sx={{ padding: "0" }} {...label} defaultChecked />
                  <span>Night </span>
                </Box>
                <Box className="shift-type-check d-flex align-items-center justify-content-between">
                  <Checkbox sx={{ padding: "0" }} {...label} defaultChecked />
                  <span>Sleepover </span>
                </Box>
                <Box className="shift-type-check d-flex align-items-center justify-content-between">
                  <Checkbox sx={{ padding: "0" }} {...label} defaultChecked />
                  <span>Select All </span>
                </Box>
              </Box>
            </Box>

            <Box className="shift-type-section d-flex align-items-center">
              <Typography
                onClick={() => setShow(!show)}
                className="d-flex align-items-center shift-select shift-type-section-box"
              >
                <span>General Shift</span> <ArrowDropDownIcon sx={{ ml: 1 }} />
              </Typography>
              <FormControl className="shift-formcontrol-box ">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Weekly"
                  name="radio-buttons-group"
                  className="d-flex align-items-center justify-content-between flex-row "
                >
                  <FormControlLabel
                    value="Weekly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Weekly"
                  />
                  <FormControlLabel
                    value="Fortnightly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Fortnightly"
                  />
                  <FormControlLabel
                    value="Monthly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Monthly"
                  />
                  <FormControlLabel
                    value="Quarterly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Quarterly"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Box>
          {show ? (
            show
          ) : (
            <Box>
              <Box className="shift-type-section d-flex align-items-center ">
                <Box className="shift-type-section-box">
                  <Typography className="form-text">Current Week</Typography>
                </Box>
                <Box className="d-flex align-items-center justify-content-between switch-box">
                  <Box
                    className="appbar-switch appbar-menu-item"
                    onClick={() => setCheck(check)}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <AntSwitch
                        id="switch"
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography>Default </Typography>
                    </Stack>
                  </Box>
                  <Box className="time-filed">
                    <label>Start at</label>
                    <input type="text" placeholder="10:00" />
                  </Box>
                  <Box className="time-filed">
                    <label>End at</label>
                    <input type="text" placeholder="18:00" />
                  </Box>
                </Box>
              </Box>
              <Box className="weakday-section weak-box-border d-flex align-items-start justify-content-between">
                <Shift />
              </Box>
              <Box className="shift-type-section d-flex align-items-center ">
                <Box className="shift-type-section-box">
                  <Typography className="form-text">Next Week</Typography>
                </Box>
                <Box className="d-flex align-items-center justify-content-between switch-box">
                  <Box
                    className="appbar-switch appbar-menu-item"
                    onClick={() => setCheck(check)}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <AntSwitch
                        id="switch"
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography>Default </Typography>
                    </Stack>
                  </Box>
                  <Box className="time-filed">
                    <label>Start at</label>
                    <input type="text" placeholder="10:00" />
                  </Box>
                  <Box className="time-filed">
                    <label>End at</label>
                    <input type="text" placeholder="18:00" />
                  </Box>
                </Box>
              </Box>
              <Box
                id="next-week"
                className="weakday-section weak-box-border d-flex align-items-start justify-content-between"
              >
                <NextWeek />
              </Box>
            </Box>
          )}

          <Box className="dashboard-form-section pt-0">
            <Box className="shift-type-section d-flex align-items-center">
              <Typography
                onClick={() => setShow2(!show2)}
                className="d-flex align-items-center shift-select shift-type-section-box"
              >
                <span>Night Shift</span> <ArrowDropDownIcon sx={{ ml: 1 }} />
              </Typography>
              <FormControl className="shift-formcontrol-box ">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Weekly"
                  name="radio-buttons-group"
                  className="d-flex align-items-center justify-content-between flex-row "
                >
                  <FormControlLabel
                    value="Weekly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Weekly"
                  />
                  <FormControlLabel
                    value="Fortnightly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Fortnightly"
                  />
                  <FormControlLabel
                    value="Monthly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Monthly"
                  />
                  <FormControlLabel
                    value="Quarterly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Quarterly"
                  />
                </RadioGroup>
              </FormControl>
            </Box>

            {show2 ? (
              show2
            ) : (
              <Box>
                <Box className="shift-type-section d-flex align-items-center ">
                  <Box className="shift-type-section-box">
                    <Typography className="form-text">Current Week</Typography>
                  </Box>
                  <Box className="d-flex align-items-center justify-content-between switch-box">
                    <Box
                      className="appbar-switch appbar-menu-item"
                      onClick={() => setCheck2(check2)}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                          id="switch"
                          inputProps={{ "aria-label": "ant design" }}
                        />
                        <Typography>Default </Typography>
                      </Stack>
                    </Box>
                    <Autocomplete
                      id="disabled-options-demo"
                      className="time-filed"
                      options={timeSlots}
                      getOptionDisabled={(option) =>
                        option === timeSlots[0] || option === timeSlots[2]
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Start at" />
                      )}
                    />

                    <Autocomplete
                      id="disabled-options-demo"
                      className="time-filed"
                      options={timeSlots}
                      getOptionDisabled={(option) =>
                        option === timeSlots[0] || option === timeSlots[2]
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Start at" />
                      )}
                    />
                  </Box>
                </Box>
                <Box className="weakday-section weak-box-border d-flex align-items-start justify-content-between">
                  <Shift />
                </Box>
              </Box>
            )}
            <Box className="shift-type-section d-flex align-items-center">
              <Typography
                onClick={() => setShow3(!show3)}
                className="d-flex align-items-center shift-select shift-type-section-box"
              >
                <span>Sleepover</span> <ArrowDropDownIcon sx={{ ml: 1 }} />
              </Typography>
              <FormControl className="shift-formcontrol-box ">
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Weekly"
                  name="radio-buttons-group"
                  className="d-flex align-items-center flex-row justify-content-between"
                >
                  <FormControlLabel
                    value="Weekly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Weekly"
                  />
                  <FormControlLabel
                    value="Fortnightly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Fortnightly"
                  />
                  <FormControlLabel
                    value="Monthly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Monthly"
                  />
                  <FormControlLabel
                    value="Quarterly"
                    className="shift-checkbox form-text"
                    control={<Radio />}
                    label="Quarterly"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            {show3 ? (
              show3
            ) : (
              <Box>
                <Box className="shift-type-section d-flex align-items-center ">
                  <Box className="shift-type-section-box">
                    <Typography className="form-text">Current Week</Typography>
                  </Box>
                  <Box className="d-flex align-items-center justify-content-between switch-box">
                    <Box
                      className="appbar-switch appbar-menu-item"
                      onClick={() => setCheck3(check3)}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                          id="switch"
                          inputProps={{ "aria-label": "ant design" }}
                        />
                        <Typography>Default </Typography>
                      </Stack>
                    </Box>
                    <Autocomplete
                      id="disabled-options-demo"
                      className="time-filed"
                      options={timeSlots}
                      getOptionDisabled={(option) =>
                        option === timeSlots[0] || option === timeSlots[2]
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Start at" />
                      )}
                    />

                    <Autocomplete
                      id="disabled-options-demo"
                      className="time-filed"
                      options={timeSlots}
                      getOptionDisabled={(option) =>
                        option === timeSlots[0] || option === timeSlots[2]
                      }
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Start at" />
                      )}
                    />
                  </Box>
                </Box>
                <Box className="weakday-section weak-box-border d-flex .align-items-start justify-content-between">
                  <Shift />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box className="agency-btn-section">
          <a href="#cancel">Previous</a>
          <a href="#save" onClick={handleClickOpen}>
            Save Details
          </a>
        </Box>
      </Box>

      {/* Modal start   */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="modal-content"
          >
            <Typography>Update 12:00 to 17:00 availability for:</Typography>

            <Box>
              <FormGroup className="d-flex flex-row align-items-center">
                <FormControlLabel control={<Checkbox />} label="Mon" />
                <FormControlLabel control={<Checkbox />} label="Tue" />
                <FormControlLabel control={<Checkbox />} label="Wed" />
                <FormControlLabel control={<Checkbox />} label="Thur" />
                <FormControlLabel control={<Checkbox />} label="Fri" />
                <FormControlLabel control={<Checkbox />} label="Sat" />
                <FormControlLabel control={<Checkbox />} label="Sun" />
              </FormGroup>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="modal-button">
          <Box className="agency-btn-section">
            <a href="#cancel" onClick={handleClose}>
              Skip
            </a>
            <a href="#save" onClick={handleClose} autoFocus>
              Update
            </a>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
