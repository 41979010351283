import React from "react";
import { Box, Typography } from "@mui/material";
import "../assets/css/web-css.css";
import "../assets/css/dashboard.css";
import { Grid } from "@mui/material";
import "./HomeUI/home-ui.css";
import footerlogo from "../assets/images/web-footer-logo.png";
import Linkdin from "../assets/images/linkdin-icon.svg";
import facebook from "../assets/images/facebook-icon.svg";
import twitter from "../assets/images/twitter-icon.svg";
import EmailIcon from '@mui/icons-material/Email';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import { Link } from "react-router-dom";

// list import

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { getWebsiteManagementListDetails } from "../api";
import { useState, useEffect } from "react";
import axios from "../api/axios";
import { useFormik } from "formik";
export default function WebFooter() {
  let userData = JSON.parse(localStorage.getItem("userData"));
  const [listData, setListData] = useState([]);
  useEffect(() => {
    getaList();
  }, []);

  const getaList = async () => {
    axios
      .get(`${getWebsiteManagementListDetails}?page=1&items_per_page=10`)
      .then((res) => {
        setListData(res?.data?.data?.docs?.[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Box className="web-footer" id="webfooter">
        <Box className="webFooter-bg">
          <Box className="webContainer">
            <Grid container className="justify-content-between">
              <Grid item xs={12} sm={12} md={2} className="text-start">
                <Box className="footer-logo">
                  <img src={footerlogo} />
                </Box>
                <div className="d-flex align-items-center">
                  <a href="mailto:Info@Hubshift.au?Subject=Hubshift%20Help%20Support" className="d-flex text-black">
                    <EmailIcon /><Typography sx={{ ml: 2 }} style={{ fontSize: "18px" }} ><b><u>Info@Hubshift.au</u></b></Typography>
                  </a>
                </div>
                <div className="d-flex align-items-center mt-4">
                  <a href="tel:1800774954" className="d-flex text-black">
                    <WifiCalling3Icon /><Typography sx={{ ml: 2 }} style={{ fontSize: "18px" }} ><b><u>1800 774 954</u></b></Typography>
                  </a>
                </div>
                {/* <Typography>
                  Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet. Lorem
                  ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                </Typography> */}
                <Typography className="mt-4" style={{ fontSize: "18px" }}><b>ABN: 37 656 268 450</b></Typography>
                <Box className="d-flex align-items-center webfooter-icons space-pt-40">
                  <a
                    href={listData?.socialMediaLinks?.linkedin}
                    target="_blank"
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.56774 6.59727C5.67544 6.59727 6.57341 5.6993 6.57341 4.5916C6.57341 3.4839 5.67544 2.58594 4.56774 2.58594C3.46004 2.58594 2.56207 3.4839 2.56207 4.5916C2.56207 5.6993 3.46004 6.59727 4.56774 6.59727Z"
                        fill="#202E2A"
                      />
                      <path
                        d="M8.4661 8.11875V19.2462H11.921V13.7434C11.921 12.2914 12.1942 10.8852 13.9945 10.8852C15.7701 10.8852 15.7921 12.5453 15.7921 13.8351V19.2471H19.2489V13.1448C19.2489 10.1473 18.6035 7.84375 15.1 7.84375C13.4179 7.84375 12.2904 8.76683 11.8294 9.64042H11.7826V8.11875H8.4661ZM2.83594 8.11875H6.29635V19.2462H2.83594V8.11875Z"
                        fill="#202E2A"
                      />
                    </svg>
                  </a>

                  <a
                    href={listData?.socialMediaLinks?.facebook}
                    target="_blank"
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.51389 11.0949V20.2431H12.5486V11.2378H15.0556L15.4514 7.95023H12.5486V5.94908C12.5486 4.9485 12.8125 4.37674 14 4.37674H15.5833V1.51794C15.3194 1.51794 14.3958 1.375 13.3403 1.375C11.0972 1.375 9.51389 2.94734 9.51389 5.6632V7.95023H6.875V11.0949H9.51389Z"
                        fill="#202E2A"
                      />
                    </svg>
                  </a>

                  {/* <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.7237 2.0625H19.756L13.1313 9.63417L20.9248 19.9375H14.8225L10.043 13.6886L4.57417 19.9375H1.54001L8.62584 11.8387L1.14951 2.0625H7.40667L11.7269 7.77425L16.7237 2.0625ZM15.6594 18.1225H17.3397L6.49367 3.78217H4.69059L15.6594 18.1225Z"
                      fill="#202E2A"
                    />
                  </svg> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={9} className="footer-listMobile">
                <Grid container className="justify-content-between ">
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    className="footer-list-wrapper d-flex footer-listMobile-align justify-content-end"
                  >
                    <Box className="footer-listWrapBox">
                      <Typography className="text-start "> Company </Typography>
                      <nav aria-label="secondary mailbox folders">
                        <List className="footer-list">
                          <ListItem disablePadding >
                            <ListItemButton component="a" href="#simple-list">
                              <a href="" to="/about">
                                {" "}
                                <ListItemText primary="About" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding className="mt-2">
                            <ListItemButton component="a" href="#simple-list">
                              <a href="/contact">
                                {" "}
                                <ListItemText primary="Contact" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding className="mt-2">
                            <ListItemButton component="a" href="#simple-list">
                              <a href="/pricing" >
                                {" "}
                                <ListItemText primary="Pricing" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding className="mt-2">
                            <ListItemButton component="a" href="/terms">
                              <ListItemText primary="Terms & Conditions" />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding className="mt-2">
                            <ListItemButton component="a" href="/privacy-policy">
                              <ListItemText primary="Privacy Policy" />
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </nav>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    className="footer-list-wrapper footer-listMobile-align d-flex justify-content-end"
                  >
                    <Box className="footer-listWrapBox">
                      <Typography className="text-start ">
                        {" "}
                        Features{" "}
                      </Typography>
                      <nav aria-label="secondary mailbox folders">
                        <List className="footer-list">
                          <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                              <a href="/sil-management" >
                                {" "}
                                <ListItemText primary="SIL Management" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding className="mt-2">
                            <ListItemButton component="a" href="#simple-list">
                              <a href="/roster-management" >
                                {" "}
                                <ListItemText primary="Roster Management" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding className="mt-2">
                            <ListItemButton component="a" href="#simple-list">
                              <a href="/staff-induction" >
                                {" "}
                                <ListItemText primary="Staff Induction" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding className="mt-2">
                            <ListItemButton component="a" href="#simple-list">
                              <a href="/document-passport" >
                                {" "}
                                <ListItemText primary="Document Passport" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding className="mt-2">
                            <ListItemButton component="a" href="#simple-list">
                              <a href="/health-dashboard" >
                                {" "}
                                <ListItemText primary="Health Dashboard" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </nav>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    className="footer-list-wrapper d-flex footer-listMobile-align justify-content-end"
                  >
                    <Box className="footer-listWrapBox">
                      <Typography className="text-start ">
                        Marketplace
                      </Typography>
                      <nav aria-label="secondary mailbox folders">
                        <List className="footer-list">
                          {(userData === null ||
                            userData.role === "healthCarer") && (
                              <ListItem disablePadding>
                                <ListItemButton component="a">
                                  <a href="/pickup-shifts">
                                    <ListItemText primary="Pick Up Shifts" />
                                  </a>
                                </ListItemButton>
                              </ListItem>
                            )}
                          {(userData === null ||
                            userData.role === "client") && (
                              <ListItem disablePadding className="mt-2">
                                <ListItemButton component="a">
                                  <a href="/get-support">
                                    <ListItemText primary="Get Support" />
                                  </a>
                                </ListItemButton>
                              </ListItem>
                            )}
                        </List>
                      </nav>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    className="footer-list-wrapper footer-listMobile-align d-flex justify-content-end"
                  >
                    <Box className="footer-listWrapBox">
                      <Typography className="text-start ">
                        {" "}
                        Resources{" "}
                      </Typography>
                      <nav aria-label="secondary mailbox folders">
                        <List className="footer-list">
                          <ListItem disablePadding>
                            <ListItemButton component="a">
                              <a href="/blogs">
                                {" "}
                                <ListItemText primary="Blogs" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding className="mt-2">
                            <ListItemButton component="a">
                              <a href="/guides">
                                {" "}
                                <ListItemText primary="Guides" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding className="mt-2">
                            <ListItemButton component="a">
                              <a href="/help-center" >
                                {" "}
                                <ListItemText primary="Help Center" />
                              </a>
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </nav>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


          </Box>
          <hr className="mt-4"></hr>
          <Box className="text-center space-pt-40">
            <Typography className="copyright-text">
              Copyright @Hubshift 2024 - All Reserved | Site developed by Codeplateau
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
