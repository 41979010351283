import React, { useState } from "react";
import Box from "@mui/material/Box";
import "../../pages/superAdmin/Timesheet/timesheet.css";
import Appbar from "../../Dashboard/Layout/Appbar";
import Sidebar from "../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";
import Autocomplete from "@mui/material/Autocomplete";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import client from "../../../assets/images/client.jpg";
import clientProfile from "../../assets/images/client-profile.jpg";
import call from "../../assets/images/call.svg";
import location from "../../assets/images/location.svg";
import mail from "../../assets/images/mail.svg";
import mapIcon from "../../assets/images/mapIcon.svg";
import RoutineIcon from "../../assets/images/routine-icon.svg";
import Progress from "../../components/ui/Progress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import mapLocation from "../../assets/images/map-location.svg";
import addLocation from "../../assets/images/add-location.svg";
import healthCarer from "../../assets/images/health-carer.svg";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import coordinator from "../../assets/images/coordinator.svg";
import supervisor from "../../assets/images/supervisor.svg";
import cancel from "../../assets/images/cancel.svg";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import verified from "../../assets/images/verified.svg";
import VerifiedIcon from "@mui/icons-material/Verified";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import meal from "../../assets/images/meal.svg";
import medicine from "../../assets/images/medicine.svg";
import exercise from "../../assets/images/exercise.svg";
import CloseIcon from "@mui/icons-material/Close";

import { useEffect } from "react";
// import axios from "axios";
import axios from "../../api/axios";
import { useFormik } from "formik";
import {
  // addSeverity,
  // editSeverity,
  AddAppointment,
  getClientlist,
  ListRegion,
  ListSupporttype,
  ListSupportgroup,
  getCategory,
  //  getClientDetailsByUserId,
  Appointmenttype,
  getClientdetails,
} from "../../api";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const repeat = [
  { label: "1" },
  { label: "2" },
  { label: "3" },
  { label: "4" },
  { label: "5" },
  { label: "6" },
  { label: "7" },
];

const week = [
  { label: "Mon" },
  { label: "Tue" },
  { label: "Wed" },
  { label: "Thu" },
  { label: "Fri" },
  { label: "Sat" },
  { label: "Sun" },
];

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];

export default function AddExpenses(props) {
  const [client, setClient] = useState([]);
  const [region, setRegion] = useState([]);
  const [stype, setStype] = useState([]);
  const [appinttype, setAppinttype] = useState([]);
  const [category, setCategory] = useState([]);
  const [sgroup, setSgroup] = useState([]);
  const [clientDetail, setClientDetail] = useState([]);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Appointments</Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography>Add Appointment</Typography>
    </Link>,
  ];

  const [value, setValue] = React.useState();

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [showResults, setShowResults] = React.useState(false);
  const [isActive, setIsActive] = useState(false);

  function toogleShow() {
    setShowResults(!showResults);
    setIsActive((current) => !current);
  }
  const initialValues = {
    // title: data !== undefined ? data.title : "",
    // assignedToId:data !== undefined ? data.assignedToId : "",
    // //  StackHolderName:data !== undefined ? data.StackHolderName : "",
    //  document:data !== undefined ? data.document : "",
    //  dueDate:data !== undefined ? data.dueDate : "",
    // status:data !== undefined ? data.status : "",
    //  clientId:data !== undefined ? data.clientId : "",
    //   // ClientName:data !== undefined ? data.ClientName : "",
    // note:data !== undefined ? data.note : "",
    //    isPrivate: data !== undefined ? data.isPrivate : "false",
  };

  const validationSchema = Yup.object().shape({
    //  title: Yup.string().required("Title is Required"),
    //   assignedToId: Yup.string().required("Steackholder is Required"),
    //     note: Yup.string().required("Description is Required"),
    //     dueDate: Yup.string().required("Date is Required"),
    //      clientId: Yup.string().required("Client is Required"),
    //      status: Yup.string().required("Status is Required"),
  });

  const getPlanDetails = (userId) => {
    axios
      .get(getClientdetails + "/" + userId)
      //   .get(`${getClientdetails}/64252c455c20ce74509fbe3a`)
      .then((res) => {
        const data = res.data.data[0]?.stakeHolderDetails;
        if (data !== undefined && data.length > 0) {
          //    setField("stakeHolderList", data);
          setClientDetail(data);
        }
        setisLoading(false);
      })
      .catch((error) => {
        setisLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    axios

      .get(getClientlist)

      .then(async (res) => {
        console.log(res.data.data);
        let clientData = res?.data?.data;

        let data = [];

        data.push({
          label: `Select`,

          id: "",
        });
        clientData &&
          clientData.map((client) => {
            data.push({ label: `${client.clientFullName}`, id: client._id });
          });
        setClient(data);

        //    topFilms=[...data]
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const setAddressClientFunction = async (clientId, setFieldValue) => {
    if (clientId) {
      setFieldValue("clientId", clientId.id);

      axios
        //   .get(getClientdetails + "/" + userId)
        .get(`${getClientdetails}/${clientId.id}`)
        .then((res) => {
          const data = res?.data?.data;

          console.log(data, "???????");
          if (data !== undefined && data.length > 0) {
            //    setField("stakeHolderList", data);
            setClientDetail(data);
            toogleShow();
          }
          setisLoading(false);
        })
        .catch((error) => {
          setisLoading(false);
          console.log(error);
        });
    }
  };
  //   useEffect(() => {
  //     console.log( clientId.id)
  //   }, [ clientId.id]);

  useEffect(() => {
    axios

      .get(ListRegion)

      .then(async (res) => {
        console.log(res.data.data);
        let regionData = res?.data?.data;

        let data = [];

        data.push({
          label: `Select`,

          id: "",
        });
        regionData &&
          regionData.map((region) => {
            data.push({ label: `${region.Region}`, id: region._id });
          });
        setRegion(data);
        //    topFilms=[...data]
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const setRegionFunction = async (regionId, setFieldValue) => {
    if (regionId) {
      setFieldValue("regionId", regionId.id);
    }
  };

  useEffect(() => {
    axios

      .get(ListSupporttype)

      .then(async (res) => {
        console.log(res.data.data);
        let StypeData = res?.data?.data;

        let data = [];

        data.push({
          label: `Select`,

          id: "",
        });
        StypeData &&
          StypeData.map((SupportType) => {
            data.push({
              label: `${SupportType.SupportType}`,
              id: SupportType._id,
            });
          });
        setStype(data);
        //    topFilms=[...data]
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const setSsupporttypeFunction = async (supportTypeId, setFieldValue) => {
    if (supportTypeId) {
      setFieldValue("supportTypeId", supportTypeId.id);
    }
  };

  useEffect(() => {
    axios

      .get(ListSupportgroup)

      .then(async (res) => {
        console.log(res.data.data);
        let SgroupData = res?.data?.data;

        let data = [];

        data.push({
          label: `Select`,

          id: "",
        });
        SgroupData &&
          SgroupData.map((SupportGroup) => {
            data.push({
              label: `${SupportGroup.SupportGroup}`,
              id: SupportGroup._id,
            });
          });
        setSgroup(data);
        //    topFilms=[...data]
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const setSsupportgroupFunction = async (suportGroupId, setFieldValue) => {
    if (suportGroupId) {
      setFieldValue("suportGroupId", suportGroupId.id);
    }
  };

  useEffect(() => {
    axios

      .get(Appointmenttype)

      .then(async (res) => {
        console.log(res.data.data);
        let AppintData = res?.data?.data;

        let data = [];

        data.push({
          label: `Select`,

          id: "",
        });
        AppintData &&
          AppintData.map((AppoitmentType) => {
            data.push({
              label: `${AppoitmentType.AppoitmentType}`,
              id: AppoitmentType._id,
            });
          });
        setAppinttype(data);
        //    topFilms=[...data]
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const setAppntFunction = async (appointmentTypeId, setFieldValue) => {
    if (appointmentTypeId) {
      setFieldValue("appointmentTypeId", appointmentTypeId.id);
    }
  };

  useEffect(() => {
    axios

      .get(getCategory)

      .then(async (res) => {
        console.log(res.data.data);
        let CategoryData = res?.data?.data;

        let data = [];

        data.push({
          label: `Select`,

          id: "",
        });
        CategoryData &&
          CategoryData.map((Categoryname) => {
            data.push({
              label: `${Categoryname.Categoryname}`,
              id: Categoryname._id,
            });
          });
        setCategory(data);
        //    topFilms=[...data]
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const setCategoryFunction = async (categoryDetailsId, setFieldValue) => {
    if (categoryDetailsId) {
      setFieldValue("categoryDetailsId", categoryDetailsId.id);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      console.log(values, "++++++==>");

      axios
        .post(`${AddAppointment}`, values)

        .then((res) => {
          if (res.data.success === false) {
            toast.error(res.data.message);
          }
          if (res.data.success === true) {
            toast.success("New Record Inserted");
            setTimeout(() => {
              alert("ok");
              //  navigate("/list-task");
            }, 2000);
          }
        })
        .catch((error) => {
          toast.error("Something went wrong");
        });
    },
  });

  const HandleDateEvent = (e, docName) => {
    const name = docName;
    formik.setFieldValue(name, new Date(e));
  };

  const [routineOpen, setOpen] = React.useState(false);

  const routineClickOpen = () => {
    setOpen(true);
  };

  const routineClose = () => {
    setOpen(false);
  };

  console.log(clientDetail, "//////////");

  const [showRepeat, setShowRepeat] = React.useState(false);
  const onWeekShow = () => setShowRepeat(true);
  const onWeekHide = () => setShowRepeat(false);

  return (
    <Box className="dashboard-form" id="add-appointment">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box className=" d-flex dashboard-main-box">
        <Sidebar />

        <Box className="main">
          <Box className="dashboard-form-typo dashboard-form-typo-up">
            <h3>Appointments</h3>
            <Box>
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {breadcrumbs}
                </Breadcrumbs>
              </Stack>
            </Box>
          </Box>
          <Box className="dashboard-form-typo dashboard-form-typo-sec  dashboard-form-typo-up">
            <h4 className="d-flex align-items-center ">
              {" "}
              <ArrowBackIosIcon /> Add Appointment
            </h4>
          </Box>

          <Box>
            <Box className="bg-strip">
              <Typography>Client Details</Typography>
            </Box>
          </Box>

          <Box sx={{ mt: 8 }}>
            <Grid
              container
              spacing={4}
              className="align-items-end add-appointment-input-box"
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className="input flex-column d-flex pt-0"
              >
                <label>Client</label>
                {/* <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={client}
                                    size="small"
                                    renderInput={(params) => <TextField placeholder="Select" {...params} sx={{ mt: 2 }} />}
                                /> */}
                <Autocomplete
                  //                                 disabled

                  name="clientId"
                  disablePortal
                  id="combo-box-demo"
                  sx={{ mt: 2, mb: 2 }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Select Client"
                      {...params}
                      error={
                        formik?.touched?.clientId &&
                        Boolean(formik?.errors?.clientId)
                      }
                      helperText={
                        formik?.touched?.clientId && formik?.errors?.clientId
                      }
                    />
                  )}
                  size="small"
                  options={client}
                  onChange={(event, newInputValue) => {
                    // setField('inputValue', newInputValue)

                    setAddressClientFunction(
                      newInputValue,
                      formik.setFieldValue
                    );
                  }}
                  value={
                    formik?.values?.clientId !== undefined &&
                    formik?.values?.clientId !== ""
                      ? client.find(
                          (option) => option.id === formik?.values?.clientId
                        )
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Box>

          {/* hide and show client details section  */}

          <Box className="hide-client-details main-padding">
            <Box>
              <Typography
                onClick={toogleShow}
                className="d-flex hide-client-drop align-items-center"
              >
                Hide Client Details{" "}
                <span className="hide-client-arrow">
                  <KeyboardArrowUpIcon
                    className={isActive ? "rotate-arrow" : ""}
                  />
                </span>{" "}
              </Typography>
            </Box>

            {showResults && (
              <Box className="hide-box">
                <Grid
                  container
                  spacing={4}
                  className="align-items-start add-appointment-input-box"
                >
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3}
                    className="input flex-column hide-client-img-main d-flex pt-0"
                  >
                    <Box className="hide-client-img-box">
                      <Box className="hide-client-img">
                        <img src={clientProfile}></img>
                      </Box>
                      <Typography>
                        {clientDetail[0]?.userFullName},{" "}
                        {clientDetail[0]?.gender}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={8}
                    className="input flex-column d-flex pt-0"
                  >
                    <Grid container spacing={4} className="align-items-start">
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <Box className="hide-content">
                            <Typography>
                              Date of Birth <span>{clientDetail[0]?.DOB}</span>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              {" "}
                              <img src={call}></img>{" "}
                              <a href="#">{clientDetail[0]?.mobileNumber}</a>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>Plan Managed</Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              Category <span>Homecare</span>
                            </Typography>
                          </Box>
                          <Box className="hide-content pb-0">
                            <Typography>Agreement Period</Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <Box className="hide-content">
                            <Typography>
                              <img src={location}></img> VLC, Brisbane
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              {" "}
                              <img src={mail}></img>{" "}
                              <a href="#"> {clientDetail[0]?.Email}</a>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              NDIS No.{" "}
                              <span> {clientDetail[0]?.ndisNumber}</span>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              Status <span>New</span>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Box>
                          <Box className="hide-content">
                            <Box className="mapicon">
                              {" "}
                              <img src={mapIcon}></img>{" "}
                            </Box>
                          </Box>
                          <Box className="hide-content">
                            <a href="#">
                              Map <br /> View
                            </a>
                          </Box>

                          <Box className="hide-content">
                            <Typography>
                              NDIS Zone <span>NSW</span>
                            </Typography>
                          </Box>
                          <Box className="hide-content">
                            <Typography>
                              Funding <span>$1244.67</span>
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} className="pt-0">
                        <Box className="hide-client-progress">
                          <Progress />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>

          <Grid
            container
            spacing={4}
            className="align-items-start add-appointment-input-box"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <label>Funding Source</label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                renderInput={(params) => (
                  <TextField
                    placeholder="Select Funding Source"
                    {...params}
                    sx={{ mt: 2 }}
                  />
                )}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <label>Region</label>

              <Autocomplete
                name="regionId"
                disablePortal
                id="combo-box-demo"
                sx={{ mt: 2, mb: 2 }}
                renderInput={(params) => (
                  <TextField
                    placeholder="Select Region"
                    {...params}
                    error={
                      formik?.touched?.regionId &&
                      Boolean(formik?.errors?.regionId)
                    }
                    helperText={
                      formik?.touched?.regionId && formik?.errors?.regionId
                    }
                  />
                )}
                size="small"
                options={region}
                onChange={(event, newInputValue) => {
                  // setField('inputValue', newInputValue)

                  setRegionFunction(newInputValue, formik.setFieldValue);
                }}
                value={
                  formik?.values?.regionId !== undefined &&
                  formik?.values?.regionId !== ""
                    ? region.find(
                        (option) => option.id === formik?.values?.regionId
                      )
                    : ""
                }
              />
            </Grid>
          </Grid>

          <Box>
            <Box className="bg-strip service-strip">
              <Typography>Service Details</Typography>
            </Box>
          </Box>

          <Grid
            container
            spacing={4}
            className="align-items-start add-appointment-input-box"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <label>Category</label>

              <Autocomplete
                name="categoryDetailsId"
                disablePortal
                id="combo-box-demo"
                sx={{ mt: 2, mb: 2 }}
                renderInput={(params) => (
                  <TextField
                    placeholder="Select Category"
                    {...params}
                    error={
                      formik?.touched?.categoryDetailsId &&
                      Boolean(formik?.errors?.categoryDetailsId)
                    }
                    helperText={
                      formik?.touched?.categoryDetailsId &&
                      formik?.errors?.categoryDetailsId
                    }
                  />
                )}
                size="small"
                options={category}
                onChange={(event, newInputValue) => {
                  // setField('inputValue', newInputValue)

                  setCategoryFunction(newInputValue, formik.setFieldValue);
                }}
                value={
                  formik?.values?.categoryDetailsId !== undefined &&
                  formik?.values?.categoryDetailsId !== ""
                    ? category.find(
                        (option) =>
                          option.id === formik?.values?.categoryDetailsId
                      )
                    : ""
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <label>Support Type</label>

              <Autocomplete
                name="supportTypeId"
                disablePortal
                id="combo-box-demo"
                sx={{ mt: 2, mb: 2 }}
                renderInput={(params) => (
                  <TextField
                    placeholder="Select Support Type"
                    {...params}
                    error={
                      formik?.touched?.supportTypeId &&
                      Boolean(formik?.errors?.supportTypeId)
                    }
                    helperText={
                      formik?.touched?.supportTypeId &&
                      formik?.errors?.supportTypeId
                    }
                  />
                )}
                size="small"
                options={stype}
                onChange={(event, newInputValue) => {
                  // setField('inputValue', newInputValue)

                  setSsupporttypeFunction(newInputValue, formik.setFieldValue);
                }}
                value={
                  formik?.values?.supportTypeId !== undefined &&
                  formik?.values?.supportTypeId !== ""
                    ? stype.find(
                        (option) => option.id === formik?.values?.supportTypeId
                      )
                    : ""
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ mt: 2 }}
              className="input flex-column d-flex pt-0"
            >
              <label>Group Name</label>

              <Autocomplete
                name="suportGroupId"
                disablePortal
                id="combo-box-demo"
                sx={{ mt: 2, mb: 2 }}
                renderInput={(params) => (
                  <TextField
                    placeholder="Select Support Type"
                    {...params}
                    error={
                      formik?.touched?.suportGroupId &&
                      Boolean(formik?.errors?.suportGroupId)
                    }
                    helperText={
                      formik?.touched?.suportGroupId &&
                      formik?.errors?.suportGroupId
                    }
                  />
                )}
                size="small"
                options={sgroup}
                onChange={(event, newInputValue) => {
                  // setField('inputValue', newInputValue)

                  setSsupportgroupFunction(newInputValue, formik.setFieldValue);
                }}
                value={
                  formik?.values?.suportGroupId !== undefined &&
                  formik?.values?.suportGroupId !== ""
                    ? sgroup.find(
                        (option) => option.id === formik?.values?.suportGroupId
                      )
                    : ""
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ mt: 2 }}
              className="input flex-column d-flex pt-0"
            >
              <label>Support Category Name</label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                renderInput={(params) => (
                  <TextField
                    placeholder="Select Support Category Name"
                    {...params}
                    sx={{ mt: 2 }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ mt: 2 }}
              className="input flex-column d-flex pt-0"
            >
              <label>Support Item Name</label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                renderInput={(params) => (
                  <TextField
                    placeholder="Select Support Item Name"
                    {...params}
                    sx={{ mt: 2 }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Box>
            <Box className="bg-strip service-strip">
              <Typography>Appointment Details</Typography>
            </Box>
          </Box>

          <Grid
            container
            spacing={4}
            className="align-items-start add-appointment-input-box"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="input flex-column d-flex pt-0"
            >
              <label>Appointment Type</label>

              <Autocomplete
                name="appointmentTypeId"
                disablePortal
                id="combo-box-demo"
                sx={{ mt: 2, mb: 2 }}
                renderInput={(params) => (
                  <TextField
                    placeholder="Select Appointment Type"
                    {...params}
                    error={
                      formik?.touched?.appointmentTypeId &&
                      Boolean(formik?.errors?.appointmentTypeId)
                    }
                    helperText={
                      formik?.touched?.appointmentTypeId &&
                      formik?.errors?.appointmentTypeId
                    }
                  />
                )}
                size="small"
                options={appinttype}
                onChange={(event, newInputValue) => {
                  // setField('inputValue', newInputValue)

                  setAppntFunction(newInputValue, formik.setFieldValue);
                }}
                value={
                  formik?.values?.appointmentTypeId !== undefined &&
                  formik?.values?.appointmentTypeId !== ""
                    ? appinttype.find(
                        (option) =>
                          option.id === formik?.values?.appointmentTypeId
                      )
                    : ""
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={4}
            className="align-items-start add-appointment-input-box"
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ mt: 3 }}
              className="align-items-start"
            >
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  className="input d-flex flex-column pt-0  "
                >
                  <label> Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <DesktopDatePicker
                        className="form-input date-input-filed"
                        inputFormat="DD-MM-YYYY"
                        maxDate={new Date()}
                        value={formik?.values?.appointmentDetails?.date}
                        onChange={(e) =>
                          HandleDateEvent(e, "appointmentDetails.date")
                        }
                        onBlur={formik?.handleBlur}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            sx={{ mt: 2, mb: 2 }}
                            name="appointmentDetails.date"
                            // sx={{ mb: 1 }}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD-MM-YY",
                            }}
                            error={
                              formik?.touched?.appointmentDetails?.date &&
                              Boolean(formik?.errors?.appointmentDetails?.date)
                            }
                            helperText={
                              formik?.touched?.appointmentDetails?.date &&
                              formik?.errors?.appointmentDetails?.date
                            }
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  className="input d-flex flex-column pt-0"
                >
                  <label>Start Time</label>
                  <TextField
                    sx={{ mt: 2, mb: 2, width: "100%" }}
                    size="small"
                    className="form-input"
                    required
                    id="outlined-required"
                    type="time"
                    ampm={false}
                    name="timeDuration.startTime"
                    placeholder="Enter Start Time"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.timeDuration?.startTime}
                    error={
                      formik?.touched?.timeDuration?.startTime &&
                      Boolean(formik?.errors?.timeDuration?.startTime)
                    }
                    helperText={
                      formik?.touched?.timeDuration?.startTime &&
                      formik?.errors?.timeDuration?.startTime
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  className="input d-flex flex-column pt-0"
                >
                  <label>End Time</label>
                  <TextField
                    sx={{ mt: 2, mb: 2, width: "100%" }}
                    size="small"
                    className="form-input"
                    required
                    id="outlined-required"
                    type="time"
                    name="timeDuration.endTime"
                    placeholder="Enter End Time"
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    value={formik?.values?.timeDuration?.endTime}
                    error={
                      formik?.touched?.timeDuration?.endTime &&
                      Boolean(formik?.errors?.timeDuration?.endTime)
                    }
                    helperText={
                      formik?.touched?.timeDuration?.endTime &&
                      formik?.errors?.timeDuration?.endTime
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              className="add-appoint-radio-group"
            >
              <label>Repeat</label>
              <Box className="d-flex">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    className="radio"
                    control={<Radio />}
                    label="Yes"
                    onClick={onWeekShow}
                  />
                  <FormControlLabel
                    value="male"
                    className="radio"
                    control={<Radio />}
                    label="No"
                    onClick={onWeekHide}
                  />
                </RadioGroup>
              </Box>
              {showRepeat ? (
                <Box className="repeat-week-section">
                  <Box className="d-flex align-items-center">
                    <Box className="d-flex align-items-center">
                      <Typography>Repeat every</Typography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={repeat}
                        value={"1"}
                        disableClearable
                        className="repeat-every-select"
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                    <Box className="d-flex align-items-center">
                      <Typography>Week</Typography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={week}
                        disableClearable
                        className="repeat-every-select"
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                  </Box>
                  <Box className="repeat-weeks d-flex align-items-center">
                    <Box>
                      <span>S</span>
                    </Box>
                    <Box>
                      <span>M</span>
                    </Box>
                    <Box>
                      <span>T</span>
                    </Box>
                    <Box>
                      <span>W</span>
                    </Box>
                    <Box>
                      <span>T</span>
                    </Box>
                    <Box>
                      <span>F</span>
                    </Box>
                    <Box>
                      <span>S</span>
                    </Box>
                  </Box>
                  <Box className="d-flex align-items-center">
                    <Typography>Occurs every Monday until</Typography>
                    <Box className="repeat-section-calender">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            className="form-input date-input-filed"
                            format="DD-MM-YYYY"
                            value={value}
                            onChange={handleChange}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                sx={{
                                  mt: 2,
                                  mb: 2,
                                }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "DD-MM-YY",
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Grid>

            <Box className="checkbox-padding">
              <FormControlLabel
                control={<Checkbox />}
                label="Next day overnight (sleepover shift)"
              />
            </Box>
          </Grid>

          <Grid container spacing={4} className="add-appointment-input-box">
            <Grid item xs={12} sm={6} md={6}>
              <Grid spacing={4}>
                <Grid item xs={12} sm={6} md={6}>
                  <label>
                    {" "}
                    <img src={mapLocation} /> From
                  </label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    size="small"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ mt: 2 }} />
                    )}
                  />
                </Grid>

                <Grid container className="align-items-end" spacing={3}>
                  <Grid item xs={12} sx={{ mt: 2 }} sm={7} md={7}>
                    <label>
                      {" "}
                      <img src={mapLocation} /> To{" "}
                    </label>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={top100Films}
                      size="small"
                      renderInput={(params) => (
                        <TextField {...params} sx={{ mt: 2 }} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 2 }} sm={5} md={5}>
                    <button className="add-location-btn">
                      <img src={addLocation} /> Add Location
                    </button>
                  </Grid>
                </Grid>

                <Grid container className="align-items-end" spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sx={{ mt: 2 }}
                    sm={4}
                    md={4}
                    className="d-flex  flex-column"
                  >
                    <label>KM To</label>
                    <TextField
                      sx={{ mt: 2, mb: 2 }}
                      className="form-input"
                      type="Name"
                      size="small"
                      placeholder="13:00"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ mt: 2 }}
                    sm={4}
                    md={4}
                    className=" flex-column"
                  >
                    <label>KM With</label>
                    <TextField
                      sx={{ mt: 2, mb: 2 }}
                      className="form-input"
                      type="Name"
                      size="small"
                      placeholder="13:00"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ mt: 2 }}
                    sm={4}
                    md={4}
                    className=" flex-column"
                  >
                    <label>Total Distance</label>
                    <TextField
                      sx={{ mt: 2, mb: 2 }}
                      className="form-input"
                      type="Name"
                      size="small"
                      placeholder="13:00"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box className="client-map">
            <Box className="map-modal bg-light">
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    width="100%"
                    height="323px"
                    className="map-iframe"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Sydney&t=&z=10&ie=UTF8&iwloc=&output=embed"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  ></iframe>
                </div>
              </div>
            </Box>
          </Box>

          <Box>
            <Box className="bg-strip service-strip">
              <Typography>Team Details</Typography>
            </Box>
          </Box>

          <Grid container className="add-appointment-input-box " spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <label>
                {" "}
                <img src={healthCarer} /> Health Carer{" "}
              </label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} sx={{ mt: 2 }} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <label>Job Type </label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} sx={{ mt: 2 }} />
                )}
              />
            </Grid>
          </Grid>

          <Box className="checkbox-padding">
            <FormControlLabel
              control={<Checkbox />}
              label="Access to add Routine & Tasks"
            />
          </Box>

          <Box className="skill-box">
            <Box>
              <lable>Skills Required</lable>

              <Grid container spacing={0}>
                <Grid item sx={2} md={2}>
                  <FormControl
                    sx={{ m: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>Domestic </Typography>
                          </>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>Oncology </Typography>
                          </>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item sx={2} md={2}>
                  <FormControl
                    sx={{ m: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>Manual Handling </Typography>
                          </>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>Car Washing </Typography>
                          </>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item sx={2} md={2}>
                  <FormControl
                    sx={{ m: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>DSQ Card </Typography>
                          </>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>Blue Card </Typography>
                          </>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item sx={2} md={2}>
                  <FormControl
                    sx={{ m: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>Police Clerk </Typography>
                          </>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>Spinal </Typography>
                          </>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item sx={2} md={2}>
                  <FormControl
                    sx={{ m: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>Meal Delivery </Typography>
                          </>
                        }
                      />
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>Hair Dressing </Typography>
                          </>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item sx={2} md={2}>
                  <FormControl
                    sx={{ m: 1 }}
                    component="fieldset"
                    variant="standard"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox {...label} />}
                        label={
                          <>
                            <Typography>Health Care </Typography>
                          </>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Grid
            container
            spacing={4}
            className="add-appointment-input-box add-appoint-support-coordinator"
          >
            <Grid item xs={12} sm={6} md={6}>
              <label>
                {" "}
                <img src={coordinator} /> Support Coordinator{" "}
              </label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} sx={{ mt: 2 }} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <label> Experience </label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} sx={{ mt: 2 }} />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
              <label> Job Type </label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} sx={{ mt: 2 }} />
                )}
              />
            </Grid>
            <Box className="checkbox-padding">
              <FormControlLabel
                control={<Checkbox />}
                label="Access to add Routine & Tasks"
              />
            </Box>
          </Grid>

          <Grid
            container
            spacing={4}
            className="add-appointment-input-box add-appoint-support-coordinator"
          >
            <Grid item xs={12} sm={6} md={6}>
              <label>
                {" "}
                <img src={supervisor} /> Supervisor{" "}
              </label>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} sx={{ mt: 2 }} />
                )}
              />
            </Grid>
          </Grid>

          <Box>
            <Box className="bg-strip service-strip">
              <Typography>Routine & Tasks</Typography>
            </Box>
          </Box>

          <Grid
            container
            className="add-appointment-input-box justify-content-between align-items-center"
          >
            <Grid
              item
              xs={12}
              sm={5}
              md={5}
              className="flex-column pt-0 d-flex"
            >
              <label>Title</label>
              <TextField
                sx={{ mt: 2, mb: 2 }}
                className="form-input"
                type="Name"
                size="small"
                placeholder="Meal"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{ mt: 2 }}
              className="d-flex justify-content-between "
            >
              <Box onClick={routineClickOpen} className="routine-icon-box">
                <img src={RoutineIcon} />
                <Typography>Select Routine Icon</Typography>
              </Box>
              <Button variant="outlined" className="outlinedstyle">
                No Routine
              </Button>
              <button className="add-routine-btn">Add Routine</button>
            </Grid>
          </Grid>

          <Box class="routine-stepper-box spaceb-md">
            <Box>
              <span className="routine-chip">
                <img src={medicine} className="iconsm" /> Medicine{" "}
                <img src={cancel} />
              </span>
            </Box>

            <Grid
              container
              sx={{ mt: 2 }}
              className="d-flex align-items-center "
            >
              <Grid item xs={12} sm={12} md={12}>
                <Box className="add-task-box d-flex flex-wrap align-items-center">
                  <Box className="not-task-item">
                    <Typography className="notask-item">
                      No Tasks Added
                    </Typography>
                  </Box>

                  <Box>
                    <button className="stepper-add-task-btn">
                      <AddCircleIcon /> Add Task
                    </button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* Routine stepper section start here  */}

          <Box class="routine-stepper-box spaceb-md">
            <Box>
              <span className="routine-chip">
                <img src={exercise} className="iconsm" /> Exercise{" "}
                <img src={cancel} />
              </span>
            </Box>

            <Grid
              container
              sx={{ mt: 2 }}
              spacing={4}
              className="d-flex align-items-center "
            >
              <Grid item xs={12} sm={12} md={12}>
                <Box className="add-task-box d-flex flex-wrap align-items-center">
                  <Box className="add-task-item">
                    <Typography>Task 1</Typography>
                    <span className="addtask done-task">
                      {" "}
                      <span className="task-num">1</span>{" "}
                    </span>
                    <img src={verified} className="verified-none"></img>
                    <VerifiedIcon className="verified-icon" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 2</Typography>
                    <span className="addtask done-task">
                      {" "}
                      <span className="task-num">2</span>{" "}
                    </span>
                    <img src={verified} className="verified-none"></img>
                    <VerifiedIcon className="verified-icon" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 3</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">3</span>{" "}
                    </span>
                    <img src={cancel}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 4</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">4</span>{" "}
                    </span>
                    <img src={cancel}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 5</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">5</span>{" "}
                    </span>
                    <img src={cancel}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 6</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">6</span>{" "}
                    </span>
                    <img src={cancel}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>
                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 7</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">7</span>{" "}
                    </span>
                    <img src={cancel}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>

                  <Box className="connector-line"></Box>
                  <Box className="add-task-item">
                    <Typography>Task 8</Typography>
                    <span className="addtask ">
                      {" "}
                      <span className="task-num">8</span>{" "}
                    </span>
                    <img src={cancel}></img>
                    <VerifiedIcon className="verified-icon verified-none" />
                  </Box>

                  <Box>
                    <button className="stepper-add-task-btn">
                      <AddCircleIcon /> Add Task
                    </button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box class="routine-stepper-box spaceb-md">
            <Box>
              <span className="routine-chip">
                No Routine <img src={cancel} />
              </span>
            </Box>

            <Grid
              container
              sx={{ mt: 2 }}
              className="d-flex align-items-center "
            >
              <Grid item xs={12} sm={12} md={12}>
                <Box className="add-task-box d-flex flex-wrap align-items-center">
                  <Box className="not-task-item">
                    <Typography className="notask-item">
                      No Tasks Added
                    </Typography>
                  </Box>

                  <Box>
                    <button className="stepper-add-task-btn">
                      <AddCircleIcon /> Add Task
                    </button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Box className="bg-strip service-strip">
              <Typography>Other Details</Typography>
            </Box>
          </Box>

          <Box className="other-details">
            <Grid container spacing={8}>
              <Grid item xs={12} sm={3} md={3} className="flex-column d-flex">
                <Typography>Accounts</Typography>
                <span className="add-settings">Add Settings</span>
              </Grid>
              <Grid item xs={12} sm={3} md={3} className="flex-column d-flex">
                <Typography>Flexible with time?</Typography>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="radio-group"
                >
                  <FormControlLabel
                    value="female"
                    className="radio"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value="male"
                    className="radio"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Box>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ mt: 1 }}
                className="input flex-column d-flex pt-0"
              >
                <label>Tasks</label>

                <TextField
                  id="outlined-basic"
                  sx={{ mt: 2 }}
                  className="w-100 other-textarea"
                  placeholder="$"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ mt: 5 }}
                className="input flex-column d-flex pt-0"
              >
                <label>Notes</label>

                <TextField
                  id="outlined-basic"
                  sx={{ mt: 2 }}
                  className="w-100 other-textarea"
                  placeholder="$"
                />
              </Grid>
            </Box>

            <Box className="agency-btn-section agency-btn-section-vk">
              <Link href="javascript:;">Cancel</Link>
              <Button type="submit">Save</Button>
              {/* <Link type="submit" >Save</Link> */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={routineOpen}
        onClose={routineClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="routine-popup"
      >
        <DialogContent className="hello">
          <DialogContentText
            id="alert-dialog-description"
            className="modal-content"
          >
            <Box className="d-flex justify-content-center add-expenses-p-10">
              <Box className="add-expenses">
                <Box className="add-expenses-head d-flex align-items-center justify-content-between">
                  <Typography className="p-0"> Select Routine Icon</Typography>
                  <span onClick={routineClose}>
                    <CloseIcon />
                  </span>
                </Box>
                <Box className="add-expenses-body">
                  <Box className="d-flex align-items-center">
                    <Box className="routine-popup-images">
                      <label for="rotineexercise" className="rotine-check">
                        <input
                          type="radio"
                          id="rotineexercise"
                          name="rotineselect"
                        />
                        <img src={exercise} />

                        <Typography>Exercise</Typography>
                      </label>
                    </Box>
                    <Box className="routine-popup-images">
                      <label for="rotinemedicine" className="rotine-check">
                        <input
                          type="radio"
                          id="rotinemedicine"
                          name="rotineselect"
                        />
                        <img src={medicine} />
                        <Typography>Medicine</Typography>
                      </label>
                    </Box>
                    <Box className="routine-popup-images">
                      <label for="rotinemeal" className="rotine-check">
                        <input
                          type="radio"
                          id="rotinemeal"
                          name="rotineselect"
                        />
                        <img src={meal} />
                        <Typography>Meal</Typography>
                      </label>
                    </Box>
                    <Box className="routine-popup-images">
                      <label for="rotineexercise" className="rotine-check">
                        <input
                          type="radio"
                          id="rotineexercise"
                          name="rotineselect"
                        />
                        <img src={exercise} />
                        <Typography>Exercise</Typography>
                      </label>
                    </Box>
                    <Box className="routine-popup-images">
                      <label for="rotinemedicine" className="rotine-check">
                        <input
                          type="radio"
                          id="rotinemedicine"
                          name="rotineselect"
                        />
                        <img src={medicine} />
                        <Typography>Medicine</Typography>
                      </label>
                    </Box>
                    <Box className="routine-popup-images">
                      <label for="rotinemeal" className="rotine-check">
                        <input
                          type="radio"
                          id="rotinemeal"
                          name="rotineselect"
                        />
                        <img src={meal} />
                        <Typography>Meal</Typography>
                      </label>
                    </Box>
                  </Box>

                  <Box className="d-flex align-items-center">
                    <Box className="routine-popup-images">
                      <label for="rotineexercise1" className="rotine-check">
                        <input
                          type="radio"
                          id="rotineexercise1"
                          name="rotineselect"
                        />
                        <img src={exercise} />

                        <Typography>Exercise</Typography>
                      </label>
                    </Box>
                    <Box className="routine-popup-images">
                      <label for="rotinemedicine1" className="rotine-check">
                        <input
                          type="radio"
                          id="rotinemedicine1"
                          name="rotineselect"
                        />
                        <img src={medicine} />
                        <Typography>Medicine</Typography>
                      </label>
                    </Box>
                    <Box className="routine-popup-images">
                      <label for="rotinemeal1" className="rotine-check">
                        <input
                          type="radio"
                          id="rotinemeal1"
                          name="rotineselect"
                        />
                        <img src={meal} />
                        <Typography>Meal</Typography>
                      </label>
                    </Box>
                    <Box className="routine-popup-images">
                      <label for="rotineexercise1" className="rotine-check">
                        <input
                          type="radio"
                          id="rotineexercise1"
                          name="rotineselect"
                        />
                        <img src={exercise} />
                        <Typography>Exercise</Typography>
                      </label>
                    </Box>
                    <Box className="routine-popup-images">
                      <label for="rotinemedicine1" className="rotine-check">
                        <input
                          type="radio"
                          id="rotinemedicine1"
                          name="rotineselect"
                        />
                        <img src={medicine} />
                        <Typography>Medicine</Typography>
                      </label>
                    </Box>
                    <Box className="routine-popup-images">
                      <label for="rotinemeal1" className="rotine-check">
                        <input
                          type="radio"
                          id="rotinemeal1"
                          name="rotineselect"
                        />
                        <img src={meal} />
                        <Typography>Meal</Typography>
                      </label>
                    </Box>
                  </Box>
                </Box>
                <DialogActions className="modal-button">
                  <Box className="agency-btn-section">
                    <a href="#cancel" onClick={routineClose}>
                      Cancel
                    </a>
                    <a href="#save" onClick={routineClose} autoFocus>
                      Save
                    </a>
                  </Box>
                </DialogActions>
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
