import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import * as React from "react";
import "../../../App.css";
import "../../../../src/assets/css/custom.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Appbar from "../../../Dashboard/Layout/Appbar";
import Sidebar from "../../../Dashboard/Layout/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import MailIcon from "@mui/icons-material/Mail";
import InputAdornment from "@mui/material/InputAdornment";
import CallIcon from "@mui/icons-material/Call";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "../../../assets/css/dashboard.css";
import Autocomplete from "@mui/material/Autocomplete";

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];

export default function Editagency() {
  return (
    <Box className="main">
     
     <Grid container className="d-flex align-items-center header-topsection">
            <Grid item xs={6} md={6} sm={6}>
              <Box id="breadcrumb">
                <ArrowBackIosIcon />
                <Box className="breadcrumb-inr">
                  <h4 className="d-flex align-items-center ">Group</h4>
                  <Box className="breadcrumbs">
                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                      Group List
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
           
          </Grid>

          <Box className="divider"></Box>


     
        <Box className="dashboard-form-section">
          <Box className="w-100">
            {/* <h3> Agency Name </h3> */}

            <Grid container className="padding-right-20">
              <Grid item xs={12} sm={6} md={6} sx={{pr:2}} className="pt-0 ">
                <label>Title</label>

                <TextField
                  sx={{ mt: 2, mb: 2, width: "100%" }}
                  size="small"
                  className="form-input"
                  required
                  id="outlined-required"
                  type="text"
                  name="name"
                  placeholder="Enter Agency name"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{pl:2}} className="input pt-0">
                <label>Discription</label>

                <TextField
                  size="small"
                  name="email"
                  type="email"
                  sx={{ mt: 2, mb: 2 }}
                  //className='form-input-icon-bg'
                  className="form-input form-input-icon"
                  margin="normal"
                  fullWidth
                  placeholder="Enter Email"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} className="input pt-0 ">
                <label>User Type</label>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="All"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="HealthCares"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Support Coordinators"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Service Provider"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{pr:2}}>
                <label> Region </label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} sx={{ mt: 2 }} />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{pl:2}}>
                <label> Users </label>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} sx={{ mt: 2 }} />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className="agency-btn-section agency-btn-section-vk">
            <a href="list-agencies">Cancel</a>

            <button type="submit"> Save </button>
          </Box>
        </Box>
      
    </Box>
  );
}
