
// import dynamic from "next/dynamic";
import React, { useState, lazy, Suspense } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Box from '@mui/material/Box';
import './appointment.css';
import TaskPending from './Tasks/Task-Pending';
import TaskStarted from './Tasks/Task-Started';
import TaskComplete from './Tasks/Task-Completed';
import TaskRejected from './Tasks/Task-Rejected';


// const Column = dynamic(() => import("./AppointmentDragCreate"), { ssr: true });
const Column = lazy(() => import("./AppointmentDragCreate"));


const reorderColumnList = (sourceCol, startIndex, endIndex) => {
  const newTaskIds = Array.from(sourceCol.taskIds);
  const [removed] = newTaskIds.splice(startIndex, 1);
  newTaskIds.splice(endIndex, 0, removed);


  const newColumn = {
    ...sourceCol,
    taskIds: newTaskIds,
  };

  return newColumn;
};

export default function Home() {

  const [state, setState] = useState(initialData);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    // If user tries to drop in an unknown destination
    if (!destination) return;

    // if the user drags and drops back in the same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // If the user drops within the same column but in a different positoin
    const sourceCol = state.columns[source.droppableId];
    const destinationCol = state.columns[destination.droppableId];

    if (sourceCol.id === destinationCol.id) {
      const newColumn = reorderColumnList(
        sourceCol,
        source.index,
        destination.index
      );

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };
      setState(newState);
      return;
    }

    // If the user moves from one column to another
    const startTaskIds = Array.from(sourceCol.taskIds);
    const [removed] = startTaskIds.splice(source.index, 1);
    const newStartCol = {
      ...sourceCol,
      taskIds: startTaskIds,
    };

    const endTaskIds = Array.from(destinationCol.taskIds);
    endTaskIds.splice(destination.index, 0, removed);
    const newEndCol = {
      ...destinationCol,
      taskIds: endTaskIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol,
      },
    };

    setState(newState);
  };




  return (
    <Box className='appoint-task-wrap' sx={{ width: "100%" }}>
      <DragDropContext onDragEnd={onDragEnd}>

        {state.columnOrder.map((columnId) => {
          const column = state.columns[columnId];
          const tasks = column.taskIds.map((taskId) => state.tasks[taskId]);

          return (
            <Suspense fallback={<div>Loading...</div>}>
              <Column id="darg-column" key={column.id} column={column} tasks={tasks} />
            </Suspense>
          )

        })}

      </DragDropContext>

    </Box>

  );
}

const initialData = {
  tasks: {
    1: {
      id: 1, content: <>
        <TaskPending />
      </>
    },
    2: {
      id: 2, content:
        <>
          <TaskStarted />
        </>

    },
    3: {
      id: 3, content:
        <>
          <TaskComplete />
        </>
    },
    4: {
      id: 4, content:
        <>
          <TaskRejected />
        </>
    }

  },

  columns: {
    "column-1": {
      id: "column-1",

      taskIds: [1],
    },
    "column-2": {
      id: "column-2",

      taskIds: [2],
    },
    "column-3": {
      id: "column-3",

      taskIds: [3],
    },
    "column-4": {
      id: "column-4",

      taskIds: [4],
    }
  },
  // Facilitate reordering of the columns
  columnOrder: ["column-1", "column-2", "column-3", "column-4"],


};

