import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../../../frontend/src/pages/loader/Loader";
//404 page
import Error from "../components/Error";

export default function Accountant() {
  //  const Home = lazy(() => import("../pages/Accountant/home"));
  const Home = lazy(() => import("../pages/Accountant/accountant-dashboard"));
  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );
  const MyAccount = lazy(() => import("../pages/Accountant/form/MyAccount"));

  //leave management new changes

  const ListLeaveCategory = lazy(() =>
    import("../pages/superAdmin/leave-category/list")
  );

  const ListLeaveManagementOld = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list")
  );

  const ListLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list-tab")
  );

  const EditLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/edit")
  );

  const ViewLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/view")
  );

  const ListSetLeaves = lazy(() => import("../pages/superAdmin/setLeave/list"));

  const SignedDocument = lazy(() =>
    import("../components/SignedDocument/ListSignedDocument")
  );

  const ViewSignedDocument = lazy(() =>
    import("../components/SignedDocument/ViewSignedDocument")
  );
  const Addbank = lazy(() => import("../pages/Accountant/form/Myaccounttab.js/Addbank"));
  const Editbank = lazy(() => import("../pages/Accountant/form/Myaccounttab.js/Editbank"));
  const Attendance = lazy(() =>
    import("../pages/CommanComponant/Attendance/Attendance")
  );
  const CommonInvoice = lazy(() =>
    import("../components/invoice/CommonInvoice")
  );

  const TimeSheet = lazy(() =>
    import("../pages/healthCarer/appointment/Timesheet/Timesheet")
  );
  return (
    <Routes>
      <Route>
        <Route
          path="/accountant/home"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/accountant/pending-approval"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <PendingApproval />
            </Suspense>
          }
        />
        <Route
          path="/accountant/myaccount"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <MyAccount />
            </Suspense>
          }
        />

        {/* leave management new changes */}
        <Route
          path="/accountant/list-leave-categories"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListLeaveCategory />
            </Suspense>
          }
        />

        <Route
          path="/accountant/list-leave-management-old"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListLeaveManagementOld />
            </Suspense>
          }
        />
        <Route
          path="/accountant/invoice"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <CommonInvoice />
            </Suspense>
          }
        />

        <Route
          path="/accountant/time-sheet"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <TimeSheet />
            </Suspense>
          }
        />

        <Route
          path="/accountant/list-leave-management"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/accountant/add-leave"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <EditLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/accountant/edit-leave"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <EditLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/accountant/view-leave"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ViewLeaveManagement />
            </Suspense>
          }
        />

        <Route
          path="/accountant/list-set-leave"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ListSetLeaves />
            </Suspense>
          }
        />

        <Route
          path="/general-manager/list-signed-document"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <SignedDocument />
            </Suspense>
          }
        />
        <Route
          path="/general-manager/view-signed-document"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <ViewSignedDocument />
            </Suspense>
          }
        />
        <Route
          path="/accountant/add-bank-details"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <Addbank />
            </Suspense>
          }
        />
        <Route
          path="/accountant/edit-bank-details"
          element={
            <Suspense fallback={<div><Loader /></div>}>
              <Editbank />
            </Suspense>
          }
        />

        <Route
          path="/accountant/attendance"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Attendance />
            </Suspense>
          }
        />

        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
