import React, {useState} from "react";
import './appointment.css';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box } from "@mui/material";


export default function RoutineCell(props) {

    const [inputText,setInputText] = useState('');
    const handleEnterPress = (e)=>{
        if(e.keyCode===13){
            props.addList(inputText)
            setInputText("")
        }
    }

    return (
        <Box className="d-flex align-items-center justify-content-between">
            <button onClick={() => {
                props.addList(inputText)
                setInputText("")
            }} className="d-flex align-items-center routine-cell-btn">
                <AddCircleIcon />  Routine
            </button>

            <input
                type="text"
                className="input-box-todo d-none"
                placeholder="Enter your todo"
                 
                onKeyDown={handleEnterPress}
            />

            <button className="d-flex align-items-center routine-cell-task-btn">
                <AddCircleIcon /> Task
            </button>
        </Box>
    )
}

