import { create } from "zustand";

const FilterStore = create((set) => ({
  state: "",
  city: "",
  maxDistance: "",
  isCleared: false,
  cl: false,
  searchFlag: false,
  showFavorites: false,
  predictions1: [{ description: "Current Location", place_id: "Current" }],
  clpredictions: [],
  placeId: "",
  searchTerm: "",
  selectedLocation: "",
  setField: (fieldName, fieldValue) =>
    set((state) => ({ ...state, [fieldName]: fieldValue })),
  resetFields: () => {
    set(() => ({
      state: "",
      city: "",
      searchTerm: "",
      maxDistance: "",
      isCleared: false,
      showFavorites: false,
      cl: false,
      searchFlag: false,
      predictions1: [{ description: "Current Location", place_id: "Current" }],
      clpredictions: [],
      placeId: "",
      selectedLocation: "",
    }));
    return true;
  },
}));

export default FilterStore;
