import React from "react";

const Hubshift = () => {
  const iframeStyle = {
    width: "100%",
    height: "100vh",
    border: "none",
  };

  return (
    <div>
      <iframe
        src="https://us18.list-manage.com/contact-form?u=a545d78a4f120c9b46d6697b2&form_id=828c17dac1298df23cd5b2bc19d3f206"
        style={iframeStyle}
        title="Hubshift Form"
      ></iframe>
    </div>
  );
};

export default Hubshift;
