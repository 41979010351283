import * as React from "react";
import Box from "@mui/material/Box";
import Appbar from "../../Dashboard/Layout/Appbar";
import { Typography } from "@mui/material";
import Sidebar from "../../Dashboard/Layout/Sidebar";
import Checkbox from "@mui/material/Checkbox";
import "../../App.css";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
// modal tools

const userData = [
  { name: "Always confirm the date and time of shift" },
  {
    name: "If you cannot reach for your shift, do inform office on time and let them find a solution never call half an hour before",
  },
  { name: "Always read the BSP (Behavior's Support plan)" },
  { name: "Carry your Driving license and ID" },
  { name: "Reach 10-15 min early at workplace for a better handover" },
  { name: "Never park in driveways during the day" },
  {
    name: "Be properly and appropriately dressed. Fully covered and proper non slippery shoes",
  },
  {
    name: "Carry your food with you. - No food without approval is to be eaten from house",
  },
  {
    name: "If you find anything uncertain when you reach always inform office",
  },
  { name: "Take a proper handover – what happened in last shift" },
  { name: "Handover document" },
  {
    name: "What to do and what not to do – short narrative by finishing staff",
  },
  { name: "Always tally the cash in front of handover staff" },
  { name: "Greet client/clients with their name and with smiling face" },
  { name: "Be confident" },
];
const label = { inputProps: { "aria-label": "Checkbox demo" } };

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function Availability() {
  

  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers(userData);
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempUser = users.map((user) => {
        return { ...user, isChecked: checked };
      });
      setUsers(tempUser);
    } else {
      let tempUser = users.map((user) =>
        user.name === name ? { ...user, isChecked: checked } : user
      );
      setUsers(tempUser);
    }
  };

  return (
    <Box className="dashboard-form client-task" id="agreement">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box className=" d-flex dashboard-main-box">
        <Sidebar />
        <Box className="main">
          <Grid container className="d-flex header-topsection">
            <Grid item xs={9} md={9} sm={9}>
              <Box id="breadcrumb">
                <ArrowBackIosIcon />
                <Box className="breadcrumb-inr">
                  <h4 className="d-flex align-items-center ">Agreement</h4>
                  <Box className="breadcrumbs">
                    <Link className="breadcrumbs-link"> Client Management</Link>
                    <span className="breadcrumbs-icon">{">"}</span>
                    <Link className="breadcrumbs-link">
                    Evaluation
                    </Link>
                    <span className="breadcrumbs-icon">{">"}</span>
                   
                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                      Agreement
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} md={3} sm={3}>
              <Typography>
                <span>Appointment Date</span>: 12-02-2023, 11:00 - 12:00
              </Typography>
            </Grid>
          </Grid>

          <Box className="divider"></Box>

          <Box className="agreement-box">
            <Box className="agreement-header d-flex align-items-center justify-content-between">
              <h2 className="f-size-20">
                Check details and proceed with Appointment
              </h2>
              <Box className="d-flex align-items-center">
                <Typography>Client :</Typography>
                <span>Jake Knap</span>
              </Box>
            </Box>

            <Box className="agreement-body d-flex flex-column">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="allSelect"
                  checked={!users.some((user) => user?.isChecked !== true)}
                  onChange={handleChange}
                />
                <label className="form-check-label ms-2">All Select</label>
              </div>
              {users.map((user, index) => (
                <div className="form-check" key={index}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name={user.name}
                    checked={user?.isChecked || false}
                    onChange={handleChange}
                  />
                  <label className="form-check-label ms-2">{user.name}</label>
                </div>
              ))}

              <Box className="agreement-check d-flex align-items-center">
                <FormControlLabel
                  control={<Checkbox />}
                  className="check"
                  label="I agree on all the work details and conditions"
                />
              </Box>

              <Box className="agency-btn-section ">
                <a href="#cancel">Back</a>
                <a href="#save">Submit</a>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
