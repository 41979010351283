import * as React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
// import axios from "axios";
import axios from "../api/axios";
import {
  getStakeHolderspecialistBasic,
  GetClientDetailsIDbyUserId,
  getFullNameOfUserById,
  getuserDetail,
  getTeamMemberDetailsById,
  SAcreatedBy,
  accessRoleList,
  fundingSourceDetailsCreatedByandName,
  GetPublicHolidayByStateId,
  CheckAccessSetValue,
  getIscCreatedBy,
  checkPayrunSetting,
  checkStringByUserId,
} from "../api";
import zustandStore from "./zustandStore";

const CalculateAge = (dateString) => {
  if (dateString === null || dateString === undefined) {
    return null;
  }

  const currentDate = new Date();
  const dob = new Date(dateString);
  let ageYears = currentDate.getFullYear() - dob.getFullYear();
  let ageMonths = currentDate.getMonth() - dob.getMonth();
  let ageDays = currentDate.getDate() - dob.getDate();

  // Check if the birthdate hasn't happened this year yet
  if (ageMonths < 0 || (ageMonths === 0 && ageDays < 0)) {
    ageYears--;
    ageMonths += 12;
  }

  // Check if the day of birth hasn't happened this month yet
  if (ageDays < 0) {
    ageMonths--;
    const daysInLastMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0
    ).getDate();
    ageDays += daysInLastMonth;
  }

  let yearConst = ageYears > 1 ? "Years" : "Year";
  let monthConst = ageMonths > 1 ? "Months" : "Month";
  let dayConst = ageDays > 1 ? "Days" : "Day";

  const comboAge =
    ageYears +
    " " +
    yearConst +
    ", " +
    ageMonths +
    " " +
    monthConst +
    ", " +
    ageDays +
    " " +
    dayConst;
  return comboAge;
};

const getAge = (dateString) => {
  if (dateString === null || dateString === undefined) {
    return null;
  }
  let today = new Date();
  let birthDate = new Date(dateString);

  if (birthDate == "Invalid Date") {
    return null;
  }
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age >= 0 ? age : null;
};

const GetBasicStack = (stackId) => {
  const [Stackval, setStackval] = useState();
  useEffect(() => {
    if (stackId) {
      axios
        .get(`${getStakeHolderspecialistBasic}/${stackId}`)
        .then((res) => {
          let dataval = res?.data?.data[0];
          setStackval(dataval);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const userObject =
    Stackval !== undefined && Stackval !== null ? Stackval : "";

  return userObject;
};

const GetCIdbyUId = (stackId) => {
  const [clientVal, setClientVal] = useState();
  useEffect(() => {
    if (stackId) {
      axios
        .get(`${GetClientDetailsIDbyUserId}/${stackId}`)
        .then((res) => {
          let dataval = res?.data?.data[0];
          setClientVal(dataval);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const userObject =
    clientVal !== undefined && clientVal !== null ? clientVal : "";

  return userObject;
};

const GetNameOfUserById = (stackId) => {
  const [userVal, setUserval] = useState([]);
  useEffect(() => {
    if (stackId) {
      axios
        .get(`${getFullNameOfUserById}/${stackId}`)
        .then((res) => {
          let dataval = res?.data?.data[0];
          setUserval(dataval);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const userObject = userVal !== undefined && userVal !== null ? userVal : "";

  return userObject;
};

const GetTeamMemberDetails = (id) => {
  const [teamMemeberData, setTeamMemberData] = useState();

  useEffect(() => {
    if (id) {
      axios
        .get(`${getTeamMemberDetailsById}/${id}`)
        .then((res) => {
          let dataval = res?.data?.data[0];
          setTeamMemberData(dataval);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const userObject =
    teamMemeberData !== undefined && teamMemeberData !== null
      ? teamMemeberData
      : "";

  return userObject;
};

const getAllCreatedByDD = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let createdBy1;
  if (userData?.role === "superAdmin") {
    createdBy1 = userData?._id;
  } else if (userData?.role === "admin") {
    createdBy1 = userData?.createdBy;
  } else if (userData?.role === "supportCoordinator") {
    createdBy1 = userData?.createdBy;
  } else if (userData?.role === "healthCarer") {
    createdBy1 = userData?.createdBy;
  } else if (userData?.role === "client") {
    createdBy1 = userData?.createdBy;
  } else if (userData?.role === "serviceProvider") {
    createdBy1 = userData?.createdBy;
  } else {
    createdBy1 = SAcreatedBy;
  }
  return createdBy1;
};

const getCreatedBy = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let createdBy;
  if (userData?.role === "superAdmin") {
    createdBy = userData?._id;
  } else if (userData?.role === "admin") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "Stakeholder") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "supportCoordinator") {
    if (userData?.createdByUserType === "superAdmin") {
      createdBy = userData.createdBy;
    } else {
      createdBy = userData?._id;
    }
  } else if (userData?.role === "healthCarer") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "client") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "serviceProvider") {
    if (userData?.createdByUserType === "superAdmin") {
      createdBy = userData.createdBy;
    } else {
      createdBy = userData?._id;
    }
  }
  return createdBy;
};

const getSpCreatedBy = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let createdBy;
  if (userData?.role === "superAdmin") {
    createdBy = userData?._id;
  } else if (userData?.role === "admin") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "supportCoordinator") {
    if (userData?.createdByUserType === "superAdmin") {
      createdBy = userData.createdBy;
    } else {
      createdBy = userData?._id;
    }
  } else if (userData?.role === "healthCarer") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "client") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "serviceProvider") {
    if (userData?.createdByUserType === "superAdmin") {
      createdBy = userData._id; // this code is commented by shubham s i and matin have added this again
      //createdBy = userData?.createdBy;
    } else {
      createdBy = userData?._id;
    }
  } else if (userData?.role === "hr") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "generalManager") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "supervisor") {
    createdBy = userData?.createdBy;
  }
  return createdBy;
};

const getScCreatedBy = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let createdBy;
  if (userData?.role === "superAdmin") {
    createdBy = userData?._id;
  } else if (userData?.role === "admin") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "serviceProvider") {
    if (userData?.createdByUserType === "superAdmin") {
      createdBy = userData.createdBy;
    } else {
      createdBy = userData?._id;
    }
  } else if (userData?.role === "healthCarer") {
    // createdBy = userData?.createdBy;
    if (userData?.createdByUserType === "superAdmin") {
      createdBy = userData._id;
    } else {
      createdBy = userData?._id;
    }
  } else if (userData?.role === "client") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "supportCoordinator") {
    if (userData?.createdByUserType === "superAdmin") {
      createdBy = userData._id;
    } else {
      createdBy = userData?._id;
    }

    if (userData?.isSoleTrader === false) {
      createdBy = userData?.createdBy;
    }
  } else if (userData?.role === "hr") {
    createdBy = userData?.createdBy;
  }
  console.log(createdBy, "check 288");
  return createdBy;
};
const getCreatedByAdmin = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let createdBy;
  if (userData?.role === "superAdmin") {
    createdBy = userData?._id;
  } else if (userData?.role === "admin") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "serviceProvider") {
    createdBy = userData?._id;
  } else if (userData?.role === "supportCoordinator") {
    createdBy = userData?._id;
  } else if (userData?.role === "hr") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "client") {
    createdBy = userData?._id;
  } else if (userData?.role === "healthCarer") {
    createdBy = userData?._id;
  }
  return createdBy;
};

const getAllCreatedByDataList = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let createdBy;
  if (userData?.role === "superAdmin") {
    // createdBy = "superAdmin";
    createdBy = userData?._id;
  } else if (userData?.role === "admin") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "supportCoordinator") {
    createdBy = userData?._id;
  } else if (userData?.role === "healthCarer") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "client") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "serviceProvider") {
    createdBy = userData?._id;
  }
  return createdBy;
};

const getAllBeforeOnboarding = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let createdBy1;
  if (userData?.role === "superAdmin") {
    // createdBy1 = "superAdmin";
    createdBy1 = SAcreatedBy;
  } else if (userData?.role === "admin") {
    createdBy1 = userData?.createdBy;
  } else if (userData?.role === "supportCoordinator") {
    createdBy1 = userData?.createdBy;
  } else if (userData?.role === "healthCarer") {
    createdBy1 = userData?.createdBy;
  } else if (userData?.role === "client") {
    createdBy1 = userData?.createdBy;
  } else if (userData?.role === "serviceProvider") {
    createdBy1 = userData?.createdBy;
  } else {
    createdBy1 = SAcreatedBy;
  }
  return createdBy1;
};

const getActiveStep = async (userId) => {
  return axios
    .get(getuserDetail + "/" + userId)
    .then((res) => {
      const data = res.data.data;
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const redirectionAfterSuscriptionPlan = async () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const roleData = userData.role;
  const data = await getActiveStep(userData._id);
  const activeStep = data.activeStep;
  const userStatusData = data.userStatus;
  const UserType = userData.createdByUserType;

  if (roleData === "healthCarer") {
    if (userStatusData === "Approved") {
      if (userData?.packageMenuId) {
        window.location.href = "/health-carer/home";
      } else {
        if (UserType === "superAdmin") {
          window.location.href = "/health-carer/list-package-menu";
        } else {
          window.location.href = "/health-carer/home";
        }
      }
    } else {
      if (activeStep === 4) {
        window.location.href = "/health-carer/pending-approval";
      } else {
        window.location.href = "/health-carer/onboard";
      }
    }
  } else if (roleData === "supportCoordinator") {
    if (userStatusData === "Approved") {
      if (userData?.packageMenuId) {
        window.location.href = "/support-coordinator/home";
      } else {
        if (UserType === "superAdmin") {
          // window.location.href = "/support-coordinator/list-package-menu";
        } else {
          window.location.href = "/support-coordinator/home";
        }
      }
    } else {
      if (activeStep === 4) {
        window.location.href = "/support-coordinator/pending-approval";
      } else {
        window.location.href = "/support-coordinator/onboard";
      }
    }
  } else if (roleData === "serviceProvider") {
    if (userStatusData === "Approved") {
      if (userData?.packageMenuId) {
        window.location.href = "/service-provider/home";
      } else {
        if (UserType === "superAdmin") {
          window.location.href = "/service-provider/list-package-menu";
        } else {
          window.location.href = "/service-provider/home";
        }
      }
    } else {
      if (activeStep === 1) {
        window.location.href = "/service-provider/pending-approval";
      } else {
        window.location.href = "/service-provider/onboard";
      }
    }
  } else if (roleData === "client") {
    if (userStatusData === "Approved") {
      if (userData?.packageMenuId) {
        window.location.href = "/client/home";
      } else {
        if (UserType === "superAdmin") {
          window.location.href = "/client/list-package-menu";
        } else {
          window.location.href = "/client/home";
        }
      }
    } else {
      if (activeStep === 4) {
        // window.location.href = "/client/pending-approval";
        window.location.href = "/client/home";
      } else {
        window.location.href = "/client/onboard";
      }
    }
  }
};

const getServiceProviderRouteAccess = () => {
  const { resetFields, setField, menuList } = zustandStore();
  const createdBy = getCreatedBy();

  const user = JSON.parse(localStorage.getItem("userData"));
  axios
    .get(
      `${accessRoleList}?roleId=${
        user.userTypeId
      }&createdBy=${createdBy}&role=${user.role}&userId=${user._id}&userType=${
        user?.createdByUserType
      }&isABNHolder=${user?.isSoleTrader}&evlClient=${
        user?.packageMenuId == false && user?.role == "client"
          ? "true"
          : "false"
      }`
    )
    .then(function (res) {
      if (res.data.data.length > 0) {
        setField("menuList", [...menuList, ...res.data.data]);
      } else {
        setField("menuList", [
          ...menuList,
          ...[
            "/health-carer/interview-list",
            "/health-carer/list-package-menu",
          ],
        ]);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

const GetFundingSourceID = (createdBy, name) => {
  const [primaryKey, setPrimaryKey] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (createdBy && name) {
          const postData = {
            createdBy: createdBy,
            name: name,
          };
          const response = await axios.post(
            fundingSourceDetailsCreatedByandName,
            postData
          );
          const data = response?.data?.data;
          if (data && data._id) {
            setPrimaryKey(data._id);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [createdBy, name]);

  return primaryKey;
};

const ConvertUnitsToHours = (
  units,
  unitType,
  year,
  month,
  hourlyRate,
  totalTimeHr
) => {
  switch (unitType) {
    case "H":
      return units * totalTimeHr * hourlyRate;
    case "D":
      return units * 8 * hourlyRate;
    case "WK":
      return units * 40 * hourlyRate;
    case "MON":
      return units * FetchHoursInMonth(year, month) * hourlyRate;
    case "YR":
      return units * FetchHoursInYear(year, month) * hourlyRate;
    case "E":
      return units * hourlyRate;
    default:
      return 0;
  }
};

const FetchHoursInMonth = (year, month) => {
  const lastDayOfMonth = new Date(year, month, 0);
  const weekdaysInMonth =
    Math.floor((lastDayOfMonth.getDate() + lastDayOfMonth.getDay()) / 7) * 5;
  const hoursInMonth = weekdaysInMonth * 8;
  return hoursInMonth > 0 ? hoursInMonth : 0;
};

const FetchHoursInYear = (year) => {
  let totalHoursInYear = 0;
  for (let month = 1; month <= 12; month++) {
    const hoursInMonth = FetchHoursInMonth(year, month);
    totalHoursInYear += hoursInMonth;
  }
  return totalHoursInYear;
};

const getCreatedByAllUser = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let createdBy;
  if (userData?.role === "superAdmin") {
    createdBy = userData?._id;
  } else if (userData?.role === "admin") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "supportCoordinator") {
    if (userData?.createdByUserType === "superAdmin") {
      createdBy = userData.createdBy;
    } else {
      createdBy = userData?._id;
    }
  } else if (userData?.role === "healthCarer") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "client") {
    createdBy = userData?.createdBy;
  } else if (userData?.role === "serviceProvider") {
    if (userData?.createdByUserType === "superAdmin") {
      createdBy = userData.createdBy;
    } else {
      createdBy = userData?._id;
    }
  } else if (
    userData?.role === "itManager" ||
    userData?.role === "accountant" ||
    userData?.role === "supervisor" ||
    userData?.role === "generalManager" ||
    userData?.role === "hr" ||
    userData?.role === "planManager"
  ) {
    createdBy = userData?.createdBy;
  }
  return createdBy;
};

const GetHolidaysByStateId = (stateId) => {
  const [stateHolidays, setStateHolidays] = useState([]);
  useEffect(() => {
    if (stateId) {
      axios
        .get(`${GetPublicHolidayByStateId}/${stateId}`)
        .then((res) => {
          let dataval = res?.data?.data;
          setStateHolidays(dataval);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [stateId]);

  const userObject =
    stateHolidays !== undefined && stateHolidays !== null ? stateHolidays : "";

  return userObject;
};

const CheckAccessSet = (createdBy, roleId) => {
  const [data, setData] = useState(0);
  axios
    .get(`${CheckAccessSetValue}?roleId=${roleId}&createdBy=${createdBy}`)
    .then(function (res) {
      setData(res.data.data.length);
    })
    .catch(function (error) {
      console.log(error);
    });
  return data;
};

const CheckPayrunSet = (userId) => {
  const [data, setData] = useState(0);
  axios
    .get(`${checkPayrunSetting}?userId=${userId}`)
    .then(function (res) {
      setData(res.data.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  return data;
};

const getCreatedByParentId = async (id) => {
  return await axios
    .get(`${getIscCreatedBy}?employeeId=${id}`)
    .then((res) => {
      const data = res.data.data;
      console.log("Here Data", data._id);
      return data._id;
    })
    .catch((error) => {
      console.log(error);
    });
};

const fetchLoginString = async (id) => {
  return await axios
    .get(`${checkStringByUserId}?id=${id}`)
    .then((res) => {
      const data = res?.data?.data?.checkLoginString;
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export {
  redirectionAfterSuscriptionPlan,
  getAge,
  getCreatedBy,
  getAllCreatedByDataList,
  getAllBeforeOnboarding,
  GetBasicStack,
  GetCIdbyUId,
  GetNameOfUserById,
  CalculateAge,
  GetTeamMemberDetails,
  getAllCreatedByDD,
  getServiceProviderRouteAccess,
  GetFundingSourceID,
  getSpCreatedBy,
  getScCreatedBy,
  ConvertUnitsToHours,
  getCreatedByAllUser,
  GetHolidaysByStateId,
  getCreatedByAdmin,
  CheckAccessSet,
  CheckPayrunSet,
  getCreatedByParentId,
  fetchLoginString,
};
