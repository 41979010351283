import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getuserDetail } from "../api";
import Loader from "../../../frontend/src/pages/loader/Loader";
import axios from "axios";

//404 page
import Error from "../components/Error";
import MedicationMError from "../components/accessErrorOfMedication";
import ShiftNotrError from "../components/accessErrorOfShiftNote";

export default function Stakeholder() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isShiftNote, setIsShiftNote] = useState("");
  const [isShiftNoteId, setIsShiftNoteId] = useState([]);
  const [isMedication, setIsMedication] = useState("");
  const [isMedicationId, setIsMedicationId] = useState([]);
  const clientId = localStorage.getItem("clientId");
  const [isFetch, setIsFetch] = useState(true);

  useEffect(() => {
    axios
      .get(`${getuserDetail}/${userData?._id}`)
      .then(function (res) {
        if (res) {
          setIsMedication(res?.data?.data?.isMedication);
          setIsMedicationId(res?.data?.data?.isMedicationClientIdForAccess || []);
          setIsShiftNote(res?.data?.data?.isShiftNote);
          setIsShiftNoteId(res?.data?.data?.isShiftNoteClientIdForAccess || []);
          setIsFetch(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [userData?._id, clientId]);

  // const Home = lazy(() => import("../pages/familyMember/form/home"));

  // const Home = lazy(() =>
  //   import("../pages/familyMember/Dashboard/familyMember-dashboard")
  // );

  const Home = lazy(() => import("../pages/client/Dashboard/Client-Dashboard"));

  const MyAccount = lazy(() => import("../pages/familyMember/form/MyAccount"));
  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );

  const Meeting = lazy(() => import("../pages/Stakeholder/meetings"));
  const TaskList = lazy(() => import("../pages/Stakeholder/task/list"));
  const NoteList = lazy(() => import("../pages/Stakeholder/note/list"));
  const ClientProfile = lazy(() =>
    import("../pages/Stakeholder/clientprofile/profileclient")
  );

  /*meeting routes*/

  const ListClientScheduleMeetingNew = lazy(() =>
    import("../pages/superAdmin/schedule-meeting/client/list-tab")
  );

  const ListCommonCalander = lazy(() =>
    import("../pages/superAdmin/schedule-common-meeting/client/list-tab")
  );

  const ClientMeetingDetailsNew = lazy(() =>
    import("../components/meeting-view/details")
  );

  const ClientSelectionPage = lazy(() =>
    import("../components/goals/ClientSelect")
  );

  const ClientGoalList = lazy(() => import("../components/goals/list"));

  //

  const ClientEditGoalRoutine = lazy(() =>
    import("../components/goals/addRoutineTask/edit")
  );
  const ClientExportPdfWithImage = lazy(() =>
    import("../components/goals/pdfWithImage")
  );
  const ClientExportPdf = lazy(() => import("../components/goals/pdf"));
  const ClientViewGoal = lazy(() => import("../components/goals/view"));
  const ClientEditGoal = lazy(() => import("../components/goals/edit"));
  const BarChartGoal = lazy(() => import("../components/goals/barChart"));
  const Riskassesment = lazy(() =>
    import("../pages/Stakeholder/Riskassesment/Riskassesment")
  );

  const ListFundManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/Fund-Management")
  );

  const ListMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
  );
  const ListIncident = lazy(() => import("../pages/Supervisor/incident/list"));
  const MedicalHistoryPdf = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/medicalHistoryPdf")
  );

  const MedicalHistoryPrint = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/print")
  );
  const RoutineTaskDashboard = lazy(() =>
    import("../components/routine-task-dashboard/AppointmentDashboard")
  );
  const MedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Medical-History")
  );

  const EditMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medical-History")
  );

  //ViewMedicalHistory

  const ViewMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/View-Medical-History")
  );

  const MedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Medication-chart")
  );

  const EditMedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medication")
  );

  const Viewincident = lazy(() => import("../pages/healthCarer/incident/view"));

  const Pdfincident = lazy(() => import("../pages/healthCarer/incident/pdf"));

  const ViewBloodObservation = lazy(() =>
    import("../pages/superAdmin/client-list/Viewtab/Observation/Blood/view")
  );

  const ViewFood = lazy(() =>
    import("../pages/superAdmin/client-list/Viewtab/Observation/Food/view")
  );
  //ViewMedicationChart

  const ViewMedicationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/View-Medication")
  );

  const ShiftNoteList = lazy(() =>
    import("../pages/superAdmin/shift-notes/list")
  );
  const ProgressNotelist = lazy(() =>
    import("../pages/client/progress-notes/list")
  );
  const ServiceTaskList = lazy(() =>
    import("../pages/superAdmin/service-task/list")
  );

  const ServiceTaskEdit = lazy(() =>
    import("../pages/superAdmin/service-task/edit")
  );
  const AppointmentView = lazy(() =>
    import("../components/calendar-view/Appointment-View")
  );

  const ListTasknote = lazy(() =>
    import("../pages/healthCarer/TaskNotes/listTasknote")
  );

  const ViewTasknote = lazy(() =>
    import("../pages/healthCarer/TaskNotes/viewTasknotes")
  );

  const ObservationChart = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Observation-chart")
  );

  const ViewFundManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/View-Fund-Management")
  );

  const ViewBowelObservation = lazy(() =>
    import("../pages/superAdmin/client-list/Viewtab/Observation/Bowel/view")
  );

  const ViewShiftNotes = lazy(() =>
    import("../pages/superAdmin/shiftNotes/view")
  );

  const PrintFundManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/print")
  );

  const ViewMestruationObservation = lazy(() =>
    import(
      "../pages/superAdmin/client-list/Viewtab/Observation/Mestruation/view"
    )
  );

  const ViewPersonalCareObservation = lazy(() =>
    import(
      "../pages/superAdmin/client-list/Viewtab/Observation/PersonalCare/view"
    )
  );

  const ViewSeizureObservation = lazy(() =>
    import(
      "../pages/superAdmin/Medication-management-dev/Observation/Seizure/view"
    )
  );

  const ViewWeightObservation = lazy(() =>
    import(
      "../pages/superAdmin/Medication-management-dev/Observation/weight/view"
    )
  );

  const ListAppointment = lazy(() =>
    import("../pages/serviceProvider/appointment/list")
  );

  const RoutineTaskPdf = lazy(() =>
    import("../components/routine-task-dashboard/pdf")
  );

  const ProgressNoteAdd = lazy(() =>
    import("../pages/superAdmin/progressNote/edit")
  );
  const clientHasShiftNoteAccess = isShiftNoteId?.includes(clientId);
  const clientHasMedicationAccess = isMedicationId?.includes(clientId);
  return (
    <Routes>
      <Route>
        <Route
          path="/family-member/home"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Home />
            </Suspense>
          }
        />
        <Route
          path="/family-member/pending-approval"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <PendingApproval />
            </Suspense>
          }
        />

        <Route
          path="/family-member/meeting"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Meeting />
            </Suspense>
          }
        />
        <Route
          path="/family-member/list-task"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <TaskList />
            </Suspense>
          }
        />
        <Route
          path="/family-member/print-funds-management"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <PrintFundManagement />
            </Suspense>
          }
        />

        <Route
          path="/family-member/list-note"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <NoteList />
            </Suspense>
          }
        />
        <Route
          path="/family-member/myaccount"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <MyAccount />
            </Suspense>
          }
        />

        <Route
          path="/family-member/add-progressnote"
          element={
            <Suspense>
              <ProgressNoteAdd />
            </Suspense>
          }
        />

        <Route
          path="/family-member/client-profile"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientProfile />
            </Suspense>
          }
        />

        <Route
          path="/family-member/add-service-task"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ServiceTaskEdit />
            </Suspense>
          }
        />

        <Route
          path="/family-member/edit-service-task"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ServiceTaskEdit />
            </Suspense>
          }
        />

        <Route
          path="/family-member/list-task-notes"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListTasknote />
            </Suspense>
          }
        />

        <Route
          path="/family-member/view-task-notes"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewTasknote />
            </Suspense>
          }
        />

        <Route
          path="/family-member/meeting-list"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListClientScheduleMeetingNew />
            </Suspense>
          }
        />

        <Route
          path="/family-member/view-funds-management"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ViewFundManagement />
            </Suspense>
          }
        />

        {/* <Route
          path="/family-member/shift-notes"
          element={
            <Suspense>
              <ViewShiftNotes />
            </Suspense>
          }
        /> */}
        <Route
          path="/family-member/list-calendar"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListCommonCalander />
            </Suspense>
          }
        />

        <Route
          path="/family-member/view-meeting-details"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientMeetingDetailsNew />
            </Suspense>
          }
        />

        <Route
          path="/family-member/view-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Viewincident />
            </Suspense>
          }
        />

        <Route
          path="/family-member/pdf-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Pdfincident />
            </Suspense>
          }
        />

        <Route
          path="/family-member/list-service-task"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ServiceTaskList />
            </Suspense>
          }
        />

        <Route
          path="/family-member/list-incident"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListIncident />
            </Suspense>
          }
        />

        <Route
          path="/family-member/select-client"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientSelectionPage />
            </Suspense>
          }
        />
        <Route
          path="/family-member/list-goals"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientGoalList />
            </Suspense>
          }
        />
        <Route
          path="/family-member/list-routine-and-tasks"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <RoutineTaskDashboard />
            </Suspense>
          }
        />
        <Route
          path="/family-member/add-goal"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientEditGoal />
            </Suspense>
          }
        />
        <Route
          path="/family-member/edit-goal"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientEditGoal />
            </Suspense>
          }
        />
        <Route
          path="/family-member/view-goal"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientViewGoal />
            </Suspense>
          }
        />
        <Route
          path="/family-member/view-task"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientViewGoal />
            </Suspense>
          }
        />
        <Route
          path="/family-member/export-pdf"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientExportPdf />
            </Suspense>
          }
        />
        <Route
          path="/family-member/export-pdf-with-image"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientExportPdfWithImage />
            </Suspense>
          }
        />
        <Route
          path="/family-member/add-goal-routine"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientEditGoalRoutine />
            </Suspense>
          }
        />
        <Route
          path="/family-member/edit-goal-routine"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ClientEditGoalRoutine />
            </Suspense>
          }
        />
        <Route
          path="/family-member/bar-chart-goal"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <BarChartGoal />
            </Suspense>
          }
        />
        <Route
          path="/family-member/risk-assesment"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <Riskassesment />
            </Suspense>
          }
        />
        <Route
          path="/family-member/list-funds-management"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListFundManagement />
            </Suspense>
          }
        />
        <Route
          path="/family-member/list-progress-notes"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ProgressNotelist />
            </Suspense>
          }
        />
        <Route
          path="/family-member/appointments-view"
          element={
            <Suspense>
              <AppointmentView />
            </Suspense>
          }
        />
        <Route
          path="/family-member/list-appointment"
          element={
            <Suspense
              fallback={
                <div>
                  <Loader />
                </div>
              }
            >
              <ListAppointment />
            </Suspense>
          }
        />
        <Route path="*" element={<Error />} />
        {/* {console.log(isMedication, "isMedication", isShiftNote, "isShiftNote")} */}
        {!isFetch ? (
          <>
            {clientHasMedicationAccess === true ? (
              <>
                <Route
                  path="/family-member/add-medication-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <EditMedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/edit-medical-history-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <EditMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-food-observation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewFood />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-mestruation-observation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewMestruationObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-personalcare-observation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewPersonalCareObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-seizure-observation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewSeizureObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-weight-observation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewWeightObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/medical-history-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <MedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-routine-and-tasks"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <RoutineTaskDashboard />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-routine-and-tasks-pdf"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <RoutineTaskPdf />
                    </Suspense>
                  }
                />

                <Route
                  path="/family-member/medication-chart-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <MedicationChart />
                    </Suspense>
                  }
                />

                <Route
                  path="/family-member/observation-chart-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ObservationChart />
                    </Suspense>
                  }
                />

                <Route
                  path="/family-member/prn-chart-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <MedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-medical-history"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ListMedicalHistory />
                    </Suspense>
                  }
                />

                <Route
                  path="/family-member/list-medication-chart"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ListMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-observation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ListMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-prn-chart"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ListMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/edit-medication-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <EditMedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-blood-observation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewBloodObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-blood-observation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewBloodObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-bowel-observation"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewBowelObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-medication-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewMedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/medical-history-pdf"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <MedicalHistoryPdf />
                    </Suspense>
                  }
                />

                <Route
                  path="/family-member/medical-history-print"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <MedicalHistoryPrint />
                    </Suspense>
                  }
                />

                <Route
                  path="/family-member/view-medication-dev"
                  element={
                    <Suspense>
                      <ViewMedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/edit-medication-dev"
                  element={
                    <Suspense>
                      <EditMedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-medical-history"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ListMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-medication-chart"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ListMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-prn-chart"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ListMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/medical-history-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <MedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/add-medical-history-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <EditMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/edit-medical-history-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <EditMedicalHistory />
                    </Suspense>
                  }
                />

                <Route
                  path="/family-member/view-medical-history-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/medication-chart-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <MedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/add-medication-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <EditMedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/edit-medication-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <EditMedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-medication-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ViewMedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/prn-chart-dev"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <MedicationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-observation"
                  element={
                    <Suspense>
                      <ListMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-blood-observation"
                  element={
                    <Suspense>
                      <ViewBloodObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-blood-observation"
                  element={
                    <Suspense>
                      <ViewBloodObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-bowel-observation"
                  element={
                    <Suspense>
                      <ViewBowelObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-medication-chart"
                  element={
                    <Suspense>
                      <ListMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/list-prn-chart"
                  element={
                    <Suspense>
                      <ListMedicalHistory />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/observation-chart-dev"
                  element={
                    <Suspense>
                      <ObservationChart />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-food-observation"
                  element={
                    <Suspense>
                      <ViewFood />
                    </Suspense>
                  }
                />

                <Route
                  path="/family-member/view-mestruation-observation"
                  element={
                    <Suspense>
                      <ViewMestruationObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-personalcare-observation"
                  element={
                    <Suspense>
                      <ViewPersonalCareObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-seizure-observation"
                  element={
                    <Suspense>
                      <ViewSeizureObservation />
                    </Suspense>
                  }
                />
                <Route
                  path="/family-member/view-weight-observation"
                  element={
                    <Suspense>
                      <ViewWeightObservation />
                    </Suspense>
                  }
                />
              </>
            ) : (
              <>
                {clientHasMedicationAccess === false ? (
                  <Route path="*" element={<MedicationMError />} />
                ) : (
                  <Route path="*" element={<Error />} />
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
        {!isFetch ? (
          <>
            {clientHasShiftNoteAccess === true ? (
              <>
                <Route
                  path="/family-member/shift-notes"
                  element={
                    <Suspense
                      fallback={
                        <div>
                          <Loader />
                        </div>
                      }
                    >
                      <ShiftNoteList />
                    </Suspense>
                  }
                />
              </>
            ) : (
              <>
                {clientHasShiftNoteAccess === false ? (
                  <Route path="*" element={<ShiftNotrError />} />
                ) : (
                  <Route path="*" element={<Error />} />
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
      </Route>
    </Routes>
  );
}
