import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CircleIcon from "@mui/icons-material/Circle";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FilterStore from "../availability/store/FilterStore";
import FormControlLabel from "@mui/material/FormControlLabel";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function RequestStatus() {
  const requestStatus1 = [
    "Accepted",
    "Rejected",
    "Pending",
    "Appointed",
    "Cancelled",
  ];

  const dynamicClassNames = [
    "view-details-status-accepted",
    "view-details-status-rejected",
    "view-details-status-pending",
    "view-details-status-appointed",
    "view-details-status-cancelled",
  ];

  const {
    resetFields,
    setField,
    selectedCity,
    ApType2,
    month,
    startDate1,
    endDate1,
    slotType,
    searchTerm,
    selectedShift,
    cityList,
    requestStatus,
  } = FilterStore();

  const selectAllStatus = (stake) => {
    if (stake.target.checked === true) {
      let stakeArr = [];
      requestStatus1.map((val) => {
        stakeArr.push(val);
      });
      setField("requestStatus", stakeArr);
    } else {
      setField("requestStatus", []);
    }
  };

  const selectCheckboxHandle = async (event, values) => {
    const index = requestStatus.indexOf(values);
    if (index === -1) {
      setField("requestStatus", [...requestStatus, values]);
    } else {
      setField(
        "requestStatus",
        requestStatus.filter((val) => val !== values)
      );
    }
  };

  return (
    <Box className="d-flex align-items-center">
      {/* <Box className="view-details-status-cheeps">
        <Checkbox
          {...label}
          defaultChecked
          disableRipple
          icon={<RadioButtonUncheckedIcon style={{ color: "#959595" }} />}
          checkedIcon={<CircleIcon style={{ color: "#959595" }} />}
          className={`view-details-status-cheeps`}
          onChange={selectAllStatus}
          checked={
            requestStatus1?.length === requestStatus.length ? true : false
          }
        />
        <Typography>All</Typography>
      </Box> */}

      {requestStatus1.map((status, index) => (
        <Box className="view-details-status-cheeps " key={status}>
          <Checkbox
            {...label}
            defaultChecked
            disableRipple
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CircleIcon />}
            className={`view-details-status-cheeps ${
              dynamicClassNames[index]
            } ${requestStatus.includes(status) ? "selected" : ""}`}
            onChange={(event) => selectCheckboxHandle(event, status)}
            checked={requestStatus.includes(status)}
          />

          <Typography>{status}</Typography>
        </Box>
      ))}
    </Box>
  );
}

export default RequestStatus;
