import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Appbar from "../Layout/Appbar";
import Sidebar from "../Layout/Sidebar";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import profileimg from "../../assets/images/profileimg.png";
import Map from "../../assets/images/map.png";
import Personaldetails from "./Personaldetails";
import { Radio } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Autocomplete from "@mui/material/Autocomplete";

//  Tab import here

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const title = [{ label: "Admin" }, { label: "Test" }];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function HealthCarerCalender() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tabvalue, setTabValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box className="dashboard-form" id="Appointment-Dashboard">
      <Box
        sx={{ width: "100%" }}
        className="appbar- 
       border"
      >
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box id="my-account" className=" dashboard-main-box d-flex">
        <Sidebar />
        <Box className="main">
          <Box className="dashboard-form-typo dashboard-form-typo-up">
            <Box id="breadcrumb">
              <ArrowBackIosIcon />
              <Box className="breadcrumb-inr">
                <h4 className="d-flex align-items-center "> My Account</h4>
                <Box className="breadcrumbs">
                  <Link className="breadcrumbs-link">My Account</Link>{" "}
                  <span className="breadcrumbs-icon">{">"}</span>
                  <Link className="breadcrumbs-link breadcrumbs-link-active">
                    Documents
                  </Link>{" "}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className="my-account-tab-main" sx={{ width: "100%", mt: 3 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Profile"
                  className="my-account-tab"
                  {...a11yProps(0)}
                />
                <Tab
                  label="Documents"
                  className="my-account-tab"
                  {...a11yProps(1)}
                />
                <Tab
                  label="Bank Details"
                  className="my-account-tab"
                  {...a11yProps(2)}
                />
                <Tab
                  label="Tax Details"
                  className="my-account-tab"
                  {...a11yProps(3)}
                />
                <Tab
                  label="Password"
                  className="my-account-tab"
                  {...a11yProps(4)}
                />
              </Tabs>
            </Box>

            <Box className="account-third-party-radio">
              <Box className="d-flex align-items-center">
                <span className="account-third-party-radio-text">
                  3rd Party Access:
                </span>

                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Partial"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Full"
                  />
                </RadioGroup>
              </Box>
            </Box>

            <CustomTabPanel
              className="my-account-tabpanel"
              value={value}
              index={0}
            >
              <Tabs
                value={tabvalue}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab
                  label="Personal Details"
                  sx={{ pl: 0 }}
                  className="my-account-subtab"
                  {...a11yProps(0)}
                />
                <Tab
                  label="Other Details"
                  className="my-account-subtab"
                  {...a11yProps(1)}
                />
                <Tab
                  label="Work Locations"
                  className="my-account-subtab"
                  {...a11yProps(2)}
                />
              </Tabs>

              <CustomTabPanel
                className="my-account-tabpanel"
                value={tabvalue}
                index={0}
              >
                <Box sx={{ mt: 2 }}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item sx={12} md={3}>
                        <Card sx={{ maxWidth: 250 }} elevation={0}>
                          <CardMedia
                            className="card-image"
                            component="img"
                            height="216px"
                            width="181px"
                            image={profileimg}
                            alt="Paella dish"
                          />

                          <CardContent className="card-content">
                            <Typography
                              color="text.secondary"
                              className="card-text"
                            >
                              Job Type: <span> Casual </span>
                            </Typography>

                            <Typography
                              color="text.secondary"
                              className="card-text"
                            >
                              Job Title: <span> Health Carer </span>
                            </Typography>

                            <Typography
                              color="text.secondary"
                              className="card-text"
                            >
                              Level: <span> : 5 </span>
                            </Typography>

                            <Typography
                              color="text.secondary"
                              className="card-text"
                            >
                              Job Title: <span> Nurse </span>
                            </Typography>

                            <Typography
                              color="text.secondary"
                              className="card-text"
                            >
                              Awards: <span> SCHADS </span>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>

                      <Grid item sx={12} md={9}>
                        <Personaldetails />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className="agency-btn-section btn">
                    <Link to="#javascript:;">Save</Link>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel
                className="my-account-tabpanel"
                value={tabvalue}
                index={1}
              >
                <Grid container>
                  {/* <Grid item sx={12} md={12}>
          <Typography component='div' className="skill-typo"> Stills</Typography>

          </Grid> */}

                  <Grid
                    item
                    sx={{ pr: 4 }}
                    xs={12}
                    sm={6}
                    md={6}
                    className="pt-0"
                  >
                    <label>Qualification </label>
                    {/* <TextField
                                sx={{ mt: 2, mb: 2 }}
                                className='form-input'
                                type="Name"
                                size="small"
                            /> */}
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={title}
                      disableClearable
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          placeholder="Select"
                          sx={{ mt: 1, mb: 1 }}
                          {...params}
                        />
                      )}
                    />
                  </Grid>

                  <Grid
                    item
                    sx={{ pl: 4 }}
                    xs={12}
                    sm={6}
                    md={6}
                    className="pt-0"
                  >
                    <label className="d-block">
                      First Name <span className="danger">*</span>
                    </label>
                    <TextField
                      sx={{ mt: 1 }}
                      className="form-input w-100"
                      type="Name"
                      placeholder="Tom"
                      size="small"
                    />
                  </Grid>
                </Grid>

                <Typography
                  component="div"
                  className="skill-typo d-block other-tab-skill"
                >
                  {" "}
                  Skills
                </Typography>

                <Grid container className="skill-checkbox-main">
                  <Grid item xs={12} md={6} sm={6}>
                    <Grid container>
                      <Grid item sx={4} md={4}>
                        <FormControl
                          sx={{ m: 1 }}
                          component="fieldset"
                          variant="standard"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>Domestic </Typography>
                                </>
                              }
                            />
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>Oncology </Typography>
                                </>
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>

                      <Grid item sx={4} md={4}>
                        <FormControl
                          sx={{ m: 1 }}
                          component="fieldset"
                          variant="standard"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>Manual Handling </Typography>
                                </>
                              }
                            />
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>Car Washing </Typography>
                                </>
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>

                      <Grid item sx={4} md={4}>
                        <FormControl
                          sx={{ m: 1 }}
                          component="fieldset"
                          variant="standard"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>DSQ Card </Typography>
                                </>
                              }
                            />
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>Blue Card </Typography>
                                </>
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ pl: 3 }} sm={6} md={6}>
                    <Grid container>
                      <Grid item sx={4} md={4}>
                        <FormControl
                          sx={{ m: 1 }}
                          component="fieldset"
                          variant="standard"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>Police Clerk </Typography>
                                </>
                              }
                            />
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>Spinal </Typography>
                                </>
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>

                      <Grid item sx={4} md={4}>
                        <FormControl
                          sx={{ m: 1 }}
                          component="fieldset"
                          variant="standard"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>Meal Delivery </Typography>
                                </>
                              }
                            />
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>Hair Dressing </Typography>
                                </>
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>

                      <Grid item sx={4} md={4}>
                        <FormControl
                          sx={{ m: 1 }}
                          component="fieldset"
                          variant="standard"
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox {...label} />}
                              label={
                                <>
                                  <Typography>Health Care </Typography>
                                </>
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  {/* <Grid item sx={12} md={12}>
          <Typography component='div' className="skill-typo"> Stills</Typography>

          </Grid> */}

                  <Grid
                    item
                    sx={{ pr: 4 }}
                    xs={12}
                    sm={6}
                    md={6}
                    className="pt-0"
                  >
                    <label className="d-block">Languages </label>
                    <TextField
                      sx={{ mt: 1 }}
                      className="form-input w-100"
                      type="Name"
                      placeholder="Type here"
                      size="small"
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{ pl: 4 }}
                    xs={12}
                    sm={6}
                    md={6}
                    className="pt-0"
                  >
                    <label className="d-block">Interest & Hobbies </label>
                    <TextField
                      sx={{ mt: 1 }}
                      className="form-input w-100"
                      type="Name"
                      placeholder="for ex, Cricket etc"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} md={6} sm={6} sx={{ mt: 2 }}>
                    <label> Immunization</label>
                    <Box className="skill-radio">
                      <div>
                        <FormControlLabel
                          value="Partial"
                          className="shift-checkbox form-text"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="Full "
                          className="shift-checkbox form-text"
                          control={<Radio />}
                          label="No"
                        />
                      </div>

                      <Box width="70%">
                        <TextField
                          placeholder="Immunisation Name"
                          fullWidth
                          sx={{ mt: 2, mb: 2 }}
                          className="form-input"
                          type="Name"
                          size="small"
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    sx={{ pl: 4, mt: 3 }}
                    xs={12}
                    sm={6}
                    md={6}
                    className="pt-0"
                  >
                    <label className="d-block">Religion </label>
                    <TextField
                      sx={{ mt: 1 }}
                      className="form-input w-100"
                      type="Name"
                      placeholder="for ex, Cricket etc"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} sx={{ mt: 2 }} md={12}>
                    <label className="d-block">About </label>

                    <TextField
                      placeholder="Type here"
                      multiline
                      sx={{ mt: 1 }}
                      rows={2}
                      maxRows={4}
                      className="w-100"
                    />
                  </Grid>
                </Grid>

                <Box className="agency-btn-section btn">
                  <Link to="#javascript:;">Save</Link>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel
                className="my-account-tabpanel"
                value={tabvalue}
                index={2}
              >
                <Grid container>
                  <Grid item xs={12} md={3} sm={3}>
                    <label className="d-block"> Preferred Work Locations</label>
                    <TextField
                      sx={{ mt: 1 }}
                      className="form-input w-100"
                      type="Name"
                      placeholder="for ex, Cricket etc"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} sx={{ mt: 4 }}>
                    <img src={Map} className="skill-map-image" alt="maps" />
                  </Grid>
                </Grid>

                <Box className="agency-btn-section btn">
                  <Link to="#javascript:;">Save</Link>
                </Box>
              </CustomTabPanel>
            </CustomTabPanel>
            <CustomTabPanel
              className="my-account-tabpanel"
              value={value}
              index={1}
            >
              Item Two
            </CustomTabPanel>
            <CustomTabPanel
              className="my-account-tabpanel"
              value={value}
              index={2}
            >
              Item Three
            </CustomTabPanel>
            <CustomTabPanel
              className="my-account-tabpanel"
              value={value}
              index={2}
            >
              Item Four
            </CustomTabPanel>
            <CustomTabPanel
              className="my-account-tabpanel"
              value={value}
              index={2}
            >
              Item Five
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
