import React from 'react';
import {
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const Once = () => {

    const [showhide, setShowhide] = React.useState("");

    const handleshowhide = (event) => {
        const getuser = event.target.value;
        setShowhide(getuser);
    };


    return (
        <>

            <Box className="d-flex require-week-box align-items-center">
                <Box className="require-week">
                    <p>
                        S
                    </p>
                </Box>
                <Box className="require-week">
                    <p>
                        M
                    </p>
                </Box>
                <Box className="require-week">
                    <p>
                        T
                    </p>
                </Box>
                <Box className="require-week">
                    <p>
                        W
                    </p>
                </Box>
                <Box className="require-week">
                    <p>
                        T
                    </p>
                </Box>
                <Box className="require-week">
                    <p>
                        F
                    </p>
                </Box>
                <Box className="require-week">
                    <p>
                        S
                    </p>
                </Box>
            </Box>

            <Box className="required-time-box">
                    <TextField
                        sx={{ mt: 1, mb: 2 }}
                        className='form-input w-100'
                        type="time"
                        size="small"
                    />
                </Box>
        </>
    );
};

export default Once;