import axios from "axios";

const setAuthToken = () => {
  const token = JSON.parse(localStorage.getItem("userData"))?.token;
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    throw new Error("Unauthorized");
  }
};

export { setAuthToken };
