import React from 'react'
import { useState } from 'react'
import { formatDate } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { Typography } from '@mui/material'
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
// import { INITIAL_EVENTS, createEventId } from './event-utils'
import { Box } from "@mui/material";
import appointPopoverImg from '../../assets/images/client-profile.jpg'
import HomeIcon from '../../assets/images/home-icon.svg'
import callIcon from '../../assets/images/table-call.png'
import locationIcon from '../../assets/images/location-icon.svg'
import popImg1 from '../../assets/images/team2.png'
import popImg2 from '../../assets/images/team3.png'
import EyeIcon from "../../assets/images/eye-icon.svg"
import Dropdown from 'react-bootstrap/Dropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import popArrow from '../../assets/images/popover-arrow.png'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalenderData from './CalenderData'

import {
    Checkbox,

} from "@mui/material";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


export default class DemoApp extends React.Component {



    state = {
        weekendsVisible: true,
        currentEvents: []
    }

    render() {


        return (


            <div id='calender'>

                {this.renderSidebar()}
                <div className='demo-app-main'>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        initialView='dayGridMonth'
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={this.state.weekendsVisible}
                        // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                        select={this.handleDateSelect}
                        eventContent={renderEventContent} // custom render function
                        //eventClick={this.handleEventClick}
                        eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                    /* you can update a remote database when these fire:
                    eventAdd={function(){}}
                    eventChange={function(){}}
                    eventRemove={function(){}}
                    */
                    />
                </div>

                <Box className='tablebutton-section d-flex align-items-center '>

                    <Box className='d-flex align-items-center justify-content-between '>

                        <Box className='buttom-check ms-0 d-flex align-items-center justify-content-between'>
                            <Checkbox sx={{ padding: '0' }} {...label} defaultChecked />
                            <span>Select All </span>
                        </Box>

                        <Box className='buttom-check ms-0 d-flex align-items-center justify-content-between'>
                            <Checkbox sx={{ padding: '0' }} {...label} defaultChecked />
                            <span>Accepted </span>
                        </Box>

                        <Box className='buttom-check ms-0 d-flex align-items-center justify-content-between'>
                            <Checkbox sx={{ padding: '0' }} {...label} defaultChecked />
                            <span>Started </span>
                        </Box>

                        <Box className='buttom-check ms-0 d-flex align-items-center justify-content-between'>
                            <Checkbox sx={{ padding: '0' }} {...label} defaultChecked />
                            <span>Completed </span>
                        </Box>

                        <Box className='buttom-check ms-0 d-flex align-items-center justify-content-between'>
                            <Checkbox sx={{ padding: '0' }} {...label} defaultChecked />
                            <span>Pending </span>
                        </Box>

                        <Box className='buttom-check ms-0 d-flex align-items-center justify-content-between'>
                            <Checkbox sx={{ padding: '0' }} {...label} defaultChecked />
                            <span>Rejected </span>
                        </Box>

                        <Box className='buttom-check ms-0 d-flex align-items-center justify-content-between'>
                            <Checkbox sx={{ padding: '0' }} {...label} defaultChecked />
                            <span>Changed by SW </span>
                        </Box>

                        <Box className='buttom-check ms-0 d-flex align-items-center justify-content-between'>
                            <Checkbox sx={{ padding: '0' }} {...label} defaultChecked />
                            <span>Changed by Admin </span>
                        </Box>



                    </Box>
                </Box>
            </div>
        )
    }

    renderSidebar() {
        return (
            <div className='demo-app-sidebar'>



            </div>
        )
    }

    handleWeekendsToggle = () => {
        this.setState({
            weekendsVisible: !this.state.weekendsVisible
        })
    }

    handleDateSelect = (selectInfo) => {
        let title = prompt('Please enter a new title for your event')
        let calendarApi = selectInfo.view.calendar

        calendarApi.unselect() // clear date selection

        if (title) {
            calendarApi.addEvent({
                // id: createEventId(),
                title,
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                allDay: selectInfo.allDay
            })
        }
    }

    handleEventClick = (clickInfo) => {
        // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        //     clickInfo.event.remove()
        // }
    }

    handleEvents = (events) => {
        this.setState({
            currentEvents: events
        })
    }


}

function renderEventContent(eventInfo) {



    return (
        <>
            <div>
                <b>{eventInfo.timeText}</b>
                <CalenderData />
            </div>
        </>
    )
}

function renderSidebarEvent(event) {
    return (
        <li key={event.id}>
            <b>{formatDate(event.start, { year: 'numeric', month: 'short', day: 'numeric' })}</b>
            <div>{event.title}</div>
        </li>
    )
}