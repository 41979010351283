import * as React from "react";
import "../assets/css/header.css";
import "../assets/css/pagination.css";
import "../App.css";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import logo from "../assets/images/hubshift-logo1.png";
import profile from "../assets/images/profile-icon.png";
import { Container } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Stack } from "@mui/material";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fieldLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("clientId");
    navigate("/");
    window.location.reload();
    toast.success("You have successfully logged out!");
  };

  const userData = localStorage.getItem("userData");
  const userObject =
    userData !== undefined && userData !== null ? JSON.parse(userData) : "";
  const [user, setUser] = useState(userObject);
  return (
    <React.Fragment>
      <Box className="header">
        <Container className="container">
          <Box className="navbar">
            <Box className="logo ">
              <Link to="/">
                <img src={logo} />
              </Link>
            </Box>
            <Box className="navbar-menu">
              <Box className="navbar-item-box">
                {/* <Typography>
                  <Link className="menu-link">Home</Link>{" "}
                </Typography>
                <Typography>
                  <Link className="menu-link">Contents</Link>
                </Typography>
                <Typography>
                  <Link className="menu-link">Categories</Link>
                </Typography>
                <Typography>
                  <Link className="menu-link">Settings</Link>
                </Typography> */}
              </Box>
              <Box className="navbar-profile-box ">
                {/* <NotificationsIcon />

                <Tooltip title="User Profile">
                  <IconButton
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar alt="Remy Sharp" src={profile} />
                  </IconButton>
                </Tooltip> */}

                <>
                  <Stack
                    onClick={handleClick}
                    className="menu-link"
                    direction="row"
                    spacing={0}
                  >
                    <Typography className="menu-link-text">
                      Welcome {user ? user?.firstName : ""}
                    </Typography>

                    <ArrowDropDownIcon className="menu-link-icons" />
                  </Stack>
                </>
              </Box>
            </Box>
            <Box className="menu-toggle">
              <MenuIcon />
            </Box>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {/* <MenuItem onClick={handleClose}>
              <Avatar /> Profile
            </MenuItem> */}
            {/* <MenuItem onClick={handleClose}>
              <Avatar /> My account
            </MenuItem> */}
            <Divider />
            {/* <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Add another account
            </MenuItem> */}
            {/* <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem> */}
            <MenuItem>Type : {user ? user?.userTypeName : ""}</MenuItem>
            <MenuItem onClick={fieldLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Container>
      </Box>
    </React.Fragment>
  );
}
