import * as React from 'react';
import Box from '@mui/material/Box';
import '../../App.css'
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import TableForm from '../ui/TableForm';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';



export default function BasicTextFields() {
    const top100Films = [
        { label: 'The Shawshank Redemption', year: 1994 },
        { label: 'The Godfather', year: 1972 },
        { label: 'The Godfather: Part II', year: 1974 },
        { label: 'The Dark Knight', year: 2008 },
        { label: '12 Angry Men', year: 1957 },
        { label: "Schindler's List", year: 1993 },
        { label: 'Pulp Fiction', year: 1994 },]




    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [value, setValue] = React.useState();

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <Box id='personal-info'>
            <Box className='d-flex align-items-center justify-content-between'>
                <Typography className='step-count'>Step 2/5</Typography>
                <Typography className='employee-id'>Employee ID: <span>12345</span> </Typography>
            </Box>

            <Box className='personal-info-main'>
                <Box className='form-heading-section'>
                    <Typography className='form-heading'>Personal Documents</Typography>
                </Box>

                <Box className='personal-info'>

                    <Grid container spacing={2}>
                        <Grid item xs={6} size="small">
                            <Typography sx={{ pb: 1 }}>Passport Size Photo</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} >
                            <Typography sx={{ pb: 1 }}>Passport</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6} >


                        </Grid>



                        <Grid item xs={3} >

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Date</label>
                        </Grid>



                        <Grid item xs={6} className='input'>
                            <Typography sx={{ pb: 1 }}>Visa</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} className='input'>
                            <Typography sx={{ pb: 1 }}>Driving License</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3} >

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Date</label>
                        </Grid>
                        <Grid item xs={3} >


                        </Grid>
                        <Grid item xs={3} >

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Date</label>
                        </Grid>
                        <Grid item xs={3} >
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={top100Films}
                                size="small"
                                renderInput={(params) => <TextField {...params} label="Select State" />}
                            />
                        </Grid>
                        <Grid item xs={6} className='input'>
                            <Typography sx={{ pb: 1 }}>Car Insurance Policy</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} className='input'>
                            <Typography sx={{ pb: 1 }}>Car Registration Document</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6} >


                        </Grid>



                        <Grid item xs={3} >

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Date</label>
                        </Grid>


                        <Grid item xs={6} className='input'>
                            <Typography sx={{ pb: 1 }}>Covid Vaccination Certificate - Dose 1</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} className='input'>
                            <Typography sx={{ pb: 1 }}>Covid Vaccination Certificate - Dose 2</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3} >

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Dose</label>
                        </Grid>

                        <Grid item xs={3} className='pt-0'>


                        </Grid>

                        <Grid item xs={3} >

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Dose</label>
                        </Grid>


                        <Grid item xs={6} className='input'>
                            <Typography sx={{ pb: 1 }}>Covid Vaccination Certificate - Dose 3</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} className='input'>
                            <Typography sx={{ pb: 1 }}>Covid Vaccination Certificate - Dose 4</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={3} >

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Dose</label>
                        </Grid>

                        <Grid item xs={3} >


                        </Grid>

                        <Grid item xs={3} >

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>
                            <label className='date-lable'>Enter Expiry Dose</label>
                        </Grid>

                        <Grid item xs={6} className='input'>
                            <Typography sx={{ pb: 1 }}>AFP Police Clearance</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        <Grid item xs={6}></Grid>
                        <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={3}>
                                    <DesktopDatePicker
                                        className='form-input date-input-filed'
                                        format="DD-MM-YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField size="small" sx={{ mb: 1 }}  {...params} inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY"
                                        }} />}
                                    />

                                </Stack>
                            </LocalizationProvider>

                        </Grid>
                        <Grid item xs={3} className='d-flex align-items-center'>
                            <Typography className='date-lable'>Expiry Date: - </Typography>
                        </Grid>
                    </Grid>


                    <Box sx={{ mt: 1 }}>

                        <Typography className='modal-add-btn'>100 points Identification Details
                            <Button ><AddIcon onClick={handleOpen} /></Button>
                        </Typography>
                        <Typography >Total Points: 0</Typography>
                    </Box>
                </Box>

            </Box>

            {/* Modal section  */}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id='modal'
            >
                <TableForm />

            </Modal>

        </Box>

    );
}