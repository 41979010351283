import * as React from 'react'
import Box from '@mui/material/Box';
import Appbar from '../Layout/Appbar';
import Sidebar from '../Layout/Sidebar';
import Appointmentschedule from './Appointmentschedule';

export default function Dashboard() {

    return (
        <Box id="appointment">
            <Box sx={{ width: '100%' }} className='appbar-border'>
                <Box className='appbar-main'>
                    <Appbar />
                </Box>
            </Box>
            <Box className=' d-flex dashboard-main-box'>
                <Sidebar />
                <Box className='main'>
                    <Appointmentschedule />
                </Box>

            </Box>
        </Box>
    )

}