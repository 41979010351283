import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Grid, Typography } from "@mui/material";
import logo from "../assets/images/hubshift-logo1.png";
import "../assets/css/web-css.css";
import "../assets/css/dashboard.css";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import headerMenuImg from "../assets/images/noImage.png";
import dashboardImg from "../assets/images/Dashboard-icon.png";
import SignOutImg from "../assets/images/web-signout.png";
import { getuserDetail } from "../../src/api";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import menuBar from "../assets/images/menu-bars-icon.png";
import PickIcon from "../assets/images/pick-icon.png";
import MenuSearch from "../assets/images/menu-search.png";
import MenuBlogIcon from "../assets/images/menublog-icon.png";
import MenuHelpCenterIcon from "../assets/images/help-center-icon.png";
import MenuGuideIcon from "../assets/images/menu-guide-icon.png";
import Feature1 from "../assets/images/feature1.png";
import Feature2 from "../assets/images/feature2.png";
import Feature3 from "../assets/images/feature3.png";
import Feature4 from "../assets/images/feature4.png";
import Feature5 from "../assets/images/feature5.png";
import Feature6 from "../assets/images/feature6.png";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

export default function WebHeader() {
  const [age, setAge] = React.useState("");
  const [fullName, setFullName] = useState("");
  const [profilePic, setProfilePic] = useState(headerMenuImg);
  const [profilePicError, setProfilePicError] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const featureMenu = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [expandedRes, setExpandedRes] = React.useState(false);
  const ResourcesMenu = (panel) => (event, isExpandedRes) => {
    setExpandedRes(isExpandedRes ? panel : false);
  };
  const [expandedMarket, setExpandedMarket] = React.useState(false);
  const MarketMenu = (panel) => (event, isExpandedMarket) => {
    setExpandedMarket(isExpandedMarket ? panel : false);
  };

  // feature menu function

  const handleProfilePicError = () => {
    setProfilePicError(true);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const fieldSignIn = () => {
    //  navigate("/sign-in");
    window.location.href = "/sign-in";
  };

  let userData = JSON.parse(localStorage.getItem("userData"));

  let exploreMarket = JSON.parse(localStorage.getItem("exploreMarket"));
  let hcId = exploreMarket?.hcId;

  let roleData, userStatusData, userValId;
  if (userData !== null) {
    roleData = userData?.role;
    userStatusData = userData?.userStatus;
    userValId = userData?._id;
  }

  const fieldDashboard = () => {
    if (roleData === "healthCarer") {
      if (userStatusData === "Approved") {
        if (userData?.packageMenuId) {
          window.location.href = "/health-carer/home";
        } else {
          window.location.href = "/health-carer/list-package-menu";
        }
      } else {
        window.location.href = "/health-carer/onboard";
      }
    } else if (roleData === "supportCoordinator") {
      if (userStatusData === "Approved") {
        if (userData?.packageMenuId) {
          window.location.href = "/support-coordinator/home";
        } else {
          window.location.href = "/support-coordinator/list-package-menu";
        }
      } else {
        window.location.href = "/support-coordinator/onboard";
      }
    } else if (roleData === "serviceProvider") {
      if (userStatusData === "Approved") {
        if (userData?.packageMenuId) {
          window.location.href = "/service-provider/home";
        } else {
          window.location.href = "/service-provider/list-package-menu";
        }
      } else {
        window.location.href = "/service-provider/onboard";
      }
    } else if (roleData === "client" && hcId !== undefined && hcId !== null) {
      window.location.href = "/client/view-details";
    } else if (roleData === "client") {
      if (userStatusData === "Approved") {
        if (userData?.packageMenuId) {
          window.location.href = "/client/home";
        } else {
          window.location.href = "/client/list-package-menu";
        }
      } else {
        window.location.href = "/client/onboard";
      }
    } else if (roleData === "superAdmin") {
      window.location.href = "/super-admin/home";
    } else if (roleData === "Stakeholder") {
      window.location.href = "/stakeholder/home";
    } else if (roleData === "itManager") {
      window.location.href = "/it-manager/home";
    } else if (roleData === "planManager") {
      window.location.href = "/plan-manager/home";
    } else if (roleData === "supervisor") {
      window.location.href = "/supervisor/home";
    } else if (roleData === "accountant") {
      window.location.href = "/accountant/home";
    } else if (roleData === "familyMember") {
      window.location.href = "/family-member/home";
    } else if (roleData === "generalManager") {
      window.location.href = "/general-manager/home";
    } else if (roleData === "hr") {
      window.location.href = "/hr/home";
    } else {
      const roleDataLowerCase = roleData.toLowerCase();
      window.location.href = `/${roleDataLowerCase}/home`;
    }
  };

  // useEffect(() => {
  //   axios
  //     .get(`${getuserDetail}/${userValId}`)
  //     .then(function (res) {
  //       if (res) {
  //         setProfilePic(res?.data?.data?.image?.location);
  //         let fullName =
  //           res?.data?.data?.firstName + " " + res?.data?.data?.lastName;
  //         setFullName(fullName);
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, [userValId]);

  useEffect(() => {
    if (userValId !== undefined) {
      getUserdataDetails();
    }
  }, [userValId]);
  const getUserdataDetails = async () => {
    axios
      .get(`${getuserDetail}/${userValId}`)
      .then(function (res) {
        if (res) {
          setProfilePic(res?.data?.data?.image?.location);
          let fullName =
            res?.data?.data?.firstName + " " + res?.data?.data?.lastName;
          setFullName(fullName);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fieldLogout = () => {
    localStorage.removeItem("userData");

    window.location.href = "/";
    toast.success("You have successfully logged out!");
  };

  const [menuItem, setMenuItem] = React.useState(null);
  const openMenu = Boolean(menuItem);
  const handleMenuClick = (event) => {
    setMenuItem(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuItem(null);
  };

  const [marketmenuItem, setmarketMenuItem] = React.useState(null);
  const openmarketMenu = Boolean(marketmenuItem);
  const markethandleMenuClick = (event) => {
    setmarketMenuItem(event.currentTarget);
  };
  const markethandleMenuClose = () => {
    setmarketMenuItem(null);
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector("#webHeader");
    const scrollTop = window.scrollY;
    scrollTop >= 150
      ? header.classList.add("header-sticky")
      : header.classList.remove("header-sticky");
  };

  //  drawer function

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      className="web-menu-drawwer"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <nav className="w-100">
        <Link to="javascript:;" className="header-menu-link">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={featureMenu("panel1")}
            className="sub-menuAccordion"
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Button id="basic-button">Features</Button>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {/* <Typography className="menu-dropsub-title">Features</Typography> */}
                <Grid container>
                  <Grid item xs={12} md={6} sm={6}>
                    <MenuItem onClick={featureClose}>
                      <Link className="header-select-link" to="/features">
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={Feature1} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Feature 1</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </Link>
                    </MenuItem>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <MenuItem onClick={featureClose}>
                      <Link className="header-select-link" to="/features">
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={Feature2} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Feature 2</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </Link>
                    </MenuItem>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <MenuItem onClick={featureClose}>
                      <Link className="header-select-link" to="/features">
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={Feature3} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Feature 3</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </Link>
                    </MenuItem>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <MenuItem onClick={featureClose}>
                      <Link className="header-select-link" to="/features">
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={Feature4} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Feature 4</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </Link>
                    </MenuItem>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <MenuItem onClick={featureClose}>
                      <Link className="header-select-link" to="/features">
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={Feature5} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Feature 5</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </Link>
                    </MenuItem>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <MenuItem onClick={featureClose}>
                      <Link className="header-select-link" to="/features">
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={Feature6} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Feature 6</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </Link>
                    </MenuItem>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Link>

        <Link to="/pricing" className="header-menu-link">
          Pricing
        </Link>
        <Link className="header-menu-link">
          <Accordion
            expanded={expandedRes === "panel1"}
            onChange={ResourcesMenu("panel1")}
            className="sub-menuAccordion"
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Button id="resource-menu">Resources</Button>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <MenuItem onClick={resourceClose}>
                      <Link className="header-select-link" to="/blogs">
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={MenuBlogIcon} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Blogs</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </Link>
                    </MenuItem>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <MenuItem onClick={resourceClose}>
                      <Link className="header-select-link" to="/guides">
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={MenuGuideIcon} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Guides</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </Link>
                    </MenuItem>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <MenuItem onClick={resourceClose}>
                      <Link className="header-select-link" to="/help-center">
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={MenuHelpCenterIcon} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Help Center</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </Link>
                    </MenuItem>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Link>

        <Link className="header-menu-link header-menu-custom">
          <Accordion
            expanded={expandedMarket === "panel1"}
            onChange={MarketMenu("panel1")}
            className="sub-menuAccordion"
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Button>Marketplace</Button>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Grid container>
                  {(userData === null || userData.role === "healthCarer") && (
                    <Grid item xs={12} sm={12} md={12}>
                      <MenuItem value="Month">
                        <Link
                          className="header-select-link"
                          to="/pickup-shifts"
                        >
                          <Box className="d-flex align-items-start">
                            <Box className="menu-icons">
                              <img src={PickIcon} />
                            </Box>
                            <Box className="menu-drop-text">
                              <Typography>Pick up Shifts</Typography>
                              <span>Find Shifts</span>
                            </Box>
                          </Box>
                        </Link>
                      </MenuItem>
                    </Grid>
                  )}

                  {(userData === null || userData.role === "client") && (
                    <Grid item xs={12} sm={12} md={12}>
                      <MenuItem value="Custom">
                        <Link className="header-select-link" to="/get-support">
                          <Box className="d-flex align-items-start">
                            <Box className="menu-icons">
                              <img src={MenuSearch} />
                            </Box>
                            <Box className="menu-drop-text">
                              <Typography>Get Support</Typography>
                              <span>Find Health Carers</span>
                            </Box>
                          </Box>
                        </Link>
                      </MenuItem>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Link>

        <Link to="/about" className="header-menu-link">
          About
        </Link>
        <a href="/contact" className="header-menu-link header-menu-custom2">
          Contact
        </a>
        {userData === null ? (
          <>
            <Link
              to="/book-demo"
              className="header-menu-link  book-demo-button"
            >
              Book A Demo
            </Link>
            <Link
              to="/sign-in"
              className="header-menu-link sign-in-button header-menu-custom3"
            >
              Sign in
            </Link>
            <Link
              to="/healthcarer-sign-up"
              className="header-menu-link sign-in-button header-menu-custom4"
            >
              Sign Up as Health Carer
            </Link>
          </>
        ) : (
          ""
        )}
      </nav>
    </Box>
  );

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const featureOpen = Boolean(anchorEl2);
  const featureClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const featureClose = () => {
    setAnchorEl2(null);
  };

  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const resourceOpen = Boolean(anchorEl3);
  const resourceClick = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const resourceClose = () => {
    setAnchorEl3(null);
  };

  return (
    <>
      <Box className="web-header" id="webHeader">
        <Box className="webContainer">
          <Box className="d-flex align-items-center justify-content-between">
            <Box className="webHeader-logo">
              <Link to="/">
                <img src={logo} />
              </Link>
            </Box>
            <Box className="webHeader-menubar  webMenu-responsive-hide">
              {userData === null ? (
                <Box className="w-100 text-end">
                  <Link
                    to="/healthcarer-sign-up"
                    className="webHeader-menubar-signUp"
                  >
                    Sign up as Health Carer
                  </Link>
                </Box>
              ) : (
                ""
              )}
              <Box className="d-flex align-items-center justify-content-end ">
                <nav className="d-flex align-items-center">
                  <Link className="header-menu-link">
                    <Button
                      id="feature-menu"
                      aria-controls={featureOpen ? "feature-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={featureOpen ? "true" : undefined}
                      onClick={featureClick}
                    >
                      Features <ArrowDropDownIcon />
                    </Button>
                  </Link>

                  <Menu
                    id="feature-menu"
                    anchorEl={anchorEl2}
                    open={featureOpen}
                    onClose={featureClose}
                    className="webmenu-dropdown"
                    MenuListProps={{
                      "aria-labelledby": "feature-menu",
                    }}
                  >
                    <Box>
                      <Typography className="menu-dropsub-title">
                        Features
                      </Typography>
                      <Grid container>
                        <Grid item xs={12} md={6} sm={6}>
                          <MenuItem onClick={featureClose}>
                            <a href="/sil-management"
                              className="header-select-link"
                            // to="/sil-management"
                            >
                              <Box className="d-flex align-items-start ">
                                <Box className="menu-icons">
                                  <img src={Feature1} />
                                </Box>
                                <Box className="menu-drop-text">
                                  <Typography>SIL Management </Typography>
                                  <span>Manage with Ease </span>
                                </Box>
                              </Box>
                            </a>
                          </MenuItem>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <MenuItem onClick={featureClose}>
                            <a href="/roster-management"
                              className="header-select-link"
                            // to="/roster-management"
                            >
                              <Box className="d-flex align-items-start ">
                                <Box className="menu-icons">
                                  <img src={Feature2} />
                                </Box>
                                <Box className="menu-drop-text">
                                  <Typography>Roster Management</Typography>
                                  <span>Optimize Your Planning </span>
                                </Box>
                              </Box>
                            </a>
                          </MenuItem>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <MenuItem onClick={featureClose}>
                            <a href="/staff-induction"
                              className="header-select-link"
                            // to="/staff-induction"
                            >
                              <Box className="d-flex align-items-start ">
                                <Box className="menu-icons">
                                  <img src={Feature3} />
                                </Box>
                                <Box className="menu-drop-text">
                                  <Typography>Staff Induction</Typography>
                                  <span>Enhance Your Training</span>
                                </Box>
                              </Box>
                            </a>
                          </MenuItem>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <MenuItem onClick={featureClose}>
                            <a href="/document-passport"
                              className="header-select-link"
                            // to="/document-passport"
                            >
                              <Box className="d-flex align-items-start ">
                                <Box className="menu-icons">
                                  <img src={Feature4} />
                                </Box>
                                <Box className="menu-drop-text">
                                  <Typography>Document Passport</Typography>
                                  <span>Secure Your Documents </span>
                                </Box>
                              </Box>
                            </a>
                          </MenuItem>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <MenuItem onClick={featureClose}>
                            <a href="/health-dashboard"
                              className="header-select-link"
                            // to="/health-dashboard"
                            >
                              <Box className="d-flex align-items-start ">
                                <Box className="menu-icons">
                                  <img src={Feature5} />
                                </Box>
                                <Box className="menu-drop-text">
                                  <Typography>Health Dashboard</Typography>
                                  <span>Manage Health Easily </span>
                                </Box>
                              </Box>
                            </a>
                          </MenuItem>
                        </Grid>
                        {/* <Grid item xs={12} md={6} sm={6}>
                          <MenuItem onClick={featureClose}>
                            <Link className="header-select-link" to="/features">
                              <Box className="d-flex align-items-start ">
                                <Box className="menu-icons">
                                  <img src={Feature6} />
                                </Box>
                                <Box className="menu-drop-text">
                                  <Typography>Feature 6</Typography>
                                  <span>Lorem Ipsum</span>
                                </Box>
                              </Box>
                            </Link>
                          </MenuItem>
                        </Grid> */}
                      </Grid>
                    </Box>
                  </Menu>

                  <a
                    href="/pricing"
                    //  to="/pricing"
                    className="header-menu-link"
                  >
                    Pricing
                  </a>

                  <Link className="header-menu-link">
                    <Button
                      id="resource-menu"
                      aria-controls={resourceOpen ? "resource-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={resourceOpen ? "true" : undefined}
                      onClick={resourceClick}
                    >
                      Resources
                      <ArrowDropDownIcon />
                    </Button>
                  </Link>
                  <Menu
                    id="resource-menu"
                    anchorEl={anchorEl3}
                    open={resourceOpen}
                    className="webmenu-dropdown"
                    onClose={resourceClose}
                    MenuListProps={{
                      "aria-labelledby": "resource-menu",
                    }}
                  >
                    <Typography className="menu-dropsub-title">
                      Resources
                    </Typography>

                    <MenuItem onClick={resourceClose}>
                      <a
                        href="/blogs"
                        className="header-select-link"
                      //  to="/blogs"
                      >
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={MenuBlogIcon} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Blogs</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </a>
                    </MenuItem>
                    <MenuItem onClick={resourceClose}>
                      <a
                        href="/guides"
                        className="header-select-link"
                      // to="/guides"
                      >
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={MenuGuideIcon} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Guides</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </a>
                    </MenuItem>

                    <MenuItem onClick={resourceClose}>

                      <a
                        href="/help-center"
                        className="header-select-link"
                      // to="/help-center"
                      >
                        <Box className="d-flex align-items-start ">
                          <Box className="menu-icons">
                            <img src={MenuHelpCenterIcon} />
                          </Box>
                          <Box className="menu-drop-text">
                            <Typography>Help Center</Typography>
                            <span>Lorem Ipsum</span>
                          </Box>
                        </Box>
                      </a>
                    </MenuItem>
                  </Menu>
                  <Link className="header-menu-link">
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={markethandleMenuClick}
                    >
                      Marketplace
                      <ArrowDropDownIcon />
                    </Button>
                  </Link>
                  <Menu
                    id="basic-menu"
                    anchorEl={marketmenuItem}
                    open={openmarketMenu}
                    className="webmenu-dropdown"
                    onClose={markethandleMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <Typography className="menu-dropsub-title">
                      MARKETPLACE
                    </Typography>

                    {(userData === null || userData.role === "healthCarer") && (
                      <MenuItem value="Month">
                        <a
                          className="header-select-link"
                          href="/pickup-shifts"
                        // to="/pickup-shifts"
                        >
                          <Box className="d-flex align-items-start">
                            <Box className="menu-icons">
                              <img src={PickIcon} />
                            </Box>
                            <Box className="menu-drop-text">
                              <Typography>Pick up Shifts</Typography>
                              <span>Find Shifts</span>
                            </Box>
                          </Box>
                        </a>
                      </MenuItem>
                    )}

                    {(userData === null || userData.role === "client") && (
                      <MenuItem value="Custom">
                        <a
                          className="header-select-link"
                          href="/get-support"
                        //  to="/get-support"

                        >
                          <Box className="d-flex align-items-start">
                            <Box className="menu-icons">
                              <img src={MenuSearch} />
                            </Box>
                            <Box className="menu-drop-text">
                              <Typography>Get Support</Typography>
                              <span>Find Health Carers</span>
                            </Box>
                          </Box>
                        </a>
                      </MenuItem>
                    )}
                  </Menu>

                  <a
                    href="/about"
                    // to="/about" 
                    className="header-menu-link"
                  >
                    About
                  </a>
                  <a href="/contact" className="header-menu-link">
                    Contact
                  </a>
                  {userData === null ? (
                    <>
                      <Link
                        to="/book-demo"
                        className="header-menu-link  book-demo-button"
                      >
                        Book A Demo
                      </Link>
                      <Link
                        to="/sign-in"
                        className="header-menu-link sign-in-button"
                      >
                        Sign in
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </nav>

                {userData !== null ? (
                  <Box>
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      className="Web-Header-ProfileButton"
                    >
                      <img
                        src={
                          profilePicError === false && profilePic
                            ? profilePic
                            : headerMenuImg
                        }
                        className="Web-Header-Profile-Img"
                        onError={handleProfilePicError}
                        alt={fullName}
                        title={fullName}
                      />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      className="Webheader-menu-Profile"
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <MenuItem
                        onClick={fieldDashboard}
                        className="d-flex align-items-center"
                      >
                        {" "}
                        <img src={dashboardImg} alt="Dashboard" /> Dashboard
                      </MenuItem>
                      <MenuItem
                        onClick={fieldLogout}
                        className="d-flex align-items-center"
                      >
                        {" "}
                        <img src={SignOutImg} alt="Sign Out" /> Sign out
                      </MenuItem>
                    </Menu>
                  </Box>
                ) : userData !== null &&
                  userData !== undefined &&
                  (userData?.role === "client" ||
                    userData?.role === "healthCarer") ? (
                  <FormControl
                    fullWidth
                    className="slot-calender web-header-select"
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="date-day-slot"
                      value={age}
                      size="small"
                      onChange={handleChange}
                      className="header-select"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="" disabled>
                        <Link className="header-select-link">
                          {" "}
                          Explore Market
                        </Link>
                      </MenuItem>

                      <MenuItem value="Month">
                        <Link
                          className="header-select-link"
                          to="/pickup-shifts"
                        >
                          Provide Support
                        </Link>
                      </MenuItem>
                      <MenuItem value="Custom">
                        <Link className="header-select-link" to="/get-support">
                          Find/Get Support
                        </Link>
                      </MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  ""
                )}
              </Box>
            </Box>
            <Box>
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button
                    onClick={toggleDrawer(anchor, true)}
                    className="menuBar-icon"
                  >
                    <img src={menuBar} />
                  </Button>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    {list(anchor)}
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
