import React from 'react';
import "../assets/css/tax.css";
import logo from "../assets/images/hubshift-logo1.png";
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header-landing">
      <div className="logo-landing ">
      <Box className="webContainer">
        <Box className="webHeader-logo">
          <Link to="/">
            <img src={logo} />
          </Link>
        </Box>
        </Box>
      </div>
    </header>
  );
};

export default Header;
