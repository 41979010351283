import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import active from "../../../assets/images/Active icon.svg";
import { Card, IconButton, Stack } from "@mui/material";

export default function BasicPopover() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Stack direction="column" alignItems={"center"}>
        <img src={active} height="20px" width="20px" />{" "}
        <Typography
          component="div"
          color="#109648"
          fontSize={11}
          // style={{ marginLeft: "-5px" }}
        >
          {" "}
          Active{" "}
        </Typography>
      </Stack>
    </div>
  );
}
