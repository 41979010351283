import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../Web-Header";
import WebFooter from "../Web-footer";
import FeatureTitleBanner from "../../assets/images/feature-1banner.jpg";
import { Link } from "react-router-dom";
import "../../assets/css/dashboard.css";
import "../../assets/css/web-css.css";
import "../HomeUI/home-ui.css";
import docImage1 from "../../assets/images/docImage1.png";
import docImage2 from "../../assets/images/docImage2.png";
import docImage3 from "../../assets/images/docImage3.png";
import docImage4 from "../../assets/images/docImage4.png";
import docIcon1 from "../../assets/images/docIcon1.svg";
import docIcon2 from "../../assets/images/docIcon2.svg";
import docIcon3 from "../../assets/images/docIcon3.svg";
import docIcon4 from "../../assets/images/docIcon4.svg";
import docIcon5 from "../../assets/images/docIcon5.svg";
import docIcon6 from "../../assets/images/docIcon6.svg";
import docIcon7 from "../../assets/images/docIcon7.svg";
import docIcon8 from "../../assets/images/docIcon8.svg";
import docIcon9 from "../../assets/images/docIcon9.svg";
import docIcon10 from "../../assets/images/docIcon10.svg";

import QuestionAccordion from "../HomeUI/Question-Accordian";
import BookDemo from "../HomeUI/BookDemo";

const DocumentPassport = () => {
  return (
    <Box id="features">
      <Box className="web-topBanner-bg  feature-main-banner">
        <WebHeader />

        <Box className="feature-titleBox">
          <Box className="webContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} className="home-content-main ">
                <Box>
                  <h1>
                    Document Passport
                    <br />
                    {/* Title */}
                  </h1>
                  <Typography>
                    Our platform revolutionizes the way you handle certificates
                    and vital identity documents, ensuring they are safely
                    stored and easily accessible whenever you need them. 
                  </Typography>

                  <Box className="d-flex align-items-center">
                    <Link
                      to="/book-demo"
                      className="home-section-button web-primary-btnLarge cta1"
                    >
                      Book A Demo
                    </Link>
                    {/* <Link className="home-section-button cta2">CTA 2</Link> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="manageCarer-cardImg manageCarer-obj">
                  <img src={docImage1} />
                </Box>

                <Box className="blurBg blurRed"> </Box>
                {/* <Box className="blurBg blurPurple"> </Box> */}
                <Box className="blurBg blurYellow"></Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box className="webContainer">
        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={docImage2} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
          >
            <Box className="manageCarer-cardContent pLeft-80">
              <h6>Effortless Identity Document Management  </h6>
              <h3>Take Control of Your Identification</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  In the digital age, managing your identity documents should be
                  straightforward and secure. Hubshift's Document Passport
                  offers a robust system for secure document storage, giving you
                  peace of mind knowing that your sensitive information is
                  protected with state-of-the-art security measures. 
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={docIcon1} />
                      </Box>
                      <Box>
                        <Typography>Robust Storage System </Typography>
                        <span>
                          Offers strong and resilient storage capabilities.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={docIcon2} />
                      </Box>
                      <Box>
                        <Typography>
                          Sensitive Information Protection
                        </Typography>
                        <span>
                          Safeguards personal and confidential data from
                          unauthorized access.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={docIcon3} />
                      </Box>
                      <Box>
                        <Typography>Secure Document Storage</Typography>
                        <span>
                          Ensures documents are stored safely and securely.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={docIcon4} />
                      </Box>
                      <Box>
                        <Typography>Simplified Identity Management</Typography>
                        <span>
                          Streamlines the management and organization of
                          identity-related documents.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>Timely Document Expiration Notifications  </h6>
              <h3>Never Miss an Expiration Date</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  One of the unique features of our platform is the automated
                  document expiration notifications. Our system diligently
                  monitors the expiry dates of your stored documents, ensuring
                  you receive timely alerts.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={docIcon5} />
                      </Box>
                      <Box>
                        <Typography>Proactive Renewal Reminders</Typography>
                        <span>
                          Get reminders in advance to facilitate timely
                          renewals.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={docIcon6} />
                      </Box>
                      <Box>
                        <Typography>Maintain Credentials</Typography>
                        <span>
                          Ensures continuous validity of important
                          certifications and credentials
                        </span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={docIcon7} />
                      </Box>
                      <Box>
                        <Typography>Reliable Notification System</Typography>
                        <span>
                          Offers consistent and dependable alert services
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg">
              <img src={docImage3} />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={docImage4} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
          >
            <Box className="manageCarer-cardContent pLeft-80">
              <h6>Streamlined Document Sharing Across Australia   </h6>
              <h3>Simplify Your Transitions</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Whether you're transitioning between jobs or need to share
                  your professional credentials, Hubshift's Document Passport
                  makes document sharing seamless. With just a few clicks, you
                  can share your profile and documents within the Hubshift
                  platform across Australia, eliminating the hassle of email and
                  ensuring your information is shared securely and efficiently.
                </Typography>

                <Grid container sx={{ mt: 2 }} className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={docIcon8} />
                      </Box>
                      <Box>
                        <Typography>Quick Document Access</Typography>
                        <span>Provides fast access to stored documents.</span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={docIcon9} />
                      </Box>
                      <Box>
                        <Typography>Hassle-Free Email Elimination</Typography>
                        <span>
                          Reduces reliance on email for document sharing.
                        </span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={docIcon10} />
                      </Box>
                      <Box>
                        <Typography>
                          Seamless Professional Credential Sharing
                        </Typography>
                        <span>
                          Facilitates smooth sharing of professional
                          certifications and credentials.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
        </Grid>

        {/* <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>Streamlined Communication  </h6>
              <h3>Keep Everyone in the Loop</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  With automatic notifications, your staff will be promptly
                  informed of their schedules, ensuring everyone is on the same
                  page.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={orangeIcon} />
                      </Box>
                      <Box>
                        <Typography>Enhanced Staff Awareness</Typography>
                        <span>
                          Keeps all team members well-informed and aligned.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={yellowIcon} />
                      </Box>
                      <Box>
                        <Typography>Reduced Confusion</Typography>
                        <span>
                          Minimizes misunderstandings and uncertainties.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={purpleIcon} />
                      </Box>
                      <Box>
                        <Typography>Improved Staff Satisfaction</Typography>
                        <span>
                          Boosts overall staff morale and job satisfaction.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={blueIcon} />
                      </Box>
                      <Box>
                        <Typography>Cohesive Team Environment</Typography>
                        <span>
                          Strengthens team cohesion and mutual understanding.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Link to="#" className="home-primary-button">
                Learn More
              </Link> 
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={featureTitle4} />
            </Box>
          </Grid>
        </Grid> */}
      </Box>

      <Box className="question-accordian web-topBanner-bg space-lg">
        <Box className="webContainer">
          <Box className="text-center">
            <h1>Questions? We've got you covered.</h1>
          </Box>
          <Box className="space-pt-60">
            <QuestionAccordion />
          </Box>
        </Box>
      </Box>

      <Box className="space-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
};

export default DocumentPassport;
