import React, { useState } from "react";
import clsx from "clsx";
import { useForm, FormProvider } from "react-hook-form";
import { Button, Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

import Header from "../Header";
import PersonalInfo from "../form/Personal-Info";
import PersonalDocument from "../form/Personal-document";
import EducationDocument from "../form/Education-documents";
import ProfessionalDocument from "../form/Professional-documents";
import References from "../form/References";
import Thankyou from "../ui/Thankyou";

var step1 = "<strong>Personal Info </strong><br/> <span>Enter Details</span>";
var step2 =
  "<strong>Personal Documents </strong><br/> <span> Browse & Upload <span/>";
var step3 =
  "<strong>Education Documents </strong><br/> <span> Browse & Upload <span/>";
var step4 =
  "<strong>Professional Documents </strong><br/> <span> Browse & Upload </span";
var step5 = "<strong>References </strong><br/> <span> Enter Details  </span>";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    <div className="content" dangerouslySetInnerHTML={{ __html: step1 }}></div>,
    <div className="content" dangerouslySetInnerHTML={{ __html: step2 }}></div>,
    <div className="content" dangerouslySetInnerHTML={{ __html: step3 }}></div>,
    <div className="content" dangerouslySetInnerHTML={{ __html: step4 }}></div>,
    <div className="content" dangerouslySetInnerHTML={{ __html: step5 }}></div>,
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <PersonalInfo />;
    case 1:
      return <PersonalDocument />;
    case 2:
      return <EducationDocument />;
    case 3:
      return <ProfessionalDocument />;
    case 4:
      return <References />;
    default:
      return "unknown step";
  }
}

const LinaerStepper = () => {
  const classes = useStyles();
  const methods = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      nickName: "",
      emailAddress: "",
      phoneNumber: "",
      alternatePhone: "",
      address1: "",
      address2: "",
      country: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    console.log(data);
    if (activeStep === steps.length - 1) {
      fetch("https://jsonplaceholder.typicode.com/comments")
        .then((data) => data.json())
        .then((res) => {
          console.log(res);
          setActiveStep(activeStep + 1);
        });
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const CustomStepIcon = (props) => {
    const classes = useStyles();
    const { active, completed } = props;

    const stepIcons = {
      1: <PersonIcon />,
      2: <DescriptionIcon />,
      3: <FindInPageIcon />,
      4: <LibraryBooksIcon />,
      5: <SupervisorAccountIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {stepIcons[String(props.icon)]}
      </div>
    );
  };

  // const onSubmit = (data) => {
  //   console.log(data);
  // };
  return (
    <div id="stepper" className="stepper-box-main vertical-stepper-main">
      <Stepper
        className="stepper"
        orientation="vertical"
        alternativeLabel
        activeStep={activeStep}
      >
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step className="step-box" {...stepProps} key={index}>
              <StepLabel
                className="step-lable"
                StepIconComponent={CustomStepIcon}
                {...labelProps}
              >
                {step}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Box className="thankyou-main-box">
          <Box className="header-fixed ">
            <Header className="header-fixed" />
          </Box>
          <Box className="thankyou-page">
            <Thankyou />
          </Box>
        </Box>
      ) : (
        <>
          <FormProvider {...methods}>
            <form
              className="form-box"
              id="account-setup-form"
              onSubmit={methods.handleSubmit(handleNext)}
            >
              {getStepContent(activeStep)}

              <Box sx={{ textAlign: "right" }}>
                <Button
                  className={classes.button}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  id="back-btn"
                >
                  back
                </Button>

                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary "
                  id="next-btn"
                  // onClick={handleNext}
                  type="submit"
                >
                  Save & Next
                </Button>
              </Box>
            </form>
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default LinaerStepper;
