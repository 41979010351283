import React from "react";
import { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box } from "@mui/material";
import axios from "../../api/axios";
import { useEffect } from "react";
import { getAllCalendarAvailability, getAllLocation } from "../../api";
import FilterStore from "./store/FilterStore";
import { FieldsListLoading } from "../../pages/components/loading/FieldsListLoading";
import { Link } from "react-router-dom";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";
import Grid from "@mui/material/Grid";
import generateUrl from "../../helpers/urlHelper";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Typography } from "@mui/material";
import CloneMonthlyAvailability from "./CloneMonthlyAvailability";
import UpdateAvailability from "./UpdateAvailability";
import MyStore from "../../pages/healthCarer/availability/MyStore";
import noImage from "../../assets/images/noImage.png";
import Dropdown from "react-bootstrap/Dropdown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { Avatar, Stack } from "@mui/material";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import "./calendarcss.css";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const QuarterlyCalendar = (props) => {
  const [events, setEventData] = useState([]);

  const [calendarKey, setCalendarKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);

  //console.log("Here selectedQuarter", props?.selectedQuarter?.startDate);

  const {
    selectedCity,
    ApType2,
    month,
    startDate1,
    endDate1,
    slotType,
    searchTerm,
    selectedShift,
    cityList,
    showMenuItem,
  } = FilterStore();

  //zustand code
  const {
    resetFields,
    setField,
    Weekly,
    NextWeekly,
    Monthly,
    defaultCheck,
    altype,
    selectedDays,
    selectAll,
  } = MyStore();

  const ref = React.createRef();

  let userData = JSON.parse(localStorage.getItem("userData"));
  let userId = userData?._id;
  const urlLink = generateUrl();

  let currentDate = new Date();
  let currentDayOfMonth = currentDate.getDate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [selectedDay, setselectedDay] = useState();
  const [selectedDate, setselectedDate] = useState();
  const [currentActiveDay, setcurrentDay] = useState(currentDayOfMonth);
  const [dayData, setDays] = useState([]);
  const [isActive, setActive] = useState(false);
  const [selectedDateCopy, setselectedDateCopy] = useState();
  const [startTimeSelected, setstartTimeSelected] = useState();
  const [endTimeSelected, setendTimeSelected] = useState();

  const [editstartTimeSelected, seteditstartTimeSelected] = useState();
  const [editendTimeSelected, seteditendTimeSelected] = useState();
  const [updateAvailabilityFlag, setupdateAvailability] = useState(false);

  const [selectedMonth, setselectedMonth] = useState();

  useEffect(() => {
    getAllData();
  }, [
    endDate1,
    startDate1,
    selectedStatus,
    ApType2,
    selectedCity,
    searchTerm,
    selectedShift,
    props.selectedQuarter.startDate,
  ]);

  const getAllData = () => {
    //console.log("here getAllData function called.....");
    setIsLoading(true);
    axios
      .get(
        `${getAllCalendarAvailability}?start=${startDate1}&role=${userData?.role}&userId=${userId}&end=${endDate1}&shiftTypeId=${props.id}&searchTerm=${searchTerm}&city=${selectedCity}`
      )
      .then((res) => {
        setEventData(res?.data?.data);
        setCalendarKey(calendarKey + 1);
        const selectedMonth = {};
        const copymonth = {};
        //console.log("check res?.data?.data.length", res?.data?.data.length);
        //console.log("check res?.data?.data", res?.data?.data);

        res?.data?.data.length > 0 &&
          res?.data?.data.map((val) => {
            let currentDate = new Date(val.extendedProps.date);
            let currentDayOfMonth = currentDate.getDate();

            const monthName = currentDate.toLocaleString("default", {
              month: "short",
            });
            //console.log("check quarterly monthName", monthName);

            const monthN =
              selectedMonth[monthName] !== undefined
                ? selectedMonth[monthName]
                : [];

            //console.log("check quarterly monthN", monthN);

            copymonth[monthName] = [];

            selectedMonth[monthName] = [
              ...monthN,
              {
                date: val.extendedProps.date,
                day: currentDayOfMonth,
                startTime: val?.extendedProps?.startTime,
                endTime: val?.extendedProps?.endTime,
              },
            ];
          });
        //console.log("check quarterly selectedMonth", selectedMonth);
        Monthly[props.id]["QlSelectedDays"] = selectedMonth;
        Monthly[props.id]["CopyQlSelectedDays"] = copymonth;
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  //console.log("eventData", events);
  const goToNextDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.next();
    setField(
      "startDate1",
      calendarApi.currentData.dateProfile.currentRange.start
    );
    setField("endDate1", calendarApi.currentData.dateProfile.currentRange.end);
  };

  const goToPreviousDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.prev();
  };

  const goToDay = async () => {
    let calendarApi = ref.current.getApi();
    calendarApi.today();
    setField(
      "startDate1",
      calendarApi.currentData.dateProfile.currentRange.start
    );
    setField("endDate1", calendarApi.currentData.dateProfile.currentRange.end);
  };

  useEffect(() => {
    const days = getDaysFromCurrentMonth(new Date());

    setDays(days);
  }, []);

  const addAvailabilityPopop = (cellInfo, day, date) => {
    setselectedDay(day);
    setselectedDate(date);

    const selectedDate = new Date(date);
    const monthName = selectedDate.toLocaleString("default", {
      month: "short",
    });

    setselectedMonth(monthName);

    setModalOpen1(true);
    setupdateAvailability(false);
  };

  const editAvailabilityPopop = (cellInfo, day, date, startTime, endTime) => {
    let currentDate = new Date(date);
    let currentDayOfMonth = currentDate.getDate();

    const monthName = currentDate.toLocaleString("default", {
      month: "short",
    });

    setselectedMonth(monthName);

    setselectedDay(currentDayOfMonth);
    setselectedDate(date);
    setModalOpen1(true);
    seteditstartTimeSelected(startTime);
    seteditendTimeSelected(endTime);
    setupdateAvailability(true);
  };

  const removeAvailability = (cellInfo, day, date, startTime, endTime) => {
    Swal.fire({
      icon: "warning",
      title: "Delete Availability",
      showCancelButton: true,
      text: `Are you sure you want to delete the Availability?`,
      confirmButtonText: "Yes, proceed it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        let currentDate = new Date(date);

        const monthName = currentDate.toLocaleString("default", {
          month: "short",
        });

        // Add your code to handle availability removal here
        // You can call a function or perform any action you need
        handleAvailabilityRemoval(
          cellInfo,
          day,
          date,
          startTime,
          endTime,
          monthName
        );
        Swal.fire("Deleted!", "Availability has been deleted.", "success");
      } else if (result.isDismissed) {
        Swal.fire("Cancelled", "Availability deletion cancelled.", "info");
      }
    });
  };

  const handleAvailabilityRemoval = (
    cellInfo,
    day,
    date,
    startTime,
    endTime,
    monthName
  ) => {
    let selectedDays = Monthly[props.id]["QlSelectedDays"][monthName];

    const selectedDayData = {
      date: date,
      day: day,
      startTime: startTime,
      endTime: endTime,
    };

    if (selectedDays.length > 0) {
      selectedDays = selectedDays.filter((val) => {
        if (val.date === selectedDayData.date) {
          return false;
        } else {
          return true;
        }
      });
    }

    Monthly[props.id]["QlSelectedDays"][monthName] = selectedDays;

    setField("Monthly", Monthly);

    const eventData = events.filter((val) => {
      if (val.extendedProps.date === date) {
        return false;
      } else {
        return true;
      }
    });

    setEventData(eventData);

    props?.setAvalabilitys();
  };

  const handleCloseModalAvailability = (e) => {
    e.preventDefault();
    setModalOpen1(false);
  };

  const handleClose = () => {
    setModalOpen1(false);
  };

  const checkDate = (date) => {
    if (Monthly[props.id]["CopyFromDate"] !== undefined && date !== undefined) {
      const eventDate = new Date(Monthly[props.id]["CopyFromDate"]);
      const currentDate = new Date(date);

      eventDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      if (eventDate.getTime() !== currentDate.getTime()) {
        Monthly[props.id]["CopyQlSelectedDays"] = {};
        setField("Monthly", Monthly);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkDateForSelectAll = (date) => {
    if (Monthly[props.id]["CopyFromDate"] !== undefined && date !== undefined) {
      const eventDate = new Date(Monthly[props.id]["CopyFromDate"]);
      const currentDate = new Date(date);

      eventDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      if (eventDate.getTime() === currentDate.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const compairDate = (date, selectedDateCopy) => {
    const eventDate = new Date(selectedDateCopy);
    const currentDate = new Date(date);

    eventDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    if (eventDate.getTime() === currentDate.getTime()) {
      return true;
    } else {
      return false;
    }
  };

  const handlePlusButtonClick = (cellInfo, startTime, endTime, date) => {
    setModalOpen(true);
    setstartTimeSelected(startTime);
    setselectedDateCopy(date);
    checkDate(date);
    setendTimeSelected(endTime);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };

  function getDaysBetweenDates(startDate, end) {
    const daysArray = [];
    const currentDate = new Date(startDate);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      daysArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return daysArray;
  }

  function getDaysFromCurrentMonth() {
    const startDate = props?.selectedQuarter?.startDate;
    const endDate = props?.selectedQuarter?.endDate;

    const months = {};

    const daysBetween = getDaysBetweenDates(startDate, endDate);

    daysBetween.forEach((day) => {
      const monthName = new Intl.DateTimeFormat("en-US", {
        month: "short",
      }).format(day);
      if (!months[monthName]) {
        months[monthName] = [];
      }
      const dayInfo = {
        date: day,
        day: day.getDate(),
        startTime: props?.selectedShift?.startTime,
        endTime: props?.selectedShift?.endTime,
      };
      months[monthName].push(dayInfo);
    });
    //console.log("Here month", month);
    return months;
  }

  const handleSelectAllChange = (e) => {
    Monthly[props.id]["selectAll"] = !Monthly[props.id]["selectAll"];

    Monthly[props.id]["CopyFromDate"] = selectedDateCopy;
    setField("Monthly", Monthly);

    if (e.target.checked) {
      const arr = [];

      const selectedData = {};

      Object.entries(dayData).map((days) => {
        const inr = [];
        days?.[1]?.map((val, key) => {
          const eventDate = new Date(val.date);
          const currentDate = new Date();

          eventDate.setHours(0, 0, 0, 0);
          currentDate.setHours(0, 0, 0, 0);

          if (eventDate.getTime() >= currentDate.getTime()) {
            inr.push(val);
            arr.push({
              start: val.date,
              end: val.date,
              title: " ",
              extendedProps: {
                address: "",
                date: val.date,
                dob: "",
                employeeId: "",
                endTime: endTimeSelected,
                fullName: "",
                gender: "",
                healthCarerId: "",
                jobtitles: "",
                jobtypes: "",
                shiftId: props.id,
                shiftName: "",
                slotId: "",
                day: val.day,
                startTime: startTimeSelected,
                status: "",
                userType: "",
                _id: "",
              },
            });
          }
        });

        selectedData[days?.[0]] = inr;
      });

      setEventData((prevData) => [...prevData, ...arr]);
      Monthly[props.id]["CopyQlSelectedDays"] = selectedData;
      setField("Monthly", Monthly);
    } else {
      getAllData();
      Monthly[props.id]["CopyQlSelectedDays"] = {};
      setField("Monthly", Monthly);
    }
    setField("Monthly", Monthly);
  };

  const handleDayChange = (e, day, month) => {
    if (props?.id !== undefined) {
      Monthly[props.id]["CopyFromDate"] = selectedDateCopy;

      let selectedDays =
        Monthly?.[props.id]?.["CopyQlSelectedDays"]?.[month] !== undefined
          ? Monthly?.[props.id]?.["CopyQlSelectedDays"]?.[month]
          : [];

      if (e.target.checked) {
        const arr = {
          start: day.date,
          end: day.date,
          title: "",
          extendedProps: {
            address: "",
            date: day.date,
            dob: "",
            employeeId: "",
            endTime: endTimeSelected,
            fullName: "",
            gender: "",
            healthCarerId: "",
            jobtitles: "",
            jobtypes: "",
            shiftId: props.id,
            shiftName: "",
            slotId: "",
            day: day.day,
            startTime: startTimeSelected,
            status: "",
            userType: "",
            _id: "",
          },
        };

        setEventData((prevData) => [...prevData, arr]);

        const selectedDayData = {
          date: day.date,
          day: day.day,
          startTime: startTimeSelected,
          endTime: endTimeSelected,
        };

        selectedDays.push(selectedDayData);

        Monthly[props.id]["CopyQlSelectedDays"][month] = selectedDays;
        setField("Monthly", Monthly);

        const dayDataLength = Object.values(dayData).reduce(
          (total, days) => total + days.length,
          0
        );

        const selecteddayLength = Object.values(
          Monthly[props.id]["CopyQlSelectedDays"]
        ).reduce((total, days) => total + days.length, 0);

        Monthly[props.id]["selectAll"] =
          dayDataLength === selecteddayLength ? true : false;
      } else {
        const eventData = events.filter((val) => {
          const eventDate = new Date(day?.date);
          const currentDate = new Date(val.extendedProps.date);

          eventDate.setHours(0, 0, 0, 0);
          currentDate.setHours(0, 0, 0, 0);

          if (eventDate.getTime() === currentDate.getTime()) {
          } else {
            return val;
          }
        });

        if (selectedDays.length > 0) {
          selectedDays = selectedDays.filter((val) => {
            const eventDate = new Date(day.date);
            const currentDate = new Date(val.date);

            eventDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);

            if (eventDate.getTime() === currentDate.getTime()) {
              return false;
            } else {
              return true;
            }
          });
        }

        Monthly[props.id]["CopyQlSelectedDays"][month] = selectedDays;

        const dayDataLength = Object.values(dayData).reduce(
          (total, days) => total + days.length,
          0
        );

        const selecteddayLength = Object.values(
          Monthly[props.id]["CopyQlSelectedDays"]
        ).reduce((total, days) => total + days.length, 0);

        Monthly[props.id]["selectAll"] =
          dayDataLength === selecteddayLength ? true : false;

        setEventData(eventData);
      }
      setField("Monthly", Monthly);
    }
  };

  const handleToggleMenuItem = (e, id) => {
    for (const key in showMenuItem) {
      if (key !== id) {
        showMenuItem[key] = false;
      }
    }
    setField("showMenuItem", {
      ...showMenuItem,
      [id]: !showMenuItem[id],
    });
  };

  const toggleClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    if (props.defaultCheck === true) {
      const arr = [];
      const selectedData = {};

      Object.entries(dayData).map((days) => {
        const inr = [];
        days?.[1]?.map((val, key) => {
          if (val.date > new Date()) {
            inr.push(val);
            arr.push({
              start: val.date,
              end: val.date,
              title: " ",
              extendedProps: {
                address: "",
                date: val.date,
                dob: "",
                employeeId: "",
                endTime: props?.selectedShift?.endTime,
                fullName: "",
                gender: "",
                healthCarerId: "",
                jobtitles: "",
                jobtypes: "",
                shiftId: props.id,
                shiftName: "",
                slotId: "",
                day: val.day,
                startTime: props?.selectedShift?.startTime,
                status: "",
                userType: "",
                _id: "",
              },
            });
          }
        });
        selectedData[days?.[0]] = inr;
      });

      setEventData((prevData) => [...prevData, ...arr]);
      Monthly[props.id]["QlSelectedDays"] = selectedData;
      setField("Monthly", Monthly);
    } else {
      getAllData();
      Monthly[props.id]["QlSelectedDays"] = {};
      setField("Monthly", Monthly);
    }
  }, [Monthly, props.defaultCheck]);

  const renderDayCellContent = (cellInfo) => {
    return (
      <div>
        {cellInfo.dayNumberText}
        {cellInfo.isPast ? (
          ""
        ) : (
          <AddIcon
            className="calender-add-icon-newcolor"
            onClick={(e) => {
              addAvailabilityPopop(
                cellInfo,
                cellInfo?.dayNumberText,
                cellInfo?.date
              );
            }}
          />
        )}
        &nbsp;
      </div>
    );
  };

  const addNewAvailability = (inputText, startTime, endTime, numberOf) => {
    const arr = [];

    for (let i = 0; i < numberOf; i++) {
      arr.push({
        start: selectedDate,
        end: selectedDate,
        title: "",
        extendedProps: {
          address: "",
          date: selectedDate,
          dob: "",
          employeeId: "",
          endTime: endTime,
          fullName: "",
          gender: "",
          healthCarerId: "",
          jobtitles: "",
          jobtypes: "",
          shiftId: props.id,
          shiftName: "",
          slotId: "",
          day: selectedDay,
          startTime: startTime,
          status: "",
          userType: "",
          _id: "",
        },
      });
    }

    setEventData((prevData) => [...prevData, ...arr]);

    const selectedDayData = {
      date: selectedDate,
      day: selectedDay,
      startTime: startTime,
      endTime: endTime,
    };

    let selectedDays =
      Monthly[props.id]["QlSelectedDays"][selectedMonth] !== undefined
        ? Monthly[props.id]["QlSelectedDays"][selectedMonth]
        : [selectedDayData];

    if (Monthly[props.id]["QlSelectedDays"][selectedMonth] !== undefined) {
      selectedDays.push(selectedDayData);
    }

    Monthly[props.id]["QlSelectedDays"][selectedMonth] = selectedDays;
    setSelectAlldata();

    setField("Monthly", Monthly);
    setModalOpen1(false);
  };

  const setSelectAlldata = () => {
    const dayDataLength = Object.values(dayData).reduce(
      (total, days) => total + days.length,
      0
    );

    const selecteddayLength = Object.values(
      Monthly[props.id]["QlSelectedDays"]
    ).reduce((total, days) => total + days.length, 0);

    Monthly[props.id]["selectAll"] =
      dayDataLength === selecteddayLength ? true : false;
  };

  const UpdateAvailabilityData = (inputText, startTime, endTime, numberOf) => {
    let selectedDays =
      Monthly[props.id]["QlSelectedDays"][selectedMonth] !== undefined
        ? Monthly[props.id]["QlSelectedDays"][selectedMonth]
        : [];

    const selectedDayData = {
      date: selectedDate,
      day: selectedDay,
      startTime: startTime,
      endTime: endTime,
    };

    if (selectedDays.length > 0) {
      selectedDays = selectedDays.map((val) => {
        if (val.date === selectedDayData.date) {
          val.startTime = selectedDayData.startTime;
          val.endTime = selectedDayData.endTime;
        }

        return val;
      });
    }

    Monthly[props.id]["QlSelectedDays"][selectedMonth] = selectedDays;

    setField("Monthly", Monthly);

    const eventData = events.map((val) => {
      if (val.extendedProps.date === selectedDate) {
        val.extendedProps.startTime = startTime;
        val.extendedProps.endTime = endTime;
      }
      return val;
    });

    setEventData(eventData);

    props?.setAvalabilitys();
    setModalOpen1(false);
  };

  const renderEventContent = (eventInfo) => {
    const eventDate = new Date(eventInfo?.event?.extendedProps?.date);
    const currentDate = new Date();

    // Set time components to be the same (00:00:00) for both dates
    eventDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    return (
      <>
        <div>
          <div
            id="#health-carer-calender"
            className="d-flex align-items-start calender-view-box justify-content-between"
          >
            <Dropdown
              autoClose="outside"
              show={showMenuItem[eventInfo?.event?.extendedProps?.slotId]}
            >
              <Dropdown.Toggle
                id="dropdown-autoclose-outside"
                className="calender-view"
                onClick={(e) =>
                  handleToggleMenuItem(
                    e,
                    eventInfo?.event?.extendedProps?.slotId
                  )
                }
              >
                <div
                  onClick={toggleClass}
                  className={`calender-client-cell calendar-top-title calender-view-gray`}
                >
                  <div>
                    <Box>
                      <Stack direction="row" spacing={1}>
                        <Avatar
                          alt="Remy Sharp"
                          src={
                            eventInfo?.event?.extendedProps?.image?.location !==
                            undefined
                              ? eventInfo?.event?.extendedProps?.image?.location
                              : noImage
                          }
                          sx={{ width: 24, height: 24 }}
                        />

                        <ContentCopyIcon
                          className="calender-add-icon-newcolor"
                          onClick={(cellInfo) =>
                            handlePlusButtonClick(
                              cellInfo,
                              eventInfo?.event?.extendedProps?.startTime,
                              eventInfo?.event?.extendedProps?.endTime,
                              eventInfo?.event?.extendedProps?.date
                            )
                          }
                        />

                        {eventDate >= currentDate ? (
                          <>
                            <EditIcon
                              className="calender-add-icon-newcolor"
                              onClick={(cellInfo) =>
                                editAvailabilityPopop(
                                  cellInfo,
                                  eventInfo?.event?.extendedProps?.day,
                                  eventInfo?.event?.extendedProps?.date,
                                  eventInfo?.event?.extendedProps?.startTime,
                                  eventInfo?.event?.extendedProps?.endTime
                                )
                              }
                            />

                            <DeleteIcon
                              className="calender-add-icon-newcolor"
                              onClick={(cellInfo) =>
                                removeAvailability(
                                  cellInfo,
                                  eventInfo?.event?.extendedProps?.day,
                                  eventInfo?.event?.extendedProps?.date,
                                  eventInfo?.event?.extendedProps?.startTime,
                                  eventInfo?.event?.extendedProps?.endTime
                                )
                              }
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </Box>
                  </div>

                  <div className="d-flex align-items-center justify-content-between">
                    <span className="d-flex align-items-center">
                      {eventInfo?.event?.extendedProps?.shiftName ===
                      "Sleepover"
                        ? eventInfo?.event?.extendedProps?.shiftName
                        : eventInfo?.event?.extendedProps?.startTime +
                          "-" +
                          eventInfo?.event?.extendedProps?.endTime}
                    </span>
                  </div>
                </div>
              </Dropdown.Toggle>
            </Dropdown>
          </div>
        </div>
      </>
    );
  };

  //console.log("check inside quarterly startDate1", startDate1);
  //console.log("check Selected quarter:", props.selectedQuarter); // Log the selected quarter

  const [initialDate, setInitialDate] = useState(new Date()); // State to hold the initial date

  useEffect(() => {
    getInitialDate(); // Call getInitialDate when the selectedQuarter changes
  }, [props.selectedQuarter]);

  // Calculate the initialDate based on the selected quarter's start date and update the state
  const getInitialDate = () => {
    //console.log("test getInitialDate called");
    //console.log("test Selected quarter:", props.selectedQuarter); // Log the selected quarter

    if (props.selectedQuarter && props.selectedQuarter.startDate) {
      const selectedStartDate = new Date(props.selectedQuarter.startDate);
      //console.log("selectedStartDate", selectedStartDate);
      setField("startDate1", selectedStartDate);
      const newInitialDate = new Date(
        selectedStartDate.getFullYear(),
        selectedStartDate.getMonth(),
        1
      );
      //console.log("test New initial date:", newInitialDate); // Log the new initial date
      setInitialDate(newInitialDate);
    }
  };
  // console.log("startDate1", startDate1);
  return (
    <>
      <Box>
        <div id="calenderal" className="padding-right-20 padding-left-20">
          <div className="demo-app-main">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "next",
                center: "title",
                right: "prev",
              }}
              ref={ref}
              key={calendarKey}
              events={events}
              initialView="dayGridMonth"
              weekends={true}
              initialDate={
                props?.selectedQuarter?.startDate === null ||
                props?.selectedQuarter?.startDate === "" ||
                props?.selectedQuarter?.startDate === undefined
                  ? new Date()
                  : new Date(props.selectedQuarter.startDate)
              }
              // events={events}
              dayMaxEvents={1}
              customButtons={{
                prev: { click: () => goToPreviousDay() },
                next: { click: () => goToNextDay() },
                today: { text: "today", click: () => goToDay() },
              }}
              dayCellContent={renderDayCellContent}
              eventContent={renderEventContent} // custom render function
            />

            {/* <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "prev",
                center: "title",
                right: "next",
              }}
              ref={ref}
              events={events}
              initialView="dayGridMonth"
              weekends={true}
              initialDate={initialDate} // Use the initialDate state here
              dayMaxEvents={1}
              customButtons={{
                prev: { click: () => goToPreviousDay() },
                next: { click: () => goToNextDay() },
                today: { text: "today", click: () => goToDay() },
              }}
              dayCellContent={renderDayCellContent}
              eventContent={renderEventContent} // custom render function
            /> */}

            <Dialog
              open={modalOpen}
              onClose={handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className="modal-content"
                >
                  <Typography>
                    Copy Availability: Start Time : {startTimeSelected} End Time
                    :{endTimeSelected}
                  </Typography>

                  <Box>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              checkDateForSelectAll(selectedDateCopy) &&
                              Monthly[props.id]["selectAll"] !== undefined &&
                              Monthly[props.id]["selectAll"] === true
                                ? true
                                : false
                            }
                            onChange={handleSelectAllChange}
                          />
                        }
                        label={"Select All"}
                      />

                      <Grid container spacing={2}>
                        {dayData !== undefined &&
                          Object.entries(dayData).map((days, index) => (
                            <React.Fragment key={index}>
                              <Grid item xs={12}>
                                {" "}
                                {/* Start a new row */}
                                <Typography>{days?.[0]}</Typography>
                              </Grid>
                              {days?.[1] !== undefined &&
                                days?.[1].length > 0 &&
                                days?.[1]?.map((day, key) => {
                                  const eventDate = new Date(day?.date);
                                  const currentDate = new Date();

                                  // Set time components to be the same (00:00:00) for both dates
                                  eventDate.setHours(0, 0, 0, 0);
                                  currentDate.setHours(0, 0, 0, 0);

                                  return (
                                    <>
                                      <Grid item key={day?.day} xs={2}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={
                                                Monthly?.[props.id] !==
                                                  undefined &&
                                                Monthly?.[props.id]?.[
                                                  "CopyQlSelectedDays"
                                                ] !== undefined &&
                                                Monthly?.[props.id]?.[
                                                  "CopyQlSelectedDays"
                                                ]?.[days?.[0]] !== undefined &&
                                                days?.[0] !== undefined &&
                                                Monthly?.[props.id]?.[
                                                  "CopyQlSelectedDays"
                                                ]?.[days?.[0]]?.filter(
                                                  (val) => val?.day === day?.day
                                                ).length > 0
                                                  ? true
                                                  : false
                                              }
                                              disabled={
                                                compairDate(
                                                  day?.date,
                                                  selectedDateCopy
                                                )
                                                  ? true
                                                  : eventDate < currentDate
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                handleDayChange(
                                                  e,
                                                  day,
                                                  days?.[0]
                                                )
                                              }
                                            />
                                          }
                                          label={day?.day}
                                        />
                                      </Grid>
                                    </>
                                  );
                                })}
                            </React.Fragment>
                          ))}
                      </Grid>
                    </FormGroup>
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="modal-button">
                <Box className="agency-btn-section">
                  <a href="#cancel" onClick={handleCloseModal}>
                    Skip
                  </a>
                  {/* <a href="#save" onClick={handleCloseModal} autoFocus>
                    Update
                  </a> */}
                </Box>
              </DialogActions>
            </Dialog>

            <Dialog
              open={modalOpen1}
              onClose={handleCloseModalAvailability}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className="modal-content"
                >
                  <Typography>
                    {updateAvailabilityFlag ? "Update" : "Clone"} availability:
                  </Typography>

                  <Box className="item">
                    {!updateAvailabilityFlag ? (
                      <CloneMonthlyAvailability
                        handleClose={handleClose}
                        addNewAvailability={addNewAvailability}
                        shift={props?.selectedShift}
                        editstartTimeSelected={editstartTimeSelected}
                        editendTimeSelected={editendTimeSelected}
                      />
                    ) : (
                      <UpdateAvailability
                        handleClose={handleClose}
                        addNewAvailability={UpdateAvailabilityData}
                        shift={props?.selectedShift}
                        editstartTimeSelected={editstartTimeSelected}
                        editendTimeSelected={editendTimeSelected}
                      />
                    )}
                  </Box>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>

          {isLoading && <FieldsListLoading />}
        </div>
      </Box>
    </>
  );
};

export default QuarterlyCalendar;
