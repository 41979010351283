import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import '../../../assets/css/Appointment.css'
import '../../Appointmentschedule/appointment.css';
import '../../../assets/css/dashboard.css';
import team1 from '../../../assets/images/team1.png';
import team2 from '../../../assets/images/team2.png';
import team3 from '../../../assets/images/team3.png';
import teamfull from '../../../assets/images/team-full.jpg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import tableCall from '../../../assets/images/table-call.png';
import tableCheck from '../../../assets/images/table-check.png';
import RoutineSubBox from '../Routine-SubBox';
import RoutineCell from '../Routine-Cell';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dropdown from 'react-bootstrap/Dropdown';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import view from '../../../assets/images/view.svg';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import filterImg from '../../../assets/images/filter.png';
import searchImg from '../../../assets/images/search.svg';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import AddCircleIcon from "@mui/icons-material/AddCircle";








import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";



const status = (
  <Box className="appointment-status d-flex align-items-center">
    <span className="status-box status-cell-2"></span> Completed
  </Box>
)

const timeCell = (
  <Box className="time-cell text-center">
    <Typography>10:00 - 12:00</Typography>
    <span>2 Hours</span>
  </Box>
)

const teamCell = (
  <Box className="team-cell">
    <Box className="table-team d-flex align-items-center">
      <img src={team1} className="teamImg1"></img>
      <img src={team2} className="teamImg2"></img>
      <img src={team3} className="teamImg3"></img>
      <Box className="team-popover team-popover1">
        <Box className="team-popover-box  d-flex align-items-center justify-content-between">
          <Box className="team-popover-img">
            <img src={teamfull}></img>
          </Box>
          <Box className="team-popover-text">
            <Typography>Dan Michel </Typography>
            <span>Health Carer</span>
          </Box>

          <ArrowDropDownIcon className="team-popover-arrow" />

        </Box>
      </Box>
      <Box className="team-popover team-popover2">
        <Box className="team-popover-box  d-flex align-items-center justify-content-between">
          <Box className="team-popover-img">
            <img src={teamfull}></img>
          </Box>
          <Box className="team-popover-text">
            <Typography>Dan Michel </Typography>
            <span>Health Carer</span>
          </Box>

          <ArrowDropDownIcon className="team-popover-arrow" />

        </Box>
      </Box>
      <Box className="team-popover team-popover3">
        <Box className="team-popover-box  d-flex align-items-center justify-content-between">
          <Box className="team-popover-img">
            <img src={teamfull}></img>
          </Box>
          <Box className="team-popover-text">
            <Typography>Dan Michel </Typography>
            <span>Health Carer</span>
          </Box>

          <ArrowDropDownIcon className="team-popover-arrow" />

        </Box>
      </Box>
    </Box>
  </Box>

)

const icon1 = (
  <Box className="table-icon">
    <img src={tableCall}></img>
    <Box className="table-icon-popover d-flex align-items-center justify-content-center">
      <img src={tableCall}></img> <Typography>On Call</Typography>
      <ArrowDropDownIcon className="team-popover-arrow" />
    </Box>
  </Box>
)

const icon2 = (
  <Box className="table-icon">
    <img src={tableCheck}></img>
    <Box className="table-icon-popover d-flex align-items-center justify-content-center">
      <img src={tableCheck}></img> <Typography>On Call</Typography>
      <ArrowDropDownIcon className="team-popover-arrow" />
    </Box>
  </Box>
)

const routineCell = (
  <Box className="routine-cell">
    {/* <RoutineCell addList={addList} onClick={handleClick} /> */}
    <RoutineCell />


    {/* {listTodo.map((listItem, i) => {
            return (
                <RoutineSubBox key={i} index={i} item={listItem} deleteItem={deleteListItem} />
            )
        })} */}







    {/* <Box id="view-all" >

            <Box className="view-all" >
                <Typography className="d-flex justify-content-center align-items-center">View All <KeyboardDoubleArrowDownIcon /> </Typography>
            </Box>

        </Box> */}
  </Box>
)

const Action = (
  <Box>
    <Box className='table-edit-del'>
      <div className="tableswap d-flex align-items-center">
        <Typography variant="p" className="swap-icon">
          {" "}
          <SwapHorizontalCircleIcon fontSize="small" /> Swap{" "}
        </Typography>
      </div>
    </Box>

    <Dropdown drop="start" autoClose="outside">
      <Dropdown.Toggle id="dropdown-autoclose-outside" className="action-toggle-button">
        <MoreVertIcon className='dot-icon' />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu client-drop-menu">
        <Dropdown.Item className='table-edit-del' >  <a href='javascript:;'
          id="domain1"
          label="Domain"
          className=" justify-content-start d-flex client-table-btn btn"
          margin="normal"
        >
          <div className="tableswap d-flex align-items-center">
            <Typography variant="p" className="swap-icon">
              {" "}
              <SwapHorizontalCircleIcon fontSize="small" /> Swap{" "}
            </Typography>
          </div>
        </a></Dropdown.Item>
        <Dropdown.Item className='table-edit-del ' >  <a href='javascript:;'
          id="domain1"
          label="Domain"
          className="table-edit justify-content-start d-flex align-items-center client-table-btn btn"
          margin="normal"
        >
          <BorderColorIcon /> Edit
        </a></Dropdown.Item>
        <Dropdown.Item className='table-edit-del' >
          <a href='javascript:;'
            id="domain2"
            label="Domain"
            className="table-del justify-content-start d-flex client-table-btn  btn"
            margin="normal"
          >
            <Box className="view-icon"><img src={view} /></Box>  <Typography>View</Typography>
          </a>

        </Dropdown.Item>


      </Dropdown.Menu>
    </Dropdown>
  </Box>
)




const sampleData = [
  {
    id: 0,
    Date: "01-02-2023",
    Service: "Core",
    Item: "Assistance in support Independent living",
    Client: "Jake Knap",
    Location: "Sydney",
    icon: icon1,
    Type: "OnCall",
    AddRoutine: "Add New Routine",
    Category: "Homecare",
    Status: "Completed",
  },
  {
    id: 1,
    Date: "01-02-2023",
    Service: "Test",
    Item: "Tassistance in support Independent living",
    Client: "john Deo",
    Location: "Sydney",
    icon: icon1,
    Type: "OnCall",
    AddRoutine: "Add New Routine",
    Category: "Homecare",
    Status: "Completed",
  },
  {
    id: 2,
    Date: "01-02-2023",
    Service: "Admin",
    Item: "Assistance in support Independent living",
    Client: "Sam",
    Location: "Sydney",
    icon: icon1,
    Type: "OnCall",
    AddRoutine: "Add New Routine",
    Category: "Homecare",
    Status: "Completed",
  },
  {
    id: 3,
    Date: "01-02-2023",
    Service: "User",
    Item: "Assistance in support Independent living",
    Client: "Dustin",
    Location: "Sydney",
    icon: icon2,
    Type: "OnCall",
    AddRoutine: "Add New Routine",
    Category: "Homecare",
    Status: "Completed",
  },
  {
    id: 4,
    Date: "01-02-2023",
    Service: "Testing",
    Item: "Assistance in support Independent living",
    Client: "josaf",
    Location: "Sydney",
    icon: icon1,
    Type: "OnCall",
    AddRoutine: "Add New Routine",
    Category: "Homecare",
    Status: "Completed",
  },


],
  sampleColumns = [
    { id: 0, property: 'Date', columnLabel: 'Date' },
    { id: 1, property: 'Service', columnLabel: 'Service' },
    { id: 2, property: 'Item', columnLabel: 'Item' },
    { id: 3, property: 'Client', columnLabel: 'Client' },
    { id: 4, property: 'Category', columnLabel: 'Category' },
    { id: 5, property: 'Location', columnLabel: 'Location' },
    { id: 6, property: 'TeamCell', columnLabel: 'Team' },
    { id: 7, property: 'Time', columnLabel: 'Time' },
    { id: 8, property: 'Icon', columnLabel: 'Icon' },
    { id: 9, property: 'RoutineCell', columnLabel: 'Add Routine' },
    { id: 10, property: 'Status', columnLabel: 'Status' },
    { id: 11, property: 'ActionCell', columnLabel: 'Action' },





  ]

const filterSearch = [
  { label: 'All' },
  { label: 'Core' },
  { label: 'Capacity Building' }

]




const MyTable = ({ tableData, tableColumns, onFilter }) => {

  const [show, setShow] = React.useState();
  const [search, setSearch] = useState('');

  const [routine, setRoutine] = useState(true);


  const handleRoutine = event => {
    // 👇️ toggle isActive state on click
    setRoutine(current => !current);
  };

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };


  const [isActive2, setIsActive2] = useState(false);

  const handleClick = event => {

    setIsActive2(current => !current);
  };

  const [signIn, setSignIn] = useState(false);
  const [showSwap, setShowSwap] = React.useState(true);
  const handleSwap = () => setShowSwap(false);

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }

  return (
    <Box id="appointment-table">
      <TableContainer component={Paper} id='document-table' className="padding-res-10">
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead id="client-table-head">
            <TableRow>
              {
                tableColumns.map(({ id, columnLabel, property }) => (
                  <TableCell key={id} className="tableHeader filter-search-field  table-head-cell tableborder">
                    <Box className="d-flex align-items-center">
                      {columnLabel}

                      <Dropdown drop="start" autoClose="outside">
                        <Dropdown.Toggle id="dropdown-autoclose-filter" >
                          <img className="fliter-img" id="filterImg" src={filterImg} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu client-drop-menu">
                          <Dropdown.Item className='table-edit-del' >  <a href=''
                            id="domain1"
                            label="Domain"
                            className=" justify-content-start d-flex client-table-btn btn"
                            margin="normal"
                          >
                            <InputGroup id="searchFilter" className='my-3 is-hidden d-flex align-items-center'>
                              <img src={searchImg} className="filter-search-icon" />
                              <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                size="small"

                                options={filterSearch}
                                sx={{ width: '200px' }}
                                renderInput={(params) => <TextField {...params} placeholder={`Search ${property}`} className="tableSearchField" key={id}

                                  name={property}
                                  onKeyUp={onFilter} />}
                              />
                            </InputGroup>
                          </a></Dropdown.Item>



                        </Dropdown.Menu>
                      </Dropdown>
                    </Box>

                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody id="client-table-body">
            {tableData.filter((row) => {
              return search.toLowerCase() === ''
                ? row
                : row.Item.toLowerCase().includes(search);
            })
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell className="appointment-cell" >{row.Date}</TableCell>
                  <TableCell className="appointment-cell">{row.Service}</TableCell>
                  <TableCell className="appointment-cell-item">{row.Item}</TableCell>

                  <TableCell className="appointment-cell">{row.Client}</TableCell>
                  <TableCell className="appointment-cell">{row.Category}</TableCell>
                  <TableCell className="appointment-cell">{row.Location}</TableCell>
                  <TableCell className="appointment-cell team-cell">
                    <Box className="table-team d-flex align-items-center">
                      <img src={team1} className="teamImg1"></img>
                      <img src={team2} className="teamImg2"></img>
                      <img src={team3} className="teamImg3"></img>
                      <Box className="team-popover team-popover1">
                        <Box className="team-popover-box  d-flex align-items-center justify-content-between">
                          <Box className="team-popover-img">
                            <img src={teamfull}></img>
                          </Box>
                          <Box className="team-popover-text">
                            <Typography>Dan Michel </Typography>
                            <span>Health Carer</span>
                          </Box>

                          <ArrowDropDownIcon className="team-popover-arrow" />

                        </Box>
                      </Box>
                      <Box className="team-popover team-popover2">
                        <Box className="team-popover-box  d-flex align-items-center justify-content-between">
                          <Box className="team-popover-img">
                            <img src={teamfull}></img>
                          </Box>
                          <Box className="team-popover-text">
                            <Typography>Dan Michel </Typography>
                            <span>Health Carer</span>
                          </Box>

                          <ArrowDropDownIcon className="team-popover-arrow" />

                        </Box>
                      </Box>
                      <Box className="team-popover team-popover3">
                        <Box className="team-popover-box  d-flex align-items-center justify-content-between">
                          <Box className="team-popover-img">
                            <img src={teamfull}></img>
                          </Box>
                          <Box className="team-popover-text">
                            <Typography>Dan Michel </Typography>
                            <span>Health Carer</span>
                          </Box>

                          <ArrowDropDownIcon className="team-popover-arrow" />

                        </Box>
                      </Box>
                    </Box>

                  </TableCell>


                  <TableCell className="appointment-cell">
                    <Box className="time-cell text-center">
                      <Typography>10:00 - 12:00</Typography>
                      <span>2 Hours</span>
                    </Box>
                  </TableCell>

                  <TableCell className="appointment-cell">{row.icon}</TableCell>

                  <TableCell className=" routine-cell ">



                    <Box className="d-flex align-items-center justify-content-between">
                      <button className="d-flex align-items-center routine-cell-btn">
                        <Box className="d-flex align-items-center" >

                          <AddCircleIcon />  Routine
                        </Box>
                      </button>


                      <button className="d-flex align-items-center routine-cell-task-btn">
                        <AddCircleIcon /> Task
                      </button>
                    </Box>
                    <Box className={routine ? 'routine-overflow' : 'routine-overflow-hide'} >
                      <Box className="routine-cell-sub-box d-flex align-items-center justify- 
                         conent-between">
                        <Box className="routine-sub-box-text">
                          <Typography>Exercise</Typography>
                          <span>2 Tasks</span>
                        </Box>
                        <button className='routine-task d-flex align-items-center'>
                          <AddCircleIcon /> Task
                        </button>
                      </Box>
                      <Box className="routine-cell-sub-box d-flex align-items-center justify- 
                         conent-between">
                        <Box className="routine-sub-box-text">
                          <Typography>Exercise</Typography>
                          <span>2 Tasks</span>
                        </Box>
                        <button className='routine-task d-flex align-items-center'>
                          <AddCircleIcon /> Task
                        </button>
                      </Box>
                      <Box className="routine-cell-sub-box d-flex align-items-center justify- 
                         conent-between">
                        <Box className="routine-sub-box-text">
                          <Typography>Exercise</Typography>
                          <span>2 Tasks</span>
                        </Box>
                        <button className='routine-task d-flex align-items-center'>
                          <AddCircleIcon /> Task
                        </button>
                      </Box>
                      <Box className="routine-cell-sub-box d-flex align-items-center justify- 
                         conent-between">
                        <Box className="routine-sub-box-text">
                          <Typography>Exercise</Typography>
                          <span>2 Tasks</span>
                        </Box>
                        <button className='routine-task d-flex align-items-center'>
                          <AddCircleIcon /> Task
                        </button>
                      </Box>
                    </Box>
                    {/* {listTodo.map((listItem, i) => {
                            return (
                              <RoutineSubBox key={i} index={i} item={listItem} deleteItem=
                                {deleteListItem} />
                            )
                          })} */}




                    <span className="view-all" onClick={() => { handleRoutine(); setSignIn(true) }} > {signIn ? "View Less" : "View All"} <KeyboardDoubleArrowDownIcon /></span>

                    <Box id="view-all" className={isActive2 ? 'show' : ''}>

                      <Box className="view-all" >
                        <Typography className="d-flex justify-content-center align-items-center">View All <KeyboardDoubleArrowDownIcon /> </Typography>
                      </Box>

                    </Box>
                  </TableCell>


                  {/* <TableCell className="status-cell"> {row.Status}</TableCell> */}
                  <TableCell >
                    <Box className={`appointment-status status-cell-${index + 1} d-flex align- 
                       items- center`}>
                      {row.Status}
                    </Box>
                  </TableCell>



                  <TableCell align="left" className='tableborder table-editcell'>

                    <Box className='table-edit-del'>
                      <div className="tableswap d-flex align-items-center">
                        {showSwap ?
                          <Typography variant="p" onClick={() => {
                            setShow(prev => !prev); handleSwap()
                          }} className=" swap-icon " >

                            <SwapHorizontalCircleOutlinedIcon fontSize="small" /> <span> Swap </span>

                          </Typography>
                          : null}
                        {show &&
                          <Box className="swapped-text">
                            <span>With</span>
                            <Typography>Norman William</Typography>
                          </Box>
                        }
                      </div>
                    </Box>

                    <Dropdown drop="start" autoClose="outside">
                      <Dropdown.Toggle id="dropdown-autoclose-outside" className="action-toggle-button">
                        <MoreVertIcon className='dot-icon' onClick={toggleClass} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="dropdown-menu   client-drop-menu">
                        <Dropdown.Item className='table-edit-del w-100' >  <a href='javascript:;'
                          id="domain1"
                          label="Domain"
                          className=" justify-content-start d-flex client-table-btn btn"
                          margin="normal"
                        >
                          <div className="tableswap d-flex align-items-center">
                            <Typography variant="p" className="swap-icon" >
                              {" "}
                              <SwapHorizontalCircleOutlinedIcon fontSize="small" /> Swap{" "}
                            </Typography>
                          </div>
                        </a></Dropdown.Item>
                        <Dropdown.Item className='table-edit-del w-100' >  <a href='javascript:;'
                          id="domain1"
                          label="Domain"
                          className="table-edit justify-content-start d-flex align-items-center client-table-btn btn"
                          margin="normal"
                        >
                          <BorderColorIcon /> Edit
                        </a></Dropdown.Item>
                        <Dropdown.Item className='table-edit-del w-100' >
                          <a href='javascript:;'
                            id="domain2"
                            label="Domain"
                            className="table-del justify-content-start d-flex client-table-btn  btn"
                            margin="normal"
                          >
                            <Box className="view-icon"><img src={view} /></Box>  <Typography>View</Typography>
                          </a>

                        </Dropdown.Item>


                      </Dropdown.Menu>
                    </Dropdown>
                  </TableCell>

                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )



}

export default function Demo() {



  const [state, setState] = useState({
    data: sampleData,
    columns: sampleColumns,
    filterObj: sampleColumns.reduce((r, { property }) => (r[property] = '', r), {})
  }),
    onFilterApply = ({ target: { name, value } }) => {
      const newFilterObj = { ...state.filterObj, [name]: value }
      setState({
        ...state,
        filterObj: newFilterObj,
        data: sampleData.filter(props =>
          Object
            .entries(newFilterObj)
            .every(([key, val]) =>
              !val.length ||
              props[key].toLowerCase().includes(val.toLowerCase()))
        )
      })
    }
  return (
    <>

      <MyTable
        tableData={state.data}
        tableColumns={state.columns}
        onFilter={onFilterApply}
      />
    </>
  )
}

