import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../Web-Header";
import WebFooter from "../Web-footer";
import FeatureTitleBanner from "../../assets/images/feature-1banner.jpg";
import { Link } from "react-router-dom";
import "../../assets/css/dashboard.css";
import "../../assets/css/web-css.css";
import "../HomeUI/home-ui.css";
import rosterImage1 from "../../assets/images/rosterImage1.png";
import rosterImage2 from "../../assets/images/rosterImage2.png";
import rosterImage3 from "../../assets/images/rosterImage3.png";
import rosterImage4 from "../../assets/images/rosterImage4.png";
import rosterImage5 from "../../assets/images/rosterImage5.png";

import rosterIcon1 from "../../assets/images/rosterIcon1.svg";
import rosterIcon2 from "../../assets/images/rosterIcon2.svg";
import rosterIcon3 from "../../assets/images/rosterIcon3.svg";
import rosterIcon4 from "../../assets/images/rosterIcon4.svg";
import rosterIcon5 from "../../assets/images/rosterIcon5.svg";
import rosterIcon6 from "../../assets/images/rosterIcon6.svg";
import rosterIcon7 from "../../assets/images/rosterIcon7.svg";
import rosterIcon8 from "../../assets/images/rosterIcon8.svg";
import rosterIcon9 from "../../assets/images/rosterIcon9.svg";
import rosterIcon10 from "../../assets/images/rosterIcon10.svg";
import rosterIcon11 from "../../assets/images/rosterIcon11.svg";
import rosterIcon12 from "../../assets/images/rosterIcon12.svg";
import rosterIcon13 from "../../assets/images/rosterIcon13.svg";
import rosterIcon14 from "../../assets/images/rosterIcon14.svg";

import QuestionAccordion from "../HomeUI/Question-Accordian";
import BookDemo from "../HomeUI/BookDemo";

const RosterManagement = () => {
  return (
    <Box id="features">
      <Box className="web-topBanner-bg  feature-main-banner">
        <WebHeader />

        <Box className="feature-titleBox">
          <Box className="webContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} className="home-content-main ">
                <Box>
                  <h1>
                    Roster Management
                    <br />
                    {/* Title */}
                  </h1>
                  <Typography>
                    Our Roster Management tool is designed to elevate your
                    planning process, ensuring you can draft and publish rosters
                    with unmatched ease and precision.
                  </Typography>

                  <Box className="d-flex align-items-center">
                    <Link
                      to="/book-demo"
                      className="home-section-button web-primary-btnLarge cta1"
                    >
                      Book A Demo
                    </Link>
                    {/* <Link className="home-section-button cta2">CTA 2</Link> */}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box className="manageCarer-cardImg manageCarer-obj">
                  <img src={rosterImage1} />
                </Box>

                <Box className="blurBg blurRed"> </Box>
                {/* <Box className="blurBg blurPurple"> </Box> */}
                <Box className="blurBg blurYellow"></Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box className="webContainer">
        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={rosterImage2} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
          >
            <Box className="manageCarer-cardContent pLeft-80">
              <h6>Effortless Roster Creation </h6>
              <h3>Drafting Made Simple</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Say goodbye to the days of starting from scratch each week.
                  With our intuitive rostering tool, you can effortlessly create
                  draft rosters based on historical data, learning from past
                  weeks to ensure smooth scheduling ahead.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon1} />
                      </Box>
                      <Box>
                        <Typography>Time-saving functionality </Typography>
                        <span>
                          Enhance operational efficiency by saving valuable
                          time.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon2} />
                      </Box>
                      <Box>
                        <Typography>Aids in error reduction</Typography>
                        <span>
                          Minimize mistakes and potential inaccuracies.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon3} />
                      </Box>
                      <Box>
                        <Typography>Optimized staffing levels</Typography>
                        <span>
                          Balance and optimizes staffing resources effectively.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon4} />
                      </Box>
                      <Box>
                        <Typography>
                          Right number of staff at right time
                        </Typography>
                        <span>
                          Ensure timely presence of staff as per requirements.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>Flexible Staffing Solutions </h6>
              <h3>Tailor-Made for Your Needs</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Whether you're adjusting for seasonal variations,
                  accommodating staff preferences, or managing unexpected shifts
                  in demand, our tool empowers you to customize rosters to fit
                  the unique needs of your operation.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon5} />
                      </Box>
                      <Box>
                        <Typography>Efficient Resource Allocation</Typography>
                        <span>
                          Optimize distribution of available resources.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon6} />
                      </Box>
                      <Box>
                        <Typography>Seamless Workflow</Typography>
                        <span>Ensure smooth and uninterrupted workflow.</span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon7} />
                      </Box>
                      <Box>
                        <Typography>High-Quality Care Provision</Typography>
                        <span>
                          Focus on providing superior quality care consistently.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg">
              <img src={rosterImage3} />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={rosterImage4} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
          >
            <Box className="manageCarer-cardContent pLeft-80">
              <h6>Dynamic Roster Publishing  </h6>
              <h3>Control at Your Fingertips</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  Once your draft roster meets your satisfaction, publishing it
                  is just a click away. But the control doesn't stop there; our
                  platform allows for easy adjustments even after publishing,
                  ensuring you can respond dynamically to any changes. This
                  level of control and adaptability ensures that you're always
                  prepared, no matter what comes your way.
                </Typography>

                <Grid container sx={{ mt: 2 }} className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon8} />
                      </Box>
                      <Box>
                        <Typography>Real-Time Adaptability</Typography>
                        <span>
                          Offers real-time adaptability to changing needs.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon9} />
                      </Box>
                      <Box>
                        <Typography>Instant Roster Updates</Typography>
                        <span>Facilitates immediate updates to rosters.</span>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon10} />
                      </Box>
                      <Box>
                        <Typography>Flexibility in Scheduling</Typography>
                        <span>
                          Offers flexibility in managing staff schedules.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="manageCarer-cardBox space-80">
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex justify-content-end"
            order={{ xs: 2, sm: 1 }}
          >
            <Box className="manageCarer-cardContent pRight-80">
              <h6>Streamlined Communication  </h6>
              <h3>Keep Everyone in the Loop</h3>

              <Box className="manageCarer-cardText">
                <Typography className="space-pb-24">
                  With automatic notifications, your staff will be promptly
                  informed of their schedules, ensuring everyone is on the same
                  page.
                </Typography>

                <Grid container className="feature-icons-box">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon11} />
                      </Box>
                      <Box>
                        <Typography>Enhanced Staff Awareness</Typography>
                        <span>
                          Keeps all team members well-informed and aligned.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon12} />
                      </Box>
                      <Box>
                        <Typography>Reduced Confusion</Typography>
                        <span>
                          Minimizes misunderstandings and uncertainties.{" "}
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon13} />
                      </Box>
                      <Box>
                        <Typography>Improved Staff Satisfaction</Typography>
                        <span>
                          Boosts overall staff morale and job satisfaction.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-start space-mb-24 feature-icons-boxWrapper">
                      <Box className="feature-star-icon">
                        <img src={rosterIcon14} />
                      </Box>
                      <Box>
                        <Typography>Cohesive Team Environment</Typography>
                        <span>
                          Strengthens team cohesion and mutual understanding.
                        </span>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* <Link to="#" className="home-primary-button">
                Learn More
              </Link> */}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className="d-flex align-items-center"
            order={{ xs: 1, sm: 2 }}
          >
            <Box className="manageCarer-cardImg manageCarer-obj">
              <img src={rosterImage5} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="question-accordian web-topBanner-bg space-lg">
        <Box className="webContainer">
          <Box className="text-center">
            <h1>Questions? We've got you covered.</h1>
          </Box>
          <Box className="space-pt-60">
            <QuestionAccordion />
          </Box>
        </Box>
      </Box>

      <Box className="space-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
};

export default RosterManagement;
