import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import "../assets/css/dashboard.css";
import "../App.css";
import { Link, useNavigate } from "react-router-dom";

export default function Error() {
  let userDataSession = JSON.parse(localStorage.getItem("userData"));

  const navigate = useNavigate();
  let navigateUrl = "";

  if (userDataSession.role === "healthCarer") {
    navigateUrl = "/health-carer/home";
  } else if (userDataSession.role === "supportCoordinator") {
    navigateUrl = "/support-coordinator/home";
  } else if (userDataSession.role === "client") {
    navigateUrl = "/client/home";
  } else if (userDataSession.role === "accountant") {
    navigateUrl = "/accountant/home";
  } else if (userDataSession.role === "itManager") {
    navigateUrl = "/it-manager/home";
  } else if (userDataSession.role === "supervisor") {
    navigateUrl = "/supervisor/home";
  } else if (userDataSession.role === "superAdmin") {
    navigateUrl = "/super-admin/home";
  } else if (userDataSession.role === "familyMember") {
    navigateUrl = "/family-member/home";
  } else if (userDataSession.role === "Stakeholder") {
    navigateUrl = "/stakeholder/home";
  } else if (userDataSession.role === "generalManager") {
    navigateUrl = "/general-manager/home";
  } else if (userDataSession.role === "serviceProvider") {
    navigateUrl = "/service-provider/home";
  } else if (userDataSession.role === "planManager") {
    navigateUrl = "/plan-manager/home";
  } else if (userDataSession.role === "hr") {
    navigateUrl = "/hr/home";
  } else if (userDataSession.role === "billing") {
    navigateUrl = "/billing/home";
  } else if (userDataSession.role === "admin") {
    navigateUrl = "/admin/home";
  }

  return (
    <Box class="error-main ">
      <Box className="text-center">
        <Typography className="error-404"> 401! </Typography>
        <Typography className="error-text">
          Opps! Unauthorized Access
        </Typography>
        <span>
          Sorry, you don't have permission to access this. Please log in or
          provide valid credentials to proceed.{" "}
        </span>

        <Box className="error-btn">
          <Link to={navigateUrl}>Go to Dashboard</Link>
        </Box>
      </Box>
    </Box>
  );
}
