import React, { useState } from "react";
import Box from "@mui/material/Box";
import "./timesheet.css";
import Appbar from "../../Dashboard/Layout/Appbar";
import Sidebar from "../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";

import call from "../../assets/images/call.svg";
import location from "../../assets/images/location.svg";
import mail from "../../assets/images/mail.svg";
import mapIcon from "../../assets/images/mapIcon.svg";
import Progress from "../../components/ui/Progress";

import cancel from "../../assets/images/cancel.svg";
// import * as Yup from "yup";
// import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import verified from "../../assets/images/verified.svg";
import VerifiedIcon from "@mui/icons-material/Verified";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import team1 from "../../assets/images/profileimg.png";
import chatSearch from "../../assets/images/chat-search.svg";
import reactionIcon from "../../assets/images/add_reaction.svg";
import sendIcon from "../../assets/images/send.svg";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import scaning from "../../assets/images/face-scan.png";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Pending",
  "Accepted",
  "Completed",
  "Started",
  "Rejected",
  "Change by Admin",
  "Change by SW",
];

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];

export default function AddExpenses(props) {
  const [client, setClient] = useState([]);
  const [region, setRegion] = useState([]);
  const [stype, setStype] = useState([]);
  const [appinttype, setAppinttype] = useState([]);
  const [category, setCategory] = useState([]);
  const [sgroup, setSgroup] = useState([]);
  const [clientDetail, setClientDetail] = useState([]);

  const [value, setValue] = React.useState();

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [showResults, setShowResults] = React.useState(false);
  const [isActive, setIsActive] = useState(false);

  function toogleShow() {
    setShowResults(!showResults);
    setIsActive((current) => !current);
  }
  const initialValues = {
    // title: data !== undefined ? data.title : "",
    // assignedToId:data !== undefined ? data.assignedToId : "",
    // //  StackHolderName:data !== undefined ? data.StackHolderName : "",
    //  document:data !== undefined ? data.document : "",
    //  dueDate:data !== undefined ? data.dueDate : "",
    // status:data !== undefined ? data.status : "",
    //  clientId:data !== undefined ? data.clientId : "",
    //   // ClientName:data !== undefined ? data.ClientName : "",
    // note:data !== undefined ? data.note : "",
    //    isPrivate: data !== undefined ? data.isPrivate : "false",
  };

  //   const validationSchema = Yup.object().shape({
  //  title: Yup.string().required("Title is Required"),
  //   assignedToId: Yup.string().required("Steackholder is Required"),
  //     note: Yup.string().required("Description is Required"),
  //     dueDate: Yup.string().required("Date is Required"),
  //      clientId: Yup.string().required("Client is Required"),
  //      status: Yup.string().required("Status is Required"),
  //   });

  const setRegionFunction = async (regionId, setFieldValue) => {
    if (regionId) {
      setFieldValue("regionId", regionId.id);
    }
  };

  const setSsupporttypeFunction = async (supportTypeId, setFieldValue) => {
    if (supportTypeId) {
      setFieldValue("supportTypeId", supportTypeId.id);
    }
  };

  const setSsupportgroupFunction = async (suportGroupId, setFieldValue) => {
    if (suportGroupId) {
      setFieldValue("suportGroupId", suportGroupId.id);
    }
  };

  const setAppntFunction = async (appointmentTypeId, setFieldValue) => {
    if (appointmentTypeId) {
      setFieldValue("appointmentTypeId", appointmentTypeId.id);
    }
  };

  const setCategoryFunction = async (categoryDetailsId, setFieldValue) => {
    if (categoryDetailsId) {
      setFieldValue("categoryDetailsId", categoryDetailsId.id);
    }
  };

  const [active, setActive] = useState(false);

  const [personName, setPersonName] = React.useState([]);

  const handleChangeCheck = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [open, setOpen] = React.useState(false);
  const [faceScan, setFaceScan] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFaceOpen = () => {
    setFaceScan(true);
  };

  const handleFaceClose = () => {
    setFaceScan(false);
  };

  console.log(clientDetail, "//////////");
  return (
    <Box className="dashboard-form" id="appointment-details">
      {
        <Box sx={{ width: "100%" }} className="appbar-border">
          <Box className="appbar-main">
            <Appbar />
          </Box>
        </Box>
      }
      <Box className=" d-flex dashboard-main-box">
        {<Sidebar />}

        <Box className="main">
          <Grid container className="d-flex header-topsection">
            <Grid item xs={9} md={9} sm={9}>
              <Box id="breadcrumb">
                <ArrowBackIosIcon />
                <Box className="breadcrumb-inr">
                  <h4 className="d-flex align-items-center ">
                    Add Appointment
                  </h4>
                  <Box className="breadcrumbs">
                    <Link className="breadcrumbs-link">Appointments</Link>
                    <span className="breadcrumbs-icon">{">"}</span>
                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                      Add Appointment
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} md={3} sm={3}>
              <Box className="dashboard-form-typo-button">
                <button className="finish-btn d-none" onClick={handleFaceOpen}>
                  Finish
                </button>

                <button className="swap-button">Swap</button>

                <button className="start-button" onClick={handleClickOpen}>
                  Start
                </button>
              </Box>
            </Grid>
          </Grid>

          <Box className="divider"></Box>  
             
          {/* hide and show client details section  */}

          <Box className="hide-client-details main-padding">
            <Box>
              <Box className="bg-strip">
                <Typography>Client Details</Typography>
              </Box>
            </Box>

            <Box className="hide-box">
              <Grid
                container
                spacing={4}
                className="align-items-start add-appointment-input-box"
              >
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  className="input flex-column hide-client-img-main d-flex pt-0"
                >
                  <Box className="hide-client-img-box">
                    <Box className="hide-client-img">
                      <img src={client}></img>
                    </Box>
                    <Typography>Jake Knap, 63 Yr (Male)</Typography>
                    <a href="javascript:;">View Client Details</a>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  className="input flex-column d-flex pt-0"
                >
                  <Box>
                    <Box className="d-flex align-items-start client-detail-divider justify-content-between">
                      <Box>
                        <Typography>
                          D.O.B <span>15-01-1960</span>
                        </Typography>

                        <Box sx={{ mt: 1 }}>
                          <Typography className="hide-content-number">
                            {" "}
                            <img src={call}></img> <a href="#">0117985214</a>
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography>
                          <img src={location}></img> VLC, Brisbane
                        </Typography>

                        <Box className="hide-content p-0" sx={{ mt: 1 }}>
                          <Typography>
                            {" "}
                            <img src={mail}></img>{" "}
                            <a href="#"> knapjack1872gmail.com</a>
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="hide-content p-0">
                        <Box className="mapicon">
                          {" "}
                          <img src={mapIcon}></img>{" "}
                        </Box>
                        <Box className="hide-content p-0" sx={{ mt: 1 }}>
                          <a href="#">
                            Map <br /> View
                          </a>
                        </Box>
                      </Box>
                    </Box>

                    <Box className="d-flex align-items-start  justify-content-between">
                      <Box className="hide-content">
                        <Typography>Plan Managed</Typography>

                        <Typography sx={{ mt: 2 }}>
                          Category <span>July</span>
                        </Typography>
                      </Box>
                      <Box className="hide-content">
                        <Typography>
                          NDIS No. <span> 4356789</span>
                        </Typography>

                        <Typography sx={{ mt: 2 }}>
                          Status <span>New</span>
                        </Typography>
                      </Box>
                      <Box className="hide-content">
                        <Typography>
                          NDIS Zone <span>NSW</span>
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="hide-content pb-0 w-100">
                      <Box className="hide-client-progress">
                        <Progress />
                        <Box className="d-flex progress-date align-items-center justify-content-between">
                          <Typography>12-01-2023</Typography>
                          <Typography>12-02-2024</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className="d-flex align-items-center justify-content-between status-wrap">
            <Box className="d-flex align-items-center ">
              <label>Status:</label>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={personName}
                className="hide-client-status"
                size="small"
                onChange={handleChangeCheck}
                input={<OutlinedInput label="Tag" />}
                //renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                sx={{ mt: 2, mb: 2, ml: 1 }}
              >
                {names.map((name, index) => (
                  <MenuItem
                    key={name}
                    onClick={() => setActive(index + 1)}
                    className={`select-item-${active === index && "1"}`}
                    value={name}
                  >
                    <span
                      className={`select-style select-box-${index + 1}`}
                    ></span>
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box>
              <Button className="follow-up-btn">Follow Up</Button>
            </Box>
          </Box>

          <Box className="team-section">
            <Box className="Team d-flex align-items-center">
              <Typography>Team:</Typography>
              <Box className="d-flex align-items-center">
                <img src={team1} />
                <span>Will Smear</span>
              </Box>
              <Box className="team-divider"></Box>
              <Box className="d-flex align-items-center">
                <img src={team1} />
                <span>Ginny Mark</span>
              </Box>
              <Box className="team-divider"></Box>
              <Box className="d-flex align-items-center">
                <img src={team1} />
                <span>Swan McCurry</span>
              </Box>
            </Box>

            <Grid container spacing={2} className="team-details">
              <Grid
                container
                spacing={2}
                sx={{ ml: 0, mt: 4 }}
                className="d-flex align-items-start justify-content-between"
              >
                <Grid item sm={4} xs={4} md={4} className=" p-0">
                  <Box className="d-flex align-items-start">
                    <Typography>Support Type: </Typography>
                    <span>Core </span>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={4}
                  xs={4}
                  md={4}
                  className="d-flex align-items-start p-0"
                >
                  <Box className="d-flex align-items-start">
                    <Typography>Appointment Date:</Typography>
                    <span>12-02-2023</span>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={4}
                  xs={4}
                  md={4}
                  className="d-flex align-items-start p-0"
                >
                  <Box className="d-flex align-items-start">
                    <Typography>Appointment Time: </Typography>
                    <span> 11:00 - 12:00 </span>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ ml: 0, mt: 4 }}
                className="d-flex align-items-start justify-content-between"
              >
                <Grid
                  item
                  sm={4}
                  xs={4}
                  md={4}
                  className="d-flex align-items-start p-0"
                >
                  <Box className="d-flex align-items-start">
                    <Typography sx={{ width: "138px" }}>Item Name: </Typography>
                    <span>
                      Assistance in Support independent Living Standard- Weekday
                      Daytime{" "}
                    </span>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={4}
                  xs={4}
                  md={4}
                  className="d-flex align-items-start p-0"
                >
                  <Box className="d-flex align-items-start">
                    <Typography>Category: </Typography>
                    <span>Homecare </span>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={4} md={4} className=" p-0">
                  <Box className="d-flex align-items-start">
                    <Typography>Appointment Type: </Typography>
                    <span>Regular </span>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ ml: 0, mt: 4 }}
                className="d-flex align-items-start justify-content-between"
              >
                <Grid item sm={4} xs={4} md={4} className=" p-0">
                  <Box className="d-flex align-items-start">
                    <Typography>Other Support Worker: </Typography>
                    <span>Swan McCurry </span>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={4} md={4} className=" p-0">
                  <Box className="d-flex align-items-start">
                    <Typography>Support Provider: </Typography>
                    <span>Ginny Mark </span>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={4} md={4} className=" p-0">
                  <Box className="d-flex align-items-start">
                    <Typography>Support Coordinator: </Typography>
                    <span>Ginny Mark </span>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ ml: 0, mt: 4 }}
                className="d-flex align-items-start justify-content-between"
              >
                <Grid item sm={4} xs={4} md={4} className=" p-0">
                  <Box className="d-flex align-items-start">
                    <Typography>Supervisor:</Typography>
                    <span>Will Smear </span>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={4} md={4} className=" p-0">
                  <Box className="d-flex align-items-start">
                    <Typography>Qualifications Required: </Typography>
                    <span>B.Sc </span>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={4} md={4} className=" p-0"></Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{ ml: 0, mt: 4 }}
                className="d-flex align-items-start justify-content-between"
              >
                <Grid item sm={4} xs={4} md={4} className=" p-0">
                  <Box className="d-flex align-items-start">
                    <Typography>Start-End Time: </Typography>
                    <span>11:00 AM - 12:00 PM </span>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={4} md={4} className=" p-0">
                  <Box className="d-flex align-items-start">
                    <Typography>Start to (Location): </Typography>
                    <span>Sydney </span>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={4} md={4} className=" p-0">
                  <Box className="d-flex align-items-start">
                    <Typography>End at (Location): </Typography>
                    <span>Brisbane </span>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box className="routine-section">
            <Box className=" routine-task-strip service-strip">
              <Typography>Routine & Tasks</Typography>
            </Box>

            <Grid
              container
              spacing={4}
              sx={{ mt: 5 }}
              className="add-appointment-input-box align-items-center"
            >
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                className="flex-column pt-0 d-flex"
              >
                <label>Add Title</label>
                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  className="form-input"
                  type="Name"
                  size="small"
                  placeholder="Meal"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={7}
                md={7}
                sx={{ mt: 2 }}
                className="d-flex justify-content-end "
              >
                <label></label>
                <button className="add-routine-btn" onClick={handleFaceOpen}>
                  Add Routine
                </button>
              </Grid>
            </Grid>

            <Box class="routine-stepper-box">
              <Box sx={{ mt: 4 }}>
                <span className="routine-chip">
                  Exercise <img src={cancel} />
                </span>
              </Box>

              <Grid
                container
                sx={{ mt: 2 }}
                spacing={4}
                className="d-flex align-items-center "
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Box className="add-task-box d-flex flex-wrap align-items-center">
                    <Box className="add-task-item">
                      <Typography>Task 1</Typography>
                      <span className="addtask done-task">
                        {" "}
                        <span className="task-num">1</span>{" "}
                      </span>
                      <img src={verified} className="verified-none"></img>
                      <VerifiedIcon className="verified-icon" />
                    </Box>
                    <Box className="connector-line"></Box>
                    <Box className="add-task-item">
                      <Typography>Task 2</Typography>
                      <span className="addtask done-task">
                        {" "}
                        <span className="task-num">2</span>{" "}
                      </span>
                      <img src={verified} className="verified-none"></img>
                      <VerifiedIcon className="verified-icon" />
                    </Box>
                    <Box className="connector-line"></Box>
                    <Box className="add-task-item">
                      <Typography>Task 3</Typography>
                      <span className="addtask ">
                        {" "}
                        <span className="task-num">3</span>{" "}
                      </span>
                      <img src={verified}></img>
                      <VerifiedIcon className="verified-icon verified-none" />
                    </Box>
                    <Box className="connector-line"></Box>
                    <Box className="add-task-item">
                      <Typography>Task 4</Typography>
                      <span className="addtask ">
                        {" "}
                        <span className="task-num">4</span>{" "}
                      </span>
                      <img src={verified}></img>
                      <VerifiedIcon className="verified-icon verified-none" />
                    </Box>

                    <Box>
                      <button className="stepper-add-task-btn">
                        <AddCircleIcon /> Add Task
                      </button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box className="client-map">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8} md={8}>
                <Box className="map-modal bg-light">
                  <div class="mapouter">
                    <div class="gmap_canvas">
                      <iframe
                        width="100%"
                        height="323px"
                        className="map-iframe"
                        id="gmap_canvas"
                        src="https://maps.google.com/maps?q=Sydney&t=&z=10&ie=UTF8&iwloc=&output=embed"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      ></iframe>
                    </div>
                  </div>
                </Box>

                <Box className="map-details">
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box className="d-flex map-details-div align-items-center">
                        <Typography>KM To:</Typography>
                        <span>45 KM</span>
                      </Box>
                      <Box className="d-flex align-items-center map-details-div">
                        <Typography>Duration:</Typography>
                        <span>120 Minutes</span>
                      </Box>
                      <Box className="d-flex align-items-center map-details-div">
                        <Typography>Accounts: </Typography>
                        <a href="javascript:;">Add Settings</a>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Box className="d-flex map-details-div align-items-center">
                        <Typography>KM With:</Typography>
                        <span>18 KM</span>
                      </Box>
                      <Box className="d-flex align-items-center map-details-div">
                        <Typography>Flexible with Time:</Typography>
                        <span>Yes</span>
                      </Box>
                      <Box className="d-flex align-items-center map-details-div">
                        <Typography>Tasks: </Typography>
                        <span> No Task has been added </span>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <Box className="chat-box">
                  <Box className="chat-search-box d-flex align-items-center justify-content-between">
                    <Typography>Chat Support</Typography>
                    <img src={chatSearch} />
                  </Box>
                  <Box className="chat-box-main">
                    <Box className="chat-date-strip">
                      <Typography>18-02-2023</Typography>
                    </Box>

                    <Box sx={{ pl: 1, pr: 1 }}>
                      <Box className="chat-in-coming">
                        <Box className="in-coming-main massege-main">
                          <Box>
                            <Box className="massege-profile d-flex align-items-center">
                              <img src={team1} />
                              <Typography>Will Smear (SW)</Typography>
                            </Box>

                            <Box className="masseges">
                              <Typography>Good morning Sir...</Typography>
                              <span>02:00 pm</span>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="chat-out-going ">
                        <Box className="massege-main out-going-main">
                          <Box>
                            <Box className="massege-profile d-flex align-items-center">
                              <img src={team1} />
                              <Typography>Supervisor (SW)</Typography>
                            </Box>

                            <Box className="out-going-massege">
                              <Typography>How I can help you?</Typography>
                              <span>02:00 pm</span>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="chat-in-coming">
                        <Box className="in-coming-main massege-main">
                          <Box>
                            <Box className="massege-profile d-flex align-items-center">
                              <img src={team1} />
                              <Typography>Will Smear (SW)</Typography>
                            </Box>

                            <Box className="masseges">
                              <Typography>My appointment is ready ?</Typography>
                              <span>02:00 pm</span>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box className="chat-send d-flex align-items-center justify-content-between">
                  <img src={reactionIcon} />

                  <TextField
                    id="outlined-basic"
                    className=" chat-send-inputFiled"
                    placeholder="I am waiting|"
                    size="small"
                  />

                  <img src={sendIcon} />
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Routine stepper section start here  */}

          <Box className="other-details">
            <Box>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                sx={{ mt: 5 }}
                className="input flex-column d-flex pt-0"
              >
                <label>Notes</label>

                <TextField
                  id="outlined-basic"
                  sx={{ mt: 2 }}
                  className="w-100 other-textarea"
                  placeholder="$"
                />
              </Grid>
            </Box>

            <Box className="agency-btn-section justify-content-center d-flex align-items-center agency-btn-section-vk">
              <Link href="javascript:;" className="add-appoint-cancel-btn">
                Cancel
              </Link>
              <Button type="submit" className="saveBtn">
                Save
              </Button>
              {/* <Link type="submit" >Save</Link> */}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Finish Appointment dailogue  */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className="location-modal">
          <DialogContent className="p-0">
            <DialogContentText
              id="alert-dialog-description"
              className="modal-content  text-center"
            >
              <Typography>
                Allow Hubshift to access this <br /> device’s location?
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className="modal-button">
            <Box className="agency-btn-section location-modal-btn">
              <a href="#cancel" onClick={handleClose}>
                Deny
              </a>
              <a href="#save" onClick={handleClose} autoFocus>
                Allow
              </a>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>

      {/* face scan modal  */}

      <Dialog
        open={faceScan}
        onClose={handleFaceClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className="location-modal">
          <DialogContent className="p-0">
            <DialogContentText
              id="alert-dialog-description"
              className="modal-content  text-center"
            >
              <Typography>Face Recognition</Typography>
              <img className="face-scan" src={scaning} />

              <button className="face-capture-btn">Click to Capture</button>
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </Box>
  );
}
