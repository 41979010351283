import React, { useState } from "react";
import Box from "@mui/material/Box";
import "../../pages/superAdmin/Timesheet/timesheet.css";
import Appbar from "../../Dashboard/Layout/Appbar";
import Sidebar from "../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Button, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "../../assets/css/dashboard.css";
import "../Appointmentschedule/appointment.css";
import "../../assets/css/Appointment.css";
import Form from "@mui/material/FormGroup";
import upload from "../../assets/images/upload.svg";
import camera from "../../assets/images/camera.svg";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function AddExpenses() {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Appointments</Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography>Appointment Details</Typography>
    </Link>,
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="javascript;:"
      onClick={handleClick}
    >
      <Typography>After Appointment Details</Typography>
    </Link>,
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="javascript;:"
      onClick={handleClick}
    >
      <Typography>Add Expenses</Typography>
    </Link>,
  ];

  return (
    <Box className="dashboard-form" id="add-expenses">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box className=" d-flex dashboard-main-box">
        <Sidebar />
        <Box className="main">
          <Grid container className="d-flex header-topsection"> 
            <Grid item xs={9} md={9} sm={9}>
              <Box id="breadcrumb">
                <ArrowBackIosIcon />
                <Box className="breadcrumb-inr">
                  <h4 className="d-flex align-items-center "> Add Expense</h4>
                  <Box className="breadcrumbs">
                    <Link className="breadcrumbs-link"> Appointments</Link>
                    <span className="breadcrumbs-icon">{">"}</span>
                    <Link className="breadcrumbs-link">
                      Appointment Details
                    </Link>
                    <span className="breadcrumbs-icon">{">"}</span>
                    <Link className="breadcrumbs-link">
                      After Appointment Details
                    </Link>
                    <span className="breadcrumbs-icon">{">"}</span>
                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                      Add Expenses
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} md={3} sm={3}>
              <Typography>
                <span>Appointment Date</span>: 12-02-2023, 11:00 - 12:00
              </Typography>
            </Grid>
          </Grid>

          <Box className="divider" ></Box>    

          {/* Tab Start here  */}

          <Box className="d-flex justify-content-center add-expenses-p-10">
            <Box className="add-expenses">
              <Box className="add-expenses-head d-flex align-items-center justify-content-between">
                <Typography> Add Expenses</Typography>
                <Typography>
                  Client: <span>Jake Knap</span>
                </Typography>
              </Box>
              <Box className="add-expenses-body">
                <Grid className="align-items-center w-100 add-expenses-input-box justify-content-between">
                  <Grid container spacing={4} className="align-items-end">
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      className="input flex-column d-flex pt-0"
                    >
                      <label>Expense Title</label>

                      <TextField
                        id="outlined-basic"
                        className="w-100"
                        size="small"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      className="input flex-column d-flex pt-0"
                    >
                      <label>Amount (in $)</label>

                      <TextField
                        id="outlined-basic"
                        className="w-100"
                        placeholder="$"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ mt: 2 }}
                    className="input pt-0"
                  >
                    <Box className="attach-document task-attachment">
                      <Box>
                        <h3>Attach Document</h3>
                      </Box>

                      <Box className="task-edit-attach-document">
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box className="d-flex align-items-center ">
                              <input
                                type="radio"
                                name="noteupload"
                                id="noteupload"
                              ></input>
                              <img src={upload} alt="upload"></img>
                              <label for="noteupload">Upload</label>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="noteupload"
                                id="notecapture"
                              ></input>
                              <img src={camera}></img>
                              <label for="notecapture">Capture</label>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ mt: 2 }}
                    className="input pt-0"
                  >
                    <Box className="d-flex align-items-center">
                      <Form className=" list-attach-document-browse w-100 ">
                        <TextField
                          id="outlined-basic"
                          size="small"
                          className="attach-file-input"
                          type="file"
                        ></TextField>
                      </Form>
                    </Box>
                    <Button className="upload-btn">Upload</Button>
                  </Grid>
                </Grid>

                <Box className="agency-btn-section add-expenses-btn">
                  <Link href="#cancel">Cancel</Link>
                  <Link href="#save" to="/timesheet">
                    Save
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
