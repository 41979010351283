import React from "react";
import Box from '@mui/material/Box';
import Header from '../../components/Header';
import Typography from '@mui/material/Typography';
import Stepper from '../../components/ui/Stepper';



export default function SetupAccount() {

    return (
        <Box id="account-setup" >
            <Header />
             
            <Box className='container'>
                <Box className="account-setup-top-content">
                    <h3>Setup Your Account</h3>
                    <Typography >Follow these 5 steps to complete setting up your account</Typography>
                </Box>
            </Box>
            <Box className='container'>
                <Stepper />
            </Box>
        </Box>
    );
}