import { useState, useEffect, lazy, Suspense, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import localstorageData from "../helpers/localstorageData";
import axios from "axios";
import { getuserDetail, accessRoleList } from "../api";
import Box from "@mui/material/Box";
import Appbar from "./../Dashboard/Layout/Appbar";
import Sidebar from "./../Dashboard/Layout/Sidebar";
import { DelayedError } from "./DelayedError";
import { getCreatedBy } from "../helpers/commonHelper";
import Loader from "../../../frontend/src/pages/loader/Loader";
//404 page
import Error from "./../components/Error";
import ProgressObjective from "../pages/superAdmin/progressObjective/list.js";

export default function SupportCoordinator() {
  let MemoizedAppbar, MemoizedSidebar;
  let createdBy = getCreatedBy();

  MemoizedAppbar = useMemo(() => <Appbar />, []);
  MemoizedSidebar = useMemo(() => <Sidebar />, []);

  const user = localstorageData();
  const [isIndividual, setIsIndividual] = useState(false);

  useEffect(() => {
    if (user.role === "supportCoordinator") {
      axios
        .get(`${getuserDetail}/${user._id}`)
        .then(function (res) {
          if (res) {
            setIsIndividual(res?.data?.data?.userData?.isIndividual);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [user._id !== undefined]);

  const SetupAccountSupportCoordinator = lazy(() =>
    import("../pages/supportCoordinator/Account-Setup")
  );

  const MyAccount = lazy(() =>
    import("../pages/supportCoordinator/form/MyAccount")
  );
  //const Home = lazy(() => import("../pages/supportCoordinator/form/home"));
  const Home = lazy(() => import("../pages/supportCoordinator/SC-Dashboard"));
  const Listpayrun = lazy(() =>
    import("../pages/superAdmin/PayRun/list-user-pay-run")
  );

  const ChatHistory = lazy(() =>
    import("../pages/superAdmin/chathistory/list")
  );
  const Appointments = lazy(() =>
    import("../pages/supportCoordinator/appointment/list")
  );
  const AppointmentView1 = lazy(() =>
    import("../components/calendar-view/Appointment-View")
  );

  const AddRoutine = lazy(() =>
    import("../components/calendar-view/routine/Add-Routine")
  );

  const AddRoutineTask = lazy(() =>
    import("../components/calendar-view/task/AddRoutineTask")
  );

  const Swapappointment = lazy(() =>
    import("../components/calendar-view/swap-appointment/swapappointment")
  );
  const Export = lazy(() => import("../pages/superAdmin/Reports/exports"));
  const ServiceTaskReportList = lazy(() =>
    import("../pages/superAdmin/Reports/serviceTask")
  );
  const Edittemplatevariablemaster = lazy(() =>
    import("../pages/superAdmin/template-master/variable/edit")
  );

  const ClientList = lazy(() => import("../pages/superAdmin/client-list/list"));
  const ClientBulkUpload = lazy(() =>
    import("../components/Bulk-Upload/Client/Import")
  );
  const EditClient = lazy(() => import("../pages/superAdmin/client-list/edit"));
  const VariableList = lazy(() =>
    import("../pages/superAdmin/template-master/variable/list")
  );
  const AddTemplateMaster = lazy(() =>
    import("../pages/superAdmin/template-master/variable/edit")
  );
  // const Report = lazy(() => import("../pages/superAdmin/Reports/report"));
  const Report = lazy(() => import("../pages/superAdmin/Reports/IndReport"));
  const EmployeeBirthday = lazy(() =>
    import("../pages/superAdmin/Reports/employee/employeeBirthday")
  );
  const EmployeeDetails = lazy(() =>
    import("../pages/superAdmin/Reports/employee/employeeDetails")
  );
  const EmergencyContact = lazy(() =>
    import("../pages/superAdmin/Reports/employee/emergencyContact")
  );
  const UnpaidEmployee = lazy(() =>
    import("../pages/superAdmin/Reports/employee/unpaidEmployee")
  );
  const JobmakerEmployeeNotice = lazy(() =>
    import("../pages/superAdmin/Reports/atoReporting/jobmakerEmployeeNotice")
  );
  const JobkeeperEmployeeNomination = lazy(() =>
    import(
      "../pages/superAdmin/Reports/atoReporting/jobkeeperEmployeeNomination"
    )
  );
  const TaxFileDeclaration = lazy(() =>
    import("../pages/superAdmin/Reports/atoReporting/taxFileDeclaration")
  );
  const AttendanceReport = lazy(() =>
    import("../pages/superAdmin/Reports/timeAndAttendance/attendanceReport")
  );
  const EmployeeSatisfaction = lazy(() =>
    import("../pages/superAdmin/Reports/employee/employeeSatisfaction")
  );
  const SingleTouchPayroll = lazy(() =>
    import("../pages/superAdmin/Reports/atoReporting/singleTouchPayroll")
  );

  const LeaveHistory = lazy(() =>
    import("../pages/superAdmin/Reports/leave-history")
  );

  const EmployeeDetailsAudit = lazy(() =>
    import("../pages/superAdmin/Reports/employee/employeeDetailsAudit")
  );

  const JobKeeperEligibility = lazy(() =>
    import("../pages/superAdmin/Reports/employee/jobKeeperEligibility")
  );

  const ViewClientDetailsOld = lazy(() =>
    import("../pages/superAdmin/client-list/view-details")
  );

  const ViewClientDetails = lazy(() =>
    import("../pages/superAdmin/client-list/view-details-client")
  );

  const EditMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/Add-Medical-History")
  );

  const HealthCarerList = lazy(() =>
    import("../pages/superAdmin/health-carer/list")
  );

  const HealthCarerEdit = lazy(() =>
    import("../pages/superAdmin/health-carer/edit")
  );
  const HealthCarerDetails = lazy(() =>
    import("../pages/superAdmin/health-carer/details")
  );
  const ViewHealthCarerDetailsOld = lazy(() =>
    import("../pages/superAdmin/health-carer/view-details")
  );

  const ViewHealthCarerDetails = lazy(() =>
    import("../pages/superAdmin/health-carer/view-details-hc")
  );

  const HealthCarerInterviewHistory = lazy(() =>
    import("../pages/superAdmin/health-carer/scheduleInterviewHistory/History")
  );

  const SupportCoordinatorList = lazy(() =>
    import("../pages/superAdmin/support-coordinator/list")
  );

  const SupportCoordinatorOnBoard = lazy(() =>
    import("../components/Bulk-Upload/SupportCoordinator/Import")
  );

  const SupportCoordinatorListEdit = lazy(() =>
    import("../pages/superAdmin/support-coordinator/edit")
  );

  const SupportCoordinatorDetails = lazy(() =>
    import("../pages/superAdmin/support-coordinator/details")
  );
  const ViewSupportCoordinatorDetailsOld = lazy(() =>
    import("../pages/superAdmin/support-coordinator/view-details")
  );

  const ViewSupportCoordinatorDetails = lazy(() =>
    import("../pages/superAdmin/support-coordinator/view-details-sc")
  );

  const CommonListemergencycontact = lazy(() =>
    import("../pages/superAdmin/emergency-contact/commonFileList.js")
  );
  const CommonAddemergencycontact = lazy(() =>
    import("../pages/superAdmin/emergency-contact/commonFileEdit.js")
  );

  const EditemergencycontactN = lazy(() =>
    import("../pages/healthCarer/Emergency-Contact/edit")
  );

  const Editemergencycontact = lazy(() =>
    import("../pages/superAdmin/emergency-contact/edit")
  );

  const ListemergencycontactN = lazy(() =>
    import("../pages/healthCarer/Emergency-Contact/list")
  );

  const Listemergencycontact = lazy(() =>
    import("../pages/superAdmin/emergency-contact/list")
  );

  const AddemergencycontactN = lazy(() =>
    import("../pages/healthCarer/Emergency-Contact/edit")
  );
  const Addemergencycontact = lazy(() =>
    import("../pages/superAdmin/emergency-contact/edit")
  );

  const ListPackageMenu = lazy(() => import("../components/packageMenu/list"));

  const ScheduleMeeting = lazy(() =>
    import("../pages/superAdmin/schedule-meeting/meeting")
  );

  const ListScheduleMeeting = lazy(() =>
    import("../pages/superAdmin/schedule-meeting/client/list-tab")
  );

  const ListCommonCalander = lazy(() =>
    import("../pages/superAdmin/schedule-common-meeting/client/list-tab")
  );

  const ScheduleOtherMeeting = lazy(() =>
    import("../pages/superAdmin/schedule-other-meeting/meeting")
  );

  const ViewOtherMeeting = lazy(() =>
    import("../components/meeting-view-other/details")
  );

  const ListViewScheduleMeeting = lazy(() =>
    import("../components/meeting-view/details")
  );

  //

  const SCscheduleInterview = lazy(() =>
    import(
      "../pages/superAdmin/schedule-interview/health-carer/schedule-interview"
    )
  );
  const SCinterviewHistory = lazy(() =>
    import("../pages/superAdmin/health-carer/scheduleInterviewHistory/History")
  );
  const ListScheduleInterview = lazy(() =>
    import("../pages/superAdmin/schedule-interview/health-carer/list-tab")
  );
  const ListViewScheduleInterview = lazy(() =>
    import("../components/interview-view/details")
  );

  const PendingApproval = lazy(() =>
    import("../pages/superAdmin/pending-approval")
  );

  const SubscriptionPlan = lazy(() =>
    import("../pages/serviceProvider/subscription-plan/list")
  );
  const PaymentGateway = lazy(() =>
    import("../pages/serviceProvider/subscription-plan/paymentGateway")
  );

  const ListSecondaryUsers = lazy(() =>
    import("../pages/superAdmin/users/list")
  );

  const EditSecondaryUsers = lazy(() =>
    import("../pages/superAdmin/users/edit")
  );

  const SignedDocument = lazy(() =>
    import("../components/SignedDocument/ListSignedDocument")
  );

  const ViewSignedDocument = lazy(() =>
    import("../components/SignedDocument/ViewSignedDocument")
  );

  const Settings = lazy(() =>
    import("../pages/serviceProvider/Settings/setting")
  );

  const MasterDataSetting = lazy(() =>
    import("../pages/serviceProvider/Settings/manageMasterData")
  );

  const SettingPayRun = lazy(() =>
    import("../pages/serviceProvider/Settings/payRun/payRun")
  );

  const SettingATO = lazy(() =>
    import("../pages/serviceProvider/Settings/atoSetting")
  );
  const SettingPayment = lazy(() =>
    import("../pages/serviceProvider/Settings/payment")
  );
  const SettingXero = lazy(() => import("../pages/superAdmin/xero-myob/list"));
  const SettingSuperannuationList = lazy(() =>
    import("../pages/serviceProvider/Settings/superAnnuation/list")
  );
  const SettingSuperannuationAdd = lazy(() =>
    import("../pages/serviceProvider/Settings/superAnnuation/edit.js")
  );
  const SettingSuperannuationEdit = lazy(() =>
    import("../pages/serviceProvider/Settings/superAnnuation/edit.js")
  );
  const SettingViewFinancial = lazy(() =>
    import(
      "../pages/serviceProvider/Settings/financialSettings/financialSettings"
    )
  );

  const ListMedicalHistory = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
  );

  const PdfPayRun = lazy(() =>
    import("../pages/superAdmin/PayRun/Pdf")
  );
  
  const SendCustomEmail = lazy(() => import("../components/email/list"));

  const HealthcarerOnboardAdmin = lazy(() =>
    import("../pages/healthCarer/Account-Setup")
  );

  const SupportcoordinatorOnboardAdmin = lazy(() =>
    import("../pages/supportCoordinator/Account-Setup")
  );

  const ServiceproviderOnboardAdmin = lazy(() =>
    import("../pages/serviceProvider/Account-Setup")
  );

  const ClientOnboardAdmin = lazy(() =>
    import("../pages/client/Account-Setup")
  );

  const ServiceProviderList = lazy(() =>
    import("../pages/superAdmin/service-provider/list")
  );

  const ServiceProviderListEdit = lazy(() =>
    import("../pages/superAdmin/service-provider/edit")
  );

  const ServiceProviderDetails = lazy(() =>
    import("../pages/superAdmin/service-provider/view-details-sp")
  );

  const ServiceItemList = lazy(() =>
    import("../pages/superAdmin/service-item/list")
  );

  const ServiceItemEdit = lazy(() =>
    import("../pages/superAdmin/service-item/edit")
  );

  const ServiceTaskList = lazy(() =>
    import("../pages/superAdmin/service-task/list")
  );

  const ServiceTaskEdit = lazy(() =>
    import("../pages/superAdmin/service-task/edit")
  );
  const ServiceTaskView = lazy(() =>
    import("../pages/superAdmin/service-task/view")
  );

  const AccessRoleListUrl = lazy(() =>
    import("../pages/superAdmin/access-role/list")
  );

  const AccessRoleAddUrl = lazy(() =>
    import("../pages/superAdmin/access-role/add.js")
  );

  const AccessRoleListPermission = lazy(() =>
    import("../pages/superAdmin/access-role-permission/list")
  );

  const PlanmanagerList = lazy(() =>
    import("../pages/supportCoordinator/planManager/list")
  );
  const PlanmanagerEdit = lazy(() =>
    import("../pages/supportCoordinator/planManager/edit")
  );

  const PlanmanagerView = lazy(() =>
    import("../pages/supportCoordinator/planManager/view")
  );

  //leave management new changes

  const ListLeaveCategory = lazy(() =>
    import("../pages/superAdmin/leave-category/list")
  );

  const EditLeaveCategory = lazy(() =>
    import("../pages/superAdmin/leave-category/edit")
  );

  const ListLeaveManagementOld = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list")
  );

  const ListLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/list-tab")
  );

  const EditLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/edit")
  );

  const ViewLeaveManagement = lazy(() =>
    import("../pages/superAdmin/leaveManagement/view")
  );

  const AddAppointment = lazy(() =>
    import("../pages/superAdmin/appointment/add-appointment")
  );

  const ListSetLeaves = lazy(() => import("../pages/superAdmin/setLeave/list"));

  const EditSetLeaves = lazy(() => import("../pages/superAdmin/setLeave/edit"));
  const Addtask = lazy(() =>
    import("../pages/superAdmin/client-list/add-task/edit")
  );
  const Addnote = lazy(() =>
    import("../pages/superAdmin/client-list/add-note/edit")
  );

  const HcOnCall = lazy(() =>
    import("../pages/superAdmin/health-carer/on-call/list")
  );
  const ListFundManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/Fund-Management")
  );
  const ViewFundManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/View-Fund-Management")
  );
  const PrintFundManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/print")
  );
  const AvailabilityCalender = lazy(() =>
    import("../components/availability/Calendar")
  );
  const ProgressNoteAdd = lazy(() =>
    import("../pages/superAdmin/progressNote/edit")
  );
  const ProgressNotelist = lazy(() =>
    import("../pages/superAdmin/progressNote/list")
  );
  const InvoicesServiceTask = lazy(() =>
    import("../components/invoice-service-task/Invoice")
  );

  //test
  const InvoicesPdf = lazy(() =>
    import("../components/invoice-service-task/InvoicePdf")
  );

  const PaySlipsServiceTask = lazy(() =>
    import("../components/payslips-service-task/Invoice")
  );
  const PaySlipsServiceTaskPdf = lazy(() =>
    import("../components/payslips-service-task/InvoicePdf")
  );
  const Listtemplatemaster = lazy(() =>
    import("../pages/supportCoordinator/template-master/list")
  );
  const Addtemplatemaster = lazy(() =>
    import("../pages/supportCoordinator/template-master/edit")
  );
  const Offerletter = lazy(() =>
    import("../pages/superAdmin/contentManagement/offer-letter/edit")
  );
  const Edittemplatemaster = lazy(() =>
    import("../pages/superAdmin/template-master/edit")
  );

  const PayslipPdf = lazy(() =>
    import("../components/payroll/payslips/PayslipPdf")
  );

  const TimeSheet = lazy(() => import("../components/timesheet/Timesheet"));

  const TimeSheetPdf = lazy(() => import("../components/pay-run/PayRunPdf"));

  const TimeSheetView = lazy(() =>
    import("../components/timesheet/TimesheetView")
  );

  const ViewFood = lazy(() =>
    import("../pages/superAdmin/client-list/Viewtab/Observation/Food/view")
  );
  const EditGoal = lazy(() => import("../components/goals/edit"));
  const ViewEmail = lazy(() => import("../components/email/view"));
  const EditEmail = lazy(() =>
    import("../components/secondaryEmail/newEmaill/edit")
  );
  const ServiceTaskNotes = lazy(() =>
    import("../pages/superAdmin/serviceTaskNotes/list")
  );

  const MenuMaster = lazy(() => import("../pages/superAdmin/menuMaster/list"));
  const AddMenuMaster = lazy(() =>
    import("../pages/superAdmin/menuMaster/edit")
  );
  const OffBoarding = lazy(() =>
    import("../pages/superAdmin/off-boarding/MyAccount")
  );
  const ListSupportCorrdinatorOffBoarding = lazy(() =>
    import("../pages/superAdmin/off-boarding/support-coordinator/list.js")
  );
  const Editgoalroutine = lazy(() =>
    import("../components/goals/addRoutineTask/edit")
  );
  const BarChart = lazy(() => import("../components/goals/barChart"));
  const Viewtask = lazy(() => import("../components/goals/view"));

  const listSupportCoordinatorOffBoarding = lazy(() =>
    import("../pages/superAdmin/off-boarding/support-coordinator/list")
  );
  const GoalList = lazy(() => import("../components/goals/list"));
  const AddGoalRoutine = lazy(() =>
    import("../components/goals/addRoutineTask/edit")
  );
  const AddGoal = lazy(() => import("../components/goals/edit"));

  const timesheetPdf = lazy(() =>
    import("../components/timesheet/timesheetPdf.js")
  );
  const PayslipList = lazy(() =>
    import("../pages/supportCoordinator/payslipSC/PayslipList")
  );
  const PdfServiceTaskNotes = lazy(() =>
    import("../pages/superAdmin/serviceTaskNotes/serviceTaskpdf.js")
  );

  const ProfitLossReportPage = lazy(() =>
    import("../pages/superAdmin/Profit/Profit.js")
  );
  const AttendanceReportPage = lazy(() =>
    import("../pages/superAdmin/Reports/timeAndAttendance/attendanceReport.js")
  );

  const SelectClient = lazy(() => import("../components/goals/ClientSelect"));

  const ViewDataBackup = lazy(() => import("../components/dataBackup/list"));

  const Payrun = lazy(() => import("../components/pay-run/PayRun.js"));
  const Attendance = lazy(() =>
    import("../pages/CommanComponant/Attendance/Attendance")
  );
  const Pdfpayrun = lazy(() => import("../components/pay-run/PayRunPdf.js"));
  const ListIncident = lazy(() => import("../pages/superAdmin/incident/list"));
  const EditIncident = lazy(() => import("../pages/superAdmin/incident/edit"));
  const ViewIncident = lazy(() => import("../pages/superAdmin/incident/view"));
  const PdfIncident = lazy(() => import("../pages/healthCarer/incident/pdf"));
  const ClientExportPdfWithImage = lazy(() =>
    import("../components/goals/pdfWithImage")
  );
  const ClientExportPdf = lazy(() => import("../components/goals/pdf"));

  const ListAssestsManagement = lazy(() =>
    import("../pages/superAdmin/assetManagement/list.js")
  );
  const EditAssetsManagement = lazy(() =>
    import("../pages/superAdmin/assetManagement/edit")
  );
  const ListSilSda = lazy(() =>
    import("../pages/superAdmin/sil-sda-location/list")
  );
  const EditSilSda = lazy(() =>
    import("../pages/superAdmin/sil-sda-location/edit")
  );

  const PayRunAttandance = lazy(() =>
    import("../pages/superAdmin/Reports/timeAndAttendance/payRunAttandance.js")
  );

  const BSPList = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/listMedicalHistory")
  );

  const BSPAdd = lazy(() =>
    import("../pages/superAdmin/Medication-management-dev/addBsp")
  );

  const SmtpSetup = lazy(() =>
    import("../pages/serviceProvider/Settings/smtpSetup.js")
  );

  const ViewFundsManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/View-Fund-Management")
  );

  const PrintFundsManagement = lazy(() =>
    import("../pages/superAdmin/FundManagement/print")
  );

  const AddFamilyMember = lazy(() =>
    import("../pages/superAdmin/family-member-client/edit")
  );

  const EditFamilyMember = lazy(() =>
    import("../pages/superAdmin/family-member-client/edit")
  );

  const ViewSecondaryUser = lazy(() =>
    import("../pages/superAdmin/users/view.js")
  );

  const ListConflict = lazy(() =>
    import("../pages/superAdmin/conflictClient/list")
  );
  const AddConflict = lazy(() =>
    import("../pages/superAdmin/conflictClient/edit")
  );
  const EditConflict = lazy(() =>
    import("../pages/superAdmin/conflictClient/edit")
  );

  const ListPublicHoliday = lazy(() =>
    import("../pages/superAdmin/public-holiday/list.js")
  );
  const AddPublicHoliday = lazy(() =>
    import("../pages/superAdmin/public-holiday/edit")
  );
  const EditPublicHoliday = lazy(() =>
    import("../pages/superAdmin/public-holiday/edit")
  );
  const ListInductionCategory = lazy(() =>
    import("../pages/superAdmin/inductionCategory/list")
  );

  const EditInductionCategory = lazy(() =>
    import("../pages/superAdmin/inductionCategory/edit")
  );

  const AddInductionCategory = lazy(() =>
    import("../pages/superAdmin/inductionCategory/edit")
  );
  const AddInductionHeading = lazy(() =>
    import("../pages/superAdmin/inductionHeading/edit")
  );

  const ListInductionHeadingTitle = lazy(() =>
    import("../pages/superAdmin/inductionHeading/list")
  );

  const ListTemplateType = lazy(() =>
    import("../pages/superAdmin/templateType/list")
  );

  const AddTemplateType = lazy(() =>
    import("../pages/superAdmin/templateType/edit")
  );
  const EditTemplateType = lazy(() =>
    import("../pages/superAdmin/templateType/edit")
  );

  const ListAgreement = lazy(() =>
    import("../pages/superAdmin/appointment/appointment-agreement/list")
  );

  const AddAgreement = lazy(() =>
    import("../pages/superAdmin/appointment/appointment-agreement/edit")
  );

  const EditAgreement = lazy(() =>
    import("../pages/superAdmin/appointment/appointment-agreement/edit")
  );
  const ListSeverity = lazy(() => import("../pages/superAdmin/severity/list"));
  const AddSeverity = lazy(() => import("../pages/superAdmin/severity/edit"));
  const EditSeverity = lazy(() => import("../pages/superAdmin/severity/edit"));

  const [menuList, setMenuList] = useState([]);

  const getServiceProviderRouteAccess = () => {
    if (user?.createdByUserType === "superAdmin") {
      createdBy = user.createdBy;
    }

    if (user.createdByUserType === "serviceProvider") {
      createdBy = user.createdBy;
    } else if (user.createdByUserType === "supportCoordinator") {
      createdBy = user.createdBy;
    }

    axios
      .get(
        `${accessRoleList}?roleId=${user.userTypeId}&createdBy=${
          user.createdBy
        }&role=${user.role}&userId=${user._id}&userType=${
          user?.createdByUserType
        }&isABNHolder=${user?.isSoleTrader}&child=${
          user?.createdByUserType
        }&isABNHolderHc=${user?.isSoleTrader}&bookAdemo=${
          user?.isBookDemoUser
        }&isSc=${user?.isSoleTrader}&evlClient=${
          user?.packageMenuId == false && user?.role == "client"
            ? "true"
            : "false"
        }`
      )
      .then(function (res) {
        if (res.data.data.length > 0) {
          console.log("check accessRoleList", res);
          setMenuList((prevData) => [
            ...prevData,
            ...res.data.data,
            "/support-coordinator/list-package-menu",
            "/support-coordinator/subscription-plans-list",
            "/support-coordinator/myaccount",
            "/support-coordinator/attendance",
            "/support-coordinator/smtp-setup",
            "/support-coordinator/settings",
          ]);
        } else {
          setMenuList((prevData) => [
            ...prevData,
            ...[
              "/support-coordinator/list-package-menu",
              "/support-coordinator/subscription-plans-list",
              "/support-coordinator/myaccount",
              "/support-coordinator/attendance",
              "/support-coordinator/smtp-setup",
              "/support-coordinator/settings",
            ],
          ]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getServiceProviderRouteAccess();
  }, []);

  const TimeSheetST = lazy(() =>
    import("../components/timesheet-service-task/Timesheet")
  );
  const TimeSheetSTUpdated = lazy(() =>
    import("../components/timesheet-service-task-updated/Timesheet.js")
  );

  const ListRiskAssessment = lazy(() =>
    import("../pages/superAdmin/risk-assessment/list")
  );

  const AddRiskAssessment = lazy(() =>
    import("../pages/superAdmin/risk-assessment/edit.js")
  );

  const EditRiskAssessment = lazy(() =>
    import("../pages/superAdmin/risk-assessment/edit.js")
  );

  const ListBSP = lazy(() => import("../pages/superAdmin/Bsp/list"));

  const AddBSP = lazy(() => import("../pages/superAdmin/Bsp/edit"));

  const EditBSP = lazy(() => import("../pages/superAdmin/Bsp/edit"));

  const closureIncident = import(
    "../pages/Supervisor/incident/incidentClosure"
  );

  const ScRoutes = [
    {
      path: "/support-coordinator/set-access-role",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AccessRoleListUrl />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-access-role",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AccessRoleAddUrl />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-medical-history",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-medication-chart",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-prn-chart",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/pdf-pay-run",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PdfPayRun />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-appointments",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Appointments />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditIncident />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-service-provider",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-appointment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddAppointment />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/select-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SelectClient />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-service-provider",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderListEdit />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-service-provider",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderListEdit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/export-pdf-with-image",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ClientExportPdfWithImage />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/export-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ClientExportPdf />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListIncident />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/service-provider-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderDetails />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/access-role-permission",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AccessRoleListPermission />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/view-service-provider-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderDetails />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/client-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ClientList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/bulk-upload-client-onboard",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ClientBulkUpload />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditClient />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditClient />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-client-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewClientDetails />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-medical-history-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-medical-history-dev",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditMedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/schedule-interview",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SCscheduleInterview />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-health-carer/interview-history",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SCinterviewHistory />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-interview-health-carer",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListScheduleInterview />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-view-schedule-interview",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListViewScheduleInterview />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-view-schedule-meeting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListViewScheduleMeeting />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-meeting-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListScheduleMeeting />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-calendar",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListCommonCalander />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/schedule-other-meeting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ScheduleOtherMeeting />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/edit-other-meeting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ScheduleOtherMeeting />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-view-other-schedule-meeting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewOtherMeeting />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/schedule-meeting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ScheduleMeeting />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-package-menu",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListPackageMenu />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/home",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Home />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/swap-appointment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Swapappointment />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-routine",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddRoutine />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/task-add",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddRoutineTask />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/appointments-view",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AppointmentView1 />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-appointments",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Appointments />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/chat-history",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ChatHistory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/myaccount",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MyAccount />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <CommonAddemergencycontact />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <CommonAddemergencycontact />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <CommonListemergencycontact />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/subscription-plans-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SubscriptionPlan />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/payment-gateway",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PaymentGateway />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-signed-document",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SignedDocument />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-signed-document",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewSignedDocument />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/settings",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Settings />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/manage-master-data",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MasterDataSetting />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-plan-manager",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PlanmanagerEdit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-plan-manager",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PlanmanagerEdit />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-plan-manager",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PlanmanagerList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-plan-manager",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PlanmanagerView />
        </Suspense>
      ),
    },

    // leave management new changes
    {
      path: "/support-coordinator/list-leave-categories",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListLeaveCategory />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-leave-categories",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditLeaveCategory />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/edit-leave-categories",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditLeaveCategory />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-leave-management-old",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListLeaveManagementOld />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-leave-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListLeaveManagement />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-leave",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditLeaveManagement />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/edit-leave",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditLeaveManagement />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/view-leave",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewLeaveManagement />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-set-leave",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListSetLeaves />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-set-leave",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditSetLeaves />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/edit-set-leave",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditSetLeaves />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskEdit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskEdit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskView />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/time-sheet-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TimeSheetST />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/time-sheet-service-task-updated",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TimeSheetSTUpdated />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-menu-masters",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MenuMaster />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/smtp-setup",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SmtpSetup />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-paysilp",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PayslipList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/service-task-payslips",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PaySlipsServiceTask />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/service-task-payslip-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PaySlipsServiceTaskPdf />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-template-master",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listtemplatemaster />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-template-master",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Addtemplatemaster />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/offer-letter",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Offerletter />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-template-master",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Edittemplatemaster />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/reports/profit-and-loss",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProfitLossReportPage />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/time-sheet",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          {/* <TimeSheet /> */}
          <TimeSheetST />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-secondary-users",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListSecondaryUsers />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/availability-calendar-view",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AvailabilityCalender />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/health-carer-on-call",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HcOnCall />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SendCustomEmail />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-funds-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListFundManagement />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/invoice",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InvoicesServiceTask />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/reports",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Report />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-service-item",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceItemList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-service-task-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskNotes />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-employee-birthdays",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmployeeBirthday />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-employee-emergency-contacts",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmergencyContact />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-secondary-users",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditSecondaryUsers />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-health-carer",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HealthCarerList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-support-coordinator",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SupportCoordinatorList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/bulk-upload-supportcoordinator-onboard",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SupportCoordinatorOnBoard />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/pay-run",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Payrun />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/pay-run-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Pdfpayrun />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/attendance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Attendance />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/bar-chart-goal",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <BarChart />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-superannuation-setting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingSuperannuationAdd />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNotelist />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/payments",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingPayment />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-user-pay-run",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listpayrun />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/view-funds-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewFundsManagement />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/pdf-service-task-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PdfServiceTaskNotes />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/print-funds-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PrintFundsManagement />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-conflict",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListConflict />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-conflict",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditConflict />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-conflict",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditConflict />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/closure-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <closureIncident />
        </Suspense>
      ),
    },
  ];

  const individividualRoutes = [
    {
      path: "/support-coordinator/list-secondary-users",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListSecondaryUsers />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-client-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewClientDetails />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListMedicalHistory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-secondary-users",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditSecondaryUsers />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-secondary-users",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditSecondaryUsers />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-menu-masters",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <MenuMaster />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-menu-masters",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddMenuMaster />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-menu-masters",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddMenuMaster />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/client-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ClientList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditClient />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditClient />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-template-variable-master",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <VariableList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-template-variable-master",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddTemplateMaster />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-client-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewClientDetails />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-health-carer",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HealthCarerList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-health-carer",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HealthCarerEdit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-health-carer",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HealthCarerEdit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/health-carer-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HealthCarerDetails />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-health-carer-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewHealthCarerDetails />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-health-carer/interview-history",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HealthCarerInterviewHistory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-support-coordinator",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SupportCoordinatorList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-support-coordinator",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SupportCoordinatorListEdit />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-support-coordinator",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SupportCoordinatorListEdit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/support-coordinator-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SupportCoordinatorDetails />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-support-coordinator-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewSupportCoordinatorDetails />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-service-provider",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-service-provider",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderListEdit />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-service-provider",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderListEdit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/service-provider-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderDetails />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-service-provider-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceProviderDetails />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-signed-document",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SignedDocument />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-signed-document",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewSignedDocument />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SendCustomEmail />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/health-carer/onboard",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HealthcarerOnboardAdmin />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/support-coordinator/onboard",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SupportcoordinatorOnboardAdmin />
        </Suspense>
      ),
    },

    //

    {
      path: "/support-coordinator/service-provider/onboard",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceproviderOnboardAdmin />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/client/onboard",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ClientOnboardAdmin />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/reports/exports",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Export />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/reports/service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskReportList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-template-variable-master",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Edittemplatevariablemaster />
        </Suspense>
      ),
    },
    // {
    //   path: "/support-coordinator/select-client",
    //   element: (
    //     <Suspense fallback={<div><Loader /></div>}>
    //       <ClientSelect />
    //     </Suspense>
    //   ),
    // },

    {
      path: "/support-coordinator/reports",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Report />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-employee-birthdays",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmployeeBirthday />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-employee-details",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmployeeDetails />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-employee-emergency-contacts",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmergencyContact />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-unpaid-employee",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <UnpaidEmployee />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-jobmaker-employee-notice",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <JobmakerEmployeeNotice />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-jobkeeper-employee-nominations",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <JobkeeperEmployeeNomination />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-tax-file-declaration",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TaxFileDeclaration />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-attendance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AttendanceReport />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-employee-satisfaction",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmployeeSatisfaction />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-single-touch-payroll",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SingleTouchPayroll />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/employee-leave-history",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <LeaveHistory />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-employee-details-audit",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EmployeeDetailsAudit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-employee-jokeeper-eligibility",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <JobKeeperEligibility />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-service-item",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceItemList />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/edit-service-item",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceItemEdit />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-service-item",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceItemEdit />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskList />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/view-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskView />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/edit-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskEdit />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/set-access-role",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AccessRoleListUrl />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-access-role",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AccessRoleAddUrl />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/access-role-permission",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AccessRoleListPermission />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskEdit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/chat-history",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ChatHistory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-appointment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddAppointment />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-task-evaluation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Addtask />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-task-evaluation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Addtask />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-note-evaluation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Addnote />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-note-evaluation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Addnote />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/health-carer-on-call",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <HcOnCall />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-funds-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListFundManagement />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-funds-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewFundManagement />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/print-funds-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PrintFundManagement />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/availability-calendar-view",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AvailabilityCalender />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/time-sheet",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          {/* <TimeSheet /> */}
          <TimeSheetST />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/timesheet-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TimeSheetPdf />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/timesheet-view",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TimeSheetView />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/time-sheet-service-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <TimeSheetST />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-food-observation",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewFood />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-goal",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditGoal />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewEmail />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/user-email/edit-email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditEmail />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-email",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditEmail />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-service-task-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ServiceTaskNotes />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNoteAdd />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-user-pay-run",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listpayrun />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNotelist />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-menu-masters",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddMenuMaster />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-progressnote",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProgressNoteAdd />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/invoice",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InvoicesServiceTask />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/service-task-payslips",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PaySlipsServiceTask />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/service-task-payslip-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PaySlipsServiceTaskPdf />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/invoice-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <InvoicesPdf />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/payslip",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PayslipPdf />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/off-boarding",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <OffBoarding />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-support-coordinator-offboarding",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListSupportCorrdinatorOffBoarding />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-goal-routine",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editgoalroutine />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/bar-chart-goal",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <BarChart />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/view-task",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Viewtask />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-support-coordinator-offboarding",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <listSupportCoordinatorOffBoarding />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-goal-as-per-client",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <GoalList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-goal-routine",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddGoalRoutine />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-goal",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddGoal />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/pdf-service-task-notes",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PdfServiceTaskNotes />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/reports/profit-and-loss",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ProfitLossReportPage />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/reports/list-attendance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AttendanceReportPage />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListIncident />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditIncident />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditIncident />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewIncident />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/pdf-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PdfIncident />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/pay-run",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Payrun />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/pay-run-pdf",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Pdfpayrun />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-backup-options",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewDataBackup />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-payrun",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingPayRun />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/ato-settings",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingATO />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/payments",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingPayment />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-superannuation-setting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingSuperannuationList />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-superannuation-setting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingSuperannuationAdd />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-superannuation-setting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingSuperannuationEdit />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/settings-xero-myob-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingXero />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/financial-setting-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SettingViewFinancial />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-view-schedule-meeting",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListViewScheduleMeeting />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-view-schedule-interview",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListViewScheduleInterview />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/schedule-interview",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <SCscheduleInterview />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-asset",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListAssestsManagement />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-asset",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditAssetsManagement />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/edit-asset",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditAssetsManagement />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/sil-sda-location-list",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListSilSda />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/sil-sda-location-add",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditSilSda />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/sil-sda-location-edit",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditSilSda />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/pay-run-attendance",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PayRunAttandance />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-bsp-chart",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <BSPList />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-bsp-chart",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <BSPAdd />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/view-funds-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewFundsManagement />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/print-funds-management",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <PrintFundsManagement />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-family-member",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddFamilyMember />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/edit-family-member",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditFamilyMember />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/view-secondary-users",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ViewSecondaryUser />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-risk-assessment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListRiskAssessment />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-risk-assessment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddRiskAssessment />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/edit-risk-assessment",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditRiskAssessment />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-bsp-question",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListBSP />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-bsp-question",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddBSP />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-bsp-question",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditBSP />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/list-holiday",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListPublicHoliday />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-holiday",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddPublicHoliday />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/edit-holiday",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditPublicHoliday />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-induction-category",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListInductionCategory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-induction-heading-title",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListInductionHeadingTitle />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-induction-category",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditInductionCategory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-induction-category",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddInductionCategory />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-induction-heading",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddInductionHeading />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-template-type",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListTemplateType />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-template-type",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddTemplateType />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-template-type",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditTemplateType />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-agreement",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListAgreement />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-agreement",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddAgreement />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-agreement",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditAgreement />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-severity",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <ListSeverity />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/add-severity",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <AddSeverity />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-severity",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <EditSeverity />
        </Suspense>
      ),
    },

    {
      path: "/support-coordinator/add-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Addemergencycontact />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/edit-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Editemergencycontact />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/list-emergency-contact",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Listemergencycontact />
        </Suspense>
      ),
    },
    {
      path: "/support-coordinator/closure-incident",
      element: (
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <closureIncident />
        </Suspense>
      ),
    },
  ];

  //console.log(isIndividual, "isIndividual");

  function isPathInRoutes(path) {
    //console.log("path", path);
    // const findAccess = await sidebar
    return menuList.includes(path);
  }

  return (
    <Box className="dashboard-form" id="agency">
      <Box sx={{ width: "100%" }} className="appbar-border">
        <Box className="appbar-main">{MemoizedAppbar}</Box>
      </Box>
      <Box className="d-flex dashboard-main-box">
        {MemoizedSidebar}

        <Routes>
          <Route>
            {ScRoutes?.map(
              (route, index) =>
                isPathInRoutes(route.path) ? (
                  <Route
                    key={index}
                    path={route.path.replace(
                      "/super-admin",
                      "/support-coordinator"
                    )}
                    element={route.element}
                  />
                ) : null // You can also use an empty fragment <> </> instead of null
            )}

            {ScRoutes?.map(
              (route, index) =>
                isPathInRoutes(route.path) ? (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ) : null // You can also use an empty fragment <> </> instead of null
            )}

            {isIndividual === true ? (
              <>
                {individividualRoutes?.map(
                  (route, index) =>
                    isPathInRoutes(route.path) ? (
                      <Route
                        key={index}
                        path={route.path.replace(
                          "/super-admin",
                          "/support-coordinator"
                        )}
                        element={route.element}
                      />
                    ) : null // You can also use an empty fragment <> </> instead of null
                )}

                {/* {individividualRoutes?.map(
                  (route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  )
                  // You can also use an empty fragment <> </> instead of null
                )} */}
              </>
            ) : (
              ""
            )}
            {/* {validation code ends here} */}
            {menuList.length > 0 ? (
              <Route path="*" element={<DelayedError />} />
            ) : (
              "loading"
            )}
            {/* <Route path="*" element={<DelayedError />} /> */}
          </Route>
        </Routes>
      </Box>
    </Box>
  );
}
