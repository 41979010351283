import React from 'react';
import {
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";

const Thrice = () => {

    const [showhide, setShowhide] = React.useState("");

    const handleshowhide = (event) => {
        const getuser = event.target.value;
        setShowhide(getuser);
    };

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <>

            

            <Grid container spacing={2} className='medical-month-field'>

              

                <Grid sx={{ mt: 2 }} item xs={12} sm={4} md={4} className="input input-field-margin-top pt-0">
                    
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                // views={['day', 'month', 'year']}
                                className="form-input date-input-filed"
                                // format="DD-MM-YYYY"
                                inputFormat="DD-MM-YYYY"
                                size="small"
                                defaultValue={"18-04-2023"}
                                // name="from"

                                onChange={handleChange}
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        sx={{ mb: 1, mt: 1 }}
                                        {...params}
                                        inputProps={{
                                            ...params.inputProps,
                                            placeholder: "DD-MM-YY",
                                        }}

                                    // error={
                                    //     formik.touched.from && Boolean(formik.errors.from)
                                    // }
                                    // helperText={formik.touched.from && formik.errors.from}
                                    />
                                )}
                                minDate={new Date()}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>

                <Grid sx={{ mt: 2 }} item xs={12} sm={2} md={2} className="input input-field-margin-top pt-0">
                <Box className="required-time-box">
                    <TextField
                        sx={{ mt: 1, mb: 2 }}
                        className='form-input w-100'
                        type="time"
                        size="small"
                    />
                </Box>
                </Grid>

            </Grid>
        </>
    );
};

export default Thrice;