import * as React from "react";
import { useState, useEffect } from "react";
// import axios from "axios";
import axios from "../../../api/axios";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
// modal tools
import {
  limit,
  getProgressObjectiveList,
  deleteProgressObjective,
} from "../../../api";
import "../../../App.css";
import "../../../assets/css/dashboard.css";
import ReactPaginate from "react-paginate";
import { FieldsListLoading } from "../../components/loading/FieldsListLoading";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Activepopover from "../Active-Inactive/Active";
import Inactivepopover from "../Active-Inactive/Inactive";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import localstorageData from "../../../helpers/localstorageData";
import { Stack } from "@mui/material";
import nodata from "../../../assets/images/nodata.png";
import generateUrl from "../../../helpers/urlHelper";
import isPathInRoutes from "../../../helpers/accessControlHelper";

export default function ProgressObjective() {
  const [listData, setListData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pageCount, setpageCount] = useState(0);
  const [currentPageVal, setCurrentPageVal] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [count, setCount] = useState("");
  const [isActive, setActive] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [list, setList] = useState([]);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let userData = localstorageData();
  let sessionId = userData._id;
  const urlLink = generateUrl();

  const headers = [
    { label: "Sr.no", key: "index" },
    { label: "Title", key: "name" },
    //     { label: "RequiredDocument", key: "requiredDocument" },
    //  { label: "Point", key: "pointsWorth" },
  ];
  const toggleClass = () => {
    setActive(!isActive);
  };

  const searchItems = (searchValue) => {
    setSearchTerm(searchValue);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getaList();
  }, [limit, searchTerm]);

  const getaList = async () => {
    axios
      .get(
        `${getProgressObjectiveList}?searchTerm=${searchTerm}&createdBy=${sessionId}&page=1&items_per_page=${limit}`
      )
      .then((res) => {
        setListData(res?.data?.data?.docs);
        let total = res.data.data.totalDocs;
        setpageCount(Math.ceil(total / limit));
        setCount(total);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchPaginationList = async (currentPage) => {
    setLoading(true);
    const res = await axios.get(
      `${getProgressObjectiveList}?searchTerm=${searchTerm}&createdBy=${sessionId}&page=${currentPage}&items_per_page=${limit}`
    );
    const op = res?.data?.data?.docs;
    return op;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await fetchPaginationList(currentPage);
    setListData(commentsFormServer);
    setCurrentPageVal(currentPage);
    setLoading(false);
  };

  const deleteCheck = (recordid) => {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "your-confirm-button-class",
        cancelButton: "your-cancel-button-class",
        icon: "deleteicon-class",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${deleteProgressObjective}/${recordid}`)
          .then((res) => {
            if (res.data.success === true) {
              toast.success(res.data.message);
              getaList();
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((error) => {
            toast.error("Something went wrong");
          });
      }
    });
  };

  return (
    <Box className="main">
      <Box id="tabs" className="w-100">
        {/* Title/ Breadcrumb & Button section */}
        <Grid
          container
          className="d-flex padding-left-20 padding-right-20 header-topsection"
        >
          <Grid item xs={6} sm={6} md={6}>
            <Box id="breadcrumb" className="ps-0">
              <Link to={"/" + urlLink + "/home"}>
                {" "}
                <ArrowBackIosIcon />{" "}
              </Link>{" "}
              <Box className="breadcrumb-inr">
                <h4 className="d-flex align-items-center ">
                  {" "}
                  Progress Objective
                </h4>
                <Box className="breadcrumbs">
                  <Link className="breadcrumbs-link">Masters</Link>{" "}
                  <span className="breadcrumbs-icon">{">"}</span>
                  <Link className="breadcrumbs-link breadcrumbs-link-active">
                    Progress Objective List
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Box className="addTask-btn">
              <Box className="d-flex align-items-center justify-content-end">
                <Link
                  to="/super-admin/add-progress-objective-masters"
                  className="agency-add btn next-btn"
                >
                  <AddIcon /> Add
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* Title/ Breadcrumb & Button section */}
        <Box className="padding-right-20 padding-left-20">
          <Box className="agency-searchbar searchbox-margin">
            <Grid
              container
              spacing={2}
              className="align-items-center justify-content-between"
            >
              <Grid item xs={6} md={6} sm={6} style={{ paddingLeft: "0px" }}>
                <TextField
                  className="search-appointment"
                  type="search"
                  size="small"
                  //name="email"
                  fullWidth
                  value={searchTerm}
                  placeholder="Search By Title"
                  onChange={(e) => searchItems(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <TableContainer
            component={Paper}
            id="document-table"
            // className="agency-table"
          >
            <Table sx={{ minWidth: 650 }} aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableborder table-head-cell">
                    Sr. No.
                  </TableCell>
                  <TableCell
                    className="tableborder table-head-cell"
                    align="left"
                  >
                    Title{" "}
                  </TableCell>

                  <TableCell
                    className="tableborder table-head-cell"
                    align="center"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    className="tableborder table-head-cell"
                    align="left"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listData?.length !== 0 ? (
                  listData?.map((item, index) => (
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className="text-start tableborder"
                      >
                        {(currentPageVal - 1) * limit + index + 1}
                      </TableCell>
                      <TableCell align="left" className="tableborder ">
                        {item.name}
                      </TableCell>

                      <TableCell
                        align="left"
                        className="tableborder status-cell-width"
                      >
                        {item.isActive === true ? (
                          <Activepopover />
                        ) : (
                          <Inactivepopover />
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        className="tableborder table-editcell"
                      >
                        {/* {tableedit} */}
                        <Box mt={2} className="table-edit-del">
                          <Box>
                            <Link
                              to={
                                "/super-admin/edit-progress-objective-masters"
                              }
                              className="table-edit border-none btn"
                              state={item._id}
                              align="center"
                            >
                              <EditIcon /> Edit
                            </Link>
                          </Box>
                        </Box>

                        <Dropdown drop="start" autoClose="outside">
                          <Dropdown.Toggle
                            id="dropdown-autoclose-outside"
                            className="action-toggle-button"
                          >
                            <MoreVertIcon
                              className="dot-icon"
                              onClick={toggleClass}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="dropdown-menu">
                            <Dropdown.Item className="table-edit-del">
                              <Link
                                to={
                                  "/super-admin/edit-progress-objective-masters"
                                }
                                className="table-edit  btn"
                                state={item._id}
                                align="center"
                              >
                                <EditIcon align="center" /> Edit
                              </Link>
                            </Dropdown.Item>
                            <Dropdown.Item className="table-edit-del">
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => deleteCheck(item._id)}
                                id="domain2"
                                label="Domain"
                                className="table-del  btn"
                                margin="normal"
                                align="center"
                              >
                                <DeleteOutlineIcon /> Delete
                              </a>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    <TableCell colSpan={12}>
                      <Stack
                        direction="column"
                        spacing={2}
                        className="no-data-available"
                      >
                        <img src={nodata} alt="No Data" />
                        <Typography component="div">
                          No Progress Objective has been added
                        </Typography>
                      </Stack>
                    </TableCell>
                  </>
                )}
              </TableBody>
            </Table>
            <div className="record-page">
              <Typography component="div" className="totalcount">
                Total Records: {count}
              </Typography>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-right"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item previous-page-link"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
              {/* {isLoading && <FieldsListLoading />} */}
            </div>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}
