import * as React from 'react';
import Box from '@mui/material/Box';
import '../../App.css'
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';



export default function BasicTextFields() {

    return (
        <Box id='personal-info'>
            <Box className='d-flex align-items-center justify-content-between'>
                <Typography className='step-count'>Step 3/5</Typography>
                <Typography className='employee-id'>Employee ID: <span>12345</span> </Typography> 
            </Box>
            <Box className='personal-info-main'>
                <Box className='form-heading-section'>
                    <Typography className='form-heading'>Education Documents</Typography>
                </Box>

                <Box className='personal-info'>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={12} size="small" className='pt-0'>
                            <Typography sx={{ mb: 1 }} className='f-size-14'>Education Certificate (Certified in Aged Care or Above)</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} sm={12} className='pt-0'>
                            <Typography sx={{ mb: 1 }} className='f-size-14'>Resume</Typography>
                            <Box className='input-box'>

                                <label for="browse" className='file-label'> <span>Browse</span> </label>
                                <TextField
                                    sx={{ mt: 2, mb: 2 }}
                                    id='browse'
                                    className='form-input d-none'
                                    type="File"
                                    size="small"

                                />
                            </Box>
                        </Grid>

                        
                    </Grid>


                    
                </Box>

            </Box>

        </Box>

    );
} 