import React from "react";
import Box from "@mui/material/Box";
import Appbar from "../Layout/Appbar";
import Sidebar from "../Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import client from "../../assets/images/client.jpg";
import scheduleclock from "../../assets/images/schedule-clock.svg";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import "../../assets/css/Appointment.css";
import "../../assets/css/dashboard.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import upload from "../../assets/images/upload.svg";
import camera from "../../assets/images/camera.svg";
import pdficon from '../../assets/images/pdf-icon.png';
import grocerybill from '../../assets/images/grocery-bill.png';
import calendertime from "../../assets/images/calender-time.svg";
import downloadicon from '../../assets/images/download-prime.svg';

import Form from "@mui/material/FormGroup";
import { Button } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function AddTask() {
  const [value, setValue] = React.useState();

  const [active, setActive] = React.useState(false);

  const [personName, setPersonName] = React.useState([]);

  const [openImg, setOpenImg] = React.useState(false);
  const handleOpenImg = () => {
    setOpenImg(true);
  };

  const handleCloseImg = () => {
    setOpenImg(false);
  };

  const handleChangeCheck = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Appointment List</Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography>Add Routine</Typography>
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      <Typography>Add Task</Typography>
    </Link>,
  ];

  return (
    <>
      <Box className="dashboard-form" id="edittimesheet">
        <Box sx={{ width: "100%" }} className="appbar-border">
          <Box className="appbar-main">
            <Appbar />
          </Box>
        </Box>
        <Box className=" d-flex dashboard-main-box">
          <Sidebar />
          <Box className="main">

            <Box className="dashboard-form-typo dashboard-form-typo-sec dashboard-form-typo-up">
              <Box className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center ">
                  <Box id="breadcrumb">
                    <ArrowBackIosIcon />
                    <Box className="breadcrumb-inr">
                      <h4 className="d-flex align-items-center ">
                        {" "}
                        Timesheets
                      </h4>
                      <Box className="breadcrumbs">


                        <Link className="breadcrumbs-link">
                          Timesheets
                        </Link>{" "}

                        <span className="breadcrumbs-icon">{">"}</span>
                        <Link className="breadcrumbs-link breadcrumbs-link-active">
                          Edit Timesheet
                        </Link>{" "}


                      </Box>
                    </Box>
                  </Box>
                </div>
                <Box className="appointment-dashboard-profile d-flex align-items-center ">
                  <img src={client} />{" "}
                  <Box>
                    {" "}
                    <Typography>Jake Knap</Typography> <span>46 yr, Male</span>{" "}
                  </Box>
                </Box>
                <Box className="appointment-dashboard-timebar d-flex align-items-center">
                  <Typography>
                    <img src={calendertime} /> 12-02-2023, 09:00 - 18:00
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box class="timesheet-overview">
              <Grid container >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className="input flex-column d-flex pt-0"
                >
                  <Box className="hide-content">
                    <Typography>

                      Support Category:
                      <span>
                        Homecare
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className="input flex-column d-flex pt-0"
                >
                  <Box className="hide-content">
                    <Typography>
                      Support Item:
                      <span>
                        Assistance in Supported Independent Living
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className="input flex-column d-flex pt-0"
                >
                  <Box className="hide-content">
                    <Typography>
                      Start&EndTime:
                      <span>
                        09:00to18:00
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  className="input flex-column d-flex pt-0"
                >
                  <Grid container spacing={3} className="align-items-end">
                    <Grid item xs={12} sm={4} md={4} className='input flex-column d-flex pt-0'>
                      <label>Break Start</label>

                      <input
                        class="icon-hide "
                        type="time"
                        id="appt"
                        name="appt"
                        min="09:00"
                        max="18:00"
                        required
                        value="13:00"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} className='input flex-column d-flex pt-0'>
                      <label>Break End</label>
                      <input
                        class="icon-hide "
                        type="time"
                        id="appt"
                        name="appt"
                        min="09:00"
                        max="18:00"
                        required
                        value="14:00"
                      />

                    </Grid>

                    <Grid item xs={12} sm={4} md={4} className='input flex-column d-flex pt-0'>
                      <label>Total Units</label>

                      <p class="txt-input"><img src={scheduleclock} />8 hours</p>
                    </Grid>


                  </Grid>

                </Grid>
              </Grid>
            </Box>
            <Grid container class="expenses-list">
              <Box>
                <Box className="bg-strip service-strip">
                  <Typography>Expenses</Typography>
                </Box>
              </Box>
              <Box className="expenses-table">
                <TableContainer
                  component={Paper}
                  id="document-table"
                  className="padding-res-10 client-profile-table"
                >
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead id="client-table-head">
                      <TableRow>
                        <TableCell className="tableborder  table-head-cell">
                          Sr. No.
                        </TableCell>
                        <TableCell className="tableborder table-head-cell">
                          Title
                        </TableCell>
                        <TableCell
                          className="tableborder table-head-cell "
                          align="left"
                        >
                          Amount ($)
                        </TableCell>



                        <TableCell
                          className="tableborder table-head-cell"
                          align="center"
                        >
                          Documents
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody id="client-table-body">

                      <TableRow  >
                        <TableCell align="left" className="tableborder">
                          1
                        </TableCell>

                        <TableCell align="left" className="tableborder">
                          Bought Fruits
                        </TableCell>
                        <TableCell align="left" className="tableborder">
                          23
                        </TableCell>
                        <TableCell
                          align="left"
                          className="tableborder table-editcell"
                        >
                          <p>Grocery Bill 1.pdf  <img src={downloadicon} /></p>
                          <p>Grocery Bill 1.pdf  <img src={downloadicon} /></p>

                        </TableCell>
                      </TableRow>
                      <TableRow  >
                        <TableCell align="left" className="tableborder">
                          1
                        </TableCell>

                        <TableCell align="left" className="tableborder">
                          Bought Fruits
                        </TableCell>
                        <TableCell align="left" className="tableborder">
                          23
                        </TableCell>
                        <TableCell
                          align="left"
                          className="tableborder table-editcell"
                        >
                          <p>Grocery Bill 1.pdf  <img src={downloadicon} /></p>
                          <p>Grocery Bill 1.pdf  <img src={downloadicon} /></p>

                        </TableCell>
                      </TableRow>

                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>

            {/* Expesnselist row start */}
            <Grid className="expenselist-wrap">
              <Box className="dashboard-form-typo dashboard-form-typo-up">
                <Box className="d-flex align-items-center justify-content-between">
                  <Grid container className="d-flex align-items-center">
                    <Grid item xs={6} sm={6} md={6}>
                      <h4 className="d-flex align-items-center color-primary">
                        Expense 1
                      </h4>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Box className="client-btn add-btn-form-div text-end">
                        <Link className="add-btn-form">
                          <AddIcon />
                          Add
                        </Link>


                        <Link className="remove-btn">
                          <RemoveIcon />
                          Remove
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="Task-list-main">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} className="pt-0">
                    <label>Expense Title</label>
                    <TextField
                      sx={{ mt: 2, mb: 2 }}
                      className="form-input w-100"
                      type="Name"
                      size="small"
                      placeholder="Bought Fruits"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-0">
                    <label>Amount (in $)</label>
                    <TextField
                      sx={{ mt: 2, mb: 2 }}
                      className="form-input w-100"
                      type="Name"
                      size="small"
                      placeholder="23"
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ mt: 2 }}
                    className="input pt-0"
                  >
                    <Box className="attach-document task-attachment">
                      <Box>
                        <label>Attach Document</label>
                      </Box>

                      <Box className="task-edit-attach-document">
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box className="d-flex align-items-center ">
                              <input
                                type="radio"
                                name="noteupload"
                                id="noteupload"
                              ></input>
                              <img src={upload} alt="upload"></img>
                              <label for="noteupload">Upload</label>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="noteupload"
                                id="notecapture"
                              ></input>
                              <img src={camera}></img>
                              <label for="notecapture">Capture</label>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ mt: 2 }}
                    className="input pt-0"
                  >
                    <Grid container className="align-items-center justify-content-between inline-upload">
                      <Grid item xs={12} sm={12} md={12}>
                        <Box className="d-flex align-items-center">
                          <Form className=" list-attach-document-browse w-100 ">
                            <TextField
                              id="outlined-basic"
                              size="small"
                              className="attach-file-input"
                              type="file"
                            ></TextField>
                          </Form>
                          <Button className="upload-btn">Upload</Button>
                        </Box>
                      </Grid>

                    </Grid>
                    <Grid container className="align-items-center justify-content-between inline-upload">
                      <Grid item xs={12} sm={12} md={12}>
                        <Box className="gallary-box" id="attached-file">
                          <Box className="gallary">
                            <img src={grocerybill} />
                            <Box className="list-image-detail">
                              <img src={pdficon} />
                              <Typography>Grocery Bill 1.pdf </Typography>
                            </Box>
                          </Box>
                          <Box className="gallary">
                            <img src={grocerybill} />
                            <Box className="list-image-detail">
                              <img src={pdficon} />
                              <Typography>Grocery Bill 1.pdf </Typography>
                            </Box>
                          </Box>
                          <Box className="gallary">
                            <img src={grocerybill} />
                            <Box className="list-image-detail">
                              <img src={pdficon} />
                              <Typography>Grocery Bill 1.pdf </Typography>
                            </Box>
                          </Box>
                          <Box className="gallary">
                            <img src={grocerybill} />
                            <Box className="list-image-detail">
                              <img src={pdficon} />
                              <Typography>Grocery Bill 1.pdf</Typography>
                            </Box>
                          </Box>
                          <Box className="gallary">
                            <img src={grocerybill} />
                            <Box className="list-image-detail">
                              <img src={pdficon} />
                              <Typography>Grocery Bill 1.pdf </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>

              </Box>
            </Grid>
            {/* Expesnselist row end */}

            {/* Expesnselist row start */}
            <Grid className="expenselist-wrap">
              <Box className="dashboard-form-typo dashboard-form-typo-up">
                <Box className="d-flex align-items-center justify-content-between">
                  <Grid container className="d-flex align-items-center">
                    <Grid item xs={6} sm={6} md={6}>
                      <h4 className="d-flex align-items-center color-primary">
                        Expense 2
                      </h4>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                      <Box className="client-btn add-btn-form-div text-end">
                        <Link className="add-btn-form">
                          <AddIcon />
                          Add
                        </Link>


                        <Link className="remove-btn">
                          <RemoveIcon />
                          Remove
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className="Task-list-main">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} className="pt-0">
                    <label>Expense Title</label>
                    <TextField
                      sx={{ mt: 2, mb: 2 }}
                      className="form-input w-100"
                      type="Name"
                      size="small"
                      placeholder="Bought Fruits"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-0">
                    <label>Amount (in $)</label>
                    <TextField
                      sx={{ mt: 2, mb: 2 }}
                      className="form-input w-100"
                      type="Name"
                      size="small"
                      placeholder="23"
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ mt: 2 }}
                    className="input pt-0"
                  >
                    <Box className="attach-document task-attachment">
                      <Box>
                        <label>Attach Document</label>
                      </Box>

                      <Box className="task-edit-attach-document">
                        <Grid container>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box className="d-flex align-items-center ">
                              <input
                                type="radio"
                                name="noteupload"
                                id="noteupload"
                              ></input>
                              <img src={upload} alt="upload"></img>
                              <label for="noteupload">Upload</label>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Box className="d-flex align-items-center">
                              <input
                                type="radio"
                                name="noteupload"
                                id="notecapture"
                              ></input>
                              <img src={camera}></img>
                              <label for="notecapture">Capture</label>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{ mt: 2 }}
                    className="input pt-0"
                  >
                    <Grid container className="align-items-center justify-content-between inline-upload">
                      <Grid item xs={12} sm={12} md={12}>
                        <Box className="d-flex align-items-center">
                          <Form className=" list-attach-document-browse w-100 ">
                            <TextField
                              id="outlined-basic"
                              size="small"
                              className="attach-file-input"
                              type="file"
                            ></TextField>
                          </Form>
                          <Button className="upload-btn">Upload</Button>
                        </Box>
                      </Grid>

                    </Grid>
                    <Grid container className="align-items-center justify-content-between inline-upload">
                      <Grid item xs={12} sm={12} md={12}>
                        <Box className="gallary-box" id="attached-file">
                          <Box className="gallary">
                            <img src={grocerybill} />
                            <Box className="list-image-detail">
                              <img src={pdficon} />
                              <Typography>Grocery Bill 1.pdf </Typography>
                            </Box>
                          </Box>
                          <Box className="gallary">
                            <img src={grocerybill} />
                            <Box className="list-image-detail">
                              <img src={pdficon} />
                              <Typography>Grocery Bill 1.pdf </Typography>
                            </Box>
                          </Box>
                          <Box className="gallary">
                            <img src={grocerybill} />
                            <Box className="list-image-detail">
                              <img src={pdficon} />
                              <Typography>Grocery Bill 1.pdf </Typography>
                            </Box>
                          </Box>
                          <Box className="gallary">
                            <img src={grocerybill} />
                            <Box className="list-image-detail">
                              <img src={pdficon} />
                              <Typography>Grocery Bill 1.pdf</Typography>
                            </Box>
                          </Box>
                          <Box className="gallary">
                            <img src={grocerybill} />
                            <Box className="list-image-detail">
                              <img src={pdficon} />
                              <Typography>Grocery Bill 1.pdf </Typography>
                            </Box>
                          </Box>

                        </Box>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>

              </Box>
            </Grid>
            {/* Expesnselist row end */}

            <Box className='agency-btn-section add-expenses-btn pb-3'>
              <Link href='#cancel' >Cancel</Link>
              <Link href='#save' to='/timesheet'>Save</Link>

            </Box>

          </Box>
        </Box>
      </Box>
    </>
  );
}
