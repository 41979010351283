import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../Web-Header";
import WebFooter from "../Web-footer";
import { Link } from "@mui/material";
import BookDemo from "../HomeUI/BookDemo";
import GuideDetailImg from "../../assets/images/guides-details-image.jpg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import phoneImg from "../../assets/images/guide-phone-img.png";
import TextField from "@mui/material/TextField";
import linkdin from "../../assets/images/web-linkdin.png";
import Facebook from "../../assets/images/web-facebook.png";
import Twitter from "../../assets/images/web-twitter.png";
import guideLike1 from "../../assets/images/blog1.jpg";
import guideLike2 from "../../assets/images/blog2.jpg";
import guideLike3 from "../../assets/images/blog3.jpg";
import "../HomeUI/home-ui.css";

const BlogsDetails = () => {
  return (
    <Box id="guides-details">
      <Box className="w-100">
        <WebHeader />

        <Box className="webContainer">
          <Box className="W-100 ">
            <Box className="guide-details-Content space-pt-80">
              <Box className="text-center">
                <h1>
                  Efficiency Unleashed: <br /> How Hubshift Streamlines <br />{" "}
                  Care Management
                </h1>
                <Typography className="space-32">
                  Discover how Hubshift's innovative features save time and
                  resources in care <br /> management, making life easier for
                  support coordinators and Health Carers.
                </Typography>

                <Typography className="text-sm-bold p-0">
                  Andy Thompson
                </Typography>
                <span>8 November, 2023</span>
              </Box>
            </Box>
          </Box>

          <Box className="w-100 space-pt-80">
            <Box className="w-100 ">
              <img src={GuideDetailImg} className="w-100 radious-20" />
            </Box>
          </Box>

          {/* Guides details text  */}

          <Box>
            <Box className="guides-details-text ">
              <Typography className="space-pt-60">
                Gain a comprehensive understanding of disability support,
                covering client needs assessment, care planning, and fostering
                independence.
              </Typography>

              <Typography className="space-pt-60">
                In the dynamic landscape of healthcare, the need for streamlined
                care management has never been more critical. Enter Hubshift, a
                revolutionary platform designed to unleash efficiency in the
                realm of caregiving. Let's delve into how Hubshift transforms
                the complexities of care management into a seamless,
                user-friendly experience.
              </Typography>

              <h6>1. Understanding the Challenges</h6>

              <Typography>
                Care management often involves a myriad of tasks, from
                scheduling and appointment management to timesheets, payroll,
                and compliance documentation. The traditional methods of
                handling these processes can be time-consuming, error-prone, and
                resource-intensive. Hubshift recognizes these challenges and
                addresses them head-on.
              </Typography>

              <h6>2. Streamlining Scheduling and Appointments</h6>

              <Typography>
                Hubshift's intelligent scheduling system takes the guesswork out
                of managing shifts and appointments. With user-friendly
                interfaces for both administrators and caregivers, scheduling
                becomes a breeze. Real-time updates ensure everyone is on the
                same page, minimizing scheduling conflicts and enhancing overall
                efficiency.
              </Typography>

              <h6>3. Effortless Timesheets and Payroll Management</h6>

              <Typography>
                One of Hubshift's standout features is its comprehensive
                timesheets and payroll management. The platform automates time
                tracking, reducing the administrative burden on caregivers. This
                not only saves time but also ensures accurate and timely
                payments, fostering a positive and trusting relationship between
                caregivers and administrators.
              </Typography>

              <h6>4. Ensuring Compliance with Ease</h6>

              <Typography>
                Staying compliant with industry regulations, especially in the
                healthcare sector, is paramount. Hubshift simplifies compliance
                management by providing tools to easily track and document
                incidents, progress notes, and other essential compliance data.
                This not only reduces the risk of errors but also prepares
                organizations for audits effortlessly.
              </Typography>

              <h6>5. Mobile Accessibility for On-the-Go Care</h6>

              <Typography>
                Recognizing the mobile nature of healthcare, Hubshift offers a
                user-friendly mobile app. Caregivers can submit timesheets,
                shift notes, and progress updates directly from their
                smartphones. This level of accessibility not only enhances
                convenience but also ensures that critical information is
                captured in real-time.
              </Typography>

              <h6>The Result: Enhanced Efficiency and Improved Care</h6>

              <Typography>
                By streamlining care management processes, Hubshift empowers
                healthcare providers to redirect their focus where it matters
                most—providing exceptional care. The platform's
                efficiency-enhancing features translate to significant time and
                cost savings, allowing organizations to allocate resources more
                strategically.
              </Typography>

              <Typography className="space-pt-60">
                In conclusion, Hubshift is not just a platform; it's a catalyst
                for change in the healthcare industry. By unleashing efficiency
                in care management, Hubshift empowers caregivers, support
                coordinators, and organizations to navigate the complexities of
                healthcare with ease, ultimately leading to improved patient
                outcomes and a brighter future for the healthcare sector.
              </Typography>
            </Box>
          </Box>

          {/* guide download section  */}

          {/* guide download section icon  */}
          <Box className="guide-sm-divider" sx={{ mt: 4 }}></Box>
          <Box className="d-flex space-pt-32 align-items-center justify-content-center">
            <Box className="guide-download-linkIcons">
              <img src={linkdin} />
            </Box>
            <Box className="guide-download-linkIcons">
              <img src={Facebook} />
            </Box>
            <Box className="guide-download-linkIcons">
              <img src={Twitter} />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* guides like section  */}

      <Box className="guides-like-main space-lg web-topBanner-bg space-mt-lg">
        <Box className="webContainer">
          <h1 className="space-pb-60">You may also like these blogs</h1>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <Box>
                <Box className="blog-cardImg">
                  <img src={guideLike1} />
                </Box>

                <Box className="blog-card-smTitle">
                  <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <span>NDIS</span>
                  </Box>
                </Box>

                <Box className="blog-card-title">
                  <h3>Navigating NDIS Changes: A Health Carer's Guide</h3>
                </Box>

                <Box className="blogCard-text">
                  <Typography>
                    Explore the recent changes in the NDIS landscape and learn
                    how Health Carers can adapt to these developments to provide
                    better care and support to NDIS participants.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Box>
                <Box className="blog-cardImg">
                  <img src={guideLike2} />
                </Box>

                <Box className="blog-card-smTitle">
                  <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <span>Disability Care</span>
                  </Box>
                </Box>

                <Box className="blog-card-title">
                  <h3>Efficiency Unleashed: How Hubshift Streamlines Care Management</h3>
                </Box>

                <Box className="blogCard-text">
                  <Typography>
                  Discover how Hubshift's innovative features save time and resources in care management, making life easier for support coordinators and Health Carers.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Box>
                <Box className="blog-cardImg">
                  <img src={guideLike3} />
                </Box>

                <Box className="blog-card-smTitle">
                  <Box className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <span>Aged Care</span>
                  </Box>
                </Box>

                <Box className="blog-card-title">
                  <h3>Client Success Stories: Inspiring Journeys with Hubshift</h3>
                </Box>

                <Box className="blogCard-text">
                  <Typography>
                  Get inspired by heartwarming success stories showcasing how Hubshift has transformed the lives of clients and Health Carers in the disability support sector.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* book demo section  */}

      <Box className="space-m-80">
        <Box className="webContainer">
          <BookDemo />
        </Box>
      </Box>

      <WebFooter />
    </Box>
  );
};

export default BlogsDetails;
