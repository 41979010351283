import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Button, Stepper, Step, StepLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

import "../../../assets/css/thankyou.css";

import { branch } from "yup";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getuserDetail,
  updateHealthCarer,
  addGuestUser,
  updateGuestUser,
  getGuestUserDetailsById,
  SAcreatedBy,
} from "../../../api";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import Header from "../../../components/HeaderExploreMarket";
import Loader from "../../../pages/loader/Loader";
import PersonalInfo from "./form/PersonalInfo";
import ResidentialAddress from "./form/ResidentialAddress";
import LookingForSupport from "./form/LookingForSupport";
import When from "./form/When";
import Thankyou from "./form/Thankyou";
import HCStore from "./HCStore";

var step1 =
  "<strong>Personal  Details </strong><br/> <span>Enter Details</span>";
var step2 =
  "<strong>Residential Address </strong><br/> <span> Enter Details <span/>";
var step3 =
  "<strong>Looking for support </strong><br/> <span> Enter Details <span/>";
var step4 = "<strong>When </strong><br/> <span> Enter Details </span";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    <div className="content" dangerouslySetInnerHTML={{ __html: step1 }}></div>,
    <div className="content" dangerouslySetInnerHTML={{ __html: step2 }}></div>,
    <div className="content" dangerouslySetInnerHTML={{ __html: step3 }}></div>,
    <div className="content" dangerouslySetInnerHTML={{ __html: step4 }}></div>,
  ];
}

const clearStorage = () => {
  window.sessionStorage.clear();
  window.localStorage.clear();
};

const LinaerStepper = (props) => {
  const getUserIdStepper = props?.statePk?.getUserIdStepper;
  const navigate = useNavigate();

  const userData = localStorage.getItem("userData");
  const userObject =
    userData !== undefined && userData !== null ? JSON.parse(userData) : "";
  const [user, setUser] = useState(userObject);
  const [hidden, setHidden] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const { resetFields, setField, buttonClicked, supportStatus, guestUserId } =
    HCStore();

  const [userTypeData, setUserTypeData] = useState([]);

  const guestUserIdLS = localStorage.getItem("guestUserIdLS");

  let userPrimaryKey = "";
  if (
    guestUserIdLS !== undefined &&
    guestUserIdLS !== null &&
    guestUserIdLS !== "undefined" &&
    guestUserIdLS !== ""
  ) {
    userPrimaryKey = guestUserIdLS;
  }

  console.log("userPrimaryKey", userPrimaryKey);
  console.log("userPrimaryKey activeStep", activeStep);

  useEffect(() => {
    if (userPrimaryKey !== "") {
      axios
        .get(`${getGuestUserDetailsById}/${userPrimaryKey}`)
        .then(function (res) {
          if (res) {
            setUserTypeData(res?.data?.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [userPrimaryKey]);

  const CustomStepIcon = (props) => {
    const classes = useStyles();
    const { active, completed } = props;

    const stepIcons = {
      1: <PersonIcon />,
      2: <DescriptionIcon />,
      3: <FindInPageIcon />,
      4: <LibraryBooksIcon />,
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {stepIcons[String(props.icon)]}
      </div>
    );
  };

  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  const emailRegex = /^[^@]+@[^@]+\.[^@.]+$/;
  let userDetailsSchema;
  if (activeStep === 0) {
    if (supportStatus === "My Client") {
      userDetailsSchema = Yup.object().shape({
        supportStatus: Yup.string().required("Support Status is required"),

        title: Yup.string().required("Title is required"),
        firstName: Yup.string()
          .min(2, "Minimum 2 symbols")
          .max(50, "Maximum 50 symbols")
          .required("First Name is required"),
        lastName: Yup.string()
          .min(2, "Minimum 2 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Last Name is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        mobileNumber: Yup.string()
          .matches(phoneRegExp, "Mobile Number is not valid")
          .required("Mobile Number is required")
          .min(10, "Mobile Number should not be less than 10 digit")
          .max(13, "Mobile Number should not be greater than 13 digit"),
        dob: Yup.string().required("Date of birth is required"),
        gender: Yup.string().required("Gender is required"),

        titleISC: Yup.string().required("Title is required"),
        firstNameISC: Yup.string()
          .min(2, "Minimum 2 symbols")
          .max(50, "Maximum 50 symbols")
          .required("First Name is required"),
        lastNameISC: Yup.string()
          .min(2, "Minimum 2 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Last Name is required"),
        emailISC: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        mobileNumberISC: Yup.string()
          .matches(phoneRegExp, "Mobile Number is not valid")
          .required("Mobile Number is required")
          .min(10, "Mobile Number should not be less than 10 digit")
          .max(13, "Mobile Number should not be greater than 13 digit"),
        dobISC: Yup.string().required("Date of birth is required"),
        genderISC: Yup.string().required("Gender is required"),
      });
    } else if (supportStatus === "Family Member") {
      userDetailsSchema = Yup.object().shape({
        supportStatus: Yup.string().required("Support Status is required"),

        title: Yup.string().required("Title is required"),
        firstName: Yup.string()
          .min(2, "Minimum 2 symbols")
          .max(50, "Maximum 50 symbols")
          .required("First Name is required"),
        lastName: Yup.string()
          .min(2, "Minimum 2 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Last Name is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        mobileNumber: Yup.string()
          .matches(phoneRegExp, "Mobile Number is not valid")
          .required("Mobile Number is required")
          .min(10, "Mobile Number should not be less than 10 digit")
          .max(13, "Mobile Number should not be greater than 13 digit"),
        dob: Yup.string().required("Date of birth is required"),
        gender: Yup.string().required("Gender is required"),

        titleFM: Yup.string().required("Title is required"),
        firstNameFM: Yup.string()
          .min(2, "Minimum 2 symbols")
          .max(50, "Maximum 50 symbols")
          .required("First Name is required"),
        lastNameFM: Yup.string()
          .min(2, "Minimum 2 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Last Name is required"),
        emailFM: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        mobileNumberFM: Yup.string()
          .matches(phoneRegExp, "Mobile Number is not valid")
          .required("Mobile Number is required")
          .min(10, "Mobile Number should not be less than 10 digit")
          .max(13, "Mobile Number should not be greater than 13 digit"),
        relationShipFM: Yup.string().required("Date of birth is required"),
      });
    } else {
      userDetailsSchema = Yup.object().shape({
        supportStatus: Yup.string().required("Support Status is required"),

        title: Yup.string().required("Title is required"),
        firstName: Yup.string()
          .min(2, "Minimum 2 symbols")
          .max(50, "Maximum 50 symbols")
          .required("First Name is required"),
        lastName: Yup.string()
          .min(2, "Minimum 2 symbols")
          .max(50, "Maximum 50 symbols")
          .required("Last Name is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        mobileNumber: Yup.string()
          .matches(phoneRegExp, "Mobile Number is not valid")
          .required("Mobile Number is required")
          .min(10, "Mobile Number should not be less than 10 digit")
          .max(13, "Mobile Number should not be greater than 13 digit"),
        dob: Yup.string().required("Date of birth is required"),
        gender: Yup.string().required("Gender is required"),
      });
    }
  }

  if (activeStep === 1) {
    userDetailsSchema = Yup.object().shape({
      isResidentialAddress: Yup.boolean(),
      address: Yup.object().shape({
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required"),
        postalCode: Yup.string()
          .min(4, "Postal Code must be 4 digits")
          .max(4, "Postal Code must be 4 digits")
          .required("Postal Code is required"),
        addressLine1: Yup.string().required("Address Line 1 is required"),
        // addressLine2: Yup.string().required("Address Line 2 is required"),
      }),

      postalAddress: Yup.object().when(
        "visiblePostAddresss",
        (visiblePostAddresss, schema) => {
          return visiblePostAddresss[0]
            ? schema.shape({
                postalStateId: Yup.string().required("State is required"),
                postalCity: Yup.string().required("City is required"),
                postalPinCode: Yup.string()
                  .min(4, "Postal Code must be 4 digit")
                  .max(4, "Postal Code must be 4 digit")
                  .required("Postal Code is required"),
                postalAddressLine1: Yup.string().required(
                  "Address Line 1 is required"
                ),
                // postalAddressLine2: Yup.string().required(
                //   "Address Line 2 is required"
                // ),
              })
            : schema;
        }
      ),
    });
  }

  const currentValidationSchema = userDetailsSchema;

  const formikGetServiceInfo = useFormik({
    initialValues: {
      supportStatus: "Me",

      userPrimaryKeyIni: "",
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      dob: null,
      gender: "",

      titleISC: "",
      firstNameISC: "",
      middleNameISC: "",
      lastNameISC: "",
      emailISC: "",
      mobileNumberISC: "",
      dobISC: null,
      genderISC: "",

      titleFM: "",
      firstNameFM: "",
      middleNameFM: "",
      lastNameFM: "",
      emailFM: "",
      mobileNumberFM: "",
      relationShipFM: "",

      residentialAddress: "",
      address: {
        country: null,
        state: null,
        city: "",
        postalCode: "",
        addressLine1: "",
        addressLine2: "",
        lat: "",
        lng: "",
      },
      postalAddress: {
        postalCountryId: null,
        postalStateId: null,
        postalCity: "",
        postalPinCode: "",
        postalAddressLine1: "",
        postalAddressLine2: "",
        postalLat: "",
        postalLng: "",
      },

      supportRequired: "Disability Care",
      skillRequired: [],
      supportRequiredPerWeekInHrs: "1-5 hours",
      whenSupportRequired: "Weekly",
      shiftTypeRequired: "OnCall",
      requiredFor: "Everyday",
      ndisNumber: "",
    },
    validationSchema: currentValidationSchema,
    validateOnChange: true,
    onSubmit: (values, actions) => {
      handleNext(values);
    },
  });

  useEffect(() => {
    if (userPrimaryKey !== "") {
      getUserDetails();
    }
  }, [userPrimaryKey]);

  const getUserDetails = () => {
    setisLoading(true);
    axios
      .get(`${getGuestUserDetailsById}/${userPrimaryKey}`)
      .then(function (res) {
        if (res) {
          setUserTypeData(res?.data?.data?.docs[0]);
          let dataval = res?.data?.data?.docs[0];
          const step = dataval ? dataval?.activeStep : 0;

          setActiveStep(step);
          let supportStatusVal = dataval?.exploreMarketGuestData?.supportStatus
            ? dataval?.exploreMarketGuestData?.supportStatus
            : "Me";
          setField("supportStatus", supportStatusVal);
          formikGetServiceInfo.setValues({
            supportStatus: supportStatusVal,
            userPrimaryKeyIni: userPrimaryKey,
            title: dataval ? dataval?.title : "",
            firstName: dataval ? dataval?.firstName : "",
            middleName: dataval ? dataval?.middleName : "",
            lastName: dataval ? dataval?.lastName : "",
            email: dataval ? dataval?.email : "",
            gender: dataval ? dataval?.gender : "",
            mobileNumber: dataval ? dataval?.mobileNumber : "",
            dob: dataval ? dataval?.dob : null,

            titleFM: dataval?.exploreMarketGuestData?.familyMemberData?.title
              ? dataval?.exploreMarketGuestData?.familyMemberData?.title
              : "",
            firstNameFM: dataval?.exploreMarketGuestData?.familyMemberData
              ?.firstName
              ? dataval?.exploreMarketGuestData?.familyMemberData?.firstName
              : "",
            middleNameFM: dataval?.exploreMarketGuestData?.familyMemberData
              ?.middleName
              ? dataval?.exploreMarketGuestData?.familyMemberData?.middleName
              : "",
            lastNameFM: dataval?.exploreMarketGuestData?.familyMemberData
              ?.lastName
              ? dataval?.exploreMarketGuestData?.familyMemberData?.lastName
              : "",
            emailFM: dataval?.exploreMarketGuestData?.familyMemberData?.email
              ? dataval?.exploreMarketGuestData?.familyMemberData?.email
              : "",
            mobileNumberFM: dataval?.exploreMarketGuestData?.familyMemberData
              ?.mobileNumber
              ? dataval?.exploreMarketGuestData?.familyMemberData?.mobileNumber
              : "",
            relationShipFM: dataval?.exploreMarketGuestData?.familyMemberData
              ?.relationShipWithClient
              ? dataval?.exploreMarketGuestData?.familyMemberData
                  ?.relationShipWithClient
              : "",

            titleISC: dataval?.exploreMarketGuestData
              ?.individualSupportCoordinatorData?.title
              ? dataval?.exploreMarketGuestData
                  ?.individualSupportCoordinatorData?.title
              : "",
            firstNameISC: dataval?.exploreMarketGuestData
              ?.individualSupportCoordinatorData?.firstName
              ? dataval?.exploreMarketGuestData
                  ?.individualSupportCoordinatorData?.firstName
              : "",
            middleNameISC: dataval?.exploreMarketGuestData
              ?.individualSupportCoordinatorData?.middleName
              ? dataval?.exploreMarketGuestData
                  ?.individualSupportCoordinatorData?.middleName
              : "",
            lastNameISC: dataval?.exploreMarketGuestData
              ?.individualSupportCoordinatorData?.lastName
              ? dataval?.exploreMarketGuestData
                  ?.individualSupportCoordinatorData?.lastName
              : "",
            emailISC: dataval?.exploreMarketGuestData
              ?.individualSupportCoordinatorData?.email
              ? dataval?.exploreMarketGuestData
                  ?.individualSupportCoordinatorData?.email
              : "",
            mobileNumberISC: dataval?.exploreMarketGuestData
              ?.individualSupportCoordinatorData?.mobileNumber
              ? dataval?.exploreMarketGuestData
                  ?.individualSupportCoordinatorData?.mobileNumber
              : "",
            genderISC: dataval?.exploreMarketGuestData
              ?.individualSupportCoordinatorData?.gender
              ? dataval?.exploreMarketGuestData
                  ?.individualSupportCoordinatorData?.gender
              : "",
            dobISC: dataval?.exploreMarketGuestData
              ?.individualSupportCoordinatorData?.dob
              ? dataval?.exploreMarketGuestData
                  ?.individualSupportCoordinatorData?.dob
              : "",

            address:
              dataval.address !== undefined && dataval.address !== ""
                ? dataval.address
                : {
                    country: null,
                    state: "",
                    city: "",
                    postalCode: "",
                    addressLine1: "",
                    addressLine2: "",
                    lat: "",
                    lng: "",
                  },

            postalAddress:
              dataval.postalAddress !== undefined &&
              dataval.postalAddress !== ""
                ? dataval.postalAddress
                : {
                    postalCountryId: null,
                    postalStateId: "",
                    postalCity: "",
                    postalPinCode: "",
                    postalAddressLine1: "",
                    postalAddressLine2: "",
                    postalLat: "",
                    postalLng: "",
                  },

            supportRequired: dataval?.exploreMarketGuestData?.supportRequired
              ? dataval?.exploreMarketGuestData?.supportRequired
              : "Disability Care",
            skillRequired: dataval?.exploreMarketGuestData?.skillRequired
              ? dataval?.exploreMarketGuestData?.skillRequired
              : [],
            supportRequiredPerWeekInHrs: dataval?.exploreMarketGuestData
              ?.supportRequiredPerWeekInHrs
              ? dataval?.exploreMarketGuestData?.supportRequiredPerWeekInHrs
              : "1-5 hours",
            whenSupportRequired: dataval?.exploreMarketGuestData
              ?.whenSupportRequired
              ? dataval?.exploreMarketGuestData?.whenSupportRequired
              : "Weekly",
            shiftTypeRequired: dataval?.exploreMarketGuestData
              ?.shiftTypeRequired
              ? dataval?.exploreMarketGuestData?.shiftTypeRequired
              : "OnCall",
            requiredFor: dataval?.exploreMarketGuestData?.requiredFor
              ? dataval?.exploreMarketGuestData?.requiredFor
              : "Everyday",
            ndisNumber: dataval?.exploreMarketGuestData?.ndisNumber
              ? dataval?.exploreMarketGuestData?.ndisNumber
              : "",
          });
        }
        setisLoading(false);
      })
      .catch(function (error) {
        setisLoading(false);
        console.log(error);
      });
  };

  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    const postData = {
      ...data,
    };

    postData.activeStep = activeStep;
    postData.createdBy = SAcreatedBy;
    postData.updatedBy = SAcreatedBy;
    postData.signUpBy = "Self";
    postData.userType = "client";

    let familyMemberVal = {};
    let individualSupportCoordinatorVal = {};
    if (supportStatus === "Family Member") {
      familyMemberVal = {
        title: data.titleFM,
        firstName: data.firstNameFM,
        middleName: data.middleNameFM,
        lastName: data.lastNameFM,
        email: data.emailFM,
        mobileNumber: data.mobileNumberFM,
        relationShipWithClient: data.relationShipFM,
        userType: "Stakeholder",
        signUpBy: "Self",
        createdBy: SAcreatedBy,
        updatedBy: SAcreatedBy,
      };
    }
    if (supportStatus === "My Client") {
      individualSupportCoordinatorVal = {
        title: data.titleISC,
        firstName: data.firstNameISC,
        middleName: data.middleNameISC,
        lastName: data.lastNameISC,
        email: data.emailISC,
        mobileNumber: data.mobileNumberISC,
        gender: data.genderISC,
        dob: data.dobISC,
        userType: "supportCoordinator",
        signUpBy: "Self",
        createdBy: SAcreatedBy,
        updatedBy: SAcreatedBy,
      };
    }

    const exploreMarketGuestData = {
      isGuestUser: true,
      supportStatus: data.supportStatus,
      supportRequired: data.supportRequired,
      skillRequired: data.skillRequired,
      supportRequiredPerWeekInHrs: data.supportRequiredPerWeekInHrs,
      whenSupportRequired: data.whenSupportRequired,
      shiftTypeRequired: data.shiftTypeRequired,
      individualSupportCoordinatorData: individualSupportCoordinatorVal,
      familyMemberData: familyMemberVal,
      requiredFor: data.requiredFor,
      ndisNumber: data.ndisNumber,
    };

    postData.exploreMarketGuestData = exploreMarketGuestData;
    setisLoading(true);

    if (userPrimaryKey !== "") {
      axios
        .put(`${updateGuestUser}/${userPrimaryKey}`, postData)
        .then((res) => {
          setisLoading(false);
          if (res.data.success === false) {
            toast.error(res.data.message);
          }
          if (res.data.success === true) {
            if (activeStep === 0) {
              toast.success("Personal Details updated successfully");
            } else if (activeStep === 1) {
              toast.success("Residential Address updated successfully");
            } else if (activeStep === 2) {
              toast.success("Looking for support updated successfully");
            } else if (activeStep === 3) {
              toast.success("When do you need support updated successfully");
            }
            setActiveStep(activeStep + 1);
          }
        })
        .catch((error) => {
          setisLoading(false);
          if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined
          ) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Please try again, something went wrong!");
          }
        });
    } else {
      postData.activeStep = 1;
      axios
        .post(`${addGuestUser}`, postData)
        .then((res) => {
          setisLoading(false);
          if (res.data.success === false) {
            toast.error(res.data.message);
          }
          if (res.data.success === true) {
            localStorage.setItem("guestUserIdLS", res?.data?.data?._id);
            if (activeStep === 0) {
              toast.success(
                "Personal Details Added successfully & Credentials sent on email."
              );
            }

            setActiveStep(1); // by default it will be 1
          }
        })
        .catch((error) => {
          setisLoading(false);
          if (
            error !== undefined &&
            error.response !== undefined &&
            error.response.data !== undefined &&
            error.response.data.message !== undefined
          ) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Please try again, something went wrong!");
          }
        });
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <PersonalInfo formik={formikGetServiceInfo} key={1} />;
      case 1:
        return <ResidentialAddress formik={formikGetServiceInfo} key={2} />;
      case 2:
        return <LookingForSupport formik={formikGetServiceInfo} key={3} />;
      case 3:
        return <When formik={formikGetServiceInfo} key={4} />;
      default:
        return "unknown step";
    }
  }
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div id="stepper" className="stepper-box-main vertical-stepper-main">
          <Stepper
            className="stepper"
            orientation="vertical"
            alternativeLabel
            activeStep={activeStep}
          >
            {steps.map((step, index) => {
              const labelProps = {};
              const stepProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step className="step-box" {...stepProps} key={index}>
                  <StepLabel
                    className="step-lable"
                    StepIconComponent={CustomStepIcon}
                    {...labelProps}
                  >
                    {step}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === steps.length ? (
            <Box className="thankyou-main-box">
              <Box className="header-fixed ">
                <Header className="header-fixed" />
              </Box>
              <Box className="thankyou-page">
                <Thankyou />
              </Box>
            </Box>
          ) : (
            <>
              <form noValidate className="form-box">
                {getStepContent(activeStep)}

                <Box sx={{ textAlign: "right" }}>
                  {activeStep === 0 ? (
                    <Button
                      style={{
                        visibility: "hidden",
                      }}
                      className={classes.button}
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      id="back-btn"
                    ></Button>
                  ) : (
                    <div style={{ display: "flex", float: "Left" }}>
                      <Button
                        className={classes.button}
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        id="back-btn"
                        variant="outlined"
                      >
                        Back
                      </Button>
                    </div>
                  )}

                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    id="next-btn"
                    type="button"
                    onClick={() => {
                      formikGetServiceInfo.handleSubmit();

                      if (activeStep === 0) {
                        setField("buttonClickedPinfo", true);
                        setField("buttonClicked", false);
                        setField("buttonClickedProdDoc", false);
                      } else if (activeStep === 1) {
                        setField("buttonClicked", true);
                        setField("buttonClickedPinfo", false);
                        setField("buttonClickedProdDoc", false);
                      } else if (activeStep === 3) {
                        setField("buttonClicked", false);
                        setField("buttonClickedPinfo", false);
                        setField("buttonClickedProdDoc", true);
                      } else {
                        setField("buttonClicked", false);
                        setField("buttonClickedPinfo", false);
                        setField("buttonClickedProdDoc", false);
                      }
                    }}
                  >
                    {activeStep === steps.length - 1
                      ? "Save & Submit"
                      : "Save & Next"}
                  </Button>
                </Box>
              </form>

              {/* <Button
                className={classes.button}
                onClick={clearStorage}
                id="back-btn"
                variant="outlined"
              >
                clear session
              </Button> */}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default LinaerStepper;
