import { create } from "zustand";

const FilterStore = create((set) => ({
  selectedCity: "",
  ApType: "",
  selectedShift: [],
  requestStatus: [],
  showMenuItem: {},

  status: "",
  slotType: "Week",
  month: "",
  startDate1: null,
  endDate1: null,
  searchTerm: "",
  tabIndex: 0,
  appointmentList: [],
  selectedStake: [],
  serviceId: undefined,
  itemId: undefined,
  clientId: undefined,
  teamDataId: undefined,
  statusDataId: undefined,
  usertype: undefined,
  location: undefined,
  showhide: "",
  cityList: [],
  isShowAllHc: false,
  maxDistance: "",
  cl: false,
  clpredictions: [],
  predictions1: [{ description: "Current Location", place_id: "Current" }],
  placeId: "",
  city: "",
  clear: false,
  setField: (fieldName, fieldValue) =>
    set((state) => ({ ...state, [fieldName]: fieldValue })),

  resetFields: () =>
    set(() => ({
      isShowAllHc: false,
      selectedCity: "",
      cityList: [],
      tabIndex: 0,
      ApType: "",
      selectedShift: [],
      requestStatus: [],
      showMenuItem: {},

      status: "",
      slotType: "Week",
      month: "",
      startDate1: null,
      endDate1: null,
      searchTerm: "",
      showhide: "",
      appointmentList: [],
      selectedStake: [],
      serviceId: undefined,
      itemId: undefined,
      clientId: undefined,
      teamDataId: undefined,
      statusDataId: undefined,
      usertype: undefined,
      location: undefined,
      maxDistance: "",
      cl: false,
      city: "",
      predictions1: [{ description: "Current Location", place_id: "Current" }],
      clpredictions: [],
      placeId: "",
      clear: false,
    })),
}));

export default FilterStore;
