import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import AttachmentIcon from "@mui/icons-material/Attachment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "../../../assets/css/dashboard.css";
import "../../../assets/css/Appointment.css";
import "../Timesheet/timesheet.css";
import Dropdown from "react-bootstrap/Dropdown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import upload from "../../../assets/images/upload.svg";
import camera from "../../../assets/images/camera.svg";
import Form from "@mui/material/FormGroup";

import Popover from "@mui/material/Popover";
import SearchIcon from "@mui/icons-material/Search";
import tableChart from '../../../assets/images/Medication Chart.png';
import ViewIcon from '../../../assets/images/view.png'
// modal import
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Radio } from "@mui/material";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ReactPaginate from "react-paginate";
import GetAppIcon from "@mui/icons-material/GetApp";
import importicon from "../../../assets/images/exporticon.svg";
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Twice from "./MedicationFields/Twice";
import Month from "./MedicationFields/Month";
import Week from "./MedicationFields/Week";

const category = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },]

export default function AddMedication() {
    const [toggle, setToggle] = useState(false);

    const [value, setValue] = React.useState(0);

    const [list, setList] = useState([]);
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    const [anchorEl, setAnchorEl] = React.useState(null)
    const openPop = Boolean(anchorEl);
    const id = openPop ? "simple-popover" : undefined;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [showhide, setShowhide] = React.useState("");

    const handleshowhide = (event) => {
        const getuser = event.target.value;
        setShowhide(getuser);
    };

    const [showSelect, setSelect] = React.useState("");

    const handleSelectShow = (event) => {
        const getuser = event.target.value;
        setSelect(getuser);
    };


    // attach file modal

    const [openAttach, setOpenAttach] = React.useState(false);
    const [isShown, setIsShown] = useState(false);

    const handleClickOpenAttach = () => {
        setOpenAttach(true);
    };

    const handleCloseAttach = () => {
        setOpenAttach(false);
    };

    // client pop-up tab

    const [isActive2, setIsActive] = useState(false);

    const handleClick2 = (event) => {
        setIsActive((current) => !current);
    };




    // Table functionality

    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    }

    function createData(
        name,
        calories,
        fat,
        carbs,
        category,
        onboarding,
        risk,
        schedule,
        meeting,
        status,
        protein
    ) {
        return {
            name,
            calories,
            fat,
            carbs,
            category,
            onboarding,
            risk,
            schedule,
            meeting,
            status,
            protein,
        };
    }

    const workType = (
        <Box className="workType-text">

            <span>
                123
            </span>
        </Box>
    );

    const appointTime = (
        <Box className="table-time">
            <span>
                1
            </span>
        </Box>
    );


    const allegies = (
        <ul>
            <li className="d-flex align-items-center"> <span className="dots-style"></span> Dairy products</li>
            <li className="d-flex align-items-center"> <span className="dots-style"></span> Wheatgrass</li>

        </ul>
    )

    const RestrictivePractices = (
        <ul>
            <li className="d-flex align-items-center"> <span className="dots-style"></span> Lock Door</li>
            <li className="d-flex align-items-center"> <span className="dots-style"></span> Keep knives out of sight</li>

        </ul>
    )

    const chart = (
        <div className="table-chart-image">
            <img src={tableChart} />
        </div>
    )
    // table state


    const [rows, setRows] = useState([
        createData(
            appointTime,
            workType,
            "Medical",
            allegies,
            RestrictivePractices,
            "City Hospital",
            chart,
            chart,


        )


    ]);





    // Table functionality

    return (
        <Box className=" add-medication main" id="add-medical">



            <Box className="w-100">
                <Box
                    className="dashboard-form-typo "
                    sx={{ borderBottom: "none" }}
                >
                    <Grid container className="d-flex header-topsection">
                        <Grid item xs={12} sm={12} md={12}>
                            <Box id="breadcrumb">
                                <ArrowBackIosIcon />
                                <Box className="breadcrumb-inr">
                                    <h4 className="d-flex align-items-center "> Medical History</h4>
                                    <Box className="breadcrumbs">
                                        <Link className="breadcrumbs-link"> Medication Management</Link>
                                        <span className="breadcrumbs-icon">{">"}</span>
                                        <Link className="breadcrumbs-link">
                                            Medical History
                                        </Link>

                                        <span className="breadcrumbs-icon">{">"}</span>
                                        <Link className="breadcrumbs-link ">
                                            Add Medical History
                                        </Link>

                                        <span className="breadcrumbs-icon">{">"}</span>
                                        <Link className="breadcrumbs-link breadcrumbs-link-active">
                                            Add Medication
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>


                    </Grid>

                    <Box className="divider">

                    </Box>


                </Box>

                <Grid container spacing={6} sx={{ mt: 5 }} className="padding-right-20 padding-left-20">
                    <Grid item xs={12} sm={6} md={6} className='pt-0'>

                        <label>Client <span className="danger">*</span> </label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ mt: 1 }}
                            options={category}
                            renderInput={(params) => <TextField {...params} placeholder="Jake Knap" />}
                            size="small"
                        />

                    </Grid>
                </Grid>

                <Grid container spacing={6} sx={{ mt: 1 }} className="padding-right-20 padding-left-20">

                    <Grid sx={{ mt: 2 }} item xs={12} sm={6} md={6} className='pt-0' >
                        <label>Medicine Name<span className="danger">*</span> </label>
                        <TextField
                            sx={{ mt: 1, mb: 2 }}
                            className='w-100 form-input'
                            type="Name"
                            placeholder="Paracetamol"
                            size="small"
                        />
                    </Grid>

                    <Grid sx={{ mt: 2 }} item xs={12} sm={6} md={6} className="input input-field-margin-top pt-0">
                        <label>Medicine Type - PRN? <span className="danger">*</span> </label>
                        <Box className="radio-box-wrraper" >

                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"

                            >
                                <FormControlLabel value="female" control={<Radio />} label="Yes" />
                                <FormControlLabel value="male" control={<Radio />} label="No" />

                            </RadioGroup>




                        </Box>
                    </Grid>

                    <Grid sx={{ mt: 1 }} item xs={12} sm={6} md={6} className="input  pt-0">
                        <Grid container spacing={2}>
                            <Grid sx={{ mt: 2 }} item xs={12} sm={6} md={6} className="input  mt-0 pt-0">
                                <label >Start Date <span className="danger">*</span> </label>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            // views={['day', 'month', 'year']}
                                            className="form-input date-input-filed"
                                            // format="DD-MM-YYYY"
                                            inputFormat="DD-MM-YYYY"
                                            size="small"
                                            defaultValue={"18-04-2023"}
                                            // name="from"

                                            onChange={handleChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    size="small"
                                                    sx={{ mb: 1, mt: 1 }}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "DD-MM-YY",
                                                    }}

                                                // error={
                                                //     formik.touched.from && Boolean(formik.errors.from)
                                                // }
                                                // helperText={formik.touched.from && formik.errors.from}
                                                />
                                            )}
                                            minDate={new Date()}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>

                            <Grid sx={{ mt: 2 }} item xs={12} sm={6} md={6} className="input pt-0">
                                <label >Stop / Review Date <span className="danger">*</span> </label>
                                <LocalizationProvider sx={{ mt: 1 }} dateAdapter={AdapterDayjs}>
                                    <Stack spacing={3}>
                                        <DesktopDatePicker
                                            // views={['day', 'month', 'year']}
                                            className="form-input date-input-filed"
                                            // format="DD-MM-YYYY"
                                            inputFormat="DD-MM-YYYY"
                                            size="small"
                                            defaultValue={"18-04-2023"}
                                            // name="from"

                                            onChange={handleChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    size="small"
                                                    sx={{ mb: 1, mt: 1 }}
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "DD-MM-YY",
                                                    }}

                                                // error={
                                                //     formik.touched.from && Boolean(formik.errors.from)
                                                // }
                                                // helperText={formik.touched.from && formik.errors.from}
                                                />
                                            )}
                                            minDate={new Date()}
                                        />
                                    </Stack>
                                </LocalizationProvider>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 1 }} sm={4} md={4} className='pt-0'>

                        <label>Dose Form <span className="danger">*</span> </label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            sx={{ mt: 1 }}
                            options={category}
                            renderInput={(params) => <TextField {...params} placeholder="Capsule" />}
                            size="small"
                        />

                    </Grid>

                    <Grid sx={{ mt: 2 }} item xs={12} sm={6} md={6} className='pt-0' >
                        <label>Dosage<span className="danger">*</span> </label>
                        <TextField
                            sx={{ mt: 1, mb: 2 }}
                            className='w-100 form-input'
                            type="Name"
                            size="small"
                            placeholder="3"
                        />
                    </Grid>

                    <Grid sx={{ mt: 2 }} item xs={12} sm={6} md={6} className='pt-0 add-medical-require' >
                        <Box className="appointment-calender-filed-select w-100 d-flex align-content-center">
                            <Typography className="">Repeat every</Typography>
                            <FormControl fullWidth className="slot-calender">
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="date-day-slot"
                                    value={showhide}
                                    size="small"
                                    onChange={(e) => handleshowhide(e)}
                                    displayEmpty
                                    className="add-medical-require-select"
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value={showhide} selected>
                                        As Required
                                    </MenuItem>
                                    <MenuItem value="1">Twice</MenuItem>
                                    <MenuItem value="2">Thrice</MenuItem>
                                    <MenuItem value="3">Once</MenuItem>

                                </Select>
                            </FormControl>

                            {showhide && (

                                <FormControl fullWidth className="slot-calender">
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="date-day-slot"
                                        value={showSelect}
                                        size="small"
                                        onChange={(e) => handleSelectShow(e)}
                                        displayEmpty
                                        className="add-medical-require-select"
                                        inputProps={{ "aria-label": "Without label" }}
                                    >

                                        <MenuItem className="d-none" value={showSelect} selected>
                                            Chose
                                        </MenuItem>
                                        <MenuItem value="1">Day</MenuItem>
                                        <MenuItem value="2">Week</MenuItem>
                                        <MenuItem value="3">Month</MenuItem>

                                    </Select>
                                </FormControl>
                            )}

                        </Box>

                        {showSelect === "1" && (
                            <Box id="week-slide" className="slide-filed">
                                <Twice />
                            </Box>
                        )}

                        {showSelect === "2" && (
                            <Box id="week-slide" className="slide-filed">
                                <Week />
                            </Box>
                        )}

                        {showSelect === "3" && (
                            <Box id="week-slide" className="slide-filed">
                                <Month />
                            </Box>
                        )}


                    </Grid>


                </Grid>

                <Box className="w-100">
                    <Grid container spacing={6} sx={{ mt: 1 }} className="padding-right-20 padding-left-20">
                        <Grid sx={{ mt: 2 }} item xs={12} sm={6} md={6} className='pt-0' >
                            <label>Unit of Measurement & Concentration<span className="danger">*</span> </label>
                            <TextField
                                sx={{ mt: 1, mb: 2 }}
                                className='w-100 form-input'
                                type="Name"
                                size="small"
                                placeholder="Lorem Ipsum"
                            />
                        </Grid>

                        <Grid sx={{ mt: 2 }} item xs={12} sm={6} md={6} className="input input-field-margin-top pt-0">
                            <label>Monitoring Required <span className="danger">*</span> </label>
                            <Box className="radio-box-wrraper" >

                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"

                                >
                                    <FormControlLabel value="female" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="male" control={<Radio />} label="No" />

                                </RadioGroup>




                            </Box>
                        </Grid>

                        <Grid sx={{ mt: 2 }} item xs={12} sm={6} md={6} className='pt-0' >
                            <label>Route of Administration<span className="danger">*</span> </label>
                            <TextField
                                sx={{ mt: 1, mb: 2 }}
                                className='w-100 form-input'
                                type="Name"
                                size="small"
                                placeholder="Lorem Ipsum"
                            />
                        </Grid>

                        <Grid sx={{ mt: 2 }} item xs={12} sm={6} md={6} className='pt-0' >
                            <label>Prescribed By<span className="danger">*</span> </label>
                            <TextField
                                sx={{ mt: 1, mb: 2 }}
                                className='w-100 form-input'
                                type="Name"
                                size="small"
                                placeholder="Dr. Anu Shah"
                            />
                        </Grid>

                    </Grid>
                </Box>

                <Box sx={{ mb: 5 }} className="agency-btn-section add-expenses-btn">
                    <Link href="#cancel">Cancel</Link>
                    <Link href="#save" to="/add-appointment" >
                        Save
                    </Link>
                </Box>
            </Box>


            {/* attach file modal   */}

            <Dialog
                open={openAttach}
                onClose={handleCloseAttach}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="attach-file-modal"
            >
                <DialogContent className="modal-text">
                    <DialogContentText id="alert-dialog-description">
                        <Box className="d-flex justify-content-center">
                            <Box className="add-expenses">
                                <Box className="add-expenses-head d-flex align-items-center justify-content-between">
                                    <Typography> Add Expenses</Typography>
                                    <Typography>
                                        Client: <span>Jake Knap</span>
                                    </Typography>
                                </Box>
                                <Box className="add-expenses-body">
                                    <Box className="attach-document">
                                        <h3>Attach Document</h3>
                                        <Box className="attach-document-body d-flex align-items-center">
                                            <Box className="d-flex align-items-center ">
                                                <input
                                                    type="radio"
                                                    name="uploadfile"
                                                    id="attachupload"
                                                ></input>
                                                <img src={upload}></img>
                                                <label for="attachupload">Upload</label>
                                            </Box>
                                            <Box className="d-flex align-items-center" sx={{ pl: 2 }}>
                                                <input
                                                    type="radio"
                                                    name="uploadfile"
                                                    id="attachcapture"
                                                ></input>
                                                <img src={camera}></img>
                                                <label for="attachcapture">Capture</label>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Form className="d-flex align-items-center attach-document-browse w-100 justify-content-between">
                                                <TextField
                                                    id="outlined-basic"
                                                    size="small"
                                                    className="attach-file-input"
                                                    type="file"
                                                ></TextField>
                                                <Button>Upload</Button>
                                            </Form>
                                        </Box>
                                    </Box>

                                    <Box className="agency-btn-section add-expenses-btn">
                                        <Link href="javascript:;" onClick={handleCloseAttach}>
                                            Cancel
                                        </Link>
                                        <Link
                                            href="javascript:;"
                                            to="/timesheet"
                                            onClick={handleCloseAttach}
                                        >
                                            Save
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="time-sheet-modal"
            >
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        className="modal-content"
                    >
                        <Box className="map-modal bg-light">
                            <div class="mapouter">
                                <div class="gmap_canvas">
                                    <iframe
                                        width="100%"
                                        height="323px"
                                        className="map-iframe"
                                        id="gmap_canvas"
                                        src="https://maps.google.com/maps?q=Sydney&t=&z=10&ie=UTF8&iwloc=&output=embed"
                                        frameborder="0"
                                        scrolling="no"
                                        marginheight="0"
                                        marginwidth="0"
                                    ></iframe>
                                    <Box className="d-flex align-items-center justify-content-between map-modal-footbar">
                                        <Typography className="distance-txt" sx={{ pb: 0 }}>
                                            Distance: 45 KM
                                        </Typography>
                                        <Button onClick={handleClose}>Go Back</Button>
                                    </Box>
                                </div>
                            </div>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
