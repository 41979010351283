import React from "react";
import Box from "@mui/material/Box";
import Appbar from "../../Dashboard/Layout/Appbar";
import Sidebar from "../../Dashboard/Layout/Sidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import client from "../../assets/images/client.jpg";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import "../../Dashboard/Appointmentschedule/appointment.css";
import "../../assets/css/dashboard.css";
import Autocomplete from "@mui/material/Autocomplete";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import upload from "../../assets/images/upload.svg";
import camera from "../../assets/images/camera.svg";
import Form from "@mui/material/FormGroup";
import { Button } from "@mui/material";
import gallary from "../../assets/images/gallary1.jpg";
import image from "../../assets/images/image.png";
import noteImage from "../../assets/images/client.jpg";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import GallaryDialog from "../../Dashboard/Appointmentschedule/Tasks/Gallary-Dialog";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "Pending",
  "Accepted",
  "Completed",
  "Started",
  "Rejected",
  "Change by Admin",
  "Change by SW",
];

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const category = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
  { label: "12 Angry Men", year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: "Pulp Fiction", year: 1994 },
];
const times = [
  { label: "1", year: 1994 },
  { label: "2", year: 1972 },
  { label: "3", year: 1974 },
  { label: "4", year: 2008 },
  { label: "5", year: 1957 },
  { label: "6", year: 1993 },
  { label: "7", year: 1994 },
];

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function Timesheet() {
  const [value, setValue] = React.useState();

  const [active, setActive] = React.useState(false);

  const [personName, setPersonName] = React.useState([]);

  const [openImg, setOpenImg] = React.useState(false);
  const handleOpenImg = () => {
    setOpenImg(true);
  };

  const handleCloseImg = () => {
    setOpenImg(false);
  };

  const handleChangeCheck = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      <Typography>Timesheets</Typography>
    </Link>,
    // <Link
    //     underline="hover"
    //     key="2"
    //     color="inherit"
    //     href="/material-ui/getting-started/installation/"
    //     onClick={handleClick}
    // >
    //     <Typography>Evaluation</Typography>
    // </Link>,
  ];

  return (
    <>
      <Box className="dashboard-form" id="timesheet">
        <Box sx={{ width: "100%" }} className="appbar-border">
          <Box className="appbar-main">
            <Appbar />
          </Box>
        </Box>
        <Box className=" d-flex dashboard-main-box">
          <Sidebar />
          <Box className="main">
            <Box id="breadcrumb">
              <ArrowBackIosIcon />
              <Box className="breadcrumb-inr">
                <h4 className="d-flex align-items-center "> Timesheets</h4>
                <Box className="breadcrumbs">
                  <Link className="breadcrumbs-link breadcrumbs-link-active">
                    Timesheets
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box className="dashboard-form-typo dashboard-form-typo-sec dashboard-form-typo-up">
              <Box className="d-flex align-items-center justify-content-between">
                <h4 className="d-flex align-items-center ">
                  {" "}
                  <ArrowBackIosIcon /> Appointment Dashboard
                </h4>
                <Box className="appointment-dashboard-profile d-flex align-items-center ">
                  <img src={client} />{" "}
                  <Box>
                    {" "}
                    <Typography>Jake Knap</Typography> <span>46 yr, Male</span>{" "}
                  </Box>
                </Box>
                <Box className="appointment-dashboard-timebar d-flex align-items-center">
                  <Typography>
                    Routine Name: <span>Exercise</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="dashboard-form-typo dashboard-form-typo-sec dashboard-form-typo-up">
              <Box className="d-flex align-items-center justify-content-between">
                <h4 className="d-flex align-items-center ">
                  {" "}
                  <ArrowBackIosIcon /> Task 3: Squats X10
                </h4>
                <Box className="appointment-dashboard-timebar d-flex align-items-center">
                  <Typography>
                    Status: <span className="select-style select-box-1"></span>
                    Pending
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="Task-list-main">
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6} md={6} className="pt-0">
                  <label>Title</label>
                  <TextField
                    sx={{ mt: 2, mb: 2 }}
                    className="form-input w-100"
                    type="Name"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="pt-0">
                  <label>Middle Name</label>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    sx={{ mt: 2, mb: 2 }}
                    options={category}
                    renderInput={(params) => <TextField {...params} />}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ mt: 2 }}
                  className="pt-0"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} className="input pt-0">
                      <label>Due Date</label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopDatePicker
                            className="form-input date-input-filed"
                            format="DD-MM-YYYY"
                            value={value}
                            onChange={handleChange}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                sx={{
                                  mt: 2,
                                  mb: 2,
                                }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "DD-MM-YY",
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} className="pt-0">
                      <label>Start Time</label>
                      <TextField
                        sx={{ mt: 2, mb: 2 }}
                        className="form-input w-100"
                        type="time"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} className="pt-0">
                      <label>End Time</label>
                      <TextField
                        sx={{ mt: 2, mb: 2 }}
                        className="form-input w-100"
                        type="time"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="pt-0">
                  <label>Priority</label>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      sx={{ mt: 2 }}
                      className="input pt-0"
                    >
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        className="d-flex align-items-center justify-content-between flex-row "
                      >
                        <FormControlLabel
                          value="Low"
                          className="shift-checkbox form-text"
                          control={<Radio />}
                          label="Low"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      sx={{ mt: 2 }}
                      className="pt-0"
                    >
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        className="d-flex align-items-center justify-content-between flex-row "
                      >
                        <FormControlLabel
                          value="Medium"
                          className="shift-checkbox form-text"
                          control={<Radio />}
                          label="Medium"
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={4}
                      sx={{ mt: 2 }}
                      className="pt-0"
                    >
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        className="d-flex align-items-center justify-content-between flex-row "
                      >
                        <FormControlLabel
                          value="High"
                          className="shift-checkbox form-text"
                          control={<Radio />}
                          label="High"
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="pt-0">
                  <label>Frequency</label>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ mt: 2 }}
                      className="input justify- 
                                     content-between d-flex align- 
                                     items-center pt-0"
                    >
                      <Box className="d-flex align-items-center justify-content-between">
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          className="d-flex align-items-center frequency-radio justify- 
                                                 content-between flex- 
                                                 row "
                        >
                          <FormControlLabel
                            value="Day"
                            className="shift-checkbox form-text"
                            control={<Radio />}
                            label="Day"
                          />
                        </RadioGroup>
                        <Box className="frequency-times d-flex align-items-center">
                          <Autocomplete
                            disablePortal
                            disableClearable
                            freeSolo
                            id="combo-box-demo"
                            options={times}
                            className="times "
                            renderInput={(params) => (
                              <TextField {...params} className="text-center" />
                            )}
                            size="small"
                          />
                          <label>times</label>
                        </Box>
                      </Box>
                      <Box className="d-flex justify-content-end align-items-center w-100">
                        <Checkbox sx={{ padding: "0", mr: 1 }} {...label} />
                        <span id="general">Repeat for every day </span>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ mt: 2 }}
                      className="input justify- 
                                     content-between d-flex align- 
                                     items-center pt-0"
                    >
                      <Box className="d-flex align-items-center justify-content-between">
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          className="d-flex align-items-center frequency-radio justify- 
                                                 content-between flex- 
                                                 row "
                        >
                          <FormControlLabel
                            value="Week"
                            className="shift-checkbox form-text"
                            control={<Radio />}
                            label="Week"
                          />
                        </RadioGroup>
                        <Box className="frequency-times d-flex align-items-center">
                          <Autocomplete
                            disablePortal
                            disableClearable
                            freeSolo
                            id="combo-box-demo"
                            options={times}
                            className="times"
                            renderInput={(params) => (
                              <TextField {...params} className="text-center" />
                            )}
                            size="small"
                          />
                          <label>times</label>
                        </Box>
                      </Box>
                      <Box className="d-flex justify-content-end align-items-center w-100">
                        <Checkbox sx={{ padding: "0", mr: 1 }} {...label} />
                        <span id="general">Repeat for every week </span>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      sx={{ mt: 2 }}
                      className="input justify- 
                                     content-between d-flex align- 
                                     items-center pt-0"
                    >
                      <Box className="d-flex align-items-center justify-content-between">
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                          className="d-flex align-items-center frequency-radio justify- 
                                                 content-between flex- 
                                                 row "
                        >
                          <FormControlLabel
                            value="Year"
                            className="shift-checkbox form-text"
                            control={<Radio />}
                            label="Year"
                          />
                        </RadioGroup>
                        <Box className="frequency-times d-flex align-items-center">
                          <Autocomplete
                            disablePortal
                            disableClearable
                            freeSolo
                            id="combo-box-demo"
                            options={times}
                            className="times"
                            renderInput={(params) => (
                              <TextField {...params} className="text-center" />
                            )}
                            size="small"
                          />
                          <label>times</label>
                        </Box>
                      </Box>
                      <Box className="d-flex justify-content-end align-items-center w-100">
                        <Checkbox sx={{ padding: "0", mr: 1 }} {...label} />
                        <span id="general">Repeat for every year </span>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ mt: 2 }}
                  className="input pt-0"
                >
                  <Box className="attach-document task-attachment">
                    <Box>
                      <h3>Attach Document</h3>
                    </Box>

                    <Box className="task-edit-attach-document">
                      <Grid container>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="d-flex align-items-center ">
                            <input
                              type="radio"
                              name="noteupload"
                              id="noteupload"
                            ></input>
                            <img src={upload} alt="upload"></img>
                            <label for="noteupload">Upload</label>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="d-flex align-items-center">
                            <input
                              type="radio"
                              name="noteupload"
                              id="notecapture"
                            ></input>
                            <img src={camera}></img>
                            <label for="notecapture">Capture</label>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ mt: 2 }}
                  className="input pt-0"
                >
                  <Box className="d-flex align-items-center">
                    <Form className=" list-attach-document-browse w-100 ">
                      <TextField
                        id="outlined-basic"
                        size="small"
                        className="attach-file-input"
                        type="file"
                      ></TextField>
                    </Form>
                    <Button className="upload-btn">Upload</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box className="gallary-box">
              <Box className="gallary">
                <img src={gallary} />
                <Box className="list-image-detail">
                  <img src={image} />
                  <Typography>Support Image.jpg </Typography>
                </Box>
              </Box>
              <Box className="gallary">
                <img src={gallary} />
                <Box className="list-image-detail">
                  <img src={image} />
                  <Typography>Support Image.jpg </Typography>
                </Box>
              </Box>
              <Box className="gallary">
                <img src={gallary} />
                <Box className="list-image-detail">
                  <img src={image} />
                  <Typography>Support Image.jpg </Typography>
                </Box>
              </Box>
              <Box className="gallary">
                <img src={gallary} />
                <Box className="list-image-detail">
                  <img src={image} />
                  <Typography>Support Image.jpg </Typography>
                </Box>
              </Box>
              <Box className="gallary">
                <img src={gallary} />
                <Box className="list-image-detail">
                  <img src={image} />
                  <Typography>Support Image.jpg </Typography>
                </Box>
              </Box>
              <Box className="gallary gallary-overlay" onClick={handleOpenImg}>
                <img src={gallary} />
                <span>View All</span>

                <Box className="list-image-detail">
                  <img src={image} />
                  <Typography>Support Image.jpg </Typography>
                </Box>
              </Box>
            </Box>

            <Grid id="listNote" container className="list-add-note">
              <Grid item sx={12} sm={12} md={12}>
                <label>Add Notes</label>
                <TextField
                  placeholder="MultiLine with rows: 2 and rowsMax: 4"
                  multiline
                  rows={3}
                  sx={{ width: "100%", mt: 2 }}
                  maxRows={4}
                />
              </Grid>

              <Grid item sx={12} sm={12} md={12} className="list-notes">
                <Box className="notes-profile d-flex align-items-center">
                  <img src={noteImage} />
                  <Typography>Monte Alter (SW)</Typography>
                </Box>

                <Box className="list-note-description">
                  <Typography>
                    Take 30 seconds break after performing 5 squats. Do your
                    squats on a flat, even surface. Grass, rubber, and other
                    surfaces that absorb shock are preferred over cement or
                    asphalt. Wear supportive shoes. Choose athletic sneakers
                    instead of sandals, heeled shoes, or boots. Pay attention to
                    your body. If you feel pain, take.. <span>See more</span>{" "}
                  </Typography>

                  <span>12-Dec-2022; 02:00 pm</span>
                </Box>
              </Grid>

              <Grid item sx={12} sm={12} md={12} className="list-notes">
                <Box className="notes-profile d-flex align-items-center">
                  <img src={noteImage} />
                  <Typography>Kane Smith (SW)</Typography>
                </Box>

                <Box className="list-note-description">
                  <Typography>Perform 10 squats.</Typography>
                  <span>12-Dec-2022; 02:00 pm</span>
                </Box>
              </Grid>

              <Grid item sx={12} sm={12} md={12} className="list-notes">
                <Box className="notes-profile d-flex align-items-center">
                  <img src={noteImage} />
                  <Typography>Monte Alter (SW)</Typography>
                </Box>

                <Box className="list-note-description">
                  <Typography>
                    Take 30 seconds break after performing 5 squats. Do your
                    squats on a flat, even surface. Grass, rubber, and other
                    surfaces that absorb shock are preferred over cement or
                    asphalt. Wear supportive shoes. Choose athletic sneakers
                    instead of sandals, heeled shoes, or boots. Pay attention to
                    your body. If you feel pain, take a break or stop your
                    session completely. <span>See less</span>
                  </Typography>

                  <span>12-Dec-2022; 02:00 pm</span>
                </Box>
              </Grid>

              <Box>
                <Link className="view-more-notes">View More Notes</Link>
              </Box>

              <Grid container className="list-status align-items-end">
                <Grid item xs={12} sm={4} md={4}>
                  <label>Status </label>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={personName}
                    className="w-100"
                    size="small"
                    onChange={handleChangeCheck}
                    input={<OutlinedInput label="Tag" />}
                    //renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    sx={{ mt: 2 }}
                  >
                    {names.map((name, index) => (
                      <MenuItem
                        key={name}
                        onClick={() => setActive(index + 1)}
                        className={`select-item-${active === index && "1"}`}
                        value={name}
                      >
                        <span
                          className={`select-style select-box-${index + 1}`}
                        ></span>
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} sm={5} md={5}>
                  <Button className="list-update-btn">Update</Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openImg}
        onClose={handleCloseImg}
        aria-labelledby="alert-dialog-title"
        aria-describedby="gallary-dialog"
        className="gallaryBox"
      >
        <DialogContent className="modal-text">
          <DialogContentText id="gallary-dialog">
            <Box className="d-flex justify-content-center">
              <Box className="add-expenses">
                {/* <img src={gallary1} /> */}

                <GallaryDialog handleCloseImg={handleCloseImg} />
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
