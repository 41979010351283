import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Typography } from "@mui/material";

import tickIcon from "../../assets/images/table-right-icon.png";
import closeIcon from "../../assets/images/table-close-icon.png";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function PricingTable() {
  return (
    <TableContainer >
      <Table sx={{ minWidth: 650 }} aria-label="simple table" className="pricing-table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}></TableCell>
            <TableCell align="center">Free</TableCell>
            <TableCell align="center">Basic</TableCell>
            <TableCell align="center">Standard</TableCell>
            <TableCell align="center">Advanced</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className="table-single-row " sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" className="table-single-row space-pt-60" colSpan={12}>
              Care Management
            </TableCell>
          </TableRow>

          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" colSpan={2}>
              <Typography>Progress notes</Typography>
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" colSpan={2}>
              <Typography>Goals & Objectives</Typography>
            </TableCell>
            <TableCell align="center">
              <img src={closeIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
          </TableRow>

          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" className="table-single-row space-pt-60" colSpan={12}>
            Shift Management
            </TableCell>
          </TableRow>

          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" colSpan={2}>
              <Typography>Shift Notes </Typography>
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" colSpan={2}>
              <Typography>Shift Swap</Typography>
            </TableCell>
            <TableCell align="center">
              <img src={closeIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" colSpan={2}>
              <Typography>On Call Shifts</Typography>
            </TableCell>
            <TableCell align="center">
              <img src={closeIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
          </TableRow>

          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" className="table-single-row" colSpan={12}>
            Timesheets and Leave management
            </TableCell>
          </TableRow>

          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" colSpan={2}>
              <Typography>Track and manage staff hours, expenses</Typography>
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" colSpan={2}>
             <Typography>Track and manage staff hours, expenses</Typography>
            </TableCell>
            <TableCell align="center">
              <img src={closeIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={closeIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
            <TableCell align="center">
              <img src={tickIcon} />
            </TableCell>
          </TableRow>

          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row" colSpan={2}>
         
            </TableCell>
            <TableCell align="center">
            <Button component="a" href="/book-demo" className="pricing-card-btn"> Start your Free Trial </Button>
            </TableCell>
            <TableCell align="center">
            <Button className="pricing-card-btn">Get Started</Button>
            </TableCell>
            <TableCell align="center">
            <Button className="pricing-card-btn">Get Started</Button>
            </TableCell>
            <TableCell align="center">
            <Button className="pricing-card-btn">Get Started</Button>
            </TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
  );
}
