import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Tabs from "./Tab/Tabs";
import "../../assets/css/Appointment.css";
import { useState, useEffect } from "react";
// import { CSVLink } from "react-csv";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Button } from "@material-ui/core";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddIcon from "@mui/icons-material/Add";
import Popover from "@mui/material/Popover";
import importicon from "../../assets/images/exporticon.svg";
import importIconimg from "../../assets/images/import.png";


import { Divider } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import GetAppIcon from "@mui/icons-material/GetApp";
import FileUploadIcon from '@mui/icons-material/FileUpload';


export default function ColumnsGrid() {
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      <Typography>Appointment List</Typography>
    </Link>,
    // <Link
    //     underline="hover"
    //     key="2"
    //     color="inherit"
    //     href="/material-ui/getting-started/installation/"
    //     onClick={handleClick}
    // >
    //     <Typography>Evaluation</Typography>
    // </Link>,
  ];

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [list, setList] = useState([]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <Box mt={2}>
        <Grid container spacing={0} className="d-flex flex-column">
          <Box className="dashboard-form-typo dashboard-form dashboard-form-typo-sec dashboard-form-typo-up">
            <Grid container className="d-flex header-topsection">
              <Grid item xs={6} sm={6} md={6}>
                <Box id="breadcrumb">
                  <ArrowBackIosIcon />
                  <Box className="breadcrumb-inr">
                    <h4 className="d-flex align-items-center ">
                      {" "}
                      Appointments
                    </h4>
                    <Box className="breadcrumbs">
                      <Link className="breadcrumbs-link">Appointments</Link>{" "}
                      <span className="breadcrumbs-icon">{">"}</span>
                      {/*   <Link className="breadcrumbs-link">Health Carer</Link>  <span
    className="breadcrumbs-icon">{">"}</span> */}
                      <Link className="breadcrumbs-link breadcrumbs-link-active">
                        Appointment List
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Box className="addTask-btn">
                  <Box className="d-flex align-items-center justify-content-end">
                    <Link
                      to="/add-hundred-points"
                      className="agency-add btn next-btn"
                    >
                      <AddIcon /> Add
                    </Link>

                    <Button
                      className="importexportbtn"
                      variant="outlined"
                      aria-describedby={id}
                      onClick={handleClick}
                      startIcon={
                        <img
                          src={importicon}
                          className="importexporticon-image"
                          alt="importicon"
                        />
                      }
                    >
                      Export
                    </Button>

                    {/* Export Pophover */}
                    <Popover
                      elevation={0}
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      {/* popover content */}
                      <div>
                        <Card>
                          <Box className="exportdropdown-wrap">
                            <Box className="cardcontent_box dropdownimport">
                              <CardContent className="import_CardContent">
                                <div className="exportdata-btn">
                                  {/* <svg
                                    width="18"
                                    height="17"
                                    viewBox="0 0 18 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M1 11.5996V15.5996H17V11.5996"
                                      stroke="white"
                                      stroke-width="1.45"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M5.80078 8.56055L9.00078 10.9605"
                                      stroke="white"
                                      stroke-width="1.45"
                                      stroke-linecap="round"
                                    />
                                    <path
                                      d="M12.2812 8.40039L9.08125 11.041"
                                      stroke="white"
                                      stroke-width="1.45"
                                      stroke-linecap="round"
                                    />
                                    <line
                                      x1="8.91367"
                                      y1="10.8727"
                                      x2="8.91367"
                                      y2="0.725842"
                                      stroke="white"
                                      stroke-width="1.45"
                                      stroke-linecap="round"
                                    />
                                  </svg> */}
                                  <img
                                    src={importIconimg}
                                    className="importexporticon-image"
                                    alt="importicon"
                                  />

                                  <Typography
                                    component="div"
                                    className="importfiletext"
                                  >
                                    Export Data
                                    <span>Download Data</span>
                                  </Typography>
                                </div>
                              </CardContent>
                            </Box>

                            <Box>
                              <CardActions className="cardactionsty2 dropdownimportbutton">
                                <Link
                                  data={list}
                                  filename="Hundred-points.csv"
                                  // headers={headers}
                                >
                                  <Button
                                    className="filebtn"
                                    variant="outlined"
                                    component="label"
                                  >
                                    <FileUploadIcon align="center" />
                                    Export
                                  </Button>
                                </Link>
                              </CardActions>
                            </Box>
                          </Box>
                        </Card>
                      </div>
                    </Popover>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>

      <Box className="appointment-tabBox" pt={2}>
        <Grid
          spacing={2}
          // direction="column-reverse"
        >
          <Grid item xs={12} md={12}>
            <Tabs />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
