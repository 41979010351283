import {
  getJobTitleList,
  getJobTypeList,
  getLevelList,
  getState,
  getCountry,
  getCountryDetails,
  getStateDetails,
  getStateList,
  getLevelDetails,
  getJobTypeDetails,
  listAllSkill,
  getJobTitleDetails,
  AustraliaId,
  addGoogleCount,
  updateGoogleCount,
  getIndividualCountDetails,
  SAcreatedBy,
  Appointmenttype,
} from "../../../../api";
import React, { useEffect, useState } from "react";
import moment from "moment";
// import axios from "axios";
import axios from "../../../../api/axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MailIcon from "@mui/icons-material/Mail";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import CallIcon from "@mui/icons-material/Call";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import HCStore from "../HCStore";

import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormGroup,
} from "@mui/material";

const LookingSupport = (props) => {
  const { formik } = props;

  const [apiCount, setApiCount] = useState(0);
  let countId = "6491bb0d50032ab6cada7d3e";

  const [jobTypeList, setJobTypeList] = useState([]);
  const [jobTypeName, setJobTypeName] = useState();
  const [levelList, setLevelList] = useState([]);
  const [levelName, setLevelName] = useState();
  const [skillList, setSkillList] = useState();
  const [inputValue, setnewInputValue] = React.useState(null);
  const [inputStateValue, setInputStateValue] = React.useState(null);
  const [stateTypeVal, setStateTypeVal] = useState([]);
  const [countryVal, setCountryVal] = useState();
  const [countryName, setCountryName] = useState();
  const [stateName, setStateName] = useState();
  const [stateData, setStateData] = useState();
  const [checkBoxVisible, setCheckBoxVisible] = useState(false);
  const [valueDobData, setDobData] = useState();

  const [visiblePostAddresss, setVisiblePostAddresss] = useState(false);
  const [latLng, setLatLng] = useState({ lat: null, lng: null });
  const [showPersonalDetails, setShowPersonalDetails] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const { resetFields, setField, buttonClickedPinfo } = HCStore();

  const gender = [
    { label: "Male", id: "Male" },
    { label: "Female", id: "Female" },
    { label: "Other", id: "Other" },
  ];

  const relation = [
    { label: "Brother", id: "Brother" },
    { label: "Sister", id: "Sister" },
    { label: "Father", id: "Father" },
    { label: "Mother", id: "Mother" },
    { label: "Uncle", id: "Uncle" },
    { label: "Aunt", id: "Aunt" },
  ];

  const titles = [
    {
      label: "Ms",
      id: "Ms",
    },
    { label: "Mr", id: "Mr" },
    { label: "Mrs", id: "Mrs" },
  ];

  console.log(props?.formik?.errors);

  useEffect(() => {
    axios
      .get(`${listAllSkill}`)
      .then((res) => {
        setSkillList(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const jobTypeNameData = (jobTypeIdVal) => {
    axios
      .get(`${getJobTypeDetails}/${jobTypeIdVal}`)
      .then(function (res) {
        if (res) {
          setJobTypeName(res?.data?.data?.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(getLevelList)
      .then((res) => {
        let levelList = res?.data?.data;
        let data = [];
        levelList &&
          levelList.map((d) => {
            data.push({
              label: `${d.name}`,
              id: d._id,
            });
          });
        setLevelList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleDobData = (newValue) => {
    setDobData(newValue.target.value);
  };

  useEffect(() => {
    countryList();
    if (
      formik.values.address.addressLine1 ||
      formik.values.address.addressLine2 ||
      formik.values.address.city ||
      formik.values.address.state
    ) {
      setCheckBoxVisible(true);
    }

    if (
      formik.values.postalAddress.postalAddressLine1 ||
      formik.values.postalAddress.postalAddressLine2 ||
      formik.values.postalAddress.postalCity ||
      formik.values.postalAddress.postalStateId
    ) {
      setVisiblePostAddresss(true);
    }
  }, []);
  const countryList = () => {
    axios
      .get(getCountry)
      .then((res) => {
        let countryData = res?.data?.data;
        let data = [];
        countryData &&
          countryData.map((country) => {
            data.push({
              label: `${country.name}`,
              id: country._id,
            });
          });
        setCountryVal(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let countryNmd = props?.formik?.values?.address?.country;

  useEffect(() => {
    const apiUrl = `${getState}/${AustraliaId}`;
    stategetFunction(apiUrl);
  }, []);

  useEffect(() => {
    if (countId !== undefined) {
      getCountDetails(countId);
    }
  }, [countId]);

  const getCountDetails = (countId) => {
    axios
      .get(`${getIndividualCountDetails}/${countId}`)
      .then(function (res) {
        if (res) {
          console.log("count", res?.data?.data?.count);
          setApiCount(res?.data?.data?.count);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("apiCount", apiCount);

  const getCountryAddFunction = (z) => {
    if (z) {
      setStateName("");
      setnewInputValue(z);
      countryNameData(z);
      const apiUrl = `${getState}/${z}`;
      stategetFunction(apiUrl);
    }
  };

  function stategetFunction(apiUrl) {
    axios
      .get(apiUrl)
      .then((res) => {
        let stateData = res?.data?.data;
        let data = [];
        stateData &&
          stateData.map((d) => {
            data.push({
              label: `${d.StateName}`,
              id: d._id,
            });
          });
        setStateTypeVal(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getStateIdFunction = (y) => {
    if (y) {
      stateNameData(y);
      setInputStateValue(y);
    }
  };

  useEffect(() => {
    if (countryNmd !== undefined) {
      countryNameData(countryNmd);
    }
  }, [countryNmd !== undefined]);

  const countryNameData = (countryNmd) => {
    axios
      .get(`${getCountryDetails}/${countryNmd}`)
      .then(function (res) {
        if (res) {
          setCountryName(res?.data?.data?.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let stateUnd = props?.formik?.values?.address?.state;
  useEffect(() => {
    if (stateUnd !== undefined) {
      stateNameData(stateUnd);
    }
  }, [stateUnd !== undefined]);

  const stateNameData = (stateUnd) => {
    axios
      .get(`${getStateDetails}/${stateUnd}`)
      .then(function (res) {
        if (res) {
          setStateName(res?.data?.data?.name);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    stateList();
  }, []);
  const stateList = () => {
    axios
      .get(getStateList)
      .then((res) => {
        let stateData = res?.data?.data;
        let data = [];
        stateData &&
          stateData.map((state) => {
            data.push({
              label: `${state.name}`,
              id: state._id,
            });
          });
        setStateData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [supportRequire, setSupportRequire] = useState("");
  const [shiftType, setShiftType] = useState([]);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    // Show personal details when "Family Member" or "My Client" is selected
    setShowPersonalDetails(
      event.target.value === "Family Member" ||
      event.target.value === "My Client"
    );
  };

  const handleCheckboxToggle = (skillId) => {
    // const skills = formikGetServiceInfo.values.skills;
    // if (skills.includes(skillId)) {
    //   // If the skill is already selected, remove it from the selected skills array
    //   formikGetServiceInfo.setFieldValue(
    //     'skills',
    //     skills.filter((id) => id !== skillId)
    //   );
    // } else {
    //   // If the skill is not selected, add it to the selected skills array
    //   formikGetServiceInfo.setFieldValue('skills', [...skills, skillId]);
    // }
  };
  const [check, setCheck] = React.useState(true);
  const handleSupportChange = (event) => {
    setSupportRequire(event.target.value);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  useEffect(() => {
    getAppointment();
  }, []);
  const getAppointment = () => {
    axios
      .get(`${Appointmenttype}?createdBy=${SAcreatedBy}`)
      .then(async (res) => {
        let AppintData = res?.data?.data;
        let data = [];

        AppintData &&
          AppintData.map((AppoitmentType) => {
            data.push({
              label: `${AppoitmentType?.AppoitmentType}`,
              id: AppoitmentType?._id,
              icon: AppoitmentType?.icon,
            });
          });
        console.log("datadatadata", data);
        setShiftType(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("shiftType", shiftType);

  return (
    <Box id="personal-info" className="account-form">
      <Box className="d-flex align-items-center justify-content-between">
        <Typography className="step-count">Step 4/4</Typography>
      </Box>

      {/* Radio Button Code  start here*/}
      <Box className="personal-info-main">
        <Box className="form-heading-section"></Box>
        <Box className="personal-info">
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <label >
                How many hours of support do you require per week?
              </label>

              <RadioGroup
                row
                aria-label="support-type"
                name="supportRequiredPerWeekInHrs"
                sx={{ p: 2, ml: -2 }}
              >
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue(
                      "supportRequiredPerWeekInHrs",
                      "1-5 hours"
                    );
                    setField("supportRequiredPerWeekInHrs", "1-5 hours");
                  }}
                  value="1-5 hours"
                  control={<Radio />}
                  label="1-5 hours"
                  checked={
                    formik.values.supportRequiredPerWeekInHrs === "1-5 hours"
                      ? true
                      : ""
                  }
                />
                &nbsp;&nbsp;
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue(
                      "supportRequiredPerWeekInHrs",
                      "6-11 hours"
                    );
                    setField("supportRequiredPerWeekInHrs", "6-11 hours");
                  }}
                  value="6-11 hours"
                  control={<Radio />}
                  label="6-11 hours"
                  checked={
                    formik.values.supportRequiredPerWeekInHrs === "6-11 hours"
                      ? true
                      : ""
                  }
                />
                &nbsp;&nbsp;
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue(
                      "supportRequiredPerWeekInHrs",
                      "11-15 hours"
                    );
                    setField("supportRequiredPerWeekInHrs", "11-15 hours");
                  }}
                  value="11-15 hours"
                  control={<Radio />}
                  label="11-15 hours"
                  checked={
                    formik.values.supportRequiredPerWeekInHrs === "11-15 hours"
                      ? true
                      : ""
                  }
                />
                &nbsp;&nbsp;
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue(
                      "supportRequiredPerWeekInHrs",
                      "More than 15 hours"
                    );
                    setField(
                      "supportRequiredPerWeekInHrs",
                      "More than 15 hours"
                    );
                  }}
                  value="More than 15 hours"
                  control={<Radio />}
                  label="More than 15 hours"
                  checked={
                    formik.values.supportRequiredPerWeekInHrs ===
                      "More than 15 hours"
                      ? true
                      : ""
                  }
                />
                &nbsp;&nbsp;
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue(
                      "supportRequiredPerWeekInHrs",
                      "Unsure at this stage"
                    );
                    setField(
                      "supportRequiredPerWeekInHrs",
                      "Unsure at this stage"
                    );
                  }}
                  value="Unsure at this stage"
                  control={<Radio />}
                  label="Unsure at this stage"
                  checked={
                    formik.values.supportRequiredPerWeekInHrs ===
                      "Unsure at this stage"
                      ? true
                      : ""
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Box>
        <Box className="personal-info">
          <Grid item xs={12}>
            <FormControl >
              <label>
                When do you require Support?
              </label>
              <RadioGroup
                row
                aria-label="support-type"
                name="whenSupportRequired"
                sx={{ p: 2, ml: -2 }}
              >
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue("whenSupportRequired", "Weekly");
                    setField("whenSupportRequired", "Weekly");
                  }}
                  value="Weekly"
                  control={<Radio />}
                  label="Weekly"
                  checked={
                    formik.values.whenSupportRequired === "Weekly" ? true : ""
                  }
                />
                &nbsp;&nbsp;
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue("whenSupportRequired", "Fortnightly");
                    setField("whenSupportRequired", "Fortnightly");
                  }}
                  value="Fortnightly"
                  control={<Radio />}
                  label="Fortnightly"
                  checked={
                    formik.values.whenSupportRequired === "Fortnightly"
                      ? true
                      : ""
                  }
                />
                &nbsp;&nbsp;
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue("whenSupportRequired", "Monthly");
                    setField("whenSupportRequired", "Monthly");
                  }}
                  value="Monthly"
                  control={<Radio />}
                  label="Monthly"
                  checked={
                    formik.values.whenSupportRequired === "Monthly" ? true : ""
                  }
                />
                &nbsp;&nbsp;
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue("whenSupportRequired", "Quarterly");
                    setField("whenSupportRequired", "Quarterly");
                  }}
                  value="Quarterly"
                  control={<Radio />}
                  label="Quarterly"
                  checked={
                    formik.values.whenSupportRequired === "Quarterly"
                      ? true
                      : ""
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Box>
        {/* {shiftType} */}
        <Box className="personal-info">
          <Grid item xs={12}>
            <FormControl>
              <label >Shift Type</label>
              <RadioGroup
                row
                aria-label="support-type"
                name="shiftTypeRequired"
                sx={{ p: 2, ml: -2 }}
              >
                {shiftType?.length > 0 &&
                  shiftType?.map((row) => (
                    <>
                      <img
                        className="checkbox-img gest-onboar-step4"
                        src={row?.icon?.location}
                        alt={row?.label}

                      />
                      &nbsp;&nbsp;
                      <FormControlLabel
                        onClick={() => {
                          formik.setFieldValue("shiftTypeRequired", row?.label);
                          setField("shiftTypeRequired", row?.label);
                        }}
                        // value="Weekly"
                        control={<Radio />}
                        label={row?.label}
                        checked={
                          formik?.values?.shiftTypeRequired === row?.label
                            ? true
                            : ""
                        }
                      />
                      &nbsp;&nbsp;
                    </>
                  ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Box>
        {/* <Box className="personal-info">
          <Box
            className="d-flex align-items-center justify-content-between switch-box"
            key={"d-flex align-items-center"}
          >
            <Box
              className="appbar-switch appbar-menu-item"
              onClick={() => setCheck(check)}
              key={"appbar-switch appbar-menu-item"}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                key={"stack-item"}
              >
                <AntSwitch
                  id="switch"
                  key={"ant-switch"}
                  inputProps={{
                    "aria-label": "ant design",
                  }}
                  checked={
                      Weekly[val.id] !== undefined &&
                          Weekly[val.id]["altype"] !== undefined
                          ? Weekly[val.id]["defaultCheck"]
                          : false
                  }
                  onChange={(e) => defaultChange(e, val)}
                />

                <Typography key={"default"}>Default </Typography>
              </Stack>
            </Box>
            <Box className="time-filed" key={"time-filed"}>
              <label key={"start-at"}>Start at</label> &nbsp;&nbsp;
              <input
                type="text"
                placeholder="10:00"
                // value={val.startTime}
                // key={"timer"}
              />
            </Box>
            <Box className="time-filed" key={"end-timer"}>
              <label key={"end-timer-label"}>End at</label> &nbsp;&nbsp;
              <input
                type="text"
                placeholder="18:00"
                // value={val.endTime}
                // key={"end-timer-placeholder" + index}
              />
            </Box>
          </Box>
        </Box> */}
        {/* <Box className="personal-info">
          <FormControl component="fieldset">
            <FormLabel component="legend">Frequency of Task</FormLabel>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                label="Repeat Everyday"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Repeat on Week Days"
              />
            </FormGroup>
          </FormControl>
        </Box> */}

        <Box className="personal-info">
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <label >Required For</label>
              <RadioGroup row aria-label="support-type" name="requiredFor" sx={{ p: 2, ml: -2 }}>
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue("requiredFor", "Everyday");
                    setField("requiredFor", "Everyday");
                  }}
                  value="Everyday"
                  control={<Radio />}
                  label="Everyday"
                  checked={formik.values.requiredFor === "Everyday" ? true : ""}
                />
                &nbsp;&nbsp;
                <FormControlLabel
                  onClick={() => {
                    formik.setFieldValue("requiredFor", "Week Days");
                    setField("requiredFor", "Week Days");
                  }}
                  value="Week Days"
                  control={<Radio />}
                  label="Week Days"
                  checked={
                    formik.values.requiredFor === "Week Days" ? true : ""
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default LookingSupport;
