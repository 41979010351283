import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ExpImage from "../../../assets/images/bag-icon.png";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MapImage from "../../../assets/images/google-maps-svg.png";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import noImage from "../../../assets/images/noImage.png";
import { toast } from "react-toastify";
import BannerSlider from "../../ui/BannerSlider";
import Link from "@mui/material/Link";
import sleepoverImage from "../../../assets/images/Date & Time.svg";
import {
  getClientRating,
  getDetailsPublicRosterAppointment,
  loginIndividualHC,
} from "../../../api";
import Rating from "@mui/material/Rating";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import WebHeader from "../../../Website/Web-Header";
import WebFooter from "../../../Website/Web-footer";
import GradeIcon from "@mui/icons-material/Grade";

export default function SignInSide() {
  const [value, setValue] = React.useState(5);
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();
  const [apptDetails, setApptDetails] = useState([]);

  const location = useLocation();
  let appointmentId;
  let type;

  appointmentId = location?.state?.ApptId;
  type = location?.state?.type

  console.log("appointmentId", appointmentId);

  useEffect(() => {
    if (appointmentId !== null) {
      getaList();
    }
  }, [appointmentId]);

  const getaList = async () => {
    try {
      const res = await axios.get(
        `${getDetailsPublicRosterAppointment}/${appointmentId}?type=${type}`
      );
      let dataval = res?.data?.data[0];
      setApptDetails(dataval);
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const [passwordTypeNew, setPasswordTypeNew] = useState("password");
  const togglePasswordNew = () => {
    if (passwordTypeNew === "password") {
      setPasswordTypeNew("text");
      return;
    }
    setPasswordTypeNew("password");
  };


  const [rating, setRating] = useState(0);
  const CheckClientRating = async () => {
    try {
      axios
        .get(`${getClientRating}/${apptDetails?.userDetails?._id}`)
        .then((res) => {
          let listUserTypeList = res?.data?.data;
          setRating(
            listUserTypeList?.roundedAverageRating !== null
              ? listUserTypeList?.roundedAverageRating
              : 0
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
    }
  };

  useEffect(() => {
    if (apptDetails?.userDetails?._id) {
      CheckClientRating();
    }
  }, [apptDetails]);


  const renderRatingIcons = () => {
    const ratingIcons = [];

    for (let i = 0; i < rating; i++) {
      ratingIcons.push(<GradeIcon key={i} className="rating-cion-sty" />);
    }

    return rating > 0 ? ratingIcons : "";
  };


  console.log("apptDetails", apptDetails);

  return (
    <>
      <WebHeader />
      <Box id="personal-info" width="100%" height="100%">
        <Grid container>
          {appointmentId !== undefined && appointmentId !== null ? (
            <React.Fragment>
              <Grid item xs={12} sm={12} md={12}>
                {/* You can replace the following placeholders with your actual JSX */}

                <Grid container className="existing-user-main">
                  <Grid
                    item
                    xs={6}
                    md={6}
                    sm={6}
                    className="existing-box d-flex align-items-center justify-content-center"
                  >
                    <Box>
                      <p className="existing-looking"> Interested in: </p>

                      <Box className="d-flex align-items-center ">
                        <h4 className="existing-user-name">
                          {apptDetails?.userDetails?.firstName !== undefined &&
                            apptDetails?.userDetails?.lastName !== undefined
                            ? `${apptDetails?.userDetails?.firstName} ${apptDetails?.userDetails?.lastName}`
                            : "NA"}
                        </h4>
                        <Box className="view-detail-rating-box">
                          {/* <Rating
                            name="simple-controlled"
                            value={value}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                          /> */}
                          <>{renderRatingIcons()}</>
                        </Box>
                      </Box>

                      <Box className="d-flex align-items-center existing-user-details ">
                        <Box className="d-flex align-items-center">
                          <img src={MapImage} />
                          <Typography variant="body2" component="div">
                            {" "}
                            {apptDetails?.userDetails?.address?.city !==
                              undefined &&
                              apptDetails?.userDetails?.address?.city !== null
                              ? apptDetails?.userDetails?.address?.city
                              : "NA"}
                            {","}
                            {apptDetails?.stateDetails?.name !== undefined &&
                              apptDetails?.stateDetails?.name !== null
                              ? apptDetails?.stateDetails?.name
                              : "NA"}
                          </Typography>
                        </Box>
                        <Box
                          className="d-flex align-items-center "
                          sx={{ ml: 3 }}
                        >
                          <img
                            className="worker-card-explore-icon"
                            sx={{ marginRight: "-3px" }}
                            src={sleepoverImage}
                          />{" "}
                          <Typography variant="body2" component="div">
                            {" "}&nbsp;
                            {apptDetails?.appointmentDetails?.date !==
                              undefined &&
                              apptDetails?.appointmentDetails?.startTime !==
                              undefined &&
                              apptDetails?.appointmentDetails?.endTime !==
                              undefined
                              ? `${new Date(
                                apptDetails?.appointmentDetails?.date
                              ).toLocaleString("en-US", {
                                weekday: "short",
                                month: "short",
                                day: "numeric",
                              })} ${apptDetails?.appointmentDetails?.startTime
                              } ${apptDetails?.appointmentDetails?.endTime}`
                              : "NA"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} className="grid-divider">
                    <Box className="w-100 existing-box existing-box-sign-in h-100 d-flex align-items-center justify-content-center">
                      <Box className="form-main-box">
                        <Box>
                          <h2 mb={3} className="sign-title">
                            Sign in
                            <p className="text-mute">
                              Welcome back! Please enter your details.
                            </p>
                          </h2>
                        </Box>
                        <Box>
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, formikHelpers) => {
                              setDisableButton(true);
                              axios
                                .post(loginIndividualHC, values)
                                .then((res) => {
                                  if (res.data.success === false) {
                                    toast.error(res.data.message);
                                    setDisableButton(false);
                                  }
                                  if (res.data.success === true) {
                                    toast.success(res.data.message);
                                    setDisableButton(false);
                                    setTimeout(() => {
                                      navigate(`/verify-otp`, {
                                        state: {
                                          emailID: values.email,
                                          pageName: "hcLogin",
                                          selectedUserId:
                                            apptDetails?.userDetails?._id,
                                          apptId: appointmentId,
                                        },
                                      });
                                    });
                                  }
                                })
                                .catch((error) => {
                                  toast.error("Something went wrong");
                                });
                            }}
                          >
                            {({ errors, isValid, touched, dirty }) => (
                              <Form>
                                <div className="form-field">
                                  <label>Email </label>

                                  <Field
                                    size="small"
                                    name="email"
                                    type="email"
                                    as={TextField}
                                    className="form-input"
                                    margin="normal"
                                    fullWidth
                                    id="outlined-required"
                                    placeholder="Email"
                                    error={
                                      Boolean(errors.email) &&
                                      Boolean(touched.email)
                                    }
                                    helperText={
                                      Boolean(touched.email) && errors.email
                                    }
                                  />
                                </div>
                                <div className="form-field">
                                  <label> Password </label>
                                  <Field
                                    size="small"
                                    name="password"
                                    type={passwordTypeNew}
                                    as={TextField}
                                    className="form-input"
                                    margin="normal"
                                    fullWidth
                                    id="outlined-required"
                                    InputProps={{
                                      endAdornment: (
                                        <endAdornment
                                          className="input-icon"
                                          position="end"
                                          onClick={togglePasswordNew}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {passwordTypeNew === "password" ? (
                                            <Visibility />
                                          ) : (
                                            <VisibilityOff />
                                          )}
                                        </endAdornment>
                                      ),
                                    }}
                                    placeholder="Password"
                                    error={
                                      Boolean(errors.password) &&
                                      Boolean(touched.password)
                                    }
                                    helperText={
                                      Boolean(touched.password) &&
                                      errors.password
                                    }
                                  />
                                </div>
                                <Box
                                  display="flex"
                                  justifyContent="flex-end"
                                  alignItems="end"
                                >
                                  <Link
                                    href="/forgot-password"
                                    variant="body2"
                                    className="forgot"
                                  >
                                    Forgot password?
                                  </Link>
                                </Box>

                                <Button
                                  className="signupbutton"
                                  type="submit"
                                  fullWidth
                                  sx={{ mt: 3, mb: 2 }}
                                  disabled={disableButton}
                                >
                                  {disableButton === true
                                    ? "Processing..."
                                    : "Sign In"}
                                </Button>
                              </Form>
                            )}
                          </Formik>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                {/* Add more JSX elements as needed */}
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={12} sm={12} md={7}>
              <Box>
                <BannerSlider />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <WebFooter />
    </>
  );
}
