const titles = [
  {
    label: "Ms",
  },
  { label: "Mr" },
  { label: "Mrs" },
];

const MeetingStatus = [
  {
    label: "Pending",
  },
  { label: "Canceled" },
  { label: "Approved" },
  { label: "Completed" },
];

const sourceFunding = [
  { label: "Plan" },
  { label: "Self" },
  { label: "Ndis-Managed" },
];

const remainders = [
  { label: "Don't Remind" },
  { label: "At time of meeting" },
  { label: "5 minutes before" },
  { label: "10 minutes before" },
  { label: "30 minutes before" },
  { label: "1 hour before" },
  { label: "2 hours before" },
];

const remaindersWithId = [
  { label: "Don't Remind", id: 0 },
  { label: "At time of meeting", id: 1 },
  { label: "5 minutes before", id: 5 },
  { label: "10 minutes before", id: 10 },
  { label: "30 minutes before", id: 30 },
  { label: "1 hour before", id: 60 },
  { label: "2 hours before", id: 120 },
];

const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const MeetingStatusPop = [
  { label: "Pending", id: "Pending" },
  { label: "Complete", id: "Completed" },
  { label: "Cancel", id: "Canceled" },
];

const MeetingStatusPopClient = [
  { label: "Accept", id: "Accepted" },
  { label: "Reject", id: "Rejected" },
  { label: "Cancel", id: "Canceled" },
];

const MeetingStatusPopClientPending = [
  { label: "Pending", id: "Pending" },
  { label: "Accept", id: "Accepted" },
  { label: "Reject", id: "Rejected" },
];

const MeetingStatusPopClientAccept = [
  { label: "Accept", id: "Accepted" },
  { label: "Cancel", id: "Canceled" },
];

const complaintStatus = [
  { label: "Open", id: "Open" },
  { label: "Closed", id: "Closed" },
];

let leaveStatus = [
  {
    label: "Pending",
    id: "Pending",
    class: "select-style leave-select-pending",
  },
  {
    label: "Canceled",
    id: "Cancel",
    class: "select-style leave-select-cancelled",
  },
  {
    label: "Approved",
    id: "Approved",
    class: "select-style leave-select-approved",
  },
  {
    label: "Rejected",
    id: "Rejected",
    class: "select-style leave-select-reject",
  },
];

let leaveStatusEmployee = [
  {
    label: "Pending",
    id: "Pending",
    class: "select-style leave-select-pending",
  },
  {
    label: "Canceled",
    id: "Cancel",
    class: "select-style leave-select-cancelled",
  },
];

const TaxCalculationOption = [
  {
    label: "Australian resident for tax purposes",
    id: "Australian resident for tax purposes",
  },
  { label: "Claim tax free threshold", id: "Claim tax free threshold" },
  {
    label:
      "Is approved working holiday maker (and business is a registered employer of working holiday makers)",
    id: "Is approved working holiday maker (and business is a registered employer of working holiday makers)",
  },
  {
    label: "Claim seniors & pensioners tax offset",
    id: "Claim seniors & pensioners tax offset",
  },
  { label: "Has accumulated STSL debt", id: "Has accumulated STSL debt" },
  {
    label:
      "Is a seasonal worker (and business is an approved employer of the SWP)",
    id: "Is a seasonal worker (and business is an approved employer of the SWP)",
  },
];

const MeetingSelctionUserCommon = [
  { label: "All", id: "All" },
  { label: "Client", value: "meeting" },
  { label: "Health Carer", value: "interview" },
  { label: "Other", value: "other" },
];

const MeetingSelctionUserClient = [
  { label: "Client", value: "meeting" },
  { label: "Other", value: "other" },
];

const MeetingSelctionUserHealthCarer = [
  { label: "Health Carer", value: "interview" },
];

export {
  titles,
  remainders,
  MeetingStatus,
  weekdays,
  remaindersWithId,
  sourceFunding,
  MeetingStatusPop,
  MeetingStatusPopClient,
  MeetingStatusPopClientPending,
  MeetingStatusPopClientAccept,
  complaintStatus,
  leaveStatus,
  leaveStatusEmployee,
  TaxCalculationOption,
  MeetingSelctionUserCommon,
  MeetingSelctionUserClient,
  MeetingSelctionUserHealthCarer,
};
