import React from 'react';
import {
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const Twice = () => {

    const [showhide, setShowhide] = React.useState("");

    const handleshowhide = (event) => {
        const getuser = event.target.value;
        setShowhide(getuser);
    };


    return (
        <>
            {/* <Box className="appointment-calender-filed-select">
                                        <FormControl fullWidth className="slot-calender">
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="date-day-slot"
                                                value={showhide}
                                                size="small"
                                                onChange={(e) => handleshowhide(e)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                            >
                                                <MenuItem value={showhide} selected>
                                                    {" "}
                                                    Select Slot{" "}
                                                </MenuItem>
                                                <MenuItem value="1">Day</MenuItem>
                                                <MenuItem value="2">Week</MenuItem>
                                                <MenuItem value="3">Month</MenuItem>
                                                <MenuItem value="4">Custom</MenuItem>
                                            </Select>
                                        </FormControl>

                                       
                                    </Box>   */}

            <Box className="d-flex align-items-center">
                <Box className="required-time-box">
                    <TextField
                        sx={{ mt: 1, mb: 2 }}
                        className='form-input w-100'
                        type="time"
                        size="small"
                    />
                </Box>
                <Box className="required-time-box">
                    <TextField
                        sx={{ mt: 1, mb: 2 }}
                        className='form-input w-100'
                        type="time"
                        size="small"
                    />
                </Box>
            </Box>
        </>
    );
};

export default Twice;