import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../Web-Header";
import { Link } from "@mui/material";

import WebFooter from "../Web-footer";
import LogoSlider from "../HomeUI/Home-logo-slider";
import careManage1 from "../../assets/images/care-manage1.jpg";
import careManage2 from "../../assets/images/care-manage2.jpg";
import careManage3 from "../../assets/images/care-manage3.jpg";
import careManage4 from "../../assets/images/care-manage4.jpg";
import featureTick from "../../assets/images/feature-tick.png";
import orangeIcon from "../../assets/images/orange-star.png";
import yellowIcon from "../../assets/images/yellow-star.png";
import transformingImg from "../../assets/images/transforming-image.jpg";
import HomeTab from "../HomeUI/Home-tab";
import Homeclientslider from "../HomeUI/Home-client-slider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DiscoverSlider from "../HomeUI/Discover-slider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BookDemo from "../HomeUI/BookDemo";
import PricingTabs from "./pricing-tab";
import PricingTable from "./Pricing-Table";
import QuestionAccordion from "../HomeUI/Question-Accordian"


const options = {
  items: 1,
  loop: true,
  margin: 10,
  autoplay: false,
  nav: true,
  //   dots: true,

  responsive: {
    0: {
      items: 1,
      nav: false,
    },
    600: {
      items: 1,
      nav: true,
    },
    1000: {
      items: 1,
      nav: true,
    },
  },
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function Home() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // const [expanded, setExpanded] = React.useState(false);

  // const handleChange = (panel) => (event, isExpanded) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  const [isActive, setActive] = React.useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const [isActive2, setActive2] = React.useState(false);

  const toggleClass2 = () => {
    setActive2(!isActive2);
  };

  const [isActive3, setActive3] = React.useState(false);

  const toggleClass3 = () => {
    setActive3(!isActive3);
  };

  return (
    <>
      <Box id="PricingMain">
        <Box className="web-topBanner-bg  blog-main-banner">
          <WebHeader />

          <Box className="webContainer">
            <Box className="W-100 ">
              <Box className="home-content-main space-lg">
                <Box className="text-center">
                  <h1>
                    Choose Plan <br />
                    That’s Right For You
                  </h1>
                  <Typography>
                    All of our plans are customized to fit the needs of small
                    and large teams.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="blurBg blurRed"> </Box>
          {/* <Box className="blurBg blurPurple"> </Box> */}
          <Box className="blurBg blurYellow"></Box>
        </Box>
        {/* home banner end here  */}


        {/* pricing tabs section here  */}

        <Box className="webContainer space-pt-80" >
          <PricingTabs />
        </Box>


        {/* Compare All Features  */}

        <Box className="webContainer home-logo-slider space-pt-80">
          <Box className="compare-features">
            <Box className="text-center space-pb-40">
              <h2> Compare All Features </h2>
            </Box>

            <PricingTable />

          </Box>
        </Box>

        {/* <Box className="home-logo-slider">
          <LogoSlider />
        </Box> */}




        <Box className="question-accordian web-topBanner-bg space-lg">
          <Box className="webContainer">
            <Box className="text-center">
              <h1>Questions? We've got you covered.</h1>
            </Box>
            <Box className="space-pt-60">
              <QuestionAccordion />
            </Box>
          </Box>
        </Box>




        {/* book a demo section here  */}
        <Box className="webContainer space-pt-80">
          <BookDemo />
        </Box>
        <Box className="space-mt-80">
          <WebFooter />
        </Box>
      </Box>
    </>
  );
}
