import { Typography } from '@mui/material';
import '../appointment.css';
import React from 'react';
import { ReactDOM } from 'react';
import gallary1 from '../../../assets/images/gallary1.jpg'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

const slideWidth = 30;

const _items = [
    {
        player: {

            image: require('../../../assets/images/gallary1.jpg')
        },
    },
    {
        player: {

            image: require('../../../assets/images/gallary2.jpg')
        },
    },


];

const length = _items.length;
_items.push(..._items);

const sleep = (ms = 0) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

const createItem = (position, idx) => {
    const item = {
        styles: {
            transform: 'translateX(146rem)',
        },
        player: _items[idx].player,
    };

    switch (position) {

        case length:
            item.styles = { ...item.styles };
            break;
        case length:
            break;
        default:
            item.styles = { ...item.styles, opacity: 0 };
            break;
    }

    return item;
};

const CarouselSlideItem = ({ pos, idx, activeIdx }) => {
    const item = createItem(pos, idx, activeIdx);

    return (
        <li className="carousel__slide-item" style={item.styles}>
            <div className="carousel__slide-item-img-link">
                <img src={item.player.image} alt={item.player.title} />
            </div>

        </li>
    );
};

const keys = Array.from(Array(_items.length).keys());

const GallaryDialog = (props) => {
    const [items, setItems] = React.useState(keys);
    const [isTicking, setIsTicking] = React.useState(false);
    const [activeIdx, setActiveIdx] = React.useState(0);
    const bigLength = items.length;

    const prevClick = (jump = 1) => {
        if (!isTicking) {
            setIsTicking(true);
            setItems((prev) => {
                return prev.map((_, i) => prev[(i + jump) % bigLength]);
            });
        }
    };

    const nextClick = (jump = 1) => {
        if (!isTicking) {
            setIsTicking(true);
            setItems((prev) => {
                return prev.map(
                    (_, i) => prev[(i - jump + bigLength) % bigLength],
                );
            });
        }
    };



    React.useEffect(() => {
        if (isTicking) sleep(300).then(() => setIsTicking(false));
    }, [isTicking]);

    React.useEffect(() => {
        setActiveIdx((length - (items[0] % length)) % length) // prettier-ignore
    }, [items]);

    return (
        <div className="carousel__wrap">
            <div className="carousel__inner">
                <CloseIcon onClick={() => { props.handleCloseImg() }} className='modal-slider-close' />
                <div className='gallary-dialog-head d-flex align-items-center'>
                    <Typography>Support Image.jpg</Typography>
                    <div className='d-flex arrow-slide-box align-items-center justify-content-center'>
                        <button className="carousel__btn carousel__btn--prev" onClick={() =>
                            prevClick()}>
                            <ArrowBackIosIcon className='modal-slide-arrow' />

                        </button>
                        <div> <span>1</span> / <span>2</span> </div>
                        <button className="carousel__btn carousel__btn--next" onClick={() => nextClick()}>
                            <ArrowForwardIosIcon className='modal-slide-arrow' />
                        </button>
                    </div>
                </div>
                <div className="carousel__container">
                    <ul className="carousel__slide-list">
                        {items.map((pos, i) => (
                            <CarouselSlideItem
                                key={i}
                                idx={i}
                                pos={pos}
                                activeIdx={activeIdx}
                            />
                        ))}
                    </ul>
                </div>


            </div>
        </div>
    );
};

export default GallaryDialog;
