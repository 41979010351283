import React, { useState } from "react";
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import upload from '../../../assets/images/upload.svg';
import camera from '../../../assets/images/camera.svg';
import plusIcon from '../../../assets/images/plus-icon.svg';
import expandDown from '../../../assets/images/expand-down.svg';
import Form from "@mui/material/FormGroup";
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import HelpIcon from '@mui/icons-material/Help';
import popOverImg from '../../../assets/images/sidebar-profile.png';


const names = [
  'Pending',
  'Accepted',
  'Completed',
  'Started',
  'Rejected',
  'Change by Admin',
  'Change by SW',

];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function TaskPending() {

  const [taskShow, setTaskShow] = useState(false);

  const [isRotate, setIsRotate] = useState();

  const [active, setActive] = useState(false)

  const rotateChange = Event => {
    setIsRotate(current => !current)
  }

  const [personName, setPersonName] = React.useState([]);

  const handleChangeCheck = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  return (
    <Box className="taskBox pendingtaskbox">
      <Box className="appointboxheader d-flex align-items-center justify-content-between">
        <Typography class="taskTitle">Task 4: Normal Walk <span className="badgeTxt position- 
         relative">2
        <Box className="taskPopover">
                         <img src={popOverImg} />
                         <Typography> Allergic to tofu & sesame seeds. </Typography>
                     </Box>
        </span></Typography> <Typography className="d-flex align-items-center tasktime"> <AccessTimeIcon />  11:00 - 11.30</Typography>
      </Box>

      {taskShow && (
        <Box className="appointcontent">
          <Box className="attach-document">
            <h3>Attach Document</h3>
            <Box className="attach-document-body d-flex align-items-center">
              <Box className="d-flex align-items-center ">
                <input type="radio" name="attach-document" id="upload"></input>
                <img src={upload}></img>
                <label for="upload">Upload</label>
              </Box>
              <Box className="d-flex align-items-center" sx={{ pl: 2 }}>
                <input type="radio" name="attach-document" id="capture"></input>
                <img src={camera}></img>
                <label for="capture" >Capture</label>
              </Box>
            </Box>
          </Box>
          <Box className="uploaddoc">
            <Form className="d-flex  flex-column-res  attach-document-browse w-100 justify-content-between">
              <TextField id="outlined-basic" size="small" className="attach-file-input" type="file"></TextField>
              <Button>Upload</Button>
            </Form>
          </Box>

          <Box className="notes-section">
            <div className="note-title "><h3 className="d-flex align-items-center">Notes <Box className="helpIcon"> <HelpIcon className="helpsvg" />
              <Box className="note-popover">
                <Box>
                  <Typography>Add notes to keep everyone updated with required information.</Typography>

                </Box>
              </Box>
            </Box> </h3> </div>
            <div className="note-link"><a href="javascript:;" className="d-flex align-items-center "><img src={plusIcon}></img> Add Note <Box className="helpIcon"> <HelpIcon className="helpsvg" />
              <Box className="note-popover">
                <Box>
                  <Typography>Add notes to keep everyone updated with required information.</Typography>

                </Box>
              </Box>
            </Box>
            </a>

            </div>

          </Box>
        </Box>
      )}

      <Box className="appointbottom">
        <Box className="d-flex appointinr">
          <div className="select-wrap">
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              //multiple
              value={personName}
              className="w-100"
              size="small"
              onChange={handleChangeCheck}
              input={<OutlinedInput label="Tag" />}
              //renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
              
            >
              {names.map((name, index) => (
                <MenuItem key={name} onClick={() => setActive(index + 1)} className={`select-item-${active === index && '1'}`} value={name}>
                  <span className={`select-style select-box-${index + 1}`}></span>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>

          </div>
          <div className="btn-upsidebox">
            <div className="d-flex ">
              <Button variant="outlined">Follow Up</Button>
              <a href="javascript:;" className="appointExpand" onClick={() => { setTaskShow(!taskShow); rotateChange() }}><img src={expandDown} className={isRotate ? 'rotateTaskIcon' : ''} ></img></a>
            </div>
          </div>
        </Box>
      </Box>
    </Box>


  )
}

