import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import WebHeader from "../Web-Header";
import WebFooter from "../Web-footer";
import { Link } from "@mui/material";
import BookDemo from "../HomeUI/BookDemo";
import HelpTabs from "./Help-Tabs";
import SignUpDetails from "../../assets/images/signup-details.png";
import VerifyEmail from "../../assets/images/verify-email.png";
import AccountSetup from "../../assets/images/setup-account.png";
import HelpModel from "./Help-Model"
import { getHelpManagement } from "../../api";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import dashboardlogo from "../../assets/images/hubshift-logo.png";
const HelpCenterDetails = () => {

  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");

  const [data, setData] = useState();

  useEffect(() => {
    if (id !== null) {
      axios
        .get(`${getHelpManagement}/${id}`)
        .then((res) => {
          let dataval = res?.data?.data;
          setData(dataval);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id !== null]);

  return (
    <Box id="help-center-details">
      <Box className=" blog-main-banner">
        <Grid item xs={12} sm={12} md={12} className="input pt-0">
          <Box className="dashboard-logo" style={{ marginLeft: "80px" }}>
            <img src={dashboardlogo} alt="logo" />
          </Box>
          <hr></hr>
        </Grid>

        <Box className="help-details-wrapper">



          <Box className="help-center-details">
            <h2 className="space-pb-40 ">{data?.title}</h2>

            <Box>
              <Box className="guides-details-text ">
                <Typography className="space-pt-60">
                  <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                </Typography>
              </Box>
            </Box>


          </Box>



        </Box>



      </Box>


    </Box>
  );
};

export default HelpCenterDetails;
