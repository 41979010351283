import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import '../../assets/css/Appointment.css'
import '../Appointmentschedule/appointment.css';
import '../../assets/css/custom.css'
import '../../assets/css/dashboard.css';
import Sidebar from '../../Dashboard/Layout/Sidebar';
import Appbar from '../../Dashboard/Layout/Appbar';
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Button } from '@material-ui/core'

const Icons = () => (
    <div>
        <CloseIcon />

    </div>
);

export default function AddGroup() {

    const [select, setselect] = React.useState('');
    const [select1, setSelect1] = React.useState('');
    const [select2, setSelect2] = React.useState('');
    const [select3, setSelect3] = React.useState('');
    const [select4, setSelect4] = React.useState('');
    const [select5, setSelect5] = React.useState('');
    const [select6, setSelect6] = React.useState('');
    const [select7, setSelect7] = React.useState('');
    const [select8, setSelect8] = React.useState('');
    const [select9, setSelect9] = React.useState('');
    const [select10, setSelect10] = React.useState('');
    const [select11, setSelect11] = React.useState('');
    const [select12, setSelect12] = React.useState('');
    const [select13, setSelect13] = React.useState('');





    const handleSelect = (event) => {
        setselect(event.target.value);
    };
    const handleSelect1 = (event) => {
        setSelect1(event.target.value);
    };

    const handleSelect2 = (event) => {
        setSelect2(event.target.value);
    };


    const handleSelect3 = (event) => {
        setSelect3(event.target.value);
    };

    const handleSelect4 = (event) => {
        setSelect4(event.target.value);
    };

    const handleSelect5 = (event) => {
        setSelect5(event.target.value);
    };

    const handleSelect6 = (event) => {
        setSelect6(event.target.value);
    };

    const handleSelect7 = (event) => {
        setSelect7(event.target.value);
    };


    const handleSelect8 = (event) => {
        setSelect8(event.target.value);
    };

    const handleSelect9 = (event) => {
        setSelect9(event.target.value);
    };

    const handleSelect10 = (event) => {
        setSelect10(event.target.value);
    };

    const handleSelect11 = (event) => {
        setSelect11(event.target.value);
    };

    const handleSelect12 = (event) => {
        setSelect12(event.target.value);
    };

    const handleSelect13 = (event) => {
        setSelect13(event.target.value);
    };





    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };

    return (
        <>
            <Box className="dashboard-form">
                <Box sx={{ width: '100%' }} className='appbar-border'>
                    <Box className='appbar-main'>
                        <Appbar />
                    </Box>
                </Box>
                <Box id="addgroup" className="d-flex dashboard-main-box">
                    <Sidebar />
                    <Box className='main section-content'>

                        <Grid container className="d-flex header-topsection">
                            <Grid item xs={6} sm={6} md={6}>
                                <Box id="breadcrumb">
                                    <ArrowBackIosIcon />
                                    <Box className="breadcrumb-inr">
                                        <h4 className="d-flex align-items-center ">
                                            {" "}
                                            Add Group
                                        </h4>
                                        <Box className="breadcrumbs">
                                            <Link className="breadcrumbs-link">
                                                Masters
                                            </Link>{" "}
                                            <span className="breadcrumbs-icon">{">"}</span>
                                            <Link className="breadcrumbs-link">
                                                Groups List
                                            </Link>{" "}

                                            <span className="breadcrumbs-icon">{">"}</span>
                                            <Link className="breadcrumbs-link breadcrumbs-link-active">
                                                Add Group
                                            </Link>{" "}


                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Box className="client-btn add-btn-form-div text-end">
                                    <Link className="add-btn-form">


                                        <AddIcon />

                                        Add New

                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ mt: 3 }}>
                            <Grid item xs={12} sm={5} md={5}>
                                <label>Title <span className="danger">*</span> </label>
                                <TextField sx={{ mt: 2 }} id="outlined-basic" placeholder="Enter Title" size="small" className="w-100" />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}></Grid>

                            <Grid item xs={12} sm={12} sx={{ mt: 2 }} md={12}>

                                <label>Description </label>
                                <TextField
                                    placeholder="MultiLine with rows: 2 and rowsMax: 4"
                                    multiline
                                    fullWidth
                                    rows={3}
                                    sx={{ mt: 2 }}
                                    maxRows={6}
                                />
                            </Grid>

                            <Grid item xs={12} sx={{ mt: 2 }} sm={12} md={12}>
                                <label>User Type </label>

                                <FormGroup className="d-flex align-items-center w-100 justify-content-between flex-row">
                                    <FormControlLabel control={<Checkbox />} label="All" />
                                    <FormControlLabel control={<Checkbox />} label="Clients" />
                                    <FormControlLabel control={<Checkbox />} label="Health Carers" />
                                    <FormControlLabel control={<Checkbox />} label="Support Coordinators" />
                                    <FormControlLabel control={<Checkbox />} label="Service providers" />
                                    <FormControlLabel control={<Checkbox />} label="General Managers" />
                                    <FormControlLabel control={<Checkbox />} label="Supervisors" />
                                </FormGroup>
                            </Grid>

                        </Grid>

                        <Grid container className="justify-content-between">
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Clients</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select}
                                        onChange={handleSelect}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <label>NDIS Client</label>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                    sx={{ mt: 2 }}
                                    className="flex-row "
                                >
                                    <FormControlLabel value="female" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="male" control={<Radio />} label="No" />

                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Health Carers</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select1}
                                        onChange={handleSelect1}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Job Type</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select2}
                                        onChange={handleSelect2}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"

                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Job Type</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select3}
                                        onChange={handleSelect3}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                deleteIcon={<Icons />}
                                                className="select-chip"
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Level</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select4}
                                        onChange={handleSelect4}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}

                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Support Coordinators</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select5}
                                        onChange={handleSelect5}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Role</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select6}
                                        onChange={handleSelect6}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                deleteIcon={<Icons />}
                                                className="select-chip"
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Experience</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select7}
                                        onChange={handleSelect7}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                deleteIcon={<Icons />}
                                                className="select-chip"
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Level</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select8}
                                        onChange={handleSelect8}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Service Providers</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select9}
                                        onChange={handleSelect9}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Package</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select10}
                                        onChange={handleSelect10}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>General Manager</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select11}
                                        onChange={handleSelect11}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Supervisors</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select12}
                                        onChange={handleSelect12}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Zone</label>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={select13}
                                        onChange={handleSelect13}
                                        size="small"
                                        sx={{ mt: 2 }}
                                        className="group-chip-select"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="age" selected>
                                            <Chip
                                                label="Norman William"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            /></MenuItem>
                                        <MenuItem value={20}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                deleteIcon={<Icons />}
                                                className="select-chip"
                                            />
                                        </MenuItem>
                                        <MenuItem value={30}>

                                            <Chip
                                                label="Norsah Seldom"
                                                onClick={handleClick}
                                                onDelete={handleDelete}
                                                className="select-chip"
                                                deleteIcon={<Icons />}
                                            />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5} sx={{ mt: 2 }}>
                                <FormControl fullWidth>
                                    <label>Status</label>
                                    <FormControlLabel sx={{ mt: 2 }} control={<Checkbox defaultChecked />} label="Is Active?" />
                                </FormControl>
                            </Grid>
                        </Grid>


                        <Box className="agency-btn-section justify-content-center d-flex align-items-center agency-btn-section-vk">
                            <Link href="javascript:;" className="add-appoint-cancel-btn">Cancel</Link>
                            <Button type="submit" className="saveBtn">Save</Button>
                            {/* <Link type="submit" >Save</Link> */}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )

}










