import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import '../../assets/css/Appointment.css'
import '../Appointmentschedule/appointment.css';
import '../../assets/css/dashboard.css';
import '../../assets/css/custom.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import tableCall from '../../assets/images/table-call.png';
import tableCheck from '../../assets/images/table-check.png';
import RoutineSubBox from '../Appointmentschedule/Routine-Cell';
import RoutineCell from '../Appointmentschedule/Routine-Cell';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dropdown from 'react-bootstrap/Dropdown';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import deleteIcon from '../../assets/images/Delete.png';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import filterImg from '../../assets/images/filter.png';
import searchImg from '../../assets/images/search.svg';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import GetAppIcon from "@mui/icons-material/GetApp";
import SearchIcon from "@mui/icons-material/Search";
import Sidebar from '../../Dashboard/Layout/Sidebar';
import Appbar from '../../Dashboard/Layout/Appbar';
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Pagination from "../../components/ui/Pagination"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

import active from '../../assets/images/active.png';
import inactive from '../../assets/images/inactive.png';







const statusActive = (
    <Box className="table-icon">
        <img src={active}></img>
        <Box className="table-icon-popover d-flex align-items-center justify-content-center">
            <img src={active}></img> <Typography>On Call</Typography>
            <ArrowDropDownIcon className="team-popover-arrow" />
        </Box>
    </Box>
)

const statusInactive = (
    <Box className="table-icon">
        <img src={inactive}></img>
        <Box className="table-icon-popover d-flex align-items-center justify-content-center">
            <img src={inactive}></img> <Typography>On Call</Typography>
            <ArrowDropDownIcon className="team-popover-arrow" />
        </Box>
    </Box>
)






const sampleData = [
    {
        id: 0,
        Sr: "1",
        Title: "Group 1",
        Description: " Lorem Ipsum dolor sit amet",
        UserType: "All",
        Users: "500",
        Zones: "All",
        Created: "22-06-2023, 14:06",
        Updated: "-",
        Status: statusActive,
    },
    {
        id: 1,
        Sr: "2",
        Title: "Group 2",
        Description: "Lorem Ipsum dolor sit amet",
        UserType: "Health Carers, Support Coordinators",
        Users: "200",
        Zones: "Zone 1, Zone 2",
        Created: "22-06-2023, 11:06",
        Updated: "22-06-2023, 11:58",
        Status: statusInactive,
    },



],
    sampleColumns = [
        { id: 0, property: 'Sr', columnLabel: 'Sr' },
        { id: 1, property: 'Title', columnLabel: 'Title' },
        { id: 2, property: 'Description', columnLabel: 'Description' },
        { id: 3, property: 'UserType', columnLabel: 'User Types' },
        { id: 4, property: 'Users', columnLabel: 'Users' },
        { id: 5, property: 'Zones', columnLabel: 'Zones' },
        { id: 6, property: 'Created', columnLabel: 'Created At' },
        { id: 7, property: 'Updated', columnLabel: 'Updated At' },
        { id: 8, property: 'Status', columnLabel: 'Status' },
        { id: 9, property: 'RoutineCell', columnLabel: 'Add Routine' },






    ]

const filterSearch = [
    { label: 'All' },
    { label: 'Core' },
    { label: 'Capacity Building' }

]




const MyTable = ({ tableData, tableColumns, onFilter }) => {

    const [show, setShow] = React.useState();
    const [search, setSearch] = useState('');

    const [routine, setRoutine] = useState(true);

    const [searchTermPending, setSearchTermPending] = useState("");
    const searchItemsPending = (searchValue) => {
        setSearchTermPending(searchValue);
    };

    const handleRoutine = event => {
        // 👇️ toggle isActive state on click
        setRoutine(current => !current);
    };

    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };


    const [isActive2, setIsActive2] = useState(false);

    const handleClick = event => {

        setIsActive2(current => !current);
    };

    const [signIn, setSignIn] = useState(false);
    const [showSwap, setShowSwap] = React.useState(true);
    const handleSwap = () => setShowSwap(false);

    // function to toggle the boolean value
    function toggleShow() {
        setShow(!show);
    }

    return (
        <Box id="appointment-table">
            <Box sx={{ width: '100%' }} className='appbar-border'>
                <Box className='appbar-main'>
                    <Appbar />
                </Box>
            </Box>

            <Box id="GroupList" className="d-flex dashboard-main-box">
                <Sidebar />
                <Box className="main">
                    <Grid container className="d-flex dashboard-form-typo-up header-topsection">
                        <Grid item xs={6} sm={6} md={6}>
                            <Box id="breadcrumb">
                                <ArrowBackIosIcon />
                                <Box className="breadcrumb-inr">
                                    <h4 className="d-flex align-items-center ">
                                        {" "}
                                        Groups List
                                    </h4>
                                    <Box className="breadcrumbs">
                                        <Link className="breadcrumbs-link">
                                            Masters
                                        </Link>{" "}
                                        <span className="breadcrumbs-icon">{">"}</span>
                                        <Link className="breadcrumbs-link breadcrumbs-link-active">
                                            Groups List
                                        </Link>{" "}


                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Box className=" add-btn-form-div text-end">
                                <Link className="add-btn-form">


                                    <AddIcon />

                                    Add New

                                </Link>
                            </Box>
                        </Grid>
                    </Grid>

                    <Box className="agency-searchbar group-list-search-main searchbox-margin ">
                        <Grid container>

                            <Grid xs={5} sm={5} md={5}>
                                <TextField
                                    className="doc-search"
                                    type="search"
                                    size="small"
                                    autoComplete="off"
                                    fullWidth
                                    value={searchTermPending}
                                    placeholder="Search By Name, Email, Phone"
                                    onChange={(e) => searchItemsPending(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                className="input-icon"
                                                position="start"
                                            >
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid xs={6} sm={6} md={6}></Grid>

                        </Grid>
                    </Box>

                    <TableContainer component={Paper} id='document-table' className="section-content padding-res-10">
                        <Table sx={{ minWidth: 650 }} aria-label="caption table">
                            <TableHead id="client-table-head">
                                <TableRow>
                                    {
                                        tableColumns.map(({ id, columnLabel, property }) => (
                                            <TableCell key={id} className="tableHeader table-head-cell filter-search-field  table-head-cell tableborder">
                                                <Box className="d-flex align-items-center">
                                                    {columnLabel}

                                                    <Dropdown drop="start" autoClose="outside">
                                                        <Dropdown.Toggle id="dropdown-autoclose-filter" >
                                                            <img className="fliter-img" id="filterImg" src={filterImg} />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="dropdown-menu client-drop-menu">
                                                            <Dropdown.Item className='table-edit-del' >  <a href=''
                                                                id="domain1"
                                                                label="Domain"
                                                                className=" justify-content-start d-flex client-table-btn btn"
                                                                margin="normal"
                                                            >
                                                                <InputGroup id="searchFilter" className='my-3 is-hidden d-flex align-items-center'>
                                                                    <img src={searchImg} className="filter-search-icon" />
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        id="combo-box-demo"
                                                                        size="small"

                                                                        options={filterSearch}
                                                                        sx={{ width: '200px' }}
                                                                        renderInput={(params) => <TextField {...params} placeholder={`Search ${property}`} className="tableSearchField" key={id}

                                                                            name={property}
                                                                            onKeyUp={onFilter} />}
                                                                    />
                                                                </InputGroup>
                                                            </a></Dropdown.Item>



                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </Box>

                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody id="client-table-body">
                                {tableData.filter((row) => {
                                    return search.toLowerCase() === ''
                                        ? row
                                        : row.Item.toLowerCase().includes(search);
                                })
                                    .map((row, index) => (
                                        <TableRow key={row.id}>
                                            <TableCell className="appointment-cell tableborder" >{row.Sr}</TableCell>
                                            <TableCell className="appointment-cell tableborder">{row.Title}</TableCell>
                                            <TableCell className="appointment-cell-item tableborder">{row.Description}</TableCell>

                                            <TableCell className="appointment-cell tableborder">{row.UserType}</TableCell>
                                            <TableCell className="appointment-cell tableborder">{row.Users}</TableCell>
                                            <TableCell className="appointment-cell tableborder">{row.Zones}</TableCell>
                                            <TableCell className="appointment-cell tableborder">{row.Created} </TableCell>


                                            <TableCell className="appointment-cell tableborder">
                                                {row.Updated}
                                            </TableCell>

                                            <TableCell className="appointment-cell tableborder">{row.Status}</TableCell>






                                            <TableCell align="left" className='tableborder table-editcell'>

                                                <Box className='table-edit-del'>
                                                    <a href='javascript:;'
                                                        id="domain1"
                                                        label="Domain"
                                                        className="table-edit justify-content-start d-flex align-items-center client-table-btn btn"
                                                        margin="normal"
                                                    >
                                                        <BorderColorIcon /> Edit
                                                    </a>
                                                </Box>

                                                <Dropdown drop="start" autoClose="outside">
                                                    <Dropdown.Toggle id="dropdown-autoclose-outside" className="action-toggle-button">
                                                        <MoreVertIcon className='dot-icon' onClick={toggleClass} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className="dropdown-menu   client-drop-menu">

                                                        <Dropdown.Item className='table-edit-del w-100' >  <a href='javascript:;'
                                                            id="domain1"
                                                            label="Domain"
                                                            className="table-edit justify-content-start d-flex align-items-center client-table-btn btn"
                                                            margin="normal"
                                                        >
                                                            <BorderColorIcon /> Edit
                                                        </a></Dropdown.Item>
                                                        <Dropdown.Item className='table-edit-del w-100' >
                                                            <a href='javascript:;'
                                                                id="domain2"
                                                                label="Domain"
                                                                className="table-del justify-content-start d-flex client-table-btn  btn"
                                                                margin="normal"
                                                            >
                                                                <Box className="view-icon"><img src={deleteIcon} /></Box>  <Typography className="danger">Delete</Typography>
                                                            </a>

                                                        </Dropdown.Item>


                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ mt: 2, pl: 2, pe: 2 }} className="d-flex align-content-center pagination-div justify-content-between">
                        <h1 className="pagination-record">Total Records : 46</h1>
                        <Pagination />
                    </Box>
                </Box>
            </Box>


        </Box>
    )



}

export default function Demo() {



    const [state, setState] = useState({
        data: sampleData,
        columns: sampleColumns,
        filterObj: sampleColumns.reduce((r, { property }) => (r[property] = '', r), {})
    }),
        onFilterApply = ({ target: { name, value } }) => {
            const newFilterObj = { ...state.filterObj, [name]: value }
            setState({
                ...state,
                filterObj: newFilterObj,
                data: sampleData.filter(props =>
                    Object
                        .entries(newFilterObj)
                        .every(([key, val]) =>
                            !val.length ||
                            props[key].toLowerCase().includes(val.toLowerCase()))
                )
            })
        }
    return (
        <>

            <MyTable
                tableData={state.data}
                tableColumns={state.columns}
                onFilter={onFilterApply}
            />
        </>
    )
}

