import React from 'react';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Pdf from "../../../assets/images/pdf.svg";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

const TimesheetExpenses = () => {


    const [routine, setRoutine] = React.useState(true);


    const handleRoutine = event => {
        // 👇️ toggle isActive state on click
        setRoutine(current => !current);
    };
    const [isActive2, setIsActive2] = React.useState(false);

    const handleClick = event => {

        setIsActive2(current => !current);
    };

    const [signIn, setSignIn] = React.useState(false);

    return (
        <div>
            <Box id="timesheet-expnses" className={routine ? 'routine-overflow' : 'routine-overflow-hide'} >

                <Table id="table-exp">
                    <TableBody >
                        <TableRow>
                            <TableCell className='tableborder table-head-cell' align="left"><a href="#javascript:;">Grocery <br /> Bought</a></TableCell>
                            <TableCell className='tableborder table-head-cell' sx={{ width: "100px" }} align="left"> <a href="#javascript:;">16 </a></TableCell>
                            <TableCell className='tableborder table-head-cell' align="left"> <img className="pdf-icon" src={Pdf}></img> </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell className='tableborder table-head-cell' align="left"><a href="#javascript:;">Grocery <br /> Bought</a></TableCell>
                            <TableCell className='tableborder table-head-cell' align="left"> <a href="#javascript:;">16 </a></TableCell>
                            <TableCell className='tableborder table-head-cell' align="left"> <img className="pdf-icon" src={Pdf}></img> </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='tableborder table-head-cell' align="left"><a href="#javascript:;">Grocery <br /> Bought</a></TableCell>
                            <TableCell className='tableborder table-head-cell' align="left"> <a href="#javascript:;">16 </a></TableCell>
                            <TableCell className='tableborder table-head-cell' align="left"> <img className="pdf-icon" src={Pdf}></img> </TableCell>

                        </TableRow>
                        <TableRow>
                            <TableCell className='tableborder table-head-cell' align="left"><a href="#javascript:;">Grocery <br /> Bought</a></TableCell>
                            <TableCell className='tableborder table-head-cell' align="left"> <a href="#javascript:;">16 </a></TableCell>
                            <TableCell className='tableborder table-head-cell' align="left"> <img className="pdf-icon" src={Pdf}></img> </TableCell>

                        </TableRow>








                    </TableBody>
                </Table >
            </Box>
            <span className="view-all" onClick={() => { handleRoutine(); setSignIn(true) }} > <span className='less'>View Less <KeyboardDoubleArrowUpIcon /></span> <span className='viewall'>View All <KeyboardDoubleArrowDownIcon /></span>  </span>




            <Link className="expenses-add" to='/add-expenses'> <AddCircleIcon />  Add Expenses</Link>
        </div>
    );
};

export default TimesheetExpenses;   