import { Flex } from "@chakra-ui/react";
// import dynamic from "next/dynamic";
import React, { useState, lazy, Suspense } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MyStore from "../../../pages/healthCarer/availability/MyStore";
import { weekdays } from "../../../helpers/staticDropdwonData";
import { toast } from "react-toastify";

const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) =>
    `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${index % 2 === 0 ? "00" : "30"
    }`
);

const Column = lazy(() => import("../../../components/form/Shifttype/NextWeekCreate"));

// const Column = dynamic(
//   () => import("../../../components/form/Shifttype/NextWeekCreate"),
//   { ssr: true }
// );

const reorderColumnList = (sourceCol, startIndex, endIndex) => {
  const newTaskIds = Array.from(sourceCol.taskIds);
  const [removed] = newTaskIds.splice(startIndex, 1);
  newTaskIds.splice(endIndex, 0, removed);

  const newColumn = {
    ...sourceCol,
    taskIds: newTaskIds,
  };

  return newColumn;
};

export default function NextWeek(props) {
  const [state, setState] = useState(initialData);
  const { NextWeekly, setField, shifttypes } = MyStore();
  const onDragEnd = (result) => {
    const { destination, source } = result;
    console.log(destination, source);

    const res = shifttypes.find((val) => val.id === props.selectedShift.id);

    if ((res.name !== undefined && res.name) === "Sleepover") {
      toast.error(
        "Sorry, drag and drop is not available for the Sleepover shift."
      );
      return;
    }

    // If user tries to drop in an unknown destination
    if (!destination) return;

    // if the user drags and drops back in the same position
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    //move from to
    const fromArr =
      NextWeekly[props.selectedShift.id][weekdays[source.droppableId]][
      source.index
      ];

    if (
      NextWeekly[props.selectedShift.id][weekdays[destination.droppableId]][
      destination.index
      ] === undefined
    ) {
      NextWeekly[props.selectedShift.id][
        weekdays[destination.droppableId]
      ].push(fromArr);

      const updatedItems = NextWeekly[props.selectedShift.id][
        weekdays[source.droppableId]
      ].filter((item, index) => {
        return index !== source.index;
      });

      NextWeekly[props.selectedShift.id][weekdays[source.droppableId]] =
        updatedItems;
    } else {
      const destIndexItem =
        NextWeekly[props.selectedShift.id][weekdays[destination.droppableId]][
        destination.index
        ];

      //push the object
      NextWeekly[props.selectedShift.id][
        weekdays[destination.droppableId]
      ].push(destIndexItem);

      //override the destination item
      NextWeekly[props.selectedShift.id][weekdays[destination.droppableId]][
        destination.index
      ] = fromArr;

      //remove the source object
      const updatedItems = NextWeekly[props.selectedShift.id][
        weekdays[source.droppableId]
      ].filter((item, index) => {
        return index !== source.index;
      });

      NextWeekly[props.selectedShift.id][weekdays[source.droppableId]] =
        updatedItems;
    }
    setField("NextWeekly", NextWeekly);
  };

  return (
    <Box className="dashboard-form" sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ mt: 5 }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Flex
              flexDir="column"
              bg="main-bg"
              w="full"
              color="white-text"
              pb="2rem"
            >
              <Flex justify="space-between">
                {state.columnOrder.map((columnId) => {
                  const column = state.columns[columnId];
                  const tasks = column.taskIds.map(
                    (taskId) => state.tasks[taskId]
                  );

                  return (
                    <Suspense fallback={<div>Loading...</div>}>
                      <Column
                        id="darg-column"
                        key={column.id}
                        column={column}
                        tasks={tasks}
                        activeDay={activeDay}
                        inactiveday={inactiveday}
                        shift={props}
                      />
                    </Suspense>
                  );
                })}
              </Flex>
            </Flex>
          </DragDropContext>
        </Box>
      </Box>
    </Box>
  );
}

//logic for check active/inactive day in the week.
const currentDate = new Date();
const currentDay = currentDate.getDay(); // Returns the current day as a number (0-6)

const activeDay = [];
const inactiveday = [];

weekdays.map((weekday, index) => {
  if (index >= currentDay - 1) {
    activeDay.push(weekday);
  } else {
    inactiveday.push(weekday);
  }
});

const initialData = {
  tasks: {
    1: {
      id: 1,
      content: (
        <Box className="item">
          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Start at" />}
          />

          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="End at" />}
          />
        </Box>
      ),
    },
    2: {
      id: 2,
      content: (
        <Box className="item">
          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Start at" />}
          />

          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="End at" />}
          />
        </Box>
      ),
    },
    3: {
      id: 3,
      content: (
        <Box className="item">
          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Start at" />}
          />

          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="End at" />}
          />
        </Box>
      ),
    },
    4: {
      id: 4,
      content: (
        <Box className="item">
          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Start at" />}
          />

          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="End at" />}
          />
        </Box>
      ),
    },
    5: {
      id: 5,
      content: (
        <Box className="item">
          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Start at" />}
          />

          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="End at" />}
          />
        </Box>
      ),
    },
    6: {
      id: 6,
      content: (
        <Box className="item">
          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Start at" />}
          />

          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="End at" />}
          />
        </Box>
      ),
    },
    7: {
      id: 7,
      content: (
        <Box className="item">
          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Start at" />}
          />

          <Autocomplete
            id="disabled-options-demo"
            className="time-filed"
            options={timeSlots}
            getOptionDisabled={(option) =>
              option === timeSlots[0] || option === timeSlots[2]
            }
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="End at" />}
          />
        </Box>
      ),
    },
  },

  columns: {
    0: {
      id: "0",
      title: (
        <Box className={`weak-name weak-warning-light`}>
          <Typography>{weekdays[0]}</Typography>
        </Box>
      ),
      taskIds: [1],
    },
    1: {
      id: "1",
      title: (
        <Box className={`weak-name weak-dark-primary`}>
          <Typography>Tue</Typography>
        </Box>
      ),
      taskIds: [2],
    },
    2: {
      id: "2",
      title: (
        <Box className={`weak-name weak-success`}>
          <Typography>Wed</Typography>
        </Box>
      ),
      taskIds: [3],
    },
    3: {
      id: "3",
      title: (
        <Box className={`weak-name weak-warning`}>
          <Typography>Thu</Typography>
        </Box>
      ),
      taskIds: [4],
    },
    4: {
      id: "4",
      title: (
        <Box className={`weak-name weak-success-dark`}>
          <Typography>Fri</Typography>
        </Box>
      ),
      taskIds: [5],
    },
    5: {
      id: "5",
      title: (
        <Box className={`weak-name weak-danger`}>
          <Typography>Sat</Typography>
        </Box>
      ),
      taskIds: [6],
    },
    6: {
      id: "6",
      title: (
        <Box className={`weak-name weak-warning-light`}>
          <Typography>Sun</Typography>
        </Box>
      ),
      taskIds: [7],
    },
  },

  // Facilitate reordering of the columns
  columnOrder: ["0", "1", "2", "3", "4", "5", "6"],
};
