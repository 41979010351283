import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./custome.css";
import refreshAccessToken from "../helpers/refreshTokanHelper";
import { fetchLoginString } from "../helpers/commonHelper";
import Session from "../assets/images/session.svg";
import { Box, Typography, Stack } from "@mui/material";

const CheckSession = async () => {
  const userData = localStorage.getItem("userData");

  const routePaths = [
    "/",
    "^/reset-password/[^/]+$",
    "/sil-management",
    "/roster-management",
    "/staff-induction",
    "/document-passport",
    "/health-dashboard",
    "/blogs",
    "/blog-details",
    "/delete-account",
    "/blog-details-old",
    "/guides",
    "/guide-details",
    "/book-demo",
    "/contact",
    "/privacy-policy",
    "/privacy-policy-page",
    "/terms",
    "/terms-condition",
    "/about",
    "/help-center",
    "/help-center-details",
    "/pricing",
    "/landing-page",
    "/sign-in",
    "/sign-up",
    "/healthcarer-sign-up",
    "/verify-otp",
    "/forgot-password",
    "/email-verify-success",
    "/client/explore-onboard",
    "/existing-user",
    "/health-carer-login",
    "/get-support",
    "/pickup-shifts",
    "/view-details",
    "/pickup-shifts/view-details",
    "/pickup-shifts/view-details-sp",
  ];

  const currUrlpathname = window.location.pathname;

  const userObject = userData ? JSON.parse(userData) : "";

  const checkLoginStringSession = userObject?.checkLoginString;
  const checkLoginString = await fetchLoginString(userObject?._id);

  const isRouteAllowed = routePaths.some((path) =>
    new RegExp(path).test(currUrlpathname)
  );

  // ------------------------------------------------------------------------
  // Matin this below line is for you
  //  if ((checkLoginString || isRouteAllowed) && checkLoginStringSession === checkLoginString) {
  // ------------------------------------------------------------------------

  if (checkLoginString || isRouteAllowed) {
    if (userObject?.token) {
      const { exp } = jwt_decode(userObject.token);
      const expirationTime = exp * 1000 - 60000;

      if (Date.now() >= expirationTime) {
        if (refreshAccessToken()) {
          return true;
        } else {
          localStorage.removeItem("userData");
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui pad">
                  <Box className="agency-btn-section agency-btn-section-vk">
                    <Box>
                      <Box>
                        <div className="d-flex align-items-center justify-content-center">
                          &nbsp; &nbsp;{" "}
                          <img src={Session} alt="sessionexprire" />
                        </div>

                        <Box className="d-flex align-items-center justify-content-center">
                          <Stack mt={1} spacing={1}>
                            <Typography
                              sx={{
                                fontWeight: "600 !important",
                                textAlign: "center !important",
                              }}
                            >
                              &nbsp; &nbsp; Your session is expired
                            </Typography>

                            <Typography
                              sx={{
                                color: "gray !important",
                                textAlign: "center !important",
                              }}
                            >
                              &nbsp; &nbsp; Please Sign in again to continue
                            </Typography>
                          </Stack>
                        </Box>

                        <div>
                          <Box className="agency-btn-section agency-btn-section-vk">
                            <Stack
                              justifyContent="center"
                              alignItems="center"
                              direction="row"
                              spacing={0}
                            >
                              <a href="/">Cancel</a>

                              <button
                                className="email-btn"
                                onClick={() =>
                                  (window.location.href = "/sign-in")
                                }
                              >
                                Sign in
                              </button>
                            </Stack>
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </div>
              );
            },
          });
        }
      }
    }
  } else {
    localStorage.removeItem("userData");
    localStorage.removeItem("clientId");
    window.location.assign("/sign-in");
  }
};

export default CheckSession;
