import React, { useState } from "react";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import "../../assets/css/dashboard.css";
import "../../assets/css/Appointment.css";
import Appbar from "../../Dashboard/Layout/Appbar";
import Sidebar from "../../Dashboard/Layout/Sidebar";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { Button } from "@mui/material";
import Form from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";


import remove from "../../assets/images/remove-menu.svg";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function AppointmentDashboard() {
  const [view, setView] = useState(false);

  const toggleView = () => {
    setView(!view);
  };

  return (
    <Box className="dashboard-form" id="Menu">
      <Box
        sx={{ width: "100%" }}
        className="appbar- 
       border"
      >
        <Box className="appbar-main">
          <Appbar />
        </Box>
      </Box>
      <Box className=" d-flex dashboard-main-box">
        <Sidebar />
        <Box className="main menu-main">
          <Grid container className="d-flex header-topsection">
            <Grid item xs={9} md={9} sm={9}>
              <Box id="breadcrumb">
                <ArrowBackIosIcon />
                <Box className="breadcrumb-inr">
                  <h4 className="d-flex align-items-center "> Masters</h4>
                  <Box className="breadcrumbs">
                   
                    <Link className="breadcrumbs-link">
                    Masters
                    </Link>
                    <span className="breadcrumbs-icon">{">"}</span>
                    <Link className="breadcrumbs-link breadcrumbs-link-active">
                      Menu
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} md={3} sm={3}>
              <Typography>
                <span>Appointment Date</span>: 12-02-2023, 11:00 - 12:00
              </Typography>
            </Grid>
          </Grid>

          <Box className="w-100 menu-filed-box">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={6} className="pt-0">
                <label>Name of Menu</label>
                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  className="form-input w-100"
                  type="Name"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="pt-0">
                <label>URL</label>
                <TextField
                  sx={{ mt: 2, mb: 2 }}
                  className="form-input w-100"
                  type="Name"
                  size="small"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} className="pt-0">
                <Box className="d-flex w-100 align-items-center">
                  <Form className=" list-attach-document-browse w-100 ">
                    <label>Menu Icon</label>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      className="attach-file-input"
                      type="file"
                      sx={{ mt: 2, mb: 2 }}
                    ></TextField>
                  </Form>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={6} className="pt-0">
                <Box className="checkbox-padding">
                  <FormControlLabel
                    sx={{ mt: 4 }}
                    control={<Checkbox />}
                    label="Containes Submenu"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className="menu-table">
            <TableContainer
              component={Paper}
              id="document-table"
              sx={{ mt: 0 }}
              className="agency- 
                     table document-table menu-table client-management-table"
            >
              <Table aria-label="simple table">
                <TableHead id="client-table-head">
                  <TableRow className="menu-table-row">
                    <TableCell
                      className="tableborder table-head-cell w-small"
                      align="left"
                    >
                      Sr. No.
                    </TableCell>

                    <TableCell
                      className="tableborder table-head-cell"
                      align="left"
                    >
                      Submenu
                    </TableCell>

                    <TableCell
                      className="tableborder table-head-cell"
                      align="left"
                    >
                      URL
                    </TableCell>

                    <TableCell
                      className="tableborder table-head-cell"
                      align="left"
                    >
                      Icon
                    </TableCell>

                    <TableCell
                      className="tableborder table-head-cell w-small"
                      align="left"
                    >
                      <AddBoxIcon className="menu-table-addicon" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody id="client-table-body">
                  <TableRow>
                    <TableCell
                      className="tableborder table-head-cell w-small"
                      align="left"
                    >
                      1
                    </TableCell>

                    <TableCell
                      className="tableborder table-head-cell"
                      align="left"
                    >
                      <TextField
                        sx={{ mt: 2, mb: 2 }}
                        className="form-input w-100"
                        type="Name"
                        placeholder="Enter Submenu"
                        size="small"
                      />
                    </TableCell>
                    <TableCell
                      className="tableborder table-head-cell"
                      align="left"
                    >
                      <TextField
                        sx={{ mt: 2, mb: 2 }}
                        className="form-input w-100"
                        type="Name"
                        placeholder="Enter URL"
                        size="small"
                      />
                    </TableCell>
                    <TableCell
                      className="tableborder table-head-cell"
                      align="left"
                    >
                      <Button className="menu-browse">Browse</Button>
                    </TableCell>
                    <TableCell
                      className="tableborder table-head-cell w-small"
                      align="left"
                    >
                      <img src={remove} className="menu-remove" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Box className="agency-btn-section add-expenses-btn">
              <Link href="javascript:;">Cancel</Link>
              <Link href="javascript:;" to="/timesheet">
                Save
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
